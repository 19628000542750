<script setup>

// https://vue-final-modal.org/examples/liveDemo : 참고
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, onMounted, computed } from 'vue'
// import { Modal } from 'bootstrap'
import { VueFinalModal } from 'vue-final-modal'

const props = defineProps(['state', 'title', 'contents', 'width', 'height', 'drag'])
const emit = defineEmits(['confirm', 'cancel'])

const { t } = useI18n()

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if((props.width || '') != '') styles['width'] = props.width
    if((props.height || '') != '') styles['height'] = props.height
    
    return styles
})
// 헤더 상태
let modalHeaderState = computed(() => {
    switch (props.state) {
        case 'info':
            return t('STATUS.STATUS0002')
        case 'warning':
            return t('STATUS.STATUS0003')
        case 'error':
            return t('STATUS.STATUS0004')
        case 'none' :
            return ''
        default:
            return t('STATUS.STATUS0001')
    }
})

function onClick() {
    emit('confirm', true)
}

</script>

<template>
    <!-- First modal -->
    <vue-final-modal :escToClose="true"
        class="flex justify-center items-center modal-container"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
            
        :drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="border-bottom">
            <span class="modal__title">{{ modalHeaderState }}{{ props.title }}</span>
            <button class="btn-close modal__close" @click="onClick()"></button>
        </div>
        <div class="modal__content" :style="modalSize">
            {{ t(contents) }}
        </div>
        <div class="modal__action">
            <!-- <button type="button" class="btn btn-primary" @click="$emit('cancel', close)">{{ t('BUTTON.BUTTON0001')}}</button> -->
            <button class="btn btn-primary" @click="onClick()">{{ t('BUTTON.BUTTON0001') }}</button>
        </div>
    </vue-final-modal>
</template>

<style >
.flex {
    display: grid !important;
}
.vfm--inset {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
}
.vfm--fixed {
    position: fixed !important;
}
.justify-center {
    justify-content: center !important;
}
.items-center {
    align-items: center !important;
}
/*
*, :after, :before {
    border: 0 solid #e5e7eb !important;
    box-sizing: border-box !important;
}
*/
</style>
<style >


.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.modal__close {
    top: 0.5rem;
    right: 0.5rem;
}

</style>
<!-- 
<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style> -->