<script setup>
import { ref, onMounted, computed, defineProps } from 'vue'
import { useStore } from "vuex";
const store = useStore()

const props = defineProps(['opened'])

let currentMessage = computed(() => {
    if(store.state.MQ.messages.length == 0) {
        return ''
    } else {
        debugger
        return store.state.MQ.messages[store.state.MQ.messages.length-1].message
    }
})

let opened = ref(false)
let isNavbarOpen = computed(() => {
    return opened.value
})
function toggleNavbar() {
    opened.value = !opened.value
}

</script>

<template>
    <div id="statusbar" :class="{navbaropen: opened, sidebarclose: !opened}" class="statusbar">
      	<div :class="{ messageopen: opened, messageclose: !opened }">
            <div>{{ currentMessage }}</div>
          </div>
        <button @click="toggleNavbar" style="width: 26px; height: 26px;line-height: normal;position: absolute; top: 2px; right: 3px;"><font-awesome-icon icon="arrow-up" font-size="9px" /></button>
    </div>
</template>


<style scoped>
.statusbar {
    position: relative;
    padding-right: 35px !important;
  }
  
  
  .statusbar.navbaropen {
    margin-top: -150px;
    height: calc(34.1vh - 150px) !important;
  }
  
  .statusbar.navbaropen .statusbar__button {
    transform: translateY(-100%);
  }
  
 
  
  .statusbar.sidebarclose {
    margin-top: 0;
    height: 30px;
  }
  
  .messageopen {
    overflow-y: auto;
    height: calc(34.1vh - 150px);
    padding-right: 8px;
  }

  .messageclose {
    overflow-y: hidden;
    height: 30px;
  }

</style>