<script setup>
import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import pq from 'pqgrid';
import { VueFinalModal } from 'vue-final-modal'
import * as commonLogic from '@/views/template/commonLogic/commonLogic.js';

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'popupSize', 'grid', 'componentList', 'targetREFCODEList', 'width', 'height', 'api', 'args', 'refcodeList'])
const emit = defineEmits(['confirm', 'cancel'])
const modalRef = ref(null)

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if ((props.width || '') != '') styles['width'] = props.width
    if ((props.height || '') != '') styles['height'] = props.height

    return styles
})

const popGrid01 = ref()
const grid_arr = {
    popGrid01: popGrid01
}

const popGrid01Button = ref([])
const gridRefButtons = {
    popGrid01Button: popGrid01Button,
}

let search = {}
let searchCompRef = ref([])
let grids = {
}
const popGrid01Search = ref([])
const gridRefSearch = {
    popGrid01Search: popGrid01Search,
}

let grid_keys = []
commonLogic.BaseInit(t, vfmBasePopup, ref, grids, grid_arr, grid_keys, search, props.refcodeList)

onMounted(async () => {
    commonLogic.MountedByInit(grids, grid_keys, search, gridRefSearch, gridRefButtons)

    console.log(props)

    // 자동 조회 처리
    for(let i = 0; i < props.refcodeList.length; i++) {
        if(props.componentList[props.refcodeList[i]].RULE_DATA.START_SEARCH == 'Y') {
            onBtnClick('READ', props.refcodeList[i])
        }
    }

})


async function onBtnClick(btnKey, compName) {
    let buttonData = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_BUTTON.filter((btn) => btn.BUTTON_CODE == btnKey)[0]
    let rtn = true
    switch (btnKey) {
        // 조회
        case 'READ':
            rtn = await commonLogic.SearchCommon(grids, search, compName, {}, {}, buttonData)
            break
        // 행 추가
        case 'PLUS':
            commonLogic.AddRowCommon(grids, compName, buttonData)
            break
        // 행 삭제
        case 'MINUS':
            commonLogic.RemoveRowCommon(grids, compName, buttonData)
            break
        // 저장
        case 'SAVE':
            rtn = await commonLogic.SaveCommon(grids, compName, buttonData)
            break
        // 엑셀 다운로드
        case 'EXCELDOWNLOAD':
            commonLogic.ExcelDownCommon(grids, compName, buttonData)
            break
        // 엑셀 업로드
        case 'EXCELUPLOAD':
            commonLogic.ExcelUploadCommon(grids, compName, buttonData)
            break
        // 개인화
        case 'PERSONALIZATION':
            commonLogic.PersonalizationCommon(grids, compName, buttonData)
            break
    }
    // 정상 처리를 못했다면 이후 로직은 실행하지 않음
    if(rtn == false) return

    // RULE_DATA 처리(다른 기본 기능을 처리하더라도 추가 처리)
    commonLogic.RULE_Data_Button(grids, compName, buttonData)
}

/** 행선택 */
function onRowClickRadio(ui, row, baseComponentData) {
    commonLogic.RowClickRadioCommon(grids, search, ui, row, baseComponentData)
}

function onCellDbClick(sender, event, ui) {
    emit('confirm', ui.rowData)
}

/** OK 버튼 */
function onConfirm() {
    let tmpSctData = popGrid01.value.getRowSelectedData()
    if (!tmpSctData) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
    } else {
        emit('confirm', tmpSctData)
    }
}

/** 취소 버튼 */
function onCancel() {
    emit('cancel', false)
}

const vfmref = ref()        // 테스트 필요함

</script>

<template>
    <!-- Modal -->
    <vue-final-modal 
        class="flex justify-center items-center modal-container"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
            
        :drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel()"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div style="width:100%; height:40px; overflow:auto;">
                <div class="searchSplitCon parentCon">
                    <div class="searchSplitCon parentCon_pop">
                        <div class="leftCon ml-1 mr-1">
                            <component v-for="(component, index) in popGrid01Search" :key="index"
                                :is="component.component" :search="component.search"
                                :searchLanguage="component.searchLanguage" :searchData="component.searchData"
                                @Search="onBtnClick"></component>
                        </div>
                        <div class="rightCon">
                            <component v-for="(component, index) in popGrid01Button" :key="index" :is="component.component"
                                :buttonData="component.buttonData" :buttonLanguage="component.buttonLanguage"
                                :grid_key="component.grid_key" @BtnClick="onBtnClick"></component>
                        </div>
                    </div>
                </div>
            </div>
            <div class="resultGrid" style="height:calc(100% - 50px);">
                <BaseGrid ref="popGrid01" @RowClickRadio="onRowClickRadio" @CellDblClick="onCellDbClick" />
            </div>
        </div>
        <div class="modal__action">
            <button type="button" class="btn btn-primary" @click="onConfirm()">{{ t('BUTTON.BUTTON0001') }}</button>
            <button type="button" class="btn btn-secondary" @click="onCancel()">{{t('BUTTON.BUTTON0002') }}</button>
        </div>
    </vue-final-modal>

</template>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}

.parentCon_pop {
    padding: 3px;
}
</style>