<script setup>
import { ref, computed, getCurrentInstance, onMounted, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import Comfirm from '@/components//popup/Comfirm.vue'
import Alert from '@/components/popup/Alert.vue'
import BoardWrite from './BoardWrite.vue'

const { t } = useI18n()
const router = useRouter()
const store = useStore()
let con = getCurrentInstance()

const vfmBasePopup = inject('vfmBasePopup')
const props = defineProps(['modalName', 'title', 'popupSize', 'width', 'height', 'api', 'boardname', 'idx', 'grid', 'boardidx', 'componentList'])
const emit = defineEmits(['confirm', 'cancel'])

const replyinput = ref('');
const boarddata = ref({});
const replydata = ref([]);
const thumbnailSrc = ref('');

const BOARDREPLY = ref()
const grid = ref()
let grids = {
    'BOARDREPLY': BOARDREPLY,
}

let search = {};
search['TABLENAME'] = ref('');
search['IDX'] = ref('');
search['BOARD_IDX'] = ref('');
search['BOARDREPLY'] = ref('');

// Reply 저장용 파라메터
const saveReplyDataArr = ['METHOD', 'TABLENAME']

for (let i = 0; i < props.componentList.BOARDREPLY.MENU_APPLY_COMPONENT_DTL_COLUMN.length; i++) {
    saveReplyDataArr.push(props.componentList.BOARDREPLY.MENU_APPLY_COMPONENT_DTL_COLUMN[i].COLUMN_CODE)
}
let saveReplyData = {};
for (let i = 0; i < saveReplyDataArr.length; i++) {
    saveReplyData[saveReplyDataArr[i]] = ref('');
}

/** 유저 정보 */
const userInfo = computed(() => store.state.NGCore.userInfo)

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    // if((props.width || '') != '') styles['width'] = props.width
    // if((props.height || '') != '') styles['height'] = props.height
    styles['min-width'] = "500px";
    styles['min-height'] = "400px";
    styles['max-width'] = "1000px";
    styles['max-height'] = "800px";

    return styles
})

onMounted(() => {
    search['TABLENAME'].value = props.boardname;
    search['IDX'].value = props.idx;
    setTimeout(() => {
        onSearch()
    }, 0);
});


/** 조회 */
function onSearch() {
    let params = {}
    if (search != null) {
        var tmpkeys = Object.keys(search)
        for (let i = 0; i < tmpkeys.length; i++) {
            params[tmpkeys[i]] = search[tmpkeys[i]].value
        }
    }
    debugger
    store.dispatch('NGCore/getSysData', { METHOD_CODE: "BOARDDETAIL", paramsdata: params, REF_CODE: baseComponentData.REF_CODE }).then((resdata) => {
        let rtndata = resdata.rtndata;
        if (rtndata.length > 0) {
            boarddata.value = rtndata[0];
            if (props.boardname == 'EVENT' && !!boarddata.value.TEMP_FIELD3) {
                thumbnailSrc.value = boarddata.value.TEMP_FIELD3
            } else if (!!boarddata.value.UPLOAD1_URL) {
                const imgBaseurl = ng_core.common.config.baseUrl;
                thumbnailSrc.value = imgBaseurl + boarddata.value.UPLOAD1_URL
            }
        }
        onSearchReply();
    }).catch((error) => {

    });
}

function onSearchReply() {
    let params = {}
    if (search != null) {
        var tmpkeys = Object.keys(search)
        for (let i = 0; i < tmpkeys.length; i++) {
            params[tmpkeys[i]] = search[tmpkeys[i]].value
        }
    }
    debugger
    store.dispatch('NGCore/getSysData', { METHOD_CODE: 'BOARDREPLY', paramsdata: params, REF_CODE: baseComponentData.REF_CODE }).then((resdata) => {
        let rtndata = resdata.rtndata;
        replydata.value = rtndata;
    }).catch((error) => {

    });
}

function onSendReply(method, idx) {
    saveReplyData['TABLENAME'].value = search['TABLENAME'].value;
    saveReplyData['BOARD_IDX'].value = search['IDX'].value;
    saveReplyData['IDX'].value = idx || 0;
    saveReplyData['CONTENTS'].value = replyinput.value;
    saveReplyData['METHOD'].value = method;
    let params = {};
    if (saveReplyData != null) {
        var tmpkeys = Object.keys(saveReplyData)
        for (let i = 0; i < tmpkeys.length; i++) {
            if (tmpkeys[i] == "METHOD") {
                params[tmpkeys[i]] = method
            } else {
                params[tmpkeys[i]] = saveReplyData[tmpkeys[i]].value
            }
        }
    }

    debugger
    store.dispatch('NGCore/saveSysData', { METHOD_CODE: 'BOARDREPLY', paramsdata: params, REF_CODE: baseComponentData.REF_CODE }).then(async (rtndata) => {
        if (method == 'D') {

            let tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0002') })
            if (tmpAlert == false) return
        }
        if (rtndata.status) {

            onSearchReply();
            replyinput.value = '';
        } else {
            await vfmBasePopup(Alert, { state: 'error', contents: t(rtndata.errorcode) })
        }
    }).catch((error) => {

    });
}

function onBeforeOpen(a, b, c) {
}

/** 취소 버튼 */
function onCancel(close) {
    emit('cancel', close, false)
}

function onModify(close) {
    emit('cancel', close, "U")
}


</script>

<template>
    <vue-final-modal v-slot="{ close }" @before-open="onBeforeOpen" classes="modal-container"
        content-class="modal-content2" :esc-to-close="true" :click-to-close="true" transition="" overlay-transition=""
        :drag="true" drag-selector=".topbar" :resize="true" :min-width="800" :max-width="1000" :min-height="550">

        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel(close)"></button>
            <span class="modal__title">{{ boarddata.SUBJECT }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div class="boardinfo">
                <span class="boardcreater">{{ !!boarddata.NAME ? boarddata.NAME : boarddata.CREATE_USERID }}</span>
                <ul class="list-inline text-muted small pt-1 m-0">
                    <li class="list-inline-item"><font-awesome-icon icon="clock"></font-awesome-icon>{{ boarddata.VIEW_DATE }}</li> <!--게시일-->
                    <li class="list-inline-item"><font-awesome-icon icon="eye"></font-awesome-icon> {{ boarddata.READCNT }} 회</li> <!--조회수-->
                    <li class="list-inline-item"><font-awesome-icon icon="comment-dots"></font-awesome-icon> {{ boarddata.REPLY_CNT }} 건</li> <!--댓글수-->
                </ul>
            </div>
            <div class="boardcontent">
                <!--Thumbnail-->
                <div class="boardthumbnail" v-if="!!thumbnailSrc">
                    <img :src="thumbnailSrc" :alt="boarddata.SUBJECT">
                </div>
                <pre>
                    {{ boarddata.CONTENTS }}
                </pre>

                <div class="boardetc">

                </div>
            </div>
            <div class="boardreply">
                <div class="col" v-for="reply in replydata" :key="reply.IDX" style="margin-bottom: 10px;">
                    <b-card bg-variant="light">
                        <div class="replyinfo" style="font-size: .8em; float:right;">
                            <ul class="list-inline text-muted small pt-1 m-0">
                                <li class="list-inline-item"><font-awesome-icon style="margin-right:4px" icon="user"></font-awesome-icon>{{ !!reply.NAME ? reply.NAME : reply.CREATE_USERID }}</li>
                                <li class="list-inline-item"><font-awesome-icon style="margin-right:4px" icon="clock"></font-awesome-icon>{{ reply.VIEW_DATE }}</li>
                                <li class="list-inline-item" @click="onSendReply('D', reply.IDX)"><font-awesome-icon style="margin-right:4px" icon="trash"></font-awesome-icon></li>
                            </ul>
                        </div>
                        <div class="replycontent" style="font-size: .95em;">{{ reply.CONTENTS }}</div>
                    </b-card>
                </div>
            </div>

            <div class="boardmodi" v-if="boarddata.CREATE_USERID == userInfo.USER_ID">
                <ul class="list-inline text-muted small pt-1 m-0" style="float:right">
                    <li class="list-inline-item" @click="onModify(close)">수정</li>
                    <li class="list-inline-item">삭제</li>
                </ul>
            </div>
        </div>
        <div class="modal__action">
            <!-- <span class="replytit">Comments</span> -->
            <input type="text" class="replyinput" v-model="replyinput" @keyup.enter="onSendReply('A')" :placeholder="t('COMMENTS')" />
            <button type="submit" class="replybutton" @click="onSendReply('A')"><font-awesome-icon icon="paper-plane" class="replybutton_icon"></font-awesome-icon></button>
        </div>
    </vue-final-modal>
</template>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}

.boardthumbnail {
    text-align: center;
    margin: 1em 0;
}

.boardcreater {
    color: #515151;
    font-size: 15px;
    font-weight: bold;
}

.replyinput {
    width: 500px;
    height: 32px;
    font-size: 15px;
    border: 0;
    border-radius: 15px;
    outline: none;
    padding-left: 10px;
    background-color: rgb(233, 233, 233);
}

.replybutton_icon {
    font-weight: normal;
    font-size: 15px;
    color: #fff;
    margin: 0 10px;
    /* display: block;
        margin-bottom: 14px; */
}

.replybutton {
    width: 71px;
    height: 32px;
    border-radius: 15px;
    /* font-size: 15px; */
    /* line-height: 38px; */
    background-color: #6bacce;
    color: #fff;
    margin-left: 10px !important;
}
</style>