// store/index.js
import { createStore } from "vuex";
import createNoligoCore from "@/store/noligocore";
import { MessageQueue } from "@/store/messagequeue";

export default function (i18n) {
    let tmpi18n = i18n
    let NoligoCore = createNoligoCore(i18n)
    const tmpStore = createStore({
        state: {
        },
        getters: {
        },
        mutations: {
            
        },
        
        modules: { 
            NGCore: NoligoCore,
            MQ: MessageQueue 
        }
    })

    return tmpStore
}

// export default createStore({
//     state: {
//     },
//     getters: {
//     },
//     mutations: {
        
//     },
    
//     modules: { 
//         NGCore: NoligoCore,
//         MQ: MessageQueue 
//     }
// });