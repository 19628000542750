<script setup>
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, onMounted, computed } from 'vue'
import { useStore } from "vuex";

const props = defineProps(['inspectorData'])
defineExpose({
    setInsprecorData, clearData
})

const { t } = useI18n()
const store = useStore()

let isData = ref(false)
let commandList = ['SEARCH', 'DATAREF', 'CALLSERVICE', 'MOVEROW', 'DEFAULTVAL']
let actionTimeList = ['BEFORE', 'AFTER']
let defaultValType = ['', 'TEXT', 'COMBO', 'DATE']
let defaultValDate = ['DAY', 'MONTH', 'YEAR']

let selectedTab = ref("")
let selectedButton = null
let inspectorData = null
let commandData = ref("")
let actionTime = ref("")
let refCode = ref("")
let selectedRectId = null
let selectType = null               // COLUMN, LINK 둘중 하나의 값을 보유, column는 배열로 데이터를 받은, link는 json 오브젝트로 데이터를 받음

let paper = null
let commandArray = ref([])      // 컨맨드 리스트
function setInsprecorData(_selectType, _paper, _selectedTab, _inspectorData, _selectedButton, _selectedRectId) {
    isData.value = true
    paper = _paper
    selectType = _selectType
    inspectorData = _inspectorData
    selectedButton = _selectedButton
    selectedRectId = _selectedRectId

    if(selectType == 'COLUMN') {
        if(inspectorData.elementView.model.groupRectAttributes.columnInData[selectedRectId.split('__')[1]] == null) {
            inspectorData.elementView.model.groupRectAttributes.columnInData[selectedRectId.split('__')[1]] = []
        }
        if(inspectorData.elementView.model.groupRectAttributes.columnInData[selectedRectId.split('__')[1]].length == 0) {
            inspectorData.elementView.model.groupRectAttributes.columnInData[selectedRectId.split('__')[1]].push({
                COMMAND: selectedButton,
                ACTIONTIME: 'AFTER',
                DATA: {
                    TYPE: '',
                }
            })
        }
        commandArray.value = inspectorData.elementView.model.groupRectAttributes.columnInData[selectedRectId.split('__')[1]]
    } else {
        // link의 경우
        commandArray.value = _inspectorData.elementView.model.attributes.data || {}

        commandData.value = (commandArray.value.COMMAND || '')
        actionTime.value = (commandArray.value.ACTIONTIME || '')

        selectedTab.value = _selectedTab
        if(commandData.value == 'SEARCH') {
            refCode.value = commandArray.value.DATA.SEARCHREFCODE || ''
        } else if(commandData.value == 'DATAREF') {
            refCode.value = commandArray.value.DATA.REFCODE || ''
        }

    }

    
}

function clearData() {
    isData.value = false
}

// 컨맨드 수
let modalSize = computed(() => {
	return commandArray.value.length
})
// 옵션

// 컨맨드 배열 역전처리
let commandDat = computed(() => {
    if(selectType == 'COLUMN') {
        return commandArray.value
    } else {
        return [commandArray.value]
    }
})

/** 선택 데이터가 달라지는 경우 데이터 동기화 처리 **/
function changeData(commandD) {
    if(commandD.COMMAND == 'DEFAULTVAL' && commandD.DATA.TYPE == 'COMBO') {
        if(commandD.DATA.BINDINGFIELD == null) {
            commandD.DATA.BINDINGFIELD = {
            }
        }
    } else if(commandD.COMMAND == 'DEFAULTVAL' && commandD.DATA.TYPE == 'DATE') {
        if(commandD.DATA.DEFAULT_VAL_DATE == null) {
            commandD.DATA.DEFAULT_VAL_DATE = {
                TYPE: ''
            }
        }
    }
    // commandArray.value.COMMAND = commandData.value || ''
    // commandArray.value.ACTIONTIME = actionTime.value || ''
    // if(commandArray.value.COMMAND == 'SEARCH') {
    //     commandArray.value.DATA.SEARCHREFCODE = refCode.value || ''
    //     if(commandArray.value.DATA.SEARCHREFCODE == '') {
    //         commandArray.value.DATA.SEARCHREFCODE = refCode.value = inspectorData.elementView.model.attributes.target.id
    //     }
    //     if((commandArray.value.DATA.SEARCHREFCODE || '') != '' ) {
    //         debugger
    //     }
    // }
}
</script>


<template>
    <div>
        <div v-if="isData">
            <form method="post" ref="send_form" id="send_form" enctype="multipart/form-data" v-for="(commandD, index) in commandDat" :key="index" :value="commandD">
                <div class="profile-tab-form">
                    <div class="form-group">
                        <label for="ID">* COMMAND</label> : 
                        <select v-model="commandD.COMMAND" @change="changeData(commandD)" disabled>
                            <option v-for="(option, index1) in commandList" :key="index1" :value="option" >
                                {{  option  }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group">
                        <label for="ID">* ACTIONTIME</label> : 
                        <select v-model="commandD.ACTIONTIME" @change="changeData(commandD)">
                            <option v-for="(actionData, index2) in actionTimeList" :key="index2" :value="actionData">
                                {{  actionData  }}
                            </option>
                        </select>
                    </div>
                    
                    <!-- SEARCH : START -->
                    <div class="form-group" v-if="commandD.COMMAND == 'SEARCH'">
                        <label for="ID">* SEARCHREFCODE</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.SEARCHREFCODE" readonly @change="changeData(commandD)">
                    </div>
                    <!-- SEARCH : END -->
                    <!-- DATAREF : START -->
                    <div class="form-group" v-if="commandD.COMMAND == 'DATAREF'">
                        <label for="ID">* REFCODE</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.REFCODE" readonly @change="changeData(commandD)">
                        <label for="ID">* REFCOLUMN</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.REFCOLUMN" readonly @change="changeData(commandD)">

                         
                    </div>
                    <!-- DATAREF : END-->
                    <!-- DEFAULTVAL : START -->
                    <div class="form-group" v-if="commandD.COMMAND == 'DEFAULTVAL'">
                        <label for="ID">* TYPE</label> : 
                        
                        <select v-model="commandD.DATA.TYPE" @change="changeData(commandD)">
                            <option v-for="(typeData, index3) in defaultValType" :key="index3" :value="typeData">
                                {{  typeData  }}
                            </option>
                        </select>
                    </div>
                    <div class="form-group" v-if="commandD.COMMAND == 'DEFAULTVAL' && commandD.DATA.TYPE != null && ['TEXT', 'COMBO'].includes(commandD.DATA.TYPE)">
                        <label for="ID">* DEVALUTTEXT</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.DEFAULT_VAL" @change="changeData(commandD)">
                    </div>
                    <div class="form-group" v-if="commandD.COMMAND == 'DEFAULTVAL' && commandD.DATA.TYPE != null && commandD.DATA.TYPE == 'COMBO'">
                        <label for="ID">* BINDINGFIELD</label> : 
                        <label for="ID">- DISPLAY</label> : 
                        <input type="text" class="form-control" v-if="commandD.DATA.BINDINGFIELD != null && commandD.DATA.BINDINGFIELD.DISPLAY != null" v-model="commandD.DATA.BINDINGFIELD.DISPLAY" @change="changeData(commandD)">
                        <label for="ID">- VALUE</label> : 
                        <input type="text" class="form-control" v-if="commandD.DATA.BINDINGFIELD != null && commandD.DATA.BINDINGFIELD.VALUE != null" v-model="commandD.DATA.BINDINGFIELD.VALUE" @change="changeData(commandD)">
                    </div>
                    <div class="form-group" v-if="commandD.COMMAND == 'DEFAULTVAL' && commandD.DATA != null && commandD.DATA.TYPE == 'DATE'">
                        <label for="ID">* DEFAULT_VAL_DATE</label> : 
                        <label for="ID">- TYPE</label> : 
                        <select v-if="commandD.DATA.DEFAULT_VAL_DATE != null & commandD.DATA.DEFAULT_VAL_DATE.TYPE != null" v-model="commandD.DATA.DEFAULT_VAL_DATE.TYPE" @change="changeData(commandD)">
                            <option v-for="(typeData, index4) in defaultValDate" :key="index4" :value="typeData">
                                {{  typeData  }}
                            </option>
                        </select>

                        <label for="ID">- FORMAT</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.DEFAULT_VAL_DATE.FORMAT" @change="changeData(commandD)">
                        <label for="ID">- DIFF</label> : 
                        <input type="text" class="form-control" v-model="commandD.DATA.DEFAULT_VAL_DATE.DIFF" @change="changeData(commandD)">
                    </div>
                    <!-- DEFAULTVAL : END -->
                    
                </div>
            </form>
        </div>
    </div>
    
</template>

<style scoped>

</style>