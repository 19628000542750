<script setup>
import { ref, getCurrentInstance, onMounted, defineAsyncComponent, inject, nextTick } from 'vue'
import * as commonLogic from '@/views/template/commonLogic/commonLogic.js';
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

// 회원 정보 호출
let formData = {
    ID: ref(ng_core.common.getUserId()),
    NAME: ref(''),
    EMAIL: ref(''),
    PHONE: ref(''),
    TEL: ref(''),
    IMG_AVATAR: ref(''),
}
let pwFormData = {
    USER_PW: ref(''),
    CHG_PW: ref(''),
    CHK_PW: ref(''),
}

// 메뉴 이동 처리
var params = {
    PREF: 'SYS',
    VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
    METHOD_CODE: 'USER_PROFILE',
    DATA: {
        USER_ID: ng_core.common.getUserId()
    },
    SQLLOG_YN: 'N',
}

ng_core.common.getData(params).then((rtn) => {
    if (rtn.data.status) {
        if (rtn.data.rtndata.length == 1) {
            formData.NAME.value = rtn.data.rtndata[0].NAME
            formData.EMAIL.value = rtn.data.rtndata[0].EMAIL
            formData.PHONE.value = rtn.data.rtndata[0].PHONE
            formData.TEL.value = rtn.data.rtndata[0].TEL
            formData.IMG_AVATAR.value = rtn.data.rtndata[0].IMG_AVATAR
        }
    }
})

// 유저 정보 수정
function onChangeUserInfo() {
    var params = {
        METHOD_CODE: 'USER_PROFILE',
        paramsdata: [{
            MODE: 'U',
            NAME: formData.NAME.value,
            EMAIL: formData.EMAIL.value,
            PHONE: formData.PHONE.value,
            TEL: formData.TEL.value,
            IMG_AVATAR: formData.IMG_AVATAR.value,
        }],
        SQLLOG_YN: 'Y',
        REF_CODE: '프로필 일반',
    }

    try {
        store.dispatch('NGCore/saveSysData', params).then(async (rtn) => {
            if (rtn.rtndata.P_RETURN_FLAG == 'Y') {
                await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.rtnmsgcode) })
                pwFormData.USER_PW.value = ''
                pwFormData.CHG_PW.value = ''
                pwFormData.CHK_PW.value = ''
            } else {
                await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.rtndata.P_RETURN_MSG_CODE) })
            }

        })

    } catch (err) {
        console.log('getData')
        console.dir(err)
        return err
    } finally {
    }
 
}

// 패스워드 변경
async function onChangePW() {
    if (pwFormData.CHG_PW.value != pwFormData.CHK_PW.value) {
        const tmpAlert = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0019') })
    } else if (pwFormData.CHG_PW.value.length < 4 || pwFormData.CHG_PW.value.length > 50) {
        const tmpAlert = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0020') })
    } else {
        var params = {
            METHOD_CODE: 'USER_PROFILE_CHGPW',
            paramsdata: {
                USER_PW: pwFormData.USER_PW.value,
                CHG_PW: pwFormData.CHG_PW.value,
                CHK_PW: pwFormData.CHK_PW.value,
            },
            SQLLOG_YN: 'N',
            REF_CODE: '암호',
        }

        try {
            store.dispatch('NGCore/saveSysData', params).then(async (rtn) => {
                if (rtn.rtndata.P_RETURN_FLAG == 'Y') {
                    await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.rtnmsgcode) })
                    pwFormData.USER_PW.value = ''
                    pwFormData.CHG_PW.value = ''
                    pwFormData.CHK_PW.value = ''
                } else {
                    await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.rtndata.P_RETURN_MSG_CODE) })
                }

            })

            // let rtn = await ng_core.common.saveData(params)
            // if (rtn.data.rtndata.P_RETURN_FLAG == 'Y') {
            //     await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.data.rtnmsgcode) })
            //     pwFormData.USER_PW.value = ''
            //     pwFormData.CHG_PW.value = ''
            //     pwFormData.CHK_PW.value = ''
            // } else {
            //     await vfmBasePopup(Alert, { state: 'info', contents: t(rtn.data.rtndata.P_RETURN_MSG_CODE) })
            // }
        } catch (err) {
            console.log('getData')
            console.dir(err)
            return err
        } finally {
        }

        // // 저장기능
        // this.$store.dispatch('nuisys/comonsyssave', params).then(
        //     (rtndata) => {
        //         const tmpAlert = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0020') })
        //         self.$NUAlert({ data: { state: 'info', contents: self.$t(rtndata.msg), buttons: ['ok'] } })
        //     },
        //     (error) => {
        //         self.$NUAlert({ data: { state: 'error', contents: self.$t('ERRORMSG0009'), buttons: ['ok'] } })
        //     }
        // );
    }
}

/****************************************
 * 그리드 공통 : Start
 * **************************************/
let search = {}

const grid01 = ref()
const grid_arr = {
    grid01: grid01,
}

const grid01Button = ref([])
const gridRefButtons = {
    grid01Button: grid01Button,
}

const grid01Search = ref([])
const gridRefSearch = {
    grid01Search: grid01Search,
}

// 조회나 로우 선택 조회시 초기화 해야 할 대상 리스트
let clearGridList = []

let grids = {
}
// 현재 화면에 사용되는 그리드 코드 리스트(팝업은 제외)
let grid_keys = []
commonLogic.BaseInit(t, vfmBasePopup, ref, grids, grid_arr, grid_keys, search)

onMounted(async () => {
    commonLogic.MountedByInit(grids, grid_keys, search, gridRefSearch, gridRefButtons)
})

async function onBtnClick(btnKey, compName) {
    let buttonData = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_BUTTON.filter((btn) => btn.BUTTON_CODE == btnKey)[0]
    let rtn = true
    switch (btnKey) {
        // 조회
        case 'READ':
            rtn = await commonLogic.SearchCommon(grids, search, compName, {}, {}, buttonData)
            break
        // 행 추가
        case 'PLUS':
            commonLogic.AddRowCommon(grids, compName, buttonData)
            break
        // 행 삭제
        case 'MINUS':
            commonLogic.RemoveRowCommon(grids, compName, buttonData)
            break
        // 저장
        case 'SAVE':
            rtn = await commonLogic.SaveCommon(grids, compName, buttonData)
            break
        // 엑셀 다운로드
        case 'EXCELDOWNLOAD':
            commonLogic.ExcelDownCommon(grids, compName, buttonData)
            break
        // 엑셀 업로드
        case 'EXCELUPLOAD':
            commonLogic.ExcelUploadCommon(grids, compName, buttonData)
            break
        // 개인화
        case 'PERSONALIZATION':
            commonLogic.PersonalizationCommon(grids, compName, buttonData)
            break
    }
    // 정상 처리를 못했다면 이후 로직은 실행하지 않음
    if (rtn == false) return

    // RULE_DATA 처리(다른 기본 기능을 처리하더라도 추가 처리)
    commonLogic.RULE_Data_Button(grids, compName, buttonData)
}

/** 행선택 */
function onRowClickRadio(ui, row, baseComponentData) {
    commonLogic.RowClickRadioCommon(grids, search, ui, row, baseComponentData)
}


/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    commonLogic.GrdCellButtonCommon(vfmBasePopup, grids, sender, ui, event)
}
/****************************************
 * 그리드 공통 : End
 * **************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            if (grids[tmpGridKeys[i]].value.refresh) {
                grids[tmpGridKeys[i]].value.refresh()
            }

        }
    });
}

/*************************************************
 * Tab 관련 : End
 *************************************************/


</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="tabPageContentResize"
                :dbl-click-splitter="false">
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab" @resize="tabPageContentResize">
                        <!-- content-class="mt-2" -->
                        <b-tab :title="t('일반')" active>
                            <form method="post" ref="send_form" id="send_form" enctype="multipart/form-data">
                                <div class="profile-tab-form">
                                    <div class="form-group">
                                        <label for="ID">ID</label>
                                        <input type="text" class="form-control" id="ID" v-model="formData.ID.value"
                                            placeholder="" :disabled="true">
                                    </div>
                                    
                                    <hr class="solid">

                                    <div class="form-group">
                                        <label for="NAME">NAME</label>
                                        <input type="text" class="form-control" id="NAME" v-model="formData.NAME.value"
                                            placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="EMAIL">EMAIL</label>
                                        <input type="text" class="form-control" id="EMAIL" v-model="formData.EMAIL.value"
                                            placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="PHONE">PHONE</label>
                                        <input type="text" class="form-control" id="PHONE" v-model="formData.PHONE.value"
                                            placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="TEL">TEL</label>
                                        <input type="text" class="form-control" id="TEL" v-model="formData.TEL.value"
                                            placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="TEL">IMG_AVATAR</label>
                                        <input type="text" class="form-control" id="IMG_AVATAR" v-model="formData.IMG_AVATAR.value"
                                            placeholder="">
                                    </div>
                                    
                                </div>
                            </form>

                            <div class="vue-dialog-buttons window-footer">
                                <button class="btn btn-primary" @click="onChangeUserInfo">{{ $t('BUTTON.BUTTON0003')
                                }}</button>
                            </div>
                        </b-tab>
                        <b-tab :title="t('암호')" active>
                            <form method="post" ref="send_form_pw" id="send_form_pw" enctype="multipart/form-data">
                                <div class="profile-tab-form">
                                    <div class="form-group">
                                        <label for="PASSWORD">{{ t('LABEL.PASSWORD') }}</label>
                                        <input type="password" class="form-control" id="PASSWORD"
                                            v-model="pwFormData.USER_PW.value" placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="CHG_PASSWORD">{{ t('LABEL.CHG_PASSWORD') }}</label>
                                        <input type="password" class="form-control" id="CHG_PASSWORD"
                                            v-model="pwFormData.CHG_PW.value" placeholder="">
                                    </div>
                                    <div class="form-group">
                                        <label for="CHK_PASSWORD">{{ t('LABEL.CHK_PASSWORD') }}</label>
                                        <input type="password" class="form-control" id="CHK_PASSWORD"
                                            v-model="pwFormData.CHK_PW.value" placeholder="">
                                    </div>
                                </div>
                            </form>

                            <div class="vue-dialog-buttons window-footer">
                                <button class="btn btn-primary" @click="onChangePW">{{ $t('BUTTON.BUTTON0003') }}</button>
                            </div>
                        </b-tab>
                        <b-tab :title="t('즐겨찾기')" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <component v-for="(component, index) in grid01Search" :key="index"
                                            :is="component.component" :search="component.search"
                                            :searchLanguage="component.searchLanguage" :searchData="component.searchData"
                                            @Search="onBtnClick">
                                        </component>
                                    </div>
                                    <div class="rightCon">
                                        <component v-for="(component, index) in grid01Button" :key="index"
                                            :is="component.component" :buttonData="component.buttonData"
                                            :buttonKey="component.buttonKey" :buttonLanguage="component.buttonLanguage"
                                            :grid_key="component.grid_key" @BtnClick="onBtnClick">
                                        </component>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="grid01" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>

<style scoped>.profile-tab-form {
    margin: 5px;
}</style>