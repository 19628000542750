<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'

import { useStore } from "vuex";

const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

// const MENU_ADM = ref()
// const MENU_USER = ref()
// let grids = {
//     'MENU_ADM': MENU_ADM,
//     'MENU_USER': MENU_USER,
// }

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for(let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for(let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        
        console.log(tmpSearchColumn)
    }
})
/*************************************************
 * Grid 관련 : Start
 *************************************************/
/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 행추가 */
function onAddRow(compName) {
    grids[compName].value.grdAddRow()
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for(let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

</script>

<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <!-- 왼쪽 영역 : Start -->
                            <!-- <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('SEARCH_COLUMN01')}}</label>
                                <input type="text" class="conInputText" v-model="search['REF_CODE01'].SEARCH_COLUMN01.value" :placeholder="t('SEARCH_COLUMN01')"
                                        @keyup.enter="onSearch('REF_CODE01')" :state="null">
                            </div> -->
                            <!-- 왼쪽 영역 : End -->
                            <!-- 오른쪽 영역 : Start -->
                            <!-- <div class="rightCon">
                                <button ref="read" @click="onSearch('REF_CODE01')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('REF_CODE01')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('REF_CODE01')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('REF_CODE01')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                            </div> -->
                            <!-- 오른쪽 영역 : End -->

                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <!-- <BaseGrid ref="REF_CODE01" :refCode="'REF_CODE01'"
                            @RowClickRadio="onRowClickRadio"
                        /> -->
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab" >
                        <!-- Tab Page 01 : Start-->
                        <b-tab :title="t('ADMIN')" active>
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                <!-- 왼쪽 영역 : Start -->
                                <!-- <div class="leftCon ml-1 mr-1">
                                    <label for="input-none" class="conLabel">{{t('SEARCH_COLUMN02')}}</label>
                                    <input type="text" class="conInputText" v-model="search['REF_CODE02'].SEARCH_COLUMN02.value" :placeholder="t('SEARCH_COLUMN02')"
                                            @keyup.enter="onSearch('REF_CODE02')" :state="null">
                                </div> -->
                                <!-- 왼쪽 영역 : End -->
                                <!-- 오른쪽 영역 : Start -->
                                <!-- <div class="rightCon">
                                    <button ref="read" @click="onSearch('REF_CODE02')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                </div> -->
                                <!-- 오른쪽 영역 : End -->

                            </div>

                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <!-- <BaseGrid ref="REF_CODE02" :refCode="'REF_CODE02'"
                                    @RowClickRadio="onRowClickRadio" /> -->
                            </div>
                            <!-- 그리드 영역 : End -->
                        </b-tab>
                        <!-- Tab Page 01 : End  -->
                        <!-- Tab Page 01 : Start-->
                        <b-tab :title="t('ADMIN')" active>
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                <!-- 왼쪽 영역 : Start -->
                                <!-- <div class="leftCon ml-1 mr-1">
                                    <label for="input-none" class="conLabel">{{t('SEARCH_COLUMN03')}}</label>
                                    <input type="text" class="conInputText" v-model="search['REF_CODE03'].SEARCH_COLUMN03.value" :placeholder="t('SEARCH_COLUMN03')"
                                            @keyup.enter="onSearch('REF_CODE03')" :state="null">
                                </div> -->
                                <!-- 왼쪽 영역 : End -->
                                <!-- 오른쪽 영역 : Start -->
                                <!-- <div class="rightCon">
                                    <button ref="read" @click="onSearch('REF_CODE03')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                </div> -->
                                <!-- 오른쪽 영역 : End -->

                            </div>

                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <!-- <BaseGrid ref="REF_CODE03" :refCode="'REF_CODE03'"
                                    @RowClickRadio="onRowClickRadio" /> -->
                            </div>
                            <!-- 그리드 영역 : End -->
                        </b-tab>
                        <!-- Tab Page 01 : End  -->
                    </b-tabs>
                    <!-- Tabs : End   -->
                </Pane>
                <!-- 하단 : End   -->
            </Splitpanes>
        </div>
    </div>
</template>

<style>

</style>