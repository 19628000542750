<script setup>
import { ref, computed, getCurrentInstance, onMounted, inject, watch } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import Alert from '@/components/popup/Alert.vue'

const { t } = useI18n()
const router = useRouter()
const store = useStore()
let con = getCurrentInstance()

const vfmBasePopup = inject('vfmBasePopup')
const props = defineProps(['modalName', 'title', 'popupSize', 'width', 'height', 'api', 'method', 'boardname', 'row'])
const emit = defineEmits(['confirm', 'cancel'])

const saveBoardDataArr = [
    'METHOD', 'TABLENAME', 'IDX', 'PW', 'NAME', 'EMAIL', 'HOMEPAGE', 'MYSTERY_YN', 'NOTICE_YN', 'POPUP_YN', 'SUBJECT', 'CONTENTS', 'CATEGORYFIELD',
    'UPLOAD1', 'UPLOAD2', 'UPLOAD3', 'UPLOAD4', 'UPLOAD5', 'UPLOAD6', 'UPLOAD7', 'UPLOAD8', 'UPLOAD9', 'UPLOAD10',
    'SEQNUM', 'REF', 'RE_STEP', 'RE_LEVEL', 'REPLY_CNT', 'LIKE_CNT', 'NOTLIKE_CNT',
    'TEMP_FIELD1', 'TEMP_FIELD2', 'TEMP_FIELD3', 'TEMP_FIELD4', 'TEMP_FIELD5', 'TEMP_FIELD6', 'TEMP_FIELD7', 'TEMP_FIELD8', 'TEMP_FIELD9', 'TEMP_FIELD10',
];
let formData = {};
for (let i = 0; i < saveBoardDataArr.length; i++) {
    formData[saveBoardDataArr[i]] = ref('');
}
/** 유저 정보 */
const userInfo = computed(() => store.state.NGCore.userInfo)

// 사이즈
let modalSize = computed(() => {
    let styles = {
        width: (props.width || '') == '' ? '0px' : props.width,
        height: (props.height || '') == '' ? '0px' : props.height,
    }
    // if((props.width || '') != '') styles['width'] = props.width
    // if((props.height || '') != '') styles['height'] = props.height
    
    return styles
})

onMounted(() => {
    if(!!props.row) {
        for (let i = 0; i < saveBoardDataArr.length; i++) {
            formData[saveBoardDataArr[i]] = ref(!!props.row[saveBoardDataArr[i]] ? props.row[saveBoardDataArr[i]] : '');
        }
    }
});

function onSaveData(close) {
    const method = props.method;
    formData['TABLENAME'].value = props.boardname;
    let params = {};
    if (formData != null) {
        var tmpkeys = Object.keys(formData)
        for (let i = 0; i < tmpkeys.length; i++) {
            if(tmpkeys[i] == "METHOD") {
                params[tmpkeys[i]] = method
            } else {
                params[tmpkeys[i]] = formData[tmpkeys[i]].value
            }
        }
    }

    debugger
    store.dispatch('NGCore/saveSysData', { METHOD_CODE: 'BOARDDETAIL', paramsdata: params, REF_CODE: baseComponentData.REF_CODE }).then(async (rtndata) => {
        if(rtndata.status) {
            emit('cancel', close, rtndata.status)
            await vfmBasePopup(Alert, { state: 'info', contents: t(rtndata.rtnmsgcode) })
        } else {
            await vfmBasePopup(Alert, { state: 'error', contents: t(rtndata.errorcode) })
        }
    }).catch((error) => {

    });
}

function onBeforeOpen(a, b, c) {
}
/** 취소 버튼 */
function onCancel(close) {
    emit('cancel', close, false)
}

</script>

<template>
    <vue-final-modal v-slot="{close}" @before-open="onBeforeOpen" classes="modal-container" content-class="modal-content2" 
        :click-to-close="true" transition="" overlay-transition="" :drag="true" drag-selector=".topbar">

        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel(close)"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <!-- <div class="row mb-3 mt-3">
                <label class="col-sm-2 col-form-label" for="ID">ID</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="ID" v-model="formData.ID" placeholder="" :disabled="!!props.row">
                </div>
            </div> -->
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="PW">PASSWORD</label>
                <div class="col-sm-10">
                    <input type="password" class="form-control" id="PW" v-model="formData.PW.value" placeholder="" :disabled="!!props.row">
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="EMAIL">EMAIL</label>
                <div class="col-sm-10">
                    <input type="email" class="form-control" id="EMAIL" v-model="formData.EMAIL.value" placeholder="id@example.com" :disabled="!!props.row">
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="HOMEPAGE">HOMEPAGE</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="HOMEPAGE" v-model="formData.HOMEPAGE.value" placeholder="" :disabled="!!props.row">
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label">OPTION</label>
                <div class="col-sm-10">
                    <div class="form-group">
                        <label for="MYSTERY_YN" style="margin-right:5px;">MYSTERY_YN :</label>
                        <input type="checkbox" class="" id="MYSTERY_YN" v-model="formData.MYSTERY_YN.value">
                    </div>
                    <div class="form-group">
                        <label for="NOTICE_YN" style="margin-right:5px;">NOTICE_YN :</label>
                        <input type="checkbox" class="" id="NOTICE_YN" v-model="formData.NOTICE_YN.value">
                    </div>
                    <div class="form-group">
                        <label for="POPUP_YN" style="margin-right:5px;">POPUP_YN :</label>
                        <input type="checkbox" class="" id="POPUP_YN" v-model="formData.POPUP_YN.value">
                    </div>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="CATEGORYFIELD">CATEGORYFIELD</label>
                <div class="col-sm-10">
                    <select class="form-control" id="CATEGORYFIELD" v-model="formData.CATEGORYFIELD.value">
                        <option v-for="option in categoryOptions" :key="option" :value="option">
                            {{ option }}
                        </option>
                    </select>
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="SUBJECT">SUBJECT</label>
                <div class="col-sm-10">
                    <input type="text" ref="subject" class="form-control" id="SUBJECT" v-model="formData.SUBJECT.value" placeholder="">
                </div>
            </div>
            <div class="row mb-3">
                <label class="col-sm-2 col-form-label" for="CONTENTS">CONTENTS</label>
                <div class="col-sm-10">
                    <textarea ref="contents" class="form-control" id="CONTENTS" v-model="formData.CONTENTS.value" rows="5"></textarea>
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="exampleFormControlUpload">UPLOAD</label>
                <div class="col-sm-10">
                    <input type="file" id="UPLOAD1" name="UPLOAD1" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD1 !== ''">{{ formData.UPLOAD1_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD1_OLD" name="UPLOAD1_OLD" v-model="formData.UPLOAD1_FILENAME" />

                    <input type="file" id="UPLOAD2" name="UPLOAD2" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD2 !== ''">{{ formData.UPLOAD2_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD2_OLD" name="UPLOAD2_OLD" v-model="formData.UPLOAD2_FILENAME" />

                    <input type="file" id="UPLOAD3" name="UPLOAD3" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD3 !== ''">{{ formData.UPLOAD3_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD3_OLD" name="UPLOAD3_OLD" v-model="formData.UPLOAD3_FILENAME" />

                    <input type="file" id="UPLOAD4" name="UPLOAD4" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD4 !== ''">{{ formData.UPLOAD4_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD4_OLD" name="UPLOAD4_OLD" v-model="formData.UPLOAD4_FILENAME" />

                    <input type="file" id="UPLOAD5" name="UPLOAD5" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD5 !== ''">{{formData.UPLOAD5_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD5_OLD" name="UPLOAD5_OLD" v-model="formData.UPLOAD5_FILENAME" />

                    <input type="file" id="UPLOAD6" name="UPLOAD6" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD6 !== ''">{{ formData.UPLOAD6_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD6_OLD" name="UPLOAD6_OLD" v-model="formData.UPLOAD6_FILENAME" />

                    <input type="file" id="UPLOAD7" name="UPLOAD7" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD7 !== ''">{{ formData.UPLOAD7_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD7_OLD" name="UPLOAD7_OLD" v-model="formData.UPLOAD7_FILENAME" />

                    <input type="file" id="UPLOAD8" name="UPLOAD8" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD8 !== ''">{{ formData.UPLOAD8_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD8_OLD" name="UPLOAD8_OLD" v-model="formData.UPLOAD8_FILENAME" />

                    <input type="file" id="UPLOAD9" name="UPLOAD9" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD9 !== ''">{{ formData.UPLOAD9_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD9_OLD" name="UPLOAD9_OLD" v-model="formData.UPLOAD9_FILENAME" />

                    <input type="file" id="UPLOAD10" name="UPLOAD10" class="form-control form-file" accept="image/*">
                    <label v-if="formData.UPLOAD10 !== ''">{{ formData.UPLOAD10_ORG_NAME }}</label>
                    <input type="hidden" id="UPLOAD10_OLD" name="UPLOAD10_OLD" v-model="formData.UPLOAD10_FILENAME" />
                </div>
            </div>

            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD1">TEMP_FIELD1</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD1" v-model="formData.TEMP_FIELD1.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD2">TEMP_FIELD2</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD2" v-model="formData.TEMP_FIELD2.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD3">TEMP_FIELD3</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD3" v-model="formData.TEMP_FIELD3.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD4">TEMP_FIELD4</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD4" v-model="formData.TEMP_FIELD4.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD5">TEMP_FIELD5</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD5" v-model="formData.TEMP_FIELD5.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD6">TEMP_FIELD6</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD6" v-model="formData.TEMP_FIELD6.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD7">TEMP_FIELD7</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD7" v-model="formData.TEMP_FIELD7.value" placeholder="">
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD8">TEMP_FIELD8</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD8" v-model="formData.TEMP_FIELD8.value" placeholder=""/>
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD9">TEMP_FIELD9</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD9" v-model="formData.TEMP_FIELD9.value" placeholder=""/>
                </div>
            </div>
            <div class="row mb-1">
                <label class="col-sm-2 col-form-label" for="TEMP_FIELD10">TEMP_FIELD10</label>
                <div class="col-sm-10">
                    <input type="text" class="form-control" id="TEMP_FIELD10" v-model="formData.TEMP_FIELD10.value" placeholder=""/>
                </div>
            </div>

        </div>
        <div class="modal__action">
            <button class="btn btn-primary" @click="onSaveData(close)">{{ $t('BUTTON.BUTTON0003') }}</button>
            <button class="btn btn btn-secondary" @click="onHandleDelete" v-show="!!props.row">{{ $t('BUTTON.BUTTON0004') }}</button>
            <button class="btn btn btn-secondary" @click="onCancel(close)">{{ $t('BUTTON.BUTTON0005') }}</button>
        </div>
    </vue-final-modal>
</template>

<style scoped>
    ::v-deep .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep .modal-content2 {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }

    .modal__title {
        margin: 0 2rem 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .modal__content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 15px;
    }

    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }

    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
</style>