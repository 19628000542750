<script setup>
import { ref, defineProps, defineEmits, onMounted, getCurrentInstance, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import Alert from '../../popup/Alert.vue'
import Comfirm from '../../popup/Comfirm.vue'
import pq from 'pqgrid';

import BaseGrid from '../baseGrid'
import createColModel from './createColModel'
import { saveAs } from 'file-saver';
const ExcelJS = require("exceljs");
import dayjs from 'dayjs';

const router = useRouter()
const vfmBasePopup = inject('vfmBasePopup')     // 기본 팝업
let { t } = useI18n()
const store = useStore()
const props = defineProps(['refCode', 'cClose'])
const emit = defineEmits([
    'RowClickRadio', 'CellDblClick', 'DataReady', 'CellClick', 'CellButtonClick', 'InitFinish', 'PreInitOption'
])
/* 부모에게 메소드 오픈 대상 */
defineExpose({ 
    grid, getGrid, grdSearchRef, grdSearch, GridbaseInit,
    grdAddRow, grdAddRowChild, grdDeleteRow, grdRemoveRow, grdDeleteWithDB, grdSave, grdExcelDown, grdExcelUpload, grdDataClear,
    getRowSelectedIndexByRadio, getRowSelectedDataByRadio, getRowSelectedIndex, 
    getRowSelectedData, getCellSelectedColumnIndex,
    setCellData, setRowData,
    gridImport, resize, refresh,
    beginupdate, endupdate, showloadelement, hideloadelement,
    getrows, getGrdCheckList,
    destroy, grdLocalDataBinding, 
    saveColumn, resetColumn
})

// 한글 입력 관련
let bComEdit = true

/** 그리드 기본 정보 */
let baseGrid = {}

var grid = ref({})
var grd = ref()
let cClose                  // 부모 노드에서 팝업의 경우 close 함수를 보내줘야 정상적으로 종료 처리가 가능함(DataSelectPopup.vue 파일 참고)

/** 그리드 이벤트 메니저 */
let gridEventManager = {
    /** 데이터가 없을때 콜백 */
    beforeSaveNoData: [],
    /** 저장 직전 콜백 */
    beforeSave: [],
    /** 저장 후 콜백 */
    afterSave: [],
    beforeAdd: [],
    afterAdd: [],
    beforeRemove: [],
    afterRemove: [],
}

/** 그리드 이벤트 메니져 함수 */
function executeGridEventManager(eventNm, data) {
    if(gridEventManager[eventNm] != null && gridEventManager[eventNm].length > 0) {
        let tmpEvent = gridEventManager[eventNm]
        for(let i = 0; i< tmpEvent.length; i++) {
            tmpEvent[i](data)
        }
    }
}


let grdSearchParams = {}
let grdOrgSearchParams = {}
let grdEditList = []

/** 체크된 리스트 */
let grdCheckList = []
function getGrdCheckList() {
    return grdCheckList
}
let grdDataOrg = []
/** 저장 대상 리스트(api호출때 사용 됨) */
let grdSaveData = []

/** 선택 행 Idx(라디오박스) */
let rowSelectedIndexByRadio = null
function getRowSelectedIndexByRadio() {
    return rowSelectedIndexByRadio
}
/** 선택 행 데이터(라디오박스) */
let rowSelectedDataByRadio = null
function getRowSelectedDataByRadio() {
    return rowSelectedDataByRadio
}
/** 선택 행 정보 */
let rowSelectedIndex = null
function getRowSelectedIndex() {
    return rowSelectedIndex
}
/** 선택 행 데이터 */
let rowSelectedData = null
function getRowSelectedData() {
    return rowSelectedData
}
/** 선택 행의 cell 컬럼 정보 */
let cellSelectedColumnIndex = null
function getCellSelectedColumnIndex() {
    return cellSelectedColumnIndex
}

/** pqgrid 가져오기 **/
function getGrid() {
    return grid;
}

let grdColumns = {}
// TREEGRID 상하 관계
let treeModel = {
    id: null,
    parentId: null
}

let baseComponentData_COLUMN_ORG       // 원본 백업(pivot을 위함)
let {
    refCode,
    baseComponentData,
    baseBindingData,
    menuCode,
    gridType,
} = BaseGrid(props.refCode)

/** PQGrid 생성용 옵션 */
let options = {
    //toolPanel: { show: true },            // pivot용 패널
    width: '100%',
    height: '100%',
    imeMode: true,
    animModel: { on: false },
    autoRowSum: true,
    //animModel:  { autoAddRow: false },
    dataModel: { data: [] },
    locale: 'kr',                   // 언어팩 적용 부분 수정해야함 ~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    scrollModel: {
        //autoFit: false,
        type: 'Cell',
        mode: 'Block',
    },
    numberCell: {
        show: true
    },
    // hoverMode: 'row',
    wrap: false,
    hwrap: true,
    resizable: true,
    rowBorders: true,
    bubble: true,
    showTop: true,
    rowSpanHead: true,
    // 그룹을 위한 옵션
    // groupModel: {
    //     on: true,
    //     dataIndx: [],
    //     collapsed: [false],
    //     title: [
    //         "{0} ({1})",
    //         "{0} - {1}"
    //     ]
    // },
    //showBottom : true,
    menuUI: {
        tabs: ['filter', 'export'], //'hideCols','filter'
    },
    contextMenu: {
        on: true,
        //header context menu items.
        headItems: function headItems(evt, ui) {
            return [
                {
                    //hide the selected columns
                    name: t('LABEL.COLUMN_HIDDEN'),
                    action: function (evt, ui) {
                        // ui.column.hidden = true;
                        // this.refreshCM(this.colModel);
                        if (ui.column.dataIndx) {
                            this.Columns().hide({
                                diHide: [ui.column.dataIndx]
                            })
                        } else if (ui.column.colModel) {
                            for (var i = 0; i < ui.column.colModel.length; i++) {
                                this.Columns().hide({
                                    diHide: [ui.column.colModel[i].dataIndx]
                                })
                            }
                        }
                        // } else {
                        //     ui.column.hidden = true;
                        //     this.refreshHeader();
                        // }
                    }
                },
                {
                    //show columns
                    name: t('LABEL.COLUMN_HIDDEN_CANCEL'),
                    action: function (evt, ui) {
                        var hiddenCols = this.colModel.filter(element => element.hidden == true);
                        var di = [];
                        for (var i = 0; i < hiddenCols.length; i++) {
                            di.push(hiddenCols[i].dataIndx);
                        }
                        this.Columns().hide({
                            diShow: di
                        })
                    }
                },
                {
                    //show columns
                    name: t('LABEL.PERSON_SAVE'),
                    action: saveColumn
                },
                {
                    //show columns
                    name: t('LABEL.PRESON_DEL'),
                    action: resetColumn
                },
            ]
        },
    },
    filterModel: { 
        on: true, 
        //header: true, 
        type: 'local', 
        menuIcon: true 
    },
    menuIcon: true, //show header menu icon initially.
    trackModel: { on: true }, //to turn on the track changes.            
    //scrollModel: { autoFit: true },
    swipeModel: { on: false },
    editModel: {        // 엔터 처서 수정하도록 처리
        saveKey: $.ui.keyCode.ENTER, //////// !!!!!!!!!!!!!!!!!!!!!!!!
        keyUpDown: true,
        clickToEdit: false,
        //pressToEdit: false,
    },
    fillHandle: '',         //  셀 선택 시 우측 하단 검은색 선택 포인트 사라짐
    editor: { select: true },
    title: baseComponentData == null ? '' : t(baseComponentData.COMPONENT_NAME),
    showTitle: true, // 상단 타이블 보일지 결정
    sortModel: { ignoreCase: true },
    history: function (evt, ui) {
        var $grid = this.widget();

        // if (ui.canUndo != null) {
        //     $("button.changes", $grid).button("option", { disabled: !ui.canUndo });
        // }
        // if (ui.canRedo != null) {
        //     $("button:contains('Redo')", $grid).button("option", "disabled", !ui.canRedo);
        // }
        // $("button:contains('Undo')", $grid).button("option", { label: 'Undo (' + ui.num_undo + ')' });
        // $("button:contains('Redo')", $grid).button("option", { label: 'Redo (' + ui.num_redo + ')' });
    },
    change: onCellvaluechanged,
    beforePaste: onBeforePaste,
    cellClick: function (event, ui) {
        if (ui.dataIndx === "ROWRADIO") {
            if (rowSelectedIndexByRadio === null || rowSelectedIndexByRadio !== ui.rowIndx) {
                rowSelectedIndexByRadio = ui.rowIndx
                rowSelectedDataByRadio = ui.rowData

                grid.value.refreshCell({ rowIndx: ui.rowIndx, dataIndx: 'ROWRADIO' })
                ui.$td[0].querySelector('input').setAttribute('checked', true)

                onGrdRowClickRadio(ui.rowData)
            }
            //ui.$td[0].querySelector('input').checked = true
        }
        rowSelectedIndex = ui.rowIndx
        rowSelectedData = ui.rowData
        cellSelectedColumnIndex = ui.colIndx
    },
    cellDblClick: function (event, ui) {
        onGrdCellDblClick(event, ui)
    },
    dataReady: function (event, ui) {
        onGrdDataReady(event, ui)
    },
    check: function (event, ui) {
        
    },
    // 화면 갱신 후 이벤트
    refresh: function (event, ui) {
        hideloadelement();
    },
    columnOrder(event, ui) {
        // DRAG AND "DROP"
        // if(this.options.freezeCols > ui.colIndx) {
        //     event.preventDefault();
        //     event.stopPropagation();
        //     return false;
        // }
    },
    editorBegin: onCellBeginEdit,
    postRenderInterval: -1,

}

var originalOnKeyDown;

onMounted(() => {


    // // 기존 onKeydown 메소드 저장
    // originalOnKeyDown = $.paramquery.cKeyNav.prototype.onKeyDown;

    // // 새로운 onKeydown 메소드 정의
    // $.paramquery.cKeyNav.prototype.onKeyDown = function (evt) {

    //     const gridInstance = this.that;

    //     if (!evt.ctrlKey && !evt.metaKey && evt.key.length === 1 && evt.code !== 'Space') {
    //         const focusedCell = gridInstance.pqGrid('getFocusCell');
    //         if (focusedCell && !focusedCell.hasClass('pq-cell-edit')) {
    //             const rowIndx = focusedCell.attr('rowIndxPage');
    //             const colIndx = focusedCell.attr('colIndx');
    //             const cell = { rowIndxPage: rowIndx, colIndx: colIndx };

    //             if (gridInstance.isEditable(cell)) {
    //                 gridInstance.editCell(cell);

    //                 const editor = gridInstance.getCellEditor(cell);
    //                 if (editor) {
    //                     setTimeout(() => {
    //                         editor.$inp.val(evt.key);
    //                     }, 0);
    //                 }
    //             }
    //         }
    //     }

    //     // 원래 onKeyDown 메소드 호출
    //     originalOnKeyDown.call(this, evt);
    // };    


    cClose = props.cClose       // 닫기 버튼

    let baseGrid = {
        refCode: refCode,
        baseComponentData: baseComponentData,
        baseBindingData: baseBindingData,
        menuCode: menuCode,
        gridType: gridType,
        options: options,
        grdColumns: grdColumns,
        treeModel: treeModel,
        onEditable: onEditable,
        onGrdCellButtonClick: onGrdCellButtonClick,
        onColumnCls: onColumnCls,
        setCellData: setCellData ,
        getRowSelectedIndexByRadio: getRowSelectedIndexByRadio,
        getRowSelectedDataByRadio: getRowSelectedDataByRadio,
        getRowSelectedIndex: getRowSelectedIndex,
        getRowSelectedData: getRowSelectedData,
        getCellSelectedColumnIndex: getCellSelectedColumnIndex,

    }
    //let currentCon = getCurrentInstance()
    if(baseComponentData != null) {
        // 값이 없을 경우는 GridbaseInit을 이용하여 처리
        GridInit(baseGrid)
    }
})

/**
 * 초기 값을 수동으로 주입
 * @param {*} data 그리드 초기값들
 */
function GridbaseInit(lBaseComponentData, lGridType) {
    baseComponentData = lBaseComponentData
    gridType = lGridType
    
    let baseGrid = {
        refCode: refCode,
        baseComponentData: baseComponentData,
        baseBindingData: baseBindingData,
        menuCode: menuCode,
        gridType: gridType,
        options: options,
        grdColumns: grdColumns,
        treeModel: treeModel,
        onEditable: onEditable,
        onGrdCellButtonClick: onGrdCellButtonClick,
        onColumnCls: onColumnCls,
        setCellData: setCellData ,
        getRowSelectedIndexByRadio: getRowSelectedIndexByRadio,
        getRowSelectedDataByRadio: getRowSelectedDataByRadio,
        getRowSelectedIndex: getRowSelectedIndex,
        getRowSelectedData: getRowSelectedData,
        getCellSelectedColumnIndex: getCellSelectedColumnIndex,

    }

    // 타이틀 설정
    options.title = baseComponentData.COMPONENT_CODE == null ? '' : t(baseComponentData.COMPONENT_NAME)

    GridInit(baseGrid)
}

/** 그리드 초기화 */
function GridInit(baseGrid) {
    // colModel을 ottions.colModel에 자동으로 붙여줌
    let colModel = createColModel(t, baseGrid)
    options['colModel'] = colModel.columns

    if(baseGrid.baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID') {
        var groupModel = {            
            on: true, //grouping mode.
            pivot: true, //pivotMode
            //checkbox: true, checkboxHead: true, select: true,
            //titleInFirstCol: true,
            //titleIndx: 'grp', //v7.0.0: new option instead of titleInFirstCol
            //indent: 20, fixCols: false,
            // groupCols: ['year'], //grouping along column axis.
            // agg:{ //aggregate fields.
            //     gold: 'sum',
            //     silver: 'sum',
            //     bronze: 'sum',
            //     total: 'sum'                
            // },
            header: false, //hide grouping toolbar.
            //grandSummary: true, //show grand summary row.           
            // dataIndx: ['country', 'sport'], //grouping along row axis.
            collapsed: [true, true],
            fillHandle: '',
            summaryEdit: false
        }
        options['groupModel'] = groupModel
        options['toolPanel'] = { show: true }
    }
    
    emit("PreInitOption", options)

    // 그리드 생성
    grid.value = pq.grid(grd.value, options)
    
    // 고정 필드 설정
    if((colModel.freezeCols || '') != '') {
        let freezeCols = grid.value.getColIndx( { dataIndx: colModel.freezeCols } )
        if((freezeCols || '') != '') {
            grid.value.options['freezeCols'] = freezeCols+1
        }
    }
    $(grid.value.element).pqGrid("refreshDataAndView")

    

    // $(grid.value.element).on("keydown", function (evt) {
    // })

    
    // 우상단 버튼
    //$('.pq-slider-icon').append('<span class="ui-widget-header pq-ui-button"><span class="ui-icon ui-icon-arrow-4-diag"></span></span>')
    //$('.pq-slider-icon').prepend('<span class="ui-icon ui-icon-flag" title="개인화 적용"></span>')


    grdInitFinish()
}

/** 업데이트 시작 */
function beginupdate() {

}

/** 업데이트 종료 */
function endupdate() {

}

function updatebounddata() {
}

function getrows() {
    return grid.value.getData()
}

/** 로딩 시작 처리 */
function showloadelement() {
    if (grid != null && grid.value.hasOwnProperty('element')) {
        $(grid.value.element).pqGrid("showLoading")
    }

}

/** 로딩 종료 */
function hideloadelement() {
    if (grid != null && grid.value.hasOwnProperty('element')) {
        $(grid.value.element).pqGrid("hideLoading")
    }

}

/** 그리드 초기화 종료 */
function grdInitFinish() {
    emit('InitFinish', grid)
}

/** 그리드 데이터 초기화 */
function grdDataClear() {
    try {
        grid.value.clear()
        grid.value.options.dataModel.data = []
        grdEditList.splice(0, grdEditList.length)
        grdCheckList.splice(0, grdCheckList.length)
        grdSearchParams = Object.assign({}, grdOrgSearchParams)

    } catch(err) {
        console.log('grdDataClear')
        console.dir(err)
    }
}

/**
 * 조회 기능(그리드 마스터 / ref 사용)
 * @param {*} paramsRef 조회 인자(ref 사용)
 * @param {*} fixDatasRef 고정 인자(ref 사용)
 * @param {string} options { hideMessageYN: alert 사용 여부 }
 */
async function grdSearchRef(paramsRef, fixDatasRef, options) {
    if(options == null) options = {}
    let params = {}
    if (paramsRef != null) {
        var tmpkeys = Object.keys(paramsRef)
        for (let i = 0; i < tmpkeys.length; i++) {
            params[tmpkeys[i]] = paramsRef[tmpkeys[i]].value
        }
    }
    let fixDatas = {}
    if (fixDatasRef != null) {
        var tmpkeys = Object.keys(fixDatasRef)
        for (let i = 0; i < tmpkeys.length; i++) {
            fixDatas[tmpkeys[i]] = paramsRef[tmpkeys[i]].value
        }
    }

    return await grdSearch(params, fixDatas, options)
}

/**
 * 조회
 * @param {JSON} params 조회 조건(없는 경우 기존 조건을 이용)
 * @param {string} fixDatas 고정 조회 조건(없는 경우 기존 조건 사용)
 * @param {string} options { hideMessageYN: alert 사용 여부 }
*/
 async function grdSearch(params, fixDatas, options) {
    if(baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID') {
        // pivot이 조회할때 풀지 않으면 데이터가 제대로 표현되지 않음(조회 끝난뒤에 다시 true처리함)
        grid.value.Group().option({
            pivot: false
        });
    }

    if(options == null) options = {}
    // 조회 조건을 임시 기록
    if (params != null) {
        grdSearchParams = Object.assign(grdSearchParams, params)
    }

    // 조회 시 고정으로 포함 시켜야 하는 값 추가
    if (fixDatas != null) {
        grdSearchParams = Object.assign(grdSearchParams, fixDatas)
    }

    grdOrgSearchParams = Object.assign({}, grdSearchParams)

    var tmp_data = grid.value.options.dataModel

    if ((grdEditList.length > 0 || grdCheckList.length) && options['hideMessageYN'] != 'Y' ) {
        // 수정 또는 삭제 할 내역이 있습니다.\n 작업 데이터를 버리고 조회 하시겠습니까?"
        const tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0003') })
        if (tmpAlert == false) return
    }
    showloadelement();

    grdEditList.splice(0, grdEditList.length)
    grdCheckList.splice(0, grdCheckList.length)
    grdSaveData.splice(0, grdSaveData.length)
    grid.value.commit()

    // 수정 사항 초기화
    try {
        tmp_data.data.splice(0, tmp_data.data.length);
    } catch (err) {
        console.log('grdSearch')
        console.dir(err)
    }
    var pref = '';
    if (baseComponentData.SYSTEM_TYPE == 'SYS') {
        pref = 'Sys';
    }

    let refData = {
        GRID_TYPE : baseComponentData.COMPONENT_TYPE
    }

    store.dispatch('NGCore/get' + pref + 'Data', { METHOD_CODE: baseComponentData.COMPONENT_CODE, pref: baseComponentData.SYSTEM_TYPE, paramsdata: grdSearchParams, REF_DATA: refData, REF_CODE: baseComponentData.REF_CODE }).then((resdata) => {
        let rtndata = resdata.rtndata;
        switch (gridType) {
            case "ALLUNPIVOTGRID":      // 전체를 UNPIVOT처리

                for (var i = 0; i < rtndata.length; i++) {
                    //grdDataOrg <== 받은 데이터를 pivot 처리하여 넣어줘야 함
                }

                grdLocalDataBinding(rtndata, null)
                break

            case 'PIVOTGRID':
                // 헤더를 재구성 해줘야 함
                
                grdLocalDataBinding(rtndata, resdata)
                break
            default:
                grdLocalDataBinding(rtndata, null)
                break
        }

    }).catch((error) => {
        // error.response.data를 이유하여 적저리 처리해야함
        // 처리 중 오류 발생
        console.log('grdSearch2')
        console.dir(error)
        
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })
        $(grid.value.element).pqGrid("refreshDataAndView")

        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

        hideloadelement()
    })

}


/**
 * 데이터를 직접 그리드에 바인딩 처리
 */
function grdLocalDataBinding(data, resultRawData) {
    if(baseComponentData.COMPONENT_TYPE == 'PIVOTGRID') {       // pivot grid인 경우
        hideloadelement()
        
        debugger
        // pivot 컬럼 구성
        createPivotColumn(resultRawData.pivot)
        debugger

        // data를 pivot 처리를 위한  기본데이터 생성
        var pivotBase = {
            // key 컬럼
            keys: [],
            // 디매저 컬럼
            demancer: [],
            // 매저 컬럼
            mazer: [],

        }

        // pivot 컬럼에 맞게 데이터 재구성
        // pivotBase 을 이용해서 key컬럼으로 중복 데이터 체크 없으면 신규 생성
        // 신규 컬럼 생성 시 디맨저로 기본 데이터 등록
        // 매저 컬럼을 이용해서 key부분 생성 및 데이터 바인딩
    }

    var tmp_data = grid.value.options.dataModel
    // 수정 사항 초기화
    try {
        tmp_data.data.splice(0, tmp_data.data.length);
    } catch (err) {
        console.log('grdLocalDataBinding')
        console.dir(err)
    }


    // 초기화
    //self.$refs.grd.clear()
    beginupdate()

    grdDataOrg = data;

    updatebounddata()

    // 데이터 바인딩
    var tmp_data = grid.value.options.dataModel
    if (grdDataOrg.hasOwnProperty('data')) {
        grdDataOrg.data.map((row) => {
            tmp_data.data.push(row)
        })
    } else {
        grdDataOrg.map((row) => {
            tmp_data.data.push(row)
        })
    }
    //$ㅋㄹtmp_data.data.push(...grdDataOrg) 
    // 키컬럼 설정(추가, 수정, 삭제 이력을 위한 필수사항)

    tmp_data.recIndx = 'pq_ri'
    grid.value.options.dataModel = tmp_data
    // $(grid.value.element).pqGrid('option', 'dataModel', tmp_data)
    // //$(grid.value.element).pqGrid('dataModel', grdSource.localdata)
    // $(grid.value.element).pqGrid("refreshDataAndView")

    rowSelectedIndexByRadio = null
    rowSelectedDataByRadio = null
    rowSelectedIndex = null
    rowSelectedData = null
    cellSelectedColumnIndex = null
    grid.value.setSelection(null)
    grdEditList.splice(0, grdEditList.length);
    grdCheckList.splice(0, grdCheckList.length);
    //grid.value.rollback()        // 수정 내역 최소처리

    // 자동 선택 오류가 있어서 빼버림
    // // 내용이 있는 경우 첫 row 자동 선택
    // if (getrows().length > 0) {
    //     try {
    //         grid.value.setSelection({ rowIndx: getrows()[0].pq_ri, colIndx: 0 })
    //     } catch(err) {
    //         console.log('grdLocalDataBinding')
    //         console.dir(err)
    //     }
    // }


    grid.value.refreshDataAndView()

    let picList = grid.value.colModel.filter((row) => row.orgColumn != null && row.orgColumn.COLUMN_COMP_TYPE == 'IMAGE')
    let tmpData = grid.value.getData()
    for(let row = 0; row < tmpData.length; row++) {
        for(let col = 0; col < picList.length; col++) {
            let tmpUrl = grid.value.getData()[row][picList[col].orgColumn.REF_COL_01+'_URL']
            grid.value.Pic().add({ src: tmpUrl, from: [5, 1, row, 1] })
        }
    }
    

    if(baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID') {
        // false 처리한 pivot을 다시 true로 변경함
        grid.value.Group().option({
            pivot: true
        });
    }

    //grid.value.Range( "2:2" )    
    endupdate()
    hideloadelement();


}

function joinArrayWithSeparator(array, separator = '__') {
  const length = array.length;
  const limit = length > 1 ? length - 1 : length;

  return array.slice(0, limit).join(separator);
}

/**
 * pivot용 컬럼 생성
 * @param {ArrayList} pivotData pivot 관련 데이터
 */
function createPivotColumn(pivotData) {
    // 백업해야함
    // baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN
    if((baseComponentData_COLUMN_ORG || '') == '') {
        baseComponentData_COLUMN_ORG = JSON.parse(JSON.stringify(baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN))
    }
    
    // 원본 컬럼 형태 가져옴
    baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN = JSON.parse(JSON.stringify(baseComponentData_COLUMN_ORG))


    // 기존에 PIVOT 컬럼을 모두 제거하는 로직 필요
    debugger

    let pivot_data_columns = Object.keys(pivotData[0])      // 받아온 데이터의 컬럼 정보
    let pivot_columns = baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((row) => (row.PIVOT_PARENT_CODE || '') != '' || baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((row2) => row2.PIVOT_PARENT_CODE == row.COLUMN_CODE).length > 0 ).map((row) => row.COLUMN_CODE)
    let pivot_columns_binding = {}
    for(let i = 0; i < pivot_columns.length; pivot_columns++) {
        pivot_columns_binding[pivot_columns[i]] = pivot_columns[i].filter((col) => pivot_data_columns.includes(col)).length == 0 ? 'N' : 'Y'
    }

    baseComponentData.PIVOT_COLUMNS = pivot_columns
    for(let i = 0; i < pivot_columns.length; i++) {
        let tmpColumn = baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((row) => row.COLUMN_CODE == pivot_columns[i])
        if(tmpColumn.length == 1) {
            // 컬럼 생성
            let deepColumns = createUniqColumn(pivotData, tmpColumn[0].COLUMN_CODE)
            for(let j = 0; j < deepColumns.length; j++) {
                let tmpKeys = Object.keys(deepColumns[j])
                let newColumnData = Object.assign({}, tmpColumn[0])        // 컬럼 복사
                newColumnData.COLUMN_CODE_ORG = newColumnData.COLUMN_CODE
                
                //let tmpColMn1 = 'P___' + tmpKeys.join('__') + '___' + Object.values(deepColumns[j]).join('__')
                let tmpColMn1 = 'P___' + Object.values(deepColumns[j]).join('__')
                if(tmpColMn1.endsWith('__')) {
                    tmpColMn1 = tmpColMn1.substring(0, tmpColMn1.length-2)
                }
                if(tmpColMn1.endsWith('_')) {
                    tmpColMn1 = tmpColMn1.substring(0, tmpColMn1.length-1)
                }
                newColumnData.COLUMN_CODE = tmpColMn1
                newColumnData.PIVOT_COLUMN_YN = 'Y'     // PIVOT 컬럼으로 설정해서 추 후 저장 기능 할때 활용해야 함
                newColumnData.COLUMN_NAME_HEADER = (deepColumns[j][tmpKeys[tmpKeys.length-1]] || newColumnData.COLUMN_CODE_ORG)  //deepColumns[j][tmpKeys[tmpKeys.length-1]]

                if((newColumnData.PIVOT_PARENT_CODE || '') != '') {
                    //let tmpColNm2 = 'p___' + joinArrayWithSeparator(tmpKeys, '__') + '___' + joinArrayWithSeparator(Object.values(deepColumns[j]))
                    let tmpColNm2 = 'P___' + joinArrayWithSeparator(Object.values(deepColumns[j]))
                    if(tmpColNm2.endsWith('__')) {
                        tmpColNm2 = tmpColNm2.substring(0, tmpColNm2.length-2)
                    }
                    if(tmpColNm2.endsWith('_')) {
                        tmpColNm2 = tmpColNm2.substring(0, tmpColMn1.length-1)
                    }
                    newColumnData.PARENT_COLUMN_CODE = tmpColNm2
                }
                newColumnData.PIVOT_PARENT_CODE = ''        // pivot컬럼을 생성 후에는 상위 pivot code를 제거하여 공통 로직에서 위치 조정을 막음

                newColumnData.PIVOT_PATH = 'P___' + joinArrayWithSeparator(tmpKeys, '__') + '___' + joinArrayWithSeparator(Object.values(deepColumns[j]))
                baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.push(newColumnData)
                // COLUMN_CODE_ORG
                // COLUMN_CODE
                // PIVOT_COLUMN_YN

            }
            //createColumnList = createColumnList.concat(deepColumns)
            //let newColumnData = Object.assign({}, tmpColumn)        // 컬럼 복사

        }
    
    }

    
    debugger
    // 모든 PIVOT 컬럼 완성 후 베이스가 되는 PIVOT 컬럼 제거
    for(let i = pivot_columns.length-1; i >= 0; i--) {
        let tmpColumn = baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((row) => row.COLUMN_CODE == pivot_columns[i])
        if(tmpColumn.length > 0) {
            baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.splice(baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.indexOf(tmpColumn[0]), 1)
        }
    }
    debugger

    let baseGrid = {
        refCode: refCode,
        baseComponentData: baseComponentData,
        baseBindingData: baseBindingData,
        menuCode: menuCode,
        gridType: baseComponentData.COMPONENT_TYPE,
        options: options,
        grdColumns: grdColumns,
        treeModel: treeModel,
        onEditable: onEditable,
        onGrdCellButtonClick: onGrdCellButtonClick,
        onColumnCls: onColumnCls,
        setCellData: setCellData ,
        getRowSelectedIndexByRadio: getRowSelectedIndexByRadio,
        getRowSelectedDataByRadio: getRowSelectedDataByRadio,
        getRowSelectedIndex: getRowSelectedIndex,
        getRowSelectedData: getRowSelectedData,
        getCellSelectedColumnIndex: getCellSelectedColumnIndex,

    }    

    grid.value.destroy()

    // 컬럼 대상 정보 재생성 후 호출
    let colModel = createColModel(t, baseGrid)
    options['colModel'] = colModel.columns

    debugger
    // 그리드 생성
    grid.value = pq.grid(grd.value, options)

    

    grid.value.refreshHeader( );

    // // 그리드 생성
    // grid.value = pq.grid(grd.value, options)
    
    // // 고정 필드 설정
    // if((colModel.freezeCols || '') != '') {
    //     let freezeCols = grid.value.getColIndx( { dataIndx: colModel.freezeCols } )
    //     if((freezeCols || '') != '') {
    //         grid.value.options['freezeCols'] = freezeCols+1
    //     }
    // }    

}

function createUniqColumn(pivotData, column_code) {
    let deepColumns = getDeepColumn(column_code).split('__')

    // 중복된 값들을 담을 배열 생성
    const duplicates = [];


    pivotData.forEach((pivotD) => {     // 전체
        let flg = true
        let tmpPutData = {}
        for(let i = 0; i < duplicates.length; i++) {
            let flg_sub = true
            let dupD = duplicates[i]


            // key별 중복 체크
            for(let j = 0; j < deepColumns.length; j++) {
                let uKey = deepColumns[j]
                if(pivotD[uKey] != dupD[uKey]) {        // 한개라도 중복이 아닌경우
                    flg_sub = false
                    break
                }
            }
            
            if(flg_sub == true) {       // 중복이 발생했을 경우 처리
                flg = false
                break
            }
        }

        if(flg == true) {       //flg : true면 중복 없음
            let tmpD = {}
            for(let j = 0; j < deepColumns.length; j++) {
                let uKey = deepColumns[j]
                tmpD[uKey] = pivotD[uKey]
            }
            duplicates.push(tmpD)
        }
    })

    // 중복된 값을 가진 항목 출력
    console.log(duplicates);

    return duplicates
}

/** 컬럼의 깊이를 배열로 반환 */
function getDeepColumn(column_code) {
    let tmpc = ''
    let tmpcolumn = baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((row) => row.COLUMN_CODE == column_code)
    if(tmpcolumn.length == 1) {     // 해당 컬럼이 존재
        tmpc = tmpcolumn[0].COLUMN_CODE
        let tmpc_ch = ''
        if((tmpcolumn[0].PIVOT_PARENT_CODE || '') != '') {
            tmpc_ch = getDeepColumn(tmpcolumn[0].PIVOT_PARENT_CODE)
        }
        if(tmpc_ch != '') {
            tmpc = tmpc_ch + '__' + tmpc
        }
    }

    return tmpc
}

// 
/**
 * grd row 추가
 * @param {JSON} data               등록할 ROW 정보
 * @param {String} insertType       insert처리할 위치(auto: 포커스 없으면 맨끝, focus: 포커스 아래, first: 첫행, last: 맨끝)
 * @returns 
 */
 function grdAddRow(data = {}, insertType = 'auto') {
    var dataCount = grid.value.getData().length
    var selectData = grid.value.Selection().getSelection()
    if (insertType === 'focus' && dataCount > 0 && selectData.length == 0) {
        // 행을 먼저 선택 하세요.
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
        return
    }

    try {
        var tmpindex = 0
        switch (insertType) {
            case 'focus':
                if (dataCount > 0 && selectData != null) {
                    tmpindex = selectData[selectData.length - 1].rowIndx + 1
                } else {
                    tmpindex = 0
                }
                break
            case 'first':
                tmpindex = 0
                break
            case 'last':
                tmpindex = dataCount
                break
            case 'auto':
                if((rowSelectedIndex || '') == '') {
                    tmpindex = dataCount
                } else {
                    tmpindex = rowSelectedIndex + 1
                }
                break

            default:
                tmpindex = 0
                break
        }

        var tmp_data = { ROWRADIO: 'N', METHOD: 'A', ROWCHECK: 'N' }
        var tmp_keys = Object.keys(data)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (data[tmp_keys[rowidx]] != null && data[tmp_keys[rowidx]] != '') {
                tmp_data[tmp_keys[rowidx]] = data[tmp_keys[rowidx]]
            } else {
                tmp_data[tmp_keys[rowidx]] = ''
            }
        }
        tmp_data['pq_ri'] = dataCount

        // for(let col = 0; col < this.grdDataAdapter._source.datafields.length; col++) {
        //     // type도 신경 써야 할것으로 보임
        //     if(!tmp_data.hasOwnProperty(this.grdDataAdapter._source.datafields[col].name)) {
        //         tmp_data[this.grdDataAdapter._source.datafields[col].name] = null
        //     }
        // }

        //this.$refs.grd.addrow(null, tmp_data, tmpindex);
        // 행 추가와 같이 데이터 넣는건 현재 버그 인거 같음
        grid.value.addRow({ rowData: Object.assign({}, tmp_data), rowIndx: tmpindex })
        grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        // if (tmpindex !== 0) {
        //     //grid.value.addRow({ newRow: Object.assign({}, tmp_data), rowIndx: tmpindex })
        //     grid.value.addRow({ newRow: Object.assign({}, tmp_data), rowIndx: tmpindex })
        //     grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        // } else {
        //     grid.value.addRow({ newRow: {} })
        // }
        //grid.value.options.dataModel.data[tmpindex] = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        let tmp = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        
        var tmp_keys = Object.keys(tmp)
        for(let ii = 0; ii < tmp_keys.length; ii++) {
            grid.value.options.dataModel.data[tmpindex][tmp_keys[ii]] = (tmp_data[tmp_keys[ii]] || null)
        }
        //grid.value.refreshRow( {rowIndx:tmpindex} )

        // 수정 이력으로 추가
        if (grdEditList.indexOf(grid.value.getData()[tmpindex]) === -1) {
            grdEditList.push(grid.value.getData()[tmpindex])
        }

        // 선택이 없으면 추가된 row를 자동 선택
        if (grid.value.Selection().getSelection().length === 0) {
            grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        }

        
        //grid.value.refreshCell({ rowIndx: tmpindex, dataIndx: 'METHOD' })

        // if(insertType === 'last') {
        //     grid.value.setSelection( { rowIndx: tmpindex, colIndx: tmpindex })
        // }

    } catch (err) {
        console.log('grdAddRow')
        console.dir(err)
    }

}

/**
 * 선택이 있으면 하위 선택이 없으면 root에 컬럼 추가
 */
 function grdAddRowChild(data = {}) {

    var dataCount = grid.value.getData().length
    //var selectData = grid.value.Selection().getSelection()
    var tmpDataList = grid.value.getData()

    try {
        // 기초 데이터 준비
        var tmp_data = { ROWRADIO: 'N', METHOD: 'A', ROWCHECK: 'N' }
        var tmp_keys = Object.keys(grdColumns)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (data[tmp_keys[rowidx]] != null && data[tmp_keys[rowidx]] != '') {
                tmp_data[tmp_keys[rowidx]] = data[tmp_keys[rowidx]]
            } else {
                tmp_data[tmp_keys[rowidx]] = ''
            }

            // if (rowSelectedIndex != null) {
            //     // TREE의 경우 선택한 로우를 상위로 설정
            //     tmp_data[this.treeModel.parentId] = tmpDataList[rowSelectedIndex].SEQ
            //     tmp_data['id'] = tmp_data[this.treeModel.id]
            //     tmp_data['parentId'] = tmp_data[this.treeModel.parentId]
            // }
        }

        var tmpindex = 0
        // if(this.componentType === 'TREEGRID' && rowSelectedIndex != null) {
        //     // radio selection이 있는 경우
        //     var tmpTree = grid.value.Tree()
        //     tmpTree.addNodes([tmp_data])

        // } else {

        // 행 추가와 같이 데이터 넣는건 현재 버그 인거 같음
        if (rowSelectedIndex != null) {
            // 셀렉트가 있는 경우
            var tmpindex = grid.value.Selection().getSelection()[grid.value.Selection().getSelection().length - 1].rowIndx + 1
            //grid.value.addRow({ newRow: tmp_data, rowIndx: tmpindex })
            grid.value.Tree().addNodes([tmp_data], tmpindex)
        } else {
            // 선택이 없는 경우
            grid.value.addRow({ newRow: {}, rowIndx: dataCount })
            tmpindex = dataCount
        }

        let tmp = Object.assign(grid.value.options.dataModel.data[tmpindex], tmp_data)
        
        var tmp_keys = Object.keys(tmp)
        for(let ii = 0; ii < tmp_keys.length; ii++) {
            grid.value.options.dataModel.data[tmpindex][tmp_keys[ii]] = (tmp_data[tmp_keys[ii]] || null)
        }

        grid.value.refreshRow( {rowIndx:tmpindex} )
        // }

        // 수정 이력으로 추가
        if (grdEditList.indexOf(tmp_data) === -1) {
            grdEditList.push(grid.value.getData()[tmpindex])
        }

        // 선택이 없으면 추가된 row를 자동 선택
        if (grid.value.Selection().getSelection().length === 0) {
            grid.value.setSelection({ rowIndx: tmpindex, colIndx: 0 })
        }
        grid.value.refreshCell({ rowIndx: tmpindex, dataIndx: 'METHOD' })

    } catch (err) {
        console.log('grdAddRowChild')
        console.dir(err)
    }

}

/**
 * 그리드 행 삭제(DB에 반영하지 않음)
 * { deleteRowYN : 행 삭제(숨김) 처리, hideMessageYN: alert 사용 여부 }
 */
async function grdDeleteRow(options) {
    if(options == null) options = {}
    //var dataCount = grid.value.getData().length
    
    //var deleteList = []
    if(grdCheckList.length == 0) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0010') })
        return false
    }

    // 정말로 삭제 대상으로 하겠습니까?(N을 넣으면 메시지 표시)
    if(options['hideMessageYN'] == 'N') {
        let rtn = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHK_DELETE') })
        if(rtn == false) return false
    }
    
    grdCheckList.map(function (row) {
        //if (row.pq_ri !== null) {
            if(row['METHOD'].indexOf('A') === -1) {
                if(row['METHOD'].indexOf('D') === -1) {
                    row.METHOD = row.METHOD + 'D';
                    row.ROWCHECK = 'N';
                    if(options['deleteRowYN'] == 'Y') {
                        grid.value.deleteRow({ rowIndx: row.pq_ri })
                    } else {
                        var datamodeltemp = grid.value.options.dataModel.data;
                        var datamodeltemppqindex = datamodeltemp.findIndex((element) => element.pq_ri == row.pq_ri);
                        grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'METHOD' })
                        grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'ROWCHECK' })
                    }
                    grdEditList.push(row);
                }
            } else {
                grdEditList.splice(grdEditList.indexOf(row), 1)
                grid.value.deleteRow({ rowIndx: row.pq_ri })
            }

        //}
    })
    // 삭제 처리 후 체크리스트 클리어
    grdCheckList.splice(0, grdCheckList.length)

    return true

}

async function grdDeleteWithDB(reloadYn = 'Y', serviceUri = '') {
    if(grdCheckList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0010') })
        return
    }

    // DB에서 데이터 삭제후 조회
    // 정말로 삭제하시겠습니까?
    const tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0002') })
    if (tmpAlert == false) return

    showloadelement()

    // 처리 대상 추출
    let params = []
    
    beginupdate()
    for(let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
        var row =  grdCheckList[rowIdx]

        if(row['METHOD'].indexOf('A') === -1) {
            var tmp_data = Object.assign({}, row)
            tmp_data.METHOD = 'D'
            params.push(tmp_data);
        } else {
            grid.value.deleteRow({ rowIndx: row.pq_ri })
        }

        var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
        grdEditList.splice(pqindex, 1);
    }
    endupdate()
    
    if(params.length == 0) {
        self.hideloadelement();
        return
    }

    try {
        let pref = '';
        if (baseComponentData.SYSTEM_TYPE == 'SYS') {
            pref = 'Sys';
        }
        let rtndata = await store.dispatch('NGCore/save' + pref + 'Data', { METHOD_CODE : baseComponentData.COMPONENT_CODE, paramsdata : params, serviceuri : serviceUri, REF_CODE: baseComponentData.REF_CODE })
        if(options['hideMessageYN'] != 'Y') {
            await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0003') })
        }

        if(reloadYn) {
            // 변경 내역 초기화
            grid.value.commit()
            // self.grdEditList.splice(0, self.grdEditList.length);
            grdCheckList.splice(0, grdCheckList.length);
            grdSearch(grdSearchParams)
        } else {
            // 재 조회를 하지 않으면 수기 초기화 로직 필요
            beginupdate()
            grid.value.commit({ type: 'delete'})
            grdCheckList.splice(0, grdCheckList.length);
            se.endupdate()
        }

        return true

    } catch(err) {
        // error.response.data를 이유하여 적저리 처리해야함
        // 처리 중 오류 발생
        console.log('grdDeleteWithDB')
        console.dir(err)
        
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })
        $(grid.value.element).pqGrid("refreshDataAndView")

        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

        hideloadelement()

        return fals

    }
}

/**
 * DB에 반영(현재 사용 안함)
 */
function grdRemoveRow(reload_yn = true, serviceUri = '') {
    // *** 추가 한 항목만 삭제 할 경우 재조회는 하지 않음
    //     필요성도 없고 수정중이라면 재조회를 싫어 할수 있음(아니면 재조회를 물어보던가)

    // 삭제 대상이 있는지 체크
    if (grdCheckList.length === 0) {
        // 삭제 대상을 선택 후 사용하세요.
        //this.$NUAlert({ data: { state: 'warning', contents: this.$t('MSG0010'), buttons: ['ok'] } })

        return
    }

    /*
    // D메소드 변경
    // 정말로 삭제하시겠습니까?
    this.$NUAlert({ data : { state: 'info', contents: this.$t('CONFIRM0002') } }).then(result => {
        if(result) {
            showloadelement();
            // 처리 대상 추출
            var params = []
            beginupdate()
            
            var datamodeltemp = grid.value.options.dataModel.data;
            for(let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
                var row =  grdCheckList[rowIdx]

                if(row['METHOD'].indexOf('A') === -1) {
                    row.METHOD = 'D';
                    row.ROWCHECK = 'N';
                    var datamodeltemppqindex = datamodeltemp.findIndex((element) => element.pq_ri == row.pq_ri);
                    grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'METHOD' })
                    grid.value.refreshCell({ rowIndx: datamodeltemppqindex, dataIndx: 'ROWCHECK' })
                    grdEditList.push(grdCheckList[rowIdx]);
                } else {
                    grid.value.deleteRow({ rowIndx: row.pq_ri })
                }

                // var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
                // grdEditList.splice(pqindex, 1);
            }
            endupdate()
            
            grdCheckList.splice(0, grdCheckList.length);
            grid.value.commit();
            hideloadelement();
        }
    })
    */

    // DB에서 데이터 삭제후 조회
    // 정말로 삭제하시겠습니까?
    this.$NUAlert({ data: { state: 'info', contents: this.$t('CONFIRM0002') } }).then(result => {
        if (result) {
            showloadelement();
            // 처리 대상 추출
            var params = []
            beginupdate()

            var datamodeltemp = grid.value.options.dataModel.data;
            for (let rowIdx = 0; rowIdx < grdCheckList.length; rowIdx++) {
                var row = grdCheckList[rowIdx]

                if (row['METHOD'].indexOf('A') === -1) {
                    var tmp_data = Object.assign({}, row)
                    tmp_data.METHOD = 'D'
                    params.push(tmp_data);
                } else {
                    grid.value.deleteRow({ rowIndx: row.pq_ri })
                }

                var pqindex = grdEditList.findIndex((element) => element.pq_ri == row.pq_ri);
                grdEditList.splice(pqindex, 1);
            }
            endupdate()

            if (params.length == 0) {
                hideloadelement();
                return
            }
            this.$store.dispatch('nuisys/comonsyssave', { methodcode: baseComponentData.COMPONENT_CODE, paramsdata: params, serviceuri: serviceUri }).then(
                (rtndata) => {

                    if (reload_yn) {
                        // 변경 내역 초기화
                        grid.value.commit()
                        // grdEditList.splice(0, grdEditList.length);
                        grdCheckList.splice(0, grdCheckList.length);
                        grdSearch(grdSearchParams)
                    } else {
                        // 재 조회를 하지 않으면 수기 초기화 로직 필요
                        beginupdate()
                        grid.value.commit({ type: 'delete' })
                        grdCheckList.splice(0, grdCheckList.length);
                        endupdate()
                    }
                    //this.$NUAlert({ data : { state: 'info', contents: this.$t(rtndata.msg) } })
                    //this.$NUAlert({ data: { state: 'info', contents: this.$t('MSG0022'), buttons: ['ok'] } })
                    hideloadelement();
                },
                error => {
                    // error.response.data를 이유하여 적저리 처리해야함
                    // 처리 중 오류 발생
                    //this.$NUAlert({ data: { state: 'error', contents: this.$t('ERRORMSG0009'), buttons: ['ok'] } })
                    console.log('grdRemoveRow')
                    console.dir(error)

                    hideloadelement();
                    let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
                    store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
                }
            );
        }
    })
}

/**
 * 그리드 저장
 * @param {string} hideComfirmYn 확인창 사용 여부(N: 사용)
 * @param {string} reloadYn 저장 후 재조회여부(Y: 재조회)
 * @param {JSON} options 옵션처리 기능 : { hideComfirm(N) : comfirm 숨김, hideMessageYN(N) : alert 숨김, reloadYn(Y) : 재조회 }
 * @param {Array} batch 배치 처리를 위한 정보 : [ 'BATCH' ]
 */
async function grdSave(options, batch) {
    if(options == null) options = {}
    if (grdEditList.length === 0) {
        //저장할 내용이 없습니다.
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0006') })
        return false
    }
    
    for (let rowIdx = 0; rowIdx < grdEditList.length; rowIdx++) {
        let row = grdEditList[rowIdx]
        if(!!options && !!options.boardName) {
            row['TABLENAME'] = options.boardName;
        }
        // key id에 값이 있는지 체크
        let tmp_keys = Object.keys(grdColumns)

        for (let col = 0; col < tmp_keys.length; col++) {
            let tmp_column = grdColumns[tmp_keys[col]]

            if(row.METHOD.indexOf('D') >= 0) continue   // 삭제의 경우 조건을 걸지 않음

            if(tmp_column.KEYID_YN === 'Y' && tmp_column.NULLABLE_YN === 'Y') {
                // 이경우는 추가할때 수정이 가능하지만 입력을 안해도 되는컬럼으로 인식됨
            } else if (tmp_column.KEYID_YN === 'Y' && row[tmp_keys[col]] == null | row[tmp_keys[col]] === '') {
                // key 값은 필수로 입력 하셔야 합니다[{0}]
                await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0007', [t('LABEL.'+tmp_keys[col])]) })
                //grid.value.setSelection({rowIndx: row.pq_ri,colIndx: tmp_keys[col]})        // 셀 선택
                grid.value.setSelection({rowIndx: row.pq_ri})        // 셀 선택
                return false
            }

            if (tmp_column.NULLABLE_YN === 'N' && row[tmp_keys[col]] == null | row[tmp_keys[col]] === '') {
                // 빈 값을 허용하지 않는 컬럼에 데이터가 없습니다.[{0}]
                await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0008', [t('LABEL.'+tmp_keys[col])]) })
                //grid.value.setSelection({rowIndx: row.pq_ri,colIndx: tmp_keys[col]})        // 셀 선택
                grid.value.setSelection({rowIndx: row.pq_ri})        // 셀 선택
                return false
            }
            
            // 숫자 처리 수정해야함

            // 체크박스의 경우
            if(tmp_column.COLUMN_COMP_TYPE == 'CHECKBOX') {     // null일경우 N으로 보정 처리
                row[tmp_column.COLUMN_CODE] = (row[tmp_column.COLUMN_CODE] || 'N')
            }
        }
    }

    if(options['hideComfirm'] != 'Y') {
        // 저장하시겠습니까? 확인장
        let tmpAlert = await vfmBasePopup(Comfirm, { state: 'info', contents: t('CONFIRM.CONFIRM0001') })
        if (tmpAlert == false) return
    }

    // 저자 데이터 추출전 콜백 처리
    executeGridEventManager('beforeSaveNoData', {})

    // 처리 대상 추출
    var params = []
    for (let rowIdx = 0; rowIdx < grdEditList.length; rowIdx++) {
        var row = grdEditList[rowIdx]

        // key id에 값이 있는지 체크
        var tmp_keys = Object.keys(grdColumns)

        var tmp_data = Object.assign({}, row)
        if (tmp_data.hasOwnProperty('parent')) {
            delete tmp_data.parent
        }
        if (tmp_data.hasOwnProperty('records')) {
            delete tmp_data.records
        }
        if (tmp_data.hasOwnProperty('children')) {
            delete tmp_data.children
        }

        if (tmp_data['METHOD'].indexOf('A') !== -1) {
            tmp_data['METHOD'] = 'A'
        }
        if (tmp_data['METHOD'].indexOf('D') !== -1) {
            tmp_data['METHOD'] = 'D'
        }
        
        // 제거된 컬럼이 있는 경우 복원(PQGRID에서 다른 해결 방법이 필요 / DEL로 CELL 값 제거시 현상)
        var tmp_keys = Object.keys(grdColumns)
        for (var rowidx = 0; rowidx < tmp_keys.length; rowidx++) {
            if (!tmp_data.hasOwnProperty(tmp_keys[rowidx]) || tmp_data[tmp_keys[rowidx]] === undefined) {
                tmp_data[tmp_keys[rowidx]] = null
            }
        }

        // var tmp_keys = Object.keys(tmp_data)
        // for(var rowIdx2 = 0; rowIdx2 < tmp_keys.length; rowIdx2++) {
        //     if(typeof tmp_data[tmp_keys[rowIdx2]] === 'boolean') {
        //         tmp_data[tmp_keys[rowIdx2]] = tmp_data[tmp_keys[rowIdx2]] === true ? 'Y' : 'N';
        //     }
        // }


        params.push(tmp_data);
    }

    // 저장 데이터 전송 직전에 콜백 호출
    grdSaveData = params
    executeGridEventManager('beforeSaveNoData', grdSaveData)

    var pref = '';
    if (baseComponentData.SYSTEM_TYPE == 'SYS') {
        pref = 'Sys';
    }
    
    var param = {
        METHOD_CODE: baseComponentData.COMPONENT_CODE,
        pref: baseComponentData.SYSTEM_TYPE,
        SQLLOG_YN: 'Y',             // db log 남길지 유무
        paramsdata: grdSaveData,
        REF_CODE: baseComponentData.REF_CODE
    }
    if(batch != null) {
        param['BATCH'] = batch
    }


    showloadelement();
    // 저장
    try {
        let rtndata = await store.dispatch('NGCore/save' + pref + 'Data', param)
        if(rtndata.status && rtndata.rtndata.P_RETURN_FLAG == 'Y') {
            if(options['hideMessageYN'] != 'Y') {
                await vfmBasePopup(Alert, { state: 'info', contents: t(rtndata.rtnmsgcode) })
            }
        } else {
            await vfmBasePopup(Alert, { state: 'error', contents: t(rtndata.rtnmsgcode == null ? rtndata.errorcode : rtndata.rtnmsgcode) })
            hideloadelement()
            return false
        }

        // 저장 후 이벤트 처리
        executeGridEventManager('afterSave', {})

        beginupdate()
        // 작업 내역 모두 초기화
        grdEditList.splice(0, grdEditList.length)
        grdCheckList.splice(0, grdCheckList.length)
        grdDataOrg.splice(0, grdDataOrg.length)
        grdSaveData.splice(0, grdSaveData.length)
        grid.value.commit()
        endupdate()

        if (options['reloadYn'] != 'N') {
            // 변경 내역 초기화
            grdSearch(null, null, options)
        } else {
            // 재 조회를 하지 않으면 수기 초기화 로직 필요
            hideloadelement()
        }        
    } catch(error) {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

        return false
    }

    return true
}

////////////////////////////////////////// 엑셀 업로드 : START /////////////////////////////////////////////////
async function grdExcelUpload() {
    let input = document.querySelector('#excelUpload');
    if (!input) {
        input = document.createElement('input');
        input.type = 'file';
        input.accept = '.xlsx';
        input.id = 'excelUpload';
        input.style.display = 'none';
        input.onchange = (event) => {
            const file = event.target.files[0];
            grdExcelUploadRun(file)
            input.value = ''
        };
        document.body.appendChild(input);
    }
    input.click();

}
/**
 * 엑셀 업로드
*/
async function grdExcelUploadRun(excelFile) {
    
    try {
        const workbook = new ExcelJS.Workbook();
        await workbook.xlsx.load(excelFile);

        const worksheet = workbook.getWorksheet(1);

        const rows = worksheet.getSheetValues();

        // 엑셀 컬럼과 pqgrid 컬럼 매칭 체크
        checkColumnsMatch(grid.value.columns, rows);

        let dataKeys = Object.keys(rows)
        let firstRow = rows[dataKeys[0]];       // excel에서 받은 컬럼 정보

        // pqgrid에 업로드할 데이터 생성
        const pqData = rows.slice(grid.value.headerCells.length+2).map((row, rowIndex) => {
            const rowData = {};
            Object.keys(row).forEach((key) => {
                console.log(worksheet)
                const tmpColData = grid.value.columns[firstRow[key]]
                if(tmpColData) {
                    rowData[firstRow[key]] = (key == 'METHOD' ? 'S' : row[key])
                }
                // const column = grid.value.columns.find((col) => col.dataIndx === key);
                // if (column) {
                //     rowData[key] = row[key];
                // } else if (rowIndex === 0) {
                //     console.warn(`Column with dataIndx '${key}' not found in grid.`);
                // }
            });
            if((rowData['METHOD'] || '') == '') {
                rowData['METHOD'] = 'S'
            }
            return rowData;
        });        

        // grid.value.colModel.filter((row) => row.dataIndx == firstRow[dataKeys[3]])[0].orgColumn.COLUMN_COMP_TYPE
        // IMAGE
        // grid.value.colModel.filter((row) => row.dataIndx == firstRow[dataKeys[3]])[0].orgColumn.REF_COL_01

        // self.setCellData(ui.rowIndx, ui.dataIndx+"_FLAG", "A")  // 파일 업로드 확인용 Flag: Create
        // self.setCellData(ui.rowIndx, ui.dataIndx, fileInfo.name)
        // self.setCellData(ui.rowIndx, ui.dataIndx+"_ORG_NAME", fileInfo.name)
        // self.setCellData(ui.rowIndx, ui.dataIndx+"_EXC", fileInfo.type)
        // self.setCellData(ui.rowIndx, ui.dataIndx+"_SIZE", fileInfo.size)
        // self.setCellData(ui.rowIndx, ui.dataIndx+"_64DATA", er.target.result)        

        // 불러온 파일을 저장시 전송하기 위한 처리
        let excelImages = worksheet.getImages()
        for(let i = 0 ; i < excelImages.length; i++) {
            const nRow = excelImages[i].range.tl.nativeRow      // 이미지의 행 위치
            if(pqData.length+1 < nRow) continue           // 데이터 행수보다 큰 행인 경우는 무시(+1는 숨김행(0), 해더행을 더한것임(1))

            const nCol = excelImages[i].range.tl.nativeCol      // 이미지의 열 위치
            let pqgridImageCol = grid.value.colModel.filter((row) => row.dataIndx == firstRow[nCol+1])
            if(pqgridImageCol.length == 1 && pqgridImageCol[0].orgColumn.COLUMN_COMP_TYPE == 'IMAGE') {       // 대상 컬럼이 있는 경우
                let pqgridTargetCol = grid.value.colModel.filter((row) => row.dataIndx == pqgridImageCol[0].orgColumn.REF_COL_01)      // 실제 이미지 대상이 되는 컬럼
                if(pqgridTargetCol.length == 1) {
                    const targetRowWithImage = pqData[nRow-(grid.value.headerCells.length+1)]
                    const targetValue = targetRowWithImage[pqgridTargetCol[0].dataIndx]         // 파일명

                    if(targetValue) {
                        const excelFileInfo = workbook.media[excelImages[i].imageId]     // 엑셀의 이미지 정보
                        // 이미지 blob 생성
                        const imageBlob = new Blob([excelFileInfo.buffer], { type: excelFileInfo.type + '/' + excelFileInfo.extension });
                        // 이미지 file 생성
                        const imageFile = new File([imageBlob], targetValue, { type: excelFileInfo.type + '/' + excelFileInfo.extension });

                        targetRowWithImage[pqgridTargetCol[0].dataIndx + '_FLAG'] = 'A'
                        targetRowWithImage[pqgridTargetCol[0].dataIndx] = imageFile.name
                        targetRowWithImage[pqgridTargetCol[0].dataIndx + '_ORG_NAME'] = imageFile.name
                        targetRowWithImage[pqgridTargetCol[0].dataIndx + '_EXC'] = imageFile.type
                        targetRowWithImage[pqgridTargetCol[0].dataIndx + '_SIZE'] = imageFile.size
                        targetRowWithImage[pqgridTargetCol[0].dataIndx + '_64DATA'] = 'data:' + imageFile.type + ';base64,' + excelFileInfo.buffer.toString('base64')
                    }
                }
            }

        }

        // 데이터 초기화
        grdDataClear()

        // 데이터 바인딩
        var tmp_data = grid.value.options.dataModel
        if (pqData.hasOwnProperty('data')) {
            pqData.data.map((row) => {
                tmp_data.data.push(row)
            })
        } else {
            pqData.map((row) => {
                tmp_data.data.push(row)
            })
        }

        // grid.value.option("dataModel.data", pqData);
        grid.value.refreshDataAndView();

        // 수정 내역 등록
        const tmpRows = grid.value.getData()
        for(let i = 0; i < tmpRows.length; i++) {
            grdEditList.push(tmpRows[i])  
        }
    } catch(err) {
        debugger
    }
}

/**
 * 엑셀의 컬럼과 pqgrid의 컬럼과 비교
*/
function checkColumnsMatch(columns, data) {
    let columnKeys = Object.keys(columns)
    let dataKeys = Object.keys(data)
    let firstRow = data[dataKeys[0]];
    let firstRowColumns = Object.keys(firstRow);

    if (columnKeys.length !== firstRowColumns.length) {
        throw new Error("Columns count not match with Excel columns count");
    }

    for (let i = 0; i < columnKeys.length; i++) {
        if (firstRow.indexOf(columns[columnKeys[i]].dataIndx) == -1) {
            throw new Error(
                `Column(${columns[columnKeys[i]].dataIndx}) not match with Excel column(${firstRow[firstRowColumns[i]]})`
            );
        }
    }
}

////////////////////////////////////////// 엑셀 업로드 : End ///////////////////////////////////////////////////

////////////////////////////////////////// 엑셀 다운로드 : START /////////////////////////////////////////////////
/**
 * 엑셀 다운로드
 */
async function grdExcelDown(fileName, sheetName) {
    
    // // //this.Pic().addPics([{name: '2', src: 'http://localhost:8900//uploadfiles/board/d70ff46a76e84de5bd5d8204797d90df.jpg', from: [2, 1, 1, 1]}])
    // grid.value.Pic().addPics([{name: '2', src: "http://localhost:8900//uploadfiles/board/d70ff46a76e84de5bd5d8204797d90df.jpg", from: [2,1,2,1] }])
    // setTimeout(() => {
    //     // var str = grid.value.exportExcel({render: true, format: 'Json' });                            
    //     // // saveAs(str, "pivot.xlsx");
    //     // saveAs(str, "pivot.json");

    //     // var format = 'htm',
    //     // var format = 'json',
    //     var format = 'xlsx',
    //     blob = grid.value.exportData({
    //         //url: "/pro/demos/exportData",
    //         format: format,        
    //         //nopqdata: true, //applicable for JSON export.                        
    //         render: true,
    //         type: 'json'
    //     });
    //     if(typeof blob === "string"){                            
    //         blob = new Blob([blob]);
    //     }
    //     saveAs(blob, "pqGrid."+ format );   
        
    // }, 3000);
    
    
    // // let data = grid.value.getData()
    // // let columns = baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.map((row) => row.COLUMN_CODE)
    // // let imageUrl = 'http://localhost:8900//uploadfiles/board/d70ff46a76e84de5bd5d8204797d90df.jpg'
    // // let imageCell = 'B2'
    // // exportToExcelWithImage(data, columns, 'test', 'test.xlsx', imageUrl, imageCell)

    const now = dayjs(); // 현재 날짜와 시간을 나타내는 dayjs 객체 생성
    const dateTime = now.format('YYYYMMDDHHmmss'); // 년월일시분초 포함한 날짜와
    fileName = t(fileName || baseComponentData.COMPONENT_NAME)
    sheetName = t(sheetName || baseComponentData.COMPONENT_NAME)
    exportToExcel(fileName + '_' + dateTime + '.xlsx', sheetName)

}

/**
 * 엑셀 파일을 생성하고 다운로드합니다.
 * @param {string} fileName 다운로드할 엑셀 파일의 이름
 * @param {string} sheetName 생성될 엑셀 시트의 이름
 */
 async function exportToExcel(fileName, sheetName) {
    // Get data and columns from pqgrid
    const data = grid.value.option("dataModel").data;
    const columns = grid.value.option("colModel");

    // Create a workbook
    const workbook = new ExcelJS.Workbook();

    // Create a worksheet and append data
    const worksheet = workbook.addWorksheet(sheetName);

    // Add dataIndx values to the first row and hide it
    addDataIndxToFirstRow(worksheet, columns);

    // Add titles to the second row and apply styles
    addTitlesToSecondRow(worksheet, columns);

    // 이미지 출력 컬럼 찾음(엑셀 다운로드 처리할때 해당 컬럼의 이미지를 다운로드 처리 refcode을 참고함)
    let imgColumns = columns.filter((row) => row.orgColumn && row.orgColumn.COLUMN_COMP_TYPE == 'IMAGE').map((row) => row.orgColumn.REF_COL_01)

    debugger
    // Append data rows
    for (let rowIndex = 0; rowIndex < data.length; rowIndex++) {
        const rowData = data[rowIndex];
        const row = worksheet.addRow(
            columns.map((column) => rowData[column.dataIndx])
        );

        // Apply column styles
        columns.forEach((column, colIndex) => {
            applyColumnStyles(row.getCell(colIndex + 1), column);
        });

        // image 컬럼의 경우 이미지를 다운 받을 수 있도록 로직 적용
        for(let imgCol = 0; imgCol < imgColumns.length; imgCol++) {
            const imageUrl = rowData[imgColumns[imgCol] + '_URL']
            if (imageUrl) {
                try {
                    //const imgBuffer = await fetch(imageUrl).then((response) => response.arrayBuffer());
                    const rtnImageData = await getImageInfo(ng_core.api.baseCoreApi.common.config.baseUrl + '/' + imageUrl);
                    if(rtnImageData.state == false) continue
                    debugger
                    const imgId = workbook.addImage({
                        //buffer: rtnImageData,
                        base64: rtnImageData.base64,
                        //filename: imageUrl,
                        //extension: "png",
                        extension: rtnImageData.ext,
                    });
                    let pos = {
                        tl: {
                            //col: columns.findIndex((column) => column.dataIndx === "THUMB_IMAGE"),
                            col: columns.findIndex((row) => row.orgColumn != null && row.orgColumn.COLUMN_COMP_TYPE == 'IMAGE' && row.orgColumn.REF_COL_01 == imgColumns[imgCol]),
                            row: rowIndex+2,
                        }, 
                        ext: {
                            width: 25,
                            height: 25,
                        }
                    }
                    worksheet.addImage(imgId, pos)

                
                } catch(err) {
                    debugger
                }
            }
        }
    }

    // 최초 쉘 선택
    const newCellAddress = 'A' + (grid.value.headerCells.length + 2);
    // worksheet.activeCell = worksheet.getCell(newCellAddress);
    
    worksheet.views = [
        { state: 'frozen', ySplit: (grid.value.headerCells.length+1), activeCell: newCellAddress },
    ];
       

    // Save the workbook
    const buffer = await workbook.xlsx.writeBuffer();
    saveAs(
        new Blob([buffer], { type: "application/octet-stream" }),
        fileName
    );
}

async function getImageInfo(url) {
    try {
        // Fetch the image
        const response = await fetch(url);

        // Check if the response is successful
        if (!response.ok) {
            throw new Error("Failed to fetch the image");
        }

        // Get the content type of the image
        const contentType = response.headers.get("Content-Type");

        // Check if the content type is supported
        if (!/^image\/(png|jpe?g|gif|bmp)$/.test(contentType)) {
            throw new Error("Unsupported image type");
        }

        // Get the image data as a Blob
        const blob = await response.blob();

        // Read the image data as a base64-encoded string
        const reader = new FileReader();
        const promise = new Promise((resolve, reject) => {
            reader.onload = () => resolve(reader.result);
            reader.onerror = reject;
        });
        reader.readAsDataURL(blob);
        const base64 = await promise;

        // Get the file extension from the content type
        const ext = contentType.split("/")[1];

        return {
            state: true,
            base64,
            ext,
        };
    } catch (error) {
        return {
            state: false,
            error: error.message,
        };
    }
}



/**
 * 첫 행에 dataIndx 값을 추가하고 숨깁니다.
 * @param {ExcelJS.Worksheet} ws 작업 중인 워크시트 객체
 * @param {Array} columns 엑셀에 추가될 컬럼 배열
 */
 function addDataIndxToFirstRow(ws, columns) {
    const row = ws.addRow(columns.map((column) => column.dataIndx));
    row.hidden = true;
}

/**
 * 두 번째 행에 타이틀을 추가하고 그리드 헤더 스타일을 적용합니다.
 * @param {ExcelJS.Worksheet} ws 작업 중인 워크시트 객체
 * @param {Array} columns 엑셀에 추가될 컬럼 배열
 */
 function addTitlesToSecondRow(ws, columns) {

    grid.value.headerCells.forEach((headerRow, rowIndex) => {
        let row = ws.addRow(headerRow.map((column) => column.title));

        // pqgrid 헤더 스타일을 적용합니다.
        headerRow.forEach((column, colIndex) => {
            // 첫번째일때 컬럼의 가로 사이즈 결정
            // Set column width
            ws.getColumn(colIndex + 1).width= column.width / 6; // You may need to adjust the divisor to fit your needs


            const cell = row.getCell(colIndex + 1);
            const cellstyle = grid.value.getCell({ rowIndx: rowIndex, dataIndx: grid.value.headerCells[rowIndex][colIndex].dataIndx })
            //const styles = getGridCellStyles(cellstyle);

            // // 예: 폰트 색상, 배경색, 폰트 이름을 pqgrid에서 적용합니다.
            // cell.font = {
            //     color: { argb: styles.color || "FF000000" },
            //     // color: { argb: styles.color || "FF000000" },
            //     // name: styles.fontFamily || "Calibri",
            //     // size: styles.fontSize || 12,
            // };
            
            cell.alignment = { vertical: (column.hvalign || ''), horizontal: (column.halign || '') }
            
            cell.fill = {
                type: 'pattern',
                pattern:'solid',
                fgColor: { argb: '96C8FB' },
                bgColor: { argb: '96C8FB' },
                // bgColor:{ argb: "FF000000" },
            }
            // cell.fill = {
            //     type: "pattern",
            //     pattern: "solid",
            //     fgColor: { argb: (styles.backgroundColor || "#FFFFFFFF").replace("#", "FF") },
            // };
            // cell.border = {
            //     top: { style: styles.borderTopStyle || "none", color: { argb: styles.borderTopColor.replace("#", "FF") } },
            //     left: { style: styles.borderLeftStyle || "none", color: { argb: styles.borderLeftColor.replace("#", "FF") } },
            //     bottom: { style: styles.borderBottomStyle || "none", color: { argb: styles.borderBottomColor.replace("#", "FF") } },
            //     right: { style: styles.borderRightStyle || "none", color: { argb: styles.borderRightColor.replace("#", "FF") } },
            // };
        });        
    })
    
    
    // // pqgrid 헤더 스타일을 적용합니다.
    // columns.forEach((column, colIndex) => {
    //     const cell = row.getCell(colIndex + 1);
    //     const cellstyle = grid.value.getCell({ rowIndx: 1, dataIndx: column.dataIndx })
    //     //const styles = getGridCellStyles(cellstyle);

    //     // // 예: 폰트 색상, 배경색, 폰트 이름을 pqgrid에서 적용합니다.
    //     // cell.font = {
    //     //     color: { argb: styles.color || "FF000000" },
    //     //     // color: { argb: styles.color || "FF000000" },
    //     //     // name: styles.fontFamily || "Calibri",
    //     //     // size: styles.fontSize || 12,
    //     // };
    //     cell.fill = {
    //         type: 'pattern',
    //         pattern:'solid',
    //         // fgColor:{argb:'#000000'},
    //         bgColor:{ argb: "FF000000" },
    //     }
    //     // cell.fill = {
    //     //     type: "pattern",
    //     //     pattern: "solid",
    //     //     fgColor: { argb: (styles.backgroundColor || "#FFFFFFFF").replace("#", "FF") },
    //     // };
    //     // cell.border = {
    //     //     top: { style: styles.borderTopStyle || "none", color: { argb: styles.borderTopColor.replace("#", "FF") } },
    //     //     left: { style: styles.borderLeftStyle || "none", color: { argb: styles.borderLeftColor.replace("#", "FF") } },
    //     //     bottom: { style: styles.borderBottomStyle || "none", color: { argb: styles.borderBottomColor.replace("#", "FF") } },
    //     //     right: { style: styles.borderRightStyle || "none", color: { argb: styles.borderRightColor.replace("#", "FF") } },
    //     // };
    // });
}

/**
 * 특정 셀에 컬럼 스타일을 적용합니다.
 * @param {ExcelJS.Cell} cell 스타일을 적용할 엑셀 셀
 * @param {Object} column 스타일 정보를 포함하는 컬럼 객체
 */
 function applyColumnStyles(cell, column) {
    cell.alignment = { vertical: (column.valign || ''), horizontal: (column.align || '') }
    
    // 추가 스타일 적용 부분
}

// Get grid cell styles
function getGridCellStyles(cell) {
    let style = {}
    if(cell.length > 0) {
        style = window.getComputedStyle(cell[0]);
    }
    

    return {
        backgroundColor: style.backgroundColor || '',
        color: style.color || '',
        fontFamily: style.fontFamily || '',
        fontSize: style.fontSize || '',
        fontWeight: style.fontWeight || '',
        textAlign: style.textAlign || '',
        verticalAlign: style.verticalAlign || '',
        borderTopColor: style.borderTopColor || '',
        borderRightColor: style.borderRightColor || '',
        borderBottomColor: style.borderBottomColor || '',
        borderLeftColor: style.borderLeftColor || '',
        borderTopStyle: style.borderTopStyle || '',
        borderRightStyle: style.borderRightStyle || '',
        borderBottomStyle: style.borderBottomStyle || '',
        borderLeftStyle: style.borderLeftStyle || '',
        borderTopWidth: style.borderTopWidth || '',
        borderRightWidth: style.borderRightWidth || '',
        borderBottomWidth: style.borderBottomWidth || '',
        borderLeftWidth: style.borderLeftWidth || '',
    };
}

// Get grid header styles
function getGridHeaderStyles(cell) {
    const titleElement = cell.titleElement;
    if (cell.length == 0) {
        return {};
    }
    const style = window.getComputedStyle(titleElement);

    return {
        backgroundColor: style.backgroundColor || '',
        color: style.color || '',
        fontFamily: style.fontFamily || '',
        fontSize: style.fontSize || '',
        fontWeight: style.fontWeight || '',
        textAlign: style.textAlign || '',
        verticalAlign: style.verticalAlign || '',
        borderTopColor: style.borderTopColor || '',
        borderRightColor: style.borderRightColor || '',
        borderBottomColor: style.borderBottomColor || '',
        borderLeftColor: style.borderLeftColor || '',
        borderTopStyle: style.borderTopStyle || '',
        borderRightStyle: style.borderRightStyle || '',
        borderBottomStyle: style.borderBottomStyle || '',
        borderLeftStyle: style.borderLeftStyle || '',
        borderTopWidth: style.borderTopWidth || '',
        borderRightWidth: style.borderRightWidth || '',
        borderBottomWidth: style.borderBottomWidth || '',
        borderLeftWidth: style.borderLeftWidth || '',
    };
}

/**
 * Convert a CSS border-style text to an ExcelJS border type
 *
 * @param {string} style - A CSS border-style text
 * @returns {string} The ExcelJS border type
 */
function borderStyleTextToType(style) {
    switch (style) {
        case "none":
            return "none";
        case "hidden":
            return "dotted";
        case "dotted":
            return "dotted";
        case "dashed":
            return "dashed";
        case "solid":
            return "thin";
        case "double":
            return "double";
        case "groove":
            return "medium";
        case "ridge":
            return "medium";
        case "inset":
            return "thin";
        case "outset":
            return "thin";
        default:
            return "thin";
    }
}
////////////////////////////////////////// 엑셀 다운로드 : END ///////////////////////////////////////////////////


/**
 * Convert a color in hexadecimal format (e.g. #FF0000) to ARGB format (e.g. FFFF0000)
 * @param {string} hexColor - A color in hexadecimal format (e.g. #FF0000)
 * @returns {string} A color in ARGB format (e.g. FFFF0000)
 */
 function colorHexToArgb(hexColor) {
    return "FF" + hexColor.replace("#", "");
}



// async function exportToExcel() {
//     // Get data and columns from pqgrid
//     const data = grid.value.option('dataModel').data;
//     const columns = grid.value.option('colModel');

//     // 다운로드 대상 컬럼
//     const columnNms = columns.map((column) => column.dataIndx)
//     columnNms.unshift('HSTATUS')
//     const titles = {}
//     const tmpkey = Object.keys(columns[0])
//     for(let i = 0; i < columns.length; i++) {
//         titles[columns[i].dataIndx] = columns[i].title
//     }
//     columns.map(function(column) {
//         let rtn = {}
//         let keys = Object.keys(column)
//         rtn[column.dataIndx] = column.title
//         return 
//     })
//     data.unshift(titles)

//     // 다운로드 대상 데이터 추출(대상 컬럼으로 필터)
//     const dataWithColumnNm = data.map(function(d) {
//         let tmpkey = Object.keys(d)
//         let rtn = {}
//         for(let i = 0; i < tmpkey.length; i++) {
//             if(columnNms.includes(tmpkey[i])) {
//                 rtn[tmpkey[i]] = d[tmpkey[i]]
//             }
//         }
//         return rtn
//     })

//     // Create a workbook
//     const wb = XLSX.utils.book_new();

//     debugger

//     // Create a worksheet and append data
//     const ws = XLSX.utils.json_to_sheet(dataWithColumnNm, {
//         header: columnNms,
//     });

//     // Hide the first row in Excel
//     hideFirstRowInExcel(ws, columns);

//     // // Add titles to the second row and apply styles
//     // addTitlesToSecondRow(ws, columns);

//     // Apply column styles
//     applyColumnStyles(ws, columns, data);

//     // // Append images to cells
//     // for (let i = 0; i < data.length; i++) {
//     //     if((data[i].UPLOAD1_URL || '') != '') {
//     //         const image = await fetchImageAsArrayBuffer(data[i].UPLOAD1_URL);
//     //         const imageId = `image${i + 1}`;
//     //         const imgData = {
//     //             '!images': [
//     //                 {
//     //                     name: imageId,
//     //                     data: image,
//     //                     opts: { base64: false },
//     //                     position: {
//     //                         type: 'twoCellAnchor',
//     //                         attrs: { editAs: 'oneCell' },
//     //                         from: { col: 2, row: i + 1 },
//     //                         to: { col: 3, row: i + 2 },
//     //                     },
//     //                 },
//     //             ],
//     //         };
//     //         XLSX.utils.sheet_add_image(ws, imgData);
//     //     }
//     // }

//     // Append the worksheet to the workbook and save it
//     XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
//     const wbOut = XLSX.write(wb, { type: 'binary', bookType: 'xlsx' });
//     saveAs(new Blob([s2ab(wbOut)], { type: 'application/octet-stream' }), 'export.xlsx');
// }

// function hideFirstRowInExcel(ws, columns) {
//     // Set the height of the first row to 0 to hide it
//     ws['!rows'] = ws['!rows'] || [];
//     ws['!rows'][0] = { hidden: true };

//     ws['!cols'] = ws['!cols'] || [];
//     ws['!cols'][0] = { hidden: true };
// }

// function applyColumnStyles(ws, columns, data) {
//     for (let i = 0; i < columns.length; i++) {
//         const column = columns[i];

//         // Apply header-like styles to the second row
//         const headerCellAddress = XLSX.utils.encode_cell({ c: i, r: 1 });
//         const headerCell = ws[headerCellAddress] || {};

//         // Add header-like styles from pqgrid here
//         headerCell.s = headerCell.s || {};

//         // Example: Apply font color, background color, and font name from pqgrid
//         headerCell.s.font = {
//             color: column.color || "FFAAAA00",
//             name: column.fontName || "Calibri",
//         };
//         headerCell.s.fill = {
//             fgColor: { rgb: column.bgColor || "FFAAAAFF" },
//         };

//         ws[headerCellAddress] = headerCell;

//         // Apply other styles to each cell in the column
//         for (let rowIndex = 2; rowIndex <= data.length + 1; rowIndex++) {
//             const cellAddress = XLSX.utils.encode_cell({ c: i, r: rowIndex });
//             const cell = ws[cellAddress] || {};

//             // Apply horizontal alignment
//             if (column.halign) {
//                 cell.s = cell.s || {};
//                 cell.s.alignment = cell.s.alignment || {};
//                 cell.s.alignment.horizontal = column.halign;
//             }

//             // Apply vertical alignment
//             if (column.valign) {
//                 cell.s = cell.s || {};
//                 cell.s.alignment = cell.s.alignment || {};
//                 cell.s.alignment.vertical = column.valign;
//             }

//             // Apply additional styles here

//             ws[cellAddress] = cell;
//         }
//     }
// }

// async function fetchImageAsArrayBuffer(url) {
//     const response = await fetch(url);
//     const blob = await response.blob();
//     return new Promise((resolve) => {
//         const reader = new FileReader();
//         reader.onload = () => resolve(reader.result);
//         reader.readAsArrayBuffer(blob);
//     });
// }

// function s2ab(s) {
//     const buf = new ArrayBuffer(s.length);
//     const view = new Uint8Array(buf);
//     for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
//     return buf;
// }


/** Row 클릭 */
function onGrdRowClick(event) {
    
}

/** Row 클릭(Radio) */
function onGrdRowClickRadio(row) {
    emit('RowClickRadio', this, row, baseComponentData)
}


/** Row Double Click */
function onGrdCellDblClick(event, ui) {
    emit('CellDblClick', this, event, ui, cClose)
}

/** Data Ready */
function onGrdDataReady(event, ui) {
    emit('DataReady', this, event, ui)
}

// grd cell click
function onGrdCellClick(event, ui) {
    //this.$refs.grd._source().keyid
    if (event.args.row.bounddata != null) {
        emit('CellClick', this, event, ui)
        //this.grd02Search(event.args.row.bounddata);
    }
}

function onCellBeginEdit(event, ui) {
    //bComEdit
    if(ui.column.orgColumn.COLUMN_COMP_TYPE == "DROPDOWNLIST") {
        // Datalist 값이 있는 경우 받아서 넘김
        if(baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING] != null) {
            ui.column['editor'].options = 
            baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING].filter((r) => {
                return r.CODE != ''
            })
        }
    }
}

function onHandlekeyboardnavigation(event) {
}

// grd cell 수정 시작 시 이벤트
function onGrdCellBeginEdit(row, datafield, columntype, value) {
    return this.ChkCellEdit(row, datafield, value)

    // // let args = event.args;
    // // let columnDataField = args.datafield;
    // // let rowIndex = args.rowindex;
    // // let cellValue = args.value;
    // // return false

    // // 수정 조건이 추가로 필요함(해당 컬럼 수정 유무 체크)
    // this.grdCellOldValue = this.$refs.grd.getcellvalue(row, datafield)
    // if(grdColumns[datafield].EDIT_YN === 'N') {
    //     return false
    // }

    // // event.args.value = event.args.oldvalue
    // // event.args.oldvalue
    // if(grdColumns[datafield].EDIT_YN === 'N'
    //   || grdColumns[datafield].KEYID_YN === 'Y') {
    //     return false
    // }


    // // 수정 조건
    // if(this.grdDataAdapter._source.keyid.indexOf(datafield) === -1
    //     || (this.grdDataAdapter._source.keyid.indexOf(datafield) !== -1)
    //         && this.$refs.grd.getrowdata(row).METHOD === 'A') {
    //     // 키 컬럼 X / 키컬럼이여도 row add 상태인 경우는 수정
    //     return true;
    // }

    // //this.grdDataAdapter._source.datafields.filter((row) => { return row.name === datafield && row.col_comp_type === "NUMBER" })
    // return false
}


/** cell에 데이터 바인딩 */
function setCellData(rowIndex, dataIndx, cellValue) {
    var tmp_data = grid.value.options.dataModel.data
    tmp_data[rowIndex][dataIndx] = cellValue
    if (tmp_data[rowIndex].hasOwnProperty('METHOD') === false) {
        tmp_data[rowIndex]['METHOD'] = 'U'
    } else {
        if (tmp_data[rowIndex]['METHOD'].indexOf('U') === -1) {
            tmp_data[rowIndex]['METHOD'] = tmp_data[rowIndex]['METHOD'] + 'U'
        }
    }
    if (grdEditList.indexOf(grid.value.getData()[rowIndex]) === -1) {
        grdEditList.push(grid.value.getData()[rowIndex])
    }

    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: dataIndx })
    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: 'METHOD' })
}

/** cell에 데이터 바인딩 */
function setRowData(rowIndex, value) {
    var tmp_data = grid.value.options.dataModel.data
    for (var c in tmp_data[rowIndex]) {
        tmp_data[rowIndex][c] = value[c];
        
        grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: c })
    }
    // tmp_data[rowIndex][column] = cellValue
    if (tmp_data[rowIndex].hasOwnProperty('METHOD') === false) {
        tmp_data[rowIndex]['METHOD'] = 'U'
    } else {
        if (tmp_data[rowIndex]['METHOD'].indexOf('U') === -1) {
            tmp_data[rowIndex]['METHOD'] = tmp_data[rowIndex]['METHOD'] + 'U'
        }
    }
    if (grdEditList.indexOf(grid.value.getData()[rowIndex]) === -1) {
        grdEditList.push(grid.value.getData()[rowIndex])
    }

    
    grid.value.refreshCell({ rowIndx: rowIndex, dataIndx: 'METHOD' })
}

/** row의 데이터 가져옴 */
function getRowData(rowIndex) {

}

/** 매치되는 데이터 검색 */
function search(column, cellvalue) {

}

/** row 선택 */
function selection(rowIndex) {

}

function onGrdCellEndEdit(row, datafield, columntype, value) {
    
    // if(this.$refs.grd == null) return
    // value = this.$refs.grd.getcellvalue(row, datafield)
    // // 값이 같으면 무조건 패스
    // if(!this.chkEndEdit(row, datafield, columntype, value)) {
    //     return
    // }

    // var rowStatus = this.$refs.grd.getcellvalue(row, 'METHOD')      // row 상태

    // if(datafield === 'rowchk') {
    //     // rowchk 수정시
    //     if(value === true) {
    //         if(rowStatus !== 'A') {
    //             this.$refs.grd.setcellvalue(row, 'METHOD', 'C')
    //         }
    //         grdCheckList.push(this.$refs.grd.getrowdata(row))
    //     } else {
    //         if(rowStatus !== 'A') {
    //             this.$refs.grd.setcellvalue(row, 'METHOD', '')
    //         }
    //         grdCheckList.splice(grdCheckList.indexOf(this.$refs.grd.getrowdata(row)), 1)
    //     }

    // } else if(rowStatus !== 'A') {
    //     // 일반 컬럼 수정시
    //     this.$refs.grd.setcellvalue(row, 'METHOD', 'U')

    //     // 수정 이력으로 추가
    //     if(grdEditList.indexOf(this.$refs.grd.getrowdata(row)) === -1) {
    //         grdEditList.push(this.$refs.grd.getrowdata(row))
    //     }
    // }

}

/** 셀 값 변경 시 */
function onCellvaluechanged(event, ui) {
    let tmpColModel = grid.value.options.colModel
    let isMETHOD = tmpColModel.filter(col => col.dataIndx == 'METHOD').length == 0 ? false : true
    let isROWRADIO = tmpColModel.filter(col => col.dataIndx == 'ROWRADIO').length == 0 ? false : true
    let isROWCHECK = tmpColModel.filter(col => col.dataIndx == 'ROWCHECK').length == 0 ? false : true

    var tmp_dataModel = grid.value.options.dataModel
    var tmp_data = tmp_dataModel.data

    ui.updateList.map(chgrow => {

        // 값이 같으면 무조건 패스
        // var tmp_keys = Object.keys(chgrow)
        // if(chgrow === event.args.oldvalue) {
        //     return
        // }
        // if(!this.chkEndEdit(rowindex, datafield, columntype, value)) {
        //     return
        // }
        if (chgrow.hasOwnProperty('rowIndx') === false) {
            
        }
        
        // if (ui.updateList[0].newRow.hasOwnProperty('ROWRADIO') === true) {
        //     onGrdRowClickRadio(ui.updateList[0].rowData)
        //     return
        // }


        // if (ui.updateList[0].newRow.hasOwnProperty('ROWCHECK') === true) {
        //     chgrow.rowIndx = getRowSelectedIndex()
        //     // 삭제 체크 관련
        //     if (ui.updateList[0].newRow.ROWCHECK === 'Y') {
        //         if (tmp_data[chgrow.rowIndx].hasOwnProperty('METHOD') === false) {
        //             tmp_data[chgrow.rowIndx]['METHOD'] = 'C'
        //         } else {
        //             tmp_data[chgrow.rowIndx]['METHOD'] = tmp_data[chgrow.rowIndx]['METHOD'] + 'C'
        //         }
        //         // 수정 리스트에 등록
        //         grdCheckList.push(tmp_data[chgrow.rowIndx])

        //     } else {
        //         // 수정 리스트에서 제거
        //         grdCheckList.splice(grdCheckList.indexOf(tmp_data[chgrow.rowIndx]), 1)
        //         tmp_data[chgrow.rowIndx]['METHOD'] = tmp_data[chgrow.rowIndx]['METHOD'].replace('C', '')
        //     }
        //     $(grid.value.element).pqGrid('refreshCell', { rowIndx: chgrow.rowIndx, dataIndx: 'METHOD' })

        //     return
        // }

        //chgrow.rowIndx = getRowSelectedIndex()
        if(Object.keys(chgrow.newRow).includes('ROWCHECK')) {   // ROWCHECK의 경우 패스
            if (chgrow.newRow.ROWCHECK === 'Y') {
                if(isMETHOD == true) {
                    tmp_data[chgrow.rowIndx]['METHOD'] = (tmp_data[chgrow.rowIndx]['METHOD'] || '') + 'C'
                }
                // 수정 리스트에 등록
                grdCheckList.push(tmp_data[chgrow.rowIndx])

            } else {
                if(isMETHOD == true) {
                    tmp_data[chgrow.rowIndx]['METHOD'] = (tmp_data[chgrow.rowIndx]['METHOD'] || '').replace('C', '')
                }
                // 수정 리스트에서 제거
                grdCheckList.splice(grdCheckList.indexOf(tmp_data[chgrow.rowIndx]), 1)
            }
            $(grid.value.element).pqGrid('refreshCell', { rowIndx: chgrow.rowIndx, dataIndx: 'METHOD' })

            return                
        }

        if (isMETHOD === true && !(tmp_data[chgrow.rowIndx]['METHOD'] || '').includes('U') ) {    // 화면에 메소드 컬럼이 있으면 수정 표시
            tmp_data[chgrow.rowIndx]['METHOD'] = (tmp_data[chgrow.rowIndx]['METHOD'] || '') + 'U'
            console.log('남김' + chgrow.rowIndx.toString())
        }
        // 수정 이력으로 추가
        if (grdEditList.indexOf(tmp_data[chgrow.rowIndx]) === -1) {
            grdEditList.push(tmp_data[chgrow.rowIndx])
        }

        $(grid.value.element).pqGrid('refreshCell', { rowIndx: chgrow.rowIndx, dataIndx: 'METHOD' })

    })

}

// cellsrenderer: function(row, column, value, defaultHtml) {
//     return defaultHtml
// },
function onCellcellclassname(row, columnfield, value) {
    if (this.ChkCellEdit(row, columnfield, value)) {
        return "yellow"
    }
    return ""
}

/**
 * 수정 가능 유무 판단
 */
function onEditable(ui, column) {
    try {
        if (ui.rowData == null) {
            if(ui.rowData == null) {        // 
                ui.rowData = {}
                ui.rowData['METHOD'] ='A'
            }             
            return true      // rowData가 없는경우는 row추가일 가능성이 가장 높음
        }

        var rowIndx = ui.rowIndx;
        var dataIndx = ui.dataIndx;
        if(ui.column.orgColumn.COLUMN_COMP_TYPE == 'NE_TEXTBOXBUTTON') {
            return false
        } else if (grdColumns[column].EDIT_YN === 'Y') {
            if (grdColumns[column].KEYID_YN === 'Y') {
                // key 컬럼의 경우에는 추가 상태인경우에만 수정 가능
                if (ui.rowData['METHOD'] !== undefined && ui.rowData['METHOD'].indexOf('A') !== -1) {
                    return true
                } else {
                    return false
                }
            } else {
                return true
            }

        } else {
            return false
            //return ui.rowData == null || !ui.rowData.disabled;
        }
    } catch (err) {
        console.log('onEditable')
        console.dir(err)
        
        return false
    }

}

/**
 * 붙여넣기시 제약 조건 추가
 */
function onBeforePaste(event, ui) {
    // ROWRADIO, METHOD, ROWCHECK
    var CM = grid.value.getColModel(),
        rows = ui.rows,
        area = ui.areas[0],
        c1 = area.c1;

    var cnt = (ui.areas[0].firstR + ui.rows.length) - grid.value.getData().length
    for(let i = 0; i < cnt; i++) {
        grid.value.addRow({ newRow: {METHOD: 'A'}})
    }

    // 추후에 mothod 붙여넣기에서 빼던지 기존 값을 가져와 업데이트 처리해야함
}

// 수정 가능 유무 체크
function ChkCellEdit(row, columnfield, value) {
    try {
        if ((grdColumns[columnfield].KEYID_YN === 'N' && grdColumns[columnfield].EDIT_YN === 'Y')
            || (grdColumns[columnfield].KEYID_YN === 'Y' && grid.getrowdatabyid(row).METHOD === 'A')) {
            return true
        }
    } catch (err) {
        console.log('ChkCellEdit')
        console.dir(err)
    }


    return false

}

// combobox 생성
function editMultipleChoiceCell(ui, arr) {
    console.log('ParamGrid: Editing MultipleChoice Cell');

    var $cell = ui.$cell;
    var data = ui.data;

    //this won't work with Pro API
    //var cellData = $.trim(data[ui.rowIndx][ui.colIndx]);
    var cellData = $.trim(ui.rowData[ui.column.dataIndx]);

    var str = "";
    for (var i = 0; i < arr.length; i++) {

        var label = arr[i].NAME;
        var value = arr[i].CODE;

        //we are assuming the cell will contain the 'label' string rather than the value
        //we need to make sure this is the case
        if (cellData == label)
            str += "<option value='" + value + "' selected='selected'>" + label + "</option>";
        else
            str += "<option value='" + value + "'>" + label + "</option>";
    }
    var $sel = $("<select style='width: 100%; height: 100%'>" + str + "</select>")
        .appendTo($cell);
}

function RenderMultipleChoiceCell(ui, arr) {

    var cellData = $.trim(ui.rowData[ui.column.dataIndx]);

    for (var i = 0; i < arr.length; i++) {

        var label = arr[i].NAME;
        var value = arr[i].CODE;

        //we are assuming the cell will continue the 'label' string rather than the value
        //we need to make sure this is the case
        if (cellData == value)
            //returning the corredt value but this is not rendering in the UI
            return label;
    }
    return "";
}

function onColumnStyle(ui) {
    return ""
}

function onColumnCls(ui, column) {
    return this.onEditable(ui, column) ? "cell-edit-apply" : ""
}


function onGrdCellButtonClick(ui, event) {
    var rowIndx = ui.rowIndx
    emit('CellButtonClick', this, ui, event)
}

function GridExport() {
    var blob = grid.value.exportData({
        //url: "/pro/demos/exportData",
        format: 'xlsx',
        render: true,
        type: 'blob'
    });
    saveAs(blob, "pqGrid.xlsx");
}

function gridImport(file) {
    if (file) {
        grid.value.showLoading();
        //import first sheet of xlsx into workbook.
        pq.excel.importXl({ file: file, sheets: [0] }, function (wb) {
            //import workbook into grid. 
            grid.value.importWb({
                workbook: wb, keepCM: true, headerRowIndx: 0
            });
            grid.value.hideLoading();
        })
    }
}

/**
 * 그리드 Resize처리
 * 설명 : Resize발생시 호출해야하는 메소드
 */
function resize() {
    if (grid.value['refreshDataAndView'] !== undefined) {
        grid.value.refreshDataAndView()
        //console.log(this.ComID + ' : resize')
    }
}

/**
 * 그리드 새로 고침 처리
 * 설명 : 그리드 새로 고침이 필요할때 호출
 */
function refresh() {
    if (grid.value['refreshDataAndView'] !== undefined) {
        grid.value.refreshDataAndView()
    }
}
// console.log(col);
// ===============================================================
// 개인화 관련 : START
// ===============================================================
// 개인화 저장
function saveColumn(evt, ui) {
    function getColumns(col) {
        var data = {
            METHOD: 'S', // ? -> 데이터 확인 해봐야함.
            MENU_CODE: baseComponentData.MENU_CODE,
            REF_CODE: baseComponentData.REF_CODE,
            COMPONENT_CODE: baseComponentData.COMPONENT_CODE,
            //SEQ: ui.column.orgHeader.SORT_HEADER,
            COLUMN_CODE: col.COLUMN_CODE,
            WIDTH: col.width,
            HALIGN: col.hvalign,
            FIX_YN: 'N',
            //PARENT_SEQ: ui.column.PARENT_SEQ ? ui.column.PARENT_SEQ : null,
            PARENT_COLUMN_CODE: col.PARENT_COLUMN_CODE ? col.PARENT_COLUMN_CODE : null,
            HIDDEN_YN: col.hidden ? 'Y' : 'N',
            SORT: col.leftPos,
            ATTR_01: '',
            ATTR_02: '',
            ATTR_03: '',
            ATTR_04: '',
            ATTR_05: '',
            ATTR_06: '',
            ATTR_07: '',
            ATTR_08: '',
            ATTR_09: '',
            ATTR_10: '',
            
        }
        if ((grid.value.options.freezeCols || '') != '' && grid.value.options.freezeCols == (col.leftPos - 1)) {
            data['FIX_YN'] = 'Y'
        }
        paramsdata.push(data);
        if ((col.PARENT_COLUMN_CODE || '') != '') {
            var findCol = paramsdata.find((el) => el.COLUMN_CODE == col.PARENT_COLUMN_CODE);
            if (!findCol) {
                getColumns(findCol);
            }
        }
    }

    var paramsdata = [];
    var rmr = 0;
    for (var c = 0; c < grid.value.colModel.length; c++) {
        if (grid.value.colModel[c].dataIndx == 'ROWRADIO' || grid.value.colModel[c].dataIndx == 'METHOD' || grid.value.colModel[c].dataIndx == 'ROWCHECK') {
            rmr++;
            continue;
        }
        getColumns(grid.value.colModel[c]);
    }

    // 저장기능
    store.dispatch("NGCore/saveSysData", { METHOD_CODE: 'MENU_COMPONENT_DTL_PERSON', paramsdata }).then((rtndata) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: rtndata.rtndata.P_RETURN_MSG_CODE })

    }).catch((error) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: 'MSG.MSG0023' })

        console.log('saveColumn')
        console.dir(error)
    })
    // this.$store.dispatch('nuisys/comonsyssave', params).then(
    //     (rtndata) => {
    //         vm.$NUAlert({ data: { state: 'info', contents: vm.$t(rtndata.msg), buttons: ['ok'] } })
    //     },
    //     (error) => {
    //         vm.$NUAlert({ data: { state: 'error', contents: vm.$t('ERRORMSG0009'), buttons: ['ok'] } })
    //     }
    // );
}

// 개인화 초기화
function resetColumn(evt, ui) {
    //router
    grid.value.destroy()
    let baseGrid = {
        refCode,
        baseComponentData,
        baseBindingData,
        menuCode,
        gridType,
        options,
        grdColumns,
        treeModel,
        onEditable,
        onGrdCellButtonClick,
        onColumnCls,
        setCellData,
        getRowSelectedIndexByRadio,
        getRowSelectedDataByRadio,
        getRowSelectedIndex,
        getRowSelectedData,
        getCellSelectedColumnIndex,

    }

    baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_PERSON_COLUMN = []
    GridInit(baseGrid)
        var data = {
            METHOD: 'D', 
            MENU_CODE: baseComponentData.MENU_CODE,
            REF_CODE: baseComponentData.REF_CODE,
            COMPONENT_CODE: baseComponentData.COMPONENT_CODE,
            //SEQ: '',
            COLUMN_CODE: '',
            WIDTH: '',
            HALIGN: '',
            FIX_YN: '',
            //PARENT_SEQ: '',
            PARENT_COLUMN_CODE: '',
            HIDDEN_YN: '',
            SORT: '',
            ATTR_01: '',
            ATTR_02: '',
            ATTR_03: '',
            ATTR_04: '',
            ATTR_05: '',
            ATTR_06: '',
            ATTR_07: '',
            ATTR_08: '',
            ATTR_09: '',
            ATTR_10: '',
        }
        // if ((grid.value.options.freezeCols || '') != '' && grid.value.options.freezeCols == (col.SEQ - 1)) {
        //     data['FIX_YN'] = 'Y'
        // }
        var paramsdata = [];

        paramsdata.push(data);

    //     var rmr = 0;
    // for (var c = 0; c < grid.value.colModel.length; c++) {
    //     if (grid.value.colModel[c].dataIndx == 'ROWRADIO' || grid.value.colModel[c].dataIndx == 'METHOD' || grid.value.colModel[c].dataIndx == 'ROWCHECK') {
    //         rmr++;
    //         continue;
    //     }
    //     getColumns(grid.value.colModel[c]);
    // }

    // 저장기능
    store.dispatch("NGCore/saveSysData", { METHOD_CODE: 'MENU_COMPONENT_DTL_PERSON', paramsdata }).then((rtndata) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: rtndata.rtnMsgCode == null ? rtndata.rtnmsgcode : rtndata.rtnMsgCode })
    }).catch((error) => {
        const tmpAlert = vfmBasePopup(Alert, { state: 'info', contents: 'MSG.MSG0023' })
        console.dir(error)
    })
}

function hideColumn(arrColIndex) {

}

/** 그리드 초기화 */
function destroy() {
    grid.value.destroy()
}


// ===============================================================
// 개인화 관련 : END
// ===============================================================

</script>

<template>
    <div ref="grd" :options="options" style="width:100%; height:100%"></div>
</template>

<style>
.yellow {
    color: block;
    background-color: yellow;
}


.cell-edit-apply {
    background-color: rgba(230, 228, 119, 0.527);
}

.gridupload label {
    display: inline-block;
    padding: .1em .35em;
    color: #999;
    /* font-size: inherit; */
    font-size: .84em;
    line-height: normal;
    vertical-align: middle;
    background-color: #fdfdfd;
    cursor: pointer;
    border: 1px solid #ebebeb;
    border-bottom-color: #e2e2e2;
    border-radius: .25em;
}
.gridupload button {
    display: inline-block;
    padding: .1em .35em;
    color: rgb(255, 247, 247);
    /* font-size: inherit; */
    font-size: .8em;
    line-height: normal;
    vertical-align: middle;
    background-color: #ff2b2b;
    cursor: pointer;
    border: 1px solid #f52020;
    border-bottom-color: #f31e1e;
    margin-right: .25em;
    border-radius: .25em;
}
.gridupload input[type="file"] { 
    position: absolute;
    width: 1px;
    height: 1px;
    padding: 0;
    margin: -1px;
    overflow: hidden;
    clip:rect(0,0,0,0);
    border: 0;
}
</style>