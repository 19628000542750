<script setup>
import { ref, getCurrentInstance, onMounted, defineAsyncComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import * as commonLogic from './commonLogic/commonLogic.js';
import { nextTick } from 'process'
const { t } = useI18n()

let search = {}
const vfmBasePopup = inject('vfmBasePopup')

const grid01 = ref()
const grid02 = ref()
const grid_arr = {
    grid01: grid01,
    grid02: grid02,
}

const grid01Button = ref([])
const grid02Button = ref([])
const gridRefButtons = {
    grid01Button: grid01Button,
    grid02Button: grid02Button,
}

const grid01Search = ref([])
const grid02Search = ref([])
const gridRefSearch = {
    grid01Search: grid01Search,
    grid02Search: grid02Search,
}

// 조회나 로우 선택 조회시 초기화 해야 할 대상 리스트
let clearGridList = []

let grids = {
}
let grid_keys = []
commonLogic.BaseInit(t, vfmBasePopup, ref, grids, grid_arr, grid_keys, search)

onMounted(async () => {
    commonLogic.MountedByInit(grids, grid_keys, search, gridRefSearch, gridRefButtons)
})


async function onBtnClick(btnKey, compName) {
    let buttonData = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_BUTTON.filter((btn) => btn.BUTTON_CODE == btnKey)[0]
    let rtn = true
    switch (btnKey) {
        // 조회
        case 'READ':
            rtn = await commonLogic.SearchCommon(grids, search, compName, {}, {}, buttonData)
            break
        // 행 추가
        case 'PLUS':
            commonLogic.AddRowCommon(grids, compName, buttonData)
            break
        // 행 삭제
        case 'MINUS':
            commonLogic.RemoveRowCommon(grids, compName, buttonData)
            break
        // 저장
        case 'SAVE':
            rtn = await commonLogic.SaveCommon(grids, compName, buttonData)
            break
        // 엑셀 다운로드
        case 'EXCELDOWNLOAD':
            commonLogic.ExcelDownCommon(grids, compName, buttonData)
            break
        // 엑셀 업로드
        case 'EXCELUPLOAD':
            commonLogic.ExcelUploadCommon(grids, compName, buttonData)
            break
        // 개인화
        case 'PERSONALIZATION':
            commonLogic.PersonalizationCommon(grids, compName, buttonData)
            break
    }
    // 정상 처리를 못했다면 이후 로직은 실행하지 않음
    if(rtn == false) return

    // RULE_DATA 처리(다른 기본 기능을 처리하더라도 추가 처리)
    commonLogic.RULE_Data_Button(grids, compName, buttonData)
}

/** 행선택 */
function onRowClickRadio(ui, row, baseComponentData) {
    commonLogic.RowClickRadioCommon(grids, search, ui, row, baseComponentData)
}

</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="searchSplitCon parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <component v-for="(component, index) in grid01Search" :key="index" :is="component.component"
                                    :search="component.search" :searchLanguage="component.searchLanguage"
                                    :searchData="component.searchData" @Search="onBtnClick">
                                </component>
                            </div>
                            <div class="rightCon">
                                <component v-for="(component, index) in grid01Button" :key="index" :is="component.component"
                                    :buttonData="component.buttonData" :buttonKey="component.buttonKey"
                                    :buttonLanguage="component.buttonLanguage" :grid_key="component.grid_key"
                                    @BtnClick="onBtnClick">
                                </component>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="grid01" @RowClickRadio="onRowClickRadio" />
                    </div>
                </Pane>
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <component v-for="(component, index) in grid02Search" :key="index" :is="component.component"
                                    :search="component.search" :searchLanguage="component.searchLanguage"
                                    :searchData="component.searchData" @Search="onBtnClick">
                                </component>
                            </div>
                            <div class="rightCon">
                                <component v-for="(component, index) in grid02Button" :key="index" :is="component.component"
                                    :buttonData="component.buttonData" :buttonLanguage="component.buttonLanguage"
                                    :grid_key="component.grid_key" @BtnClick="onBtnClick">
                                </component>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref='grid02' @rowclick="onGrd02RowClick" />
                    </div>
            </Pane>
        </Splitpanes>
    </div>
</div></template>
<style></style>