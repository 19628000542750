<script setup>
import { computed, onMounted, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { ModalsContainer } from 'vue-final-modal'
import 'vue-final-modal/style.css'

const router = useRouter()
const store = useStore();

let isLoading = computed(() => {
    return store.state.NGCore.isLoading
})
// ngCoreReadyYn 값을 computed로 가져옵니다.
const ngCoreReadyYn = computed(() => store.state.NGCore.ngCoreReadyYn);

// ngCoreReadyYn 값이 변경될 때마다 로직을 수행합니다.
watch(ngCoreReadyYn, (newVal, oldVal) => {
    if(newVal == 'Y'){
        const link = document.createElement('link')
        link.rel = 'icon'
        link.type = 'image/png'
        link.href = ng_core.common.siteInfo.LOGO_URL ? `.${ng_core.common.siteInfo.LOGO_URL}` : require('@/assets/hs_logo.png')
        document.head.appendChild(link)
    }
});
      
onMounted(() => {
    
})
</script>

<template>
    <div id="allTheNav">
        <router-view></router-view>
    </div>
    <ModalsContainer></ModalsContainer>
    <div id="backdrop" v-if="isLoading == 'Y'">
        <div class="text-center loading">
            <div class="spinner-border" role="status"></div>
        </div>
    </div>
</template>
<style lang="scss">
#backdrop {
    z-index: 9999 !important;
}

:root {
    --primary-color: #2D83AC;
    --secondary-color: #114F6B !important;
    --menubackg-color: #175C7C !important;
    --menucollapse-color: #175572 !important;
    // --success-color: #80b855!important;
    // --warning-color: #eaca44!important;
    // --error-color: #ef4d4d!important;
}

.h5,
h5 {
    font-size: var(--title-size) !important;
}

.logo:hover {
    text-decoration: none;
}

.tab-content,
.tab-pane {
    height: 100%;
}

.my-tab-content {
    min-height: 100%;
    background: rgba(80, 10, 10, 0.5);
}

#statusbar {
    height: 30px;
    line-height: 30px;
    font-size: .8em;
    color: #FFFFFF;
    background: var(--secondary-color);
    transition: margin-left 0.3s;
    padding: 0 10px;
    text-align: right;
}

#allTheNav {
    height: 100vh;
}

.header-card {
    margin-left: 15px;
    margin-right: 15px;
    margin-bottom: 10px;
    // 공통 폰트 정의
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;
}

.header-body {
    padding: 5px;
    margin: 0px;
}

body {
    overflow-x: hidden;
    height: 100%;
    // min-width: 1400px;
}

button {
    margin-bottom: 10px;
    background-color: var(--primary-color);
    color: white;
    border: none;
    margin-left: 2px !important;
    padding-left: 9px;
    padding-right: 9px;
    padding-bottom: 2px;
    padding-top: 2px;
}

/* Start top bar ===================================================== */
.navbar {
    background-color: var(--primary-color);
    height: 50px;
}

.navbar-collapse {
    flex-grow: 0 !important;
}

.navbaropen {
    background-color: var(--primary-color);
    height: 50px;
    margin-left: 250px;
}

nav {
    transition: margin-left 0.3s;
}

.sidemenu-close {
    position: absolute;
    right: 20px;
    top: 20px;
    display: none;
}

@media (max-width: 992px) {
    .sidemenu-close {
        display: block;
    }
}
// 메뉴 간격 조정
.v-sidebar-menu .vsm--item {
    margin-left: 8px;
}

.v-sidebar-menu .vsm--item .vsm--title {
    font-size: .8em;
    margin-right: 10px;
}

.v-sidebar-menu .vsm--item .vsm--link {
    padding: 2px 2px;
}

.v-sidebar-menu .vsm--link_active {
    color: #FFC800 !important;
}

.v-sidebar-menu .vsm--icon {
    width: 15px !important;
    height: 15px !important;
}

.vsm--scroll-wrapper::-webkit-scrollbar {
    width: 6px;
}

.vsm--scroll-wrapper::-webkit-scrollbar-thumb {
    background: #999999;
    border-radius: 8px;
}

.card-img {
    width: 50px;
    height: 120px;
}

.navbar a:hover {
    color: #fff;
}

.th {
    margin-top: 6px;
}

/* menu route ================================================ */
.nav-item span {
    margin-left: 5px;
    font-size: 14px;
    font-family: 'Nanum Gothic', sans-serif;
}

/* menu open icon button ============================================= */

.dropdown-menu.show {
    border-radius: 0;
    outline: none;
    // margin-top: 12px;
}

.dropdown-menu.show:focus {
    outline: none;
    box-shadow: none;
}

.dropdown-menu.show .dropdown-item:hover {
    background-color: #67a2bd;
    color: #fff;
}

.svg-inline--fa.fa-w-16 {
    width: 2em;
}

/* User button dropdown menu ========================================================== */
.log {
    margin-left: -10px;
    margin-right: 10px;
    color: #1A668A;
    margin-bottom: -2px;
}

.nav-link_log {
    color: #000;
}

.nav-link_log:hover {
    text-decoration: none;
    color: #000;
}

/* End top bar ================================================================================== */

/* End side menu ============================================================================== */

/* Start content ================================================================================== */
#main {
    transition: margin-left 0.3s;
    padding: 10px 0;
    overflow: hidden;
    max-width: 100%;
    background-color: #e7e7e7;
    overflow-y: auto;
}

.mainopen {
    transition: margin-left 0.3s;
    padding: 20px 0;
    overflow: hidden;
    max-width: 100%;
    margin-left: 250px;
}

.sidebarclose {
    margin-left: 0;
}

.container {
    // max-width: 100%;
    height: calc(100% - 20px);
    display: flex;
    flex-flow: column;
}

#main .container-fluid {
    height: 100%;
    display: flex;
    flex-flow: column;
    // 공통 폰트 정의
    font-family: 'Nanum Gothic', sans-serif;
    font-size: 14px;

    height: calc(100% - 40px);
}

.container-main,
.kendo-wrap {
    height: 100%;
}

.kendo-grid {
    height: calc(100% - 40px);
}

.resultSplitter {
    height: 100% // calc(100% - 30px)!important;
}

/* End content ================================================================================== */

/* End content ================================================================================== */
.hidden {
    display: none;
}

.side_bot {
    // position: fixed;
    // bottom: 0;
    // width: 250px;
    // height: 70px;
    list-style: none;
    padding: 0;
    margin: 0;
    // border:1px solid black;
    background: #2f6680;
    text-align: center;
    color: #fff;
}

.side_bot li {
    line-height: 35px;
    font-size: .8em;
}

.side_bot li:hover {
    background: #29617a;
    cursor: pointer;
}

@media (max-width: 835px) {

    .navbar-nav,
    .ass {
        display: none !important;
    }
}

// @media (max-width: 600px){
// 	.sidenavopen, .side_bot {
// 		width: 100%;
// 	}
// 	.mainopen, .navbaropen {
// 		margin-left: 100%;
// 	}
// 	.sidemenu-close {
// 		display: block;
// 	}
// }

/** ==== Grid Cell : Start ===== */
/* 그리드 스타일 */
.pq-grid-cell div {
    margin: 0;
    font-size: 12px;
}

.pq-editor-focus {
    font-size: 12px;
}

.pq-grid-header-table {
    overflow-x: auto;
}
  
.pq-grid-header-table th {
    text-align: center;
}

.pq-cell-editor {
    font-size: 12px !important;
}

.pq-grid-cell button {
    margin: 0;
    height: 18px;
}
.pq-theme input[type=checkbox], .pq-theme input[type=radio] {
    width: 12px;
    height: 12px;
}

/**  // 화면이 깜박거림
.pq-table {
    background-color: #2D83AC !important;
}
 */
.pq-grid-col {
    background-color: #2D83AC !important;
    text-decoration: none;
    color: white;
    z-index: 2;
}

.pq-td-div span {
    font-size: .8em;
}

.pq-grid-col:hover {
    background-color: transparent;
}

.pq-grid-col-leaf .pq-title-span:hover {
    text-decoration: none;
}

/* 그리드 헤더 우측 아이콘 버튼 제거*/
// .ui-widget-header .pq-ui-button {
//     display: none;
// }
/* 그리드 헤더 우측 아이콘 버튼 제거*/
.ui-widget-header .pq-ui-button:last-child {
    display: none;
}

/* 수정 가능한 항목 배경색 표시 */
.yellow {
    color: block;
    background-color: yellow;
}

.ui-autocomplete {
    max-height: 200px;
    overflow-y: auto;
    /* prevent horizontal scrollbar */
    overflow-x: hidden;
}

.pq-side-icon.pq-grid-cell>div:before {
    content: "▼";
    width: 20px;
    height: 20px;
    color: #ccc;
    float: right;
}

.pq-grid-cell.pq-calendar>div:before {
    content: "\01F4C5";
}

.grid-method-icon {
    width: 12px;
}

.pq-cmenu.pq-theme.pq-popup {
    font-size: .8em;
    border-radius: 2px;
}

/** ==== Grid Cell : End ======= */

.modal-backdrop {
    opacity: .5;
}

.r {
    text-align: right;
    margin-top: -18px;
}

.resultArea {
    height: 100%
}

/** 상단 검색바 */
.parentCon {
    padding: 5px;
    overflow: auto;
}

.leftCon {
    float: left;
    margin-right: 5px;
}

.rightCon {
    float: right;
}

.conLabel {
    margin-right: 10px;
    margin-bottom: 0;
    font-size: 12px;
}

.conInputText {
    // max-width: 140px;
    // min-width: 40px;
    // width:100%;
    width: 140px;
    height: 23px;
    font-size: 12px;
}

button {
    font-size: 12px !important;
}

.conInputText:focus {
    outline: none;
}

.searchPane {
    height: 35px !important;
}

// POPUP CSS
.window-header {
    width: 100%;
    // height: 40px;
    padding: 12px;
    background: #1e1e1e;
    color: #FFFFFF;
}

.window-header-close {
    float: right;
    background: none;
    width: 23px;
    height: 23px;
    padding: 0;
    margin: 0;
}

.window-body {
    height: calc(100% - 48px);
    padding: 12px;
}

.window-footer {
    padding: 12px 0 0 0;
}

.favorite-icon {
    color: #FFC800;
    cursor: pointer;
}

.text-center {
    width: 100%;
    position: relative;
    height: 100%;
}

.spinner-border {
    display: block;
    position: fixed;
    top: calc(50% - (58px / 2));
    right: calc(50% - (58px / 2));
    color: red;
}

#backdrop {
    position: absolute;
    top: 0;
    width: 100vw;
    height: 100vh;
    z-index: 999;
    background-color: rgba(0, 0, 0, 0.2);
}
</style>

