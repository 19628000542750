<script setup>

// https://vue-final-modal.org/examples/liveDemo : 참고
import { ref } from 'vue'
import { useI18n } from 'vue-i18n'
import { defineProps, defineEmits, onMounted, computed, reactive } from 'vue'
import { useStore } from "vuex";
import { VueFinalModal } from 'vue-final-modal'
import * as joint from 'jointjs';
import { Shape, setSVG, setT, enableVirtualRendering, showLinkTools, tabAttr, columnSelectList } from '@/components/joint/CustomShape'
//import { Inspector } from '@/components/joint/Inspector'
import Inspector from '@/components/joint/Inspector.vue'

const props = defineProps(['state', 'title', 'contents', 'width', 'height', 'drag', 'selectedTab', 'currentView', 'viewSelectData', 'compSelectData'])
const emit = defineEmits(['confirm', 'cancel'])

const { t } = useI18n()
const store = useStore()

setT(t)

// 사이즈
let modalSize = computed(() => {
	let styles = {
		//width: (props.width || '') == '' ? '0px' : props.width,
		//height: (props.height || '') == '' ? '0px' : props.height,
	}
	if ((props.width || '') != '') styles['width'] = props.width
	if ((props.height || '') != '') styles['height'] = props.height

	return styles
})
// 헤더 상태
let modalHeaderState = computed(() => {
	switch (props.state) {
		case 'info':
			return t('STATUS.STATUS0002')
		case 'warning':
			return t('STATUS.STATUS0003')
		case 'error':
			return t('STATUS.STATUS0004')
		case 'none':
			return ''
		default:
			return t('STATUS.STATUS0001')
	}
})

// 버튼 나열
const buttons = reactive([
	{ label: '조회', active: false, val: 'SEARCH', visible: true },
	{ label: '데이터 참조', active: false, val: 'DATAREF', visible: true },
	{ label: 'REST API호출', active: false, val: 'CALLSERVICE', visible: true },
	{ label: '행 이동', active: false, val: 'MOVEROW', visible: true },
	{ label: '기본값', active: false, val: 'DEFAULTVAL', visible: true },
])
let showButtons = computed(() => {
	return buttons.filter((row) => row.visible == true)
})
const isActive = (button) => buttons.filter((row) => row.val == button.val)[0].active;

const toggleButton = (button, index) => {
	for(let i = 0; i < buttons.length; i++) {
		if(buttons[i].val == button.val) {
			buttons[i].active = true
			selectedMode = i
		} else {
			buttons[i].active = false
		}
	}

	lineProc()
};

function lineProc() {

	// 기존 선택 풀기(인스펙터 숨기기)
	inspectorRef.value.clearData()
	shouldAddClass.value = false

	// 선의 경우 자신의 선을 최상위로 올리고 색상을 변경해 줌
	const tmplinks = paper.model.getLinks()
	const btnMode = selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val]

	// 라인 처리
	tmplinks.filter(function(link) {
		if(btnMode.IM.includes('LINE') && selectedMode != -1 && buttons[selectedMode].val == link.attributes.data.COMMAND) {		// 해당 라인
			link.attr({
				line: {
					stroke: 'blue'
				}
			});
			link.toFront()
		} else {		// 해당 사항 없는 라인
			link.attr({
				line: {
					stroke: 'gray'
				}
			});
		}
	})
	selectLink = null
	
	// 컬럼 처리(선택된 컬럼을 원색으로 변경 처리)
	if (selectEl != null) {
		selectEl.attr('fill', selectElOrgColor)
	}
	selectEl = null

	// 메인이 아닌 다른 테이블에 상활에 맞게 magnet 옵션 설정

}

// 버튼 필터 처리
let selectedTabBtnData = tabAttr[props.selectedTab]
let bkeys = Object.keys(selectedTabBtnData.BUTTONTYPE)
buttons.map(function(row) {
	if(bkeys.includes(row.val)) {
		row.visible = true
	} else {
		row.visible = false
	}
})


// 선택 버튼
let selectedMode = -1

// GRIDDATAJSON 데이터
let gridDataJson = {}


onMounted(() => {
	getFirstData()
})

// 최초 데이터 가져오기
async function getFirstData() {

	let self = this
	let paramData = {
		VIEW_CODE: props.viewSelectData.VIEW_CODE,
		REF_CODE: 'getDisplayData',
	}
	store.dispatch('NGCore/getDisplayData', paramData).then((resdata) => {
		if (resdata.status) {
			let rtndata = resdata.rtndata.GRIDDATAJSON;
			InitJoint(rtndata)

		}

	}).catch((error) => {
		// error.response.data를 이유하여 적저리 처리해야함
		// 처리 중 오류 발생
		console.log('grdSearch2')
		console.dir(error)

		vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })
		$(grid.value.element).pqGrid("refreshDataAndView")

		let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
		store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })

		hideloadelement()
	})
}

const paperContainer = ref(null);
var paper = null
var selectEl = null
var selectElOrgColor = null
var inspectorRef = ref({})
var shouldAddClass = ref(false)
var inspectorData = {}
var selectLink = null
var selectLinkColor = null;

async function InitJoint(GRIDDATAJSON) {

	gridDataJson = GRIDDATAJSON

	// 링크 재정의
	var CustomLink = joint.shapes.standard.Link.extend({
		defaults: joint.util.deepSupplement({
			attrs: {
				line: {
					stroke: '#555555',
					strokeWidth: 2,
				},
				targetMarker: {
					type: 'path',
					d: 'M 10 -5 0 0 10 5 z',
					fill: 'blue',
				},
				wrapper: {
					cursor: 'default'
				},
				'.connection': { stroke: 'blue', 'stroke-width': 2 },
				'.marker-target': { fill: 'blue', d: 'M 10 0 L 0 5 L 10 10 z' }
			},
			data: {
				COMMAND: '',
				ACTIONTIME: '',
				DATA: {},
			},
			connector: { name: 'jumpover' },
			router: { name: 'manhattan' },
		}, joint.shapes.standard.Link.prototype.defaults),

		validateConnection: function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
			// Prevent loop linking
			if (magnetS == magnetT) return false;
			if (cellViewS == cellViewT) return false;
			if (magnetT == null) return false;

			// Get target model positions
			var targetX = cellViewT.model.get('position').x;
			var sourceX = cellViewS.model.get('position').x;

			// Set startDirections and endDirections based on target model position
			var startDirections = targetX < sourceX ? ['left'] : ['right'];
			var endDirections = targetX < sourceX ? ['right'] : ['left'];

			// Set the router's startDirections and endDirections dynamically
			linkView.model.set('router', { name: 'manhattan', args: { startDirections: startDirections, endDirections: endDirections } });

			return true;
		}
	});


	const graph = new joint.dia.Graph({}, { cellNamespace: joint.shapes });
	paper = new joint.dia.Paper({
		model: graph,
		cellViewNamespace: joint.shapes,
		width: "100%",
		height: "100%",
		gridSize: 20,
		drawGrid: { name: "mesh" },
		async: true,
		sorting: joint.dia.Paper.sorting.APPROX,
		background: { color: "#efefef" },

		linkPinning: false, // Prevent link being dropped in blank paper area
		markAvailable: true,
		highlighting: {
			'magnetAvailability': {
				name: 'addClass',
				options: {
					className: 'custom-available-magnet'
				}
			},
			'elementAvailability': {
				name: 'stroke',
				options: {
					padding: 20,
					attrs: {
						'stroke-width': 3,
						'stroke': '#ED6A5A'
					}
				}
			}
		},
		defaultLink: () => {
			return new CustomLink();
		},
		defaultConnectionPoint: { name: 'boundary' },
		// link에 도착지 벨리데이션 처리
		validateConnection: function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
			if(selectedMode == -1 || selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val].ISM == null) return false
			if(selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val].ISM.includes('COLUMN')) {		// 컬럼 선택
				if (magnetS == magnetT) return false
				if (cellViewS == cellViewT) return false
				if (magnetT == null) return false
				//if(linkView.model.attributes.source.selector == null) return
				// $('g[model-id="GROUP_AUTH_DTL_MENU"]' + linkView.model.attributes.source.selector)[0].id
				
				return true;

			} else if(selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val].ISM.includes('TABLE')) {		// 테이블 선택
				if (magnetS == magnetT) return false
				if (cellViewS == cellViewT) return false
				if (magnetT != null) return false
				return true;

			} else {
				debugger
				return false
			}
			// if(buttons[selectedMode].val == 'SEARCH') {	// 0 : 조회
			// 	if (magnetS == magnetT) return false
			// 	if (cellViewS == cellViewT) return false
			// 	if (magnetT != null) return false
			// 	console.log(cellViewT)
			// 	console.log(magnetT)
			// 	return true;

			// } else {
			// 	// Prevent loop linking
			// 	if (magnetS == magnetT) return false
			// 	if (cellViewS == cellViewT) return false
			// 	if (magnetT == null) return false

			// 	return true;
			// }
		}
	});
	// Register events
	paper.on('link:mouseenter', (linkView) => {
		// 해당되는 라인만 x버튼 표시 처리
		if(selectedMode != -1 && linkView.model.attributes.data.COMMAND == buttons[selectedMode].val) {
			showLinkTools(linkView);
		}
	});

	paper.on('link:mouseleave', (linkView) => {
		linkView.removeTools();
	});

	paper.on('element:pointerclick', function (elementView, evt) {
		// 선타입의 경우 컬럼 선택 방지 처리
		if(selectedMode == -1) return
		if(selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val].IM.includes('COLUMN') == false) {		// 컬럼 선택이 아닌 경우
			return
		}

		//const btnMode = selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val]
		if(elementView.model.attributes.mainYN == 'N') return	// 메인 테이블이 아니면 클릭 금지 처리

		// 기본 선 원복 처리
		if(selectLink != null) {
			selectLink.model.attr({
				line: {
					stroke: selectLinkColor
				}
			})
			selectLink = null
		}

		let gtype = $(evt.target.parentElement).attr('gtype')
		if (!['columnGroup', 'columnLabel'].includes(gtype)) {
			return
		}
		let tt1 = paper.model.getElements()[0]

		evt.stopPropagation(); // stop any further actions with the element view (e.g. dragging)

		var model = elementView.model;
		var tt = $(evt.target.parentElement.parentElement)

		var selectedEl = null
		if ($(evt.target.parentElement).attr('gtype') == 'columnGroup') {
			selectedEl = $(evt.target.parentElement).find('rect')
		} else if ($(evt.target.parentElement).attr('gtype') == 'columnLabel') {
			selectedEl = $(evt.target.parentElement.parentElement).find('rect')
		}


		if (selectEl != null && selectedEl != selectEl) {
			let tmpIds = selectEl.prevObject[0].id.split('__')
			let tmpcolumnInData = paper.getModelById(tmpIds[0]).groupRectAttributes.columnInData
			let keys = Object.keys(tmpcolumnInData)
			if(keys.includes(tmpIds[1]) && tmpcolumnInData[tmpIds[1]].length > 0 && tmpcolumnInData[tmpIds[1]].filter((row) => (row.DATA.TYPE || '') != '').length > 0) {
				selectEl.attr('fill', 'yellow')
			} else {
				//selectEl.attr('fill', selectElOrgColor)
				selectEl.attr('fill', 'white')
			}
		}
		selectEl = selectedEl
		selectElOrgColor = selectEl.attr('fill')
		selectEl.attr('fill', '#aaaaaa')

		let inspData = {
			elementView: elementView,
			evt: evt,
		}
		if(inspData.elementView.model.attributes.data == null) {
			inspData.elementView.model.attributes.data = []
			inspData.elementView.model.attributes.data.push([])
		}
		inspectorRef.value.setInsprecorData('COLUMN', paper, props.selectedTab, inspData, buttons[selectedMode].val, selectedEl.prevObject[0].id)
	});

	paper.on('link:pointerclick', function (elementView, evt) {
		// 선타입의 경우 컬럼 선택 방지 처리
		if(selectedMode == -1) return
		if(selectedTabBtnData.BUTTONTYPE[buttons[selectedMode].val].IM.includes('COLUMN') == true) {		// 컬럼 선택이 아닌 경우
			return
		}

		if (selectEl != null) {
			selectEl.attr('fill', selectElOrgColor)
		}
		selectEl = null

		if(selectLink != null) {
			selectLink.model.attr({
				line: {
					stroke: selectLinkColor
				}
			})
		}
		selectLink = elementView
		var link = elementView.model;
		selectLinkColor = selectLink.model.attributes.attrs.line.stroke
		link.attr({
			line: {
				stroke: 'red'
			}
		});

		let inspData = {
			elementView: elementView,
			evt: evt,
		}
		link.toFront()
		if(inspData.elementView.model.attributes.data == null) {
			inspData.elementView.model.attributes.data = {}
		}
		shouldAddClass.value = true
		inspectorRef.value.setInsprecorData('LINK', paper, props.selectedTab, inspData, buttons[selectedMode].val)
	});

    // "link:connect" 이벤트 핸들러 등록
    paper.on('link:connect', function(linkView, evt, elementViewConnected) {
		if(selectedMode == -1) return

		linkView.model.attributes.data.COMMAND = buttons[selectedMode].val
		linkView.model.attributes.data.ACTIONTIME  = 'AFTER'	// 기본 값으로 설정
	  	linkView.model.attributes.source.selector = '#' + linkView.sourceMagnet.id

		if(buttons[selectedMode].val == 'SEARCH') {
			linkView.model.attributes.data.DATA['SEARCHREFCODE'] = elementViewConnected.model.id
		} else if(buttons[selectedMode].val == 'DATAREF') {
			linkView.model.attributes.data.DATA['REFCODE'] = elementViewConnected.model.id
			linkView.model.attributes.data.DATA['REFCOLUMN'] = $('g[model-id="' + linkView.model.attributes.target.id + '"] ' + linkView.model.attributes.target.selector)[0].id.split('__')[1]
			linkView.model.attributes.target.selector = '#' + $('g[model-id="' + linkView.model.attributes.target.id + '"] ' + linkView.model.attributes.target.selector)[0].id
		}

		lineProc()
    });	


	var svg = paper.svg
	setSVG(paper.svg)

	paperContainer.value.appendChild(paper.el);

	enableVirtualRendering(paper);

	const gridKeys = Object.keys(GRIDDATAJSON)
	let tableList = []

	// 테이블 생성
	for (let i = 0; i < gridKeys.length; i++) {
		let mainYN = gridKeys[i] == props.compSelectData.REF_CODE ? 'Y' : 'N'
		let customShape = new Shape();
		let x = 10
		let y = 10

		
		if (GRIDDATAJSON[gridKeys[i]].DEV_RULE_DATA.MODELPOSITION) {
			// 모델 좌표 처리
			let modelPosition = GRIDDATAJSON[gridKeys[i]].DEV_RULE_DATA.MODELPOSITION
			if (modelPosition.X) {
				x = modelPosition.X
			}
			if (modelPosition.Y) {
				y = modelPosition.Y
			}
		}
		props.compSelectData.REF_CODE == GRIDDATAJSON[gridKeys[i]].REF_CODE
		// 속성 컬럼 헤더 버튼 조회 각 데이터를 조회해서 보여줄수 있도록 조건 처리 필요
		if(props.selectedTab == 'OPTION') {		// OPTION 탭의 경우 처리
			customShape = new Shape({
				id: GRIDDATAJSON[gridKeys[i]].REF_CODE,
				label: GRIDDATAJSON[gridKeys[i]].COMPONENT_NAME,
				eleJsonData: GRIDDATAJSON[gridKeys[i]],
				outlineColor: mainYN == 'Y' ? 'red' : 'blue',
				gridType: 'grid01',
				tabType: (props.compSelectData.REF_CODE == GRIDDATAJSON[gridKeys[i]].REF_CODE ? props.selectedTab : 'COLUMN'),
				mainYN: mainYN,
			});

		} else {
			customShape = new Shape({
				id: GRIDDATAJSON[gridKeys[i]].REF_CODE,
				label: GRIDDATAJSON[gridKeys[i]].COMPONENT_NAME,
				eleJsonData: GRIDDATAJSON[gridKeys[i]],
				outlineColor: mainYN == 'Y' ? 'red' : 'blue',
				gridType: 'grid01',
				tabType: (props.compSelectData.REF_CODE == GRIDDATAJSON[gridKeys[i]].REF_CODE ? props.selectedTab : 'COLUMN'),
				mainYN: mainYN,
			});
		}
		customShape
			.set('id', GRIDDATAJSON[gridKeys[i]].REF_CODE)
			.position(x, y)
			.prop('fillColor', 'lightgray')
			.addTo(graph);

		tableList.push(customShape)
	}

	// 화면 링크 생성
	let tabData = GRIDDATAJSON[props.compSelectData.REF_CODE][selectedTabBtnData.eleNM]
	for(let i = 0; i < tabData.length; i++) {
		let ruleDatas = tabData[i].RULE_DATA
		for(let j = 0; j < ruleDatas.length; j++) {
			let ruleData = ruleDatas[j]
			if(ruleData.COMMAND == 'SEARCH') {
				if(ruleData && ruleData.DATA && ruleData.DATA.SEARCHREFCODE) {
					const link = new CustomLink({
						source: {
							id: props.compSelectData.REF_CODE,
							selector: ' #' + props.compSelectData.REF_CODE + '__' + tabData[i][selectedTabBtnData.columnCD],
						},
						target: {
							id: ruleData.DATA.SEARCHREFCODE,
						},
						data: ruleData
					})

					graph.addCell(link);				
				}
			} else if(ruleData.COMMAND == 'DATAREF') {
				if(ruleData && ruleData.DATA && ruleData.DATA.REFCODE) {
					const link = new CustomLink({
						source: {
							id: props.compSelectData.REF_CODE,
							selector: ' #' + props.compSelectData.REF_CODE + '__' + tabData[i][selectedTabBtnData.columnCD],
						},
						target: {
							id: ruleData.DATA.REFCODE,
							selector: ' #' + ruleData.DATA.REFCODE + '__' + ruleData.DATA.REFCOLUMN,
						},
						data: ruleData
					})
					// GROUP_AUTH_MST__GROUP_CODE
					// GROUP_AUTH_MST__GROUP_CODE
					// GROUP_AUTH_MST__GROUP_CODE

					graph.addCell(link);				
				}
			}
		}
	}

	
	// if (tableList.length >= 2) {
	// 	// let id1 = tableList[0].findView(paper).id
	// 	// let id2 = tableList[1].findView(paper).id
	// 	let id111 = GRIDDATAJSON[gridKeys[0]].REF_CODE + '__' + GRIDDATAJSON[gridKeys[0]].MENU_APPLY_COMPONENT_DTL_COLUMN[0].COLUMN_CODE_HEADER
	// 	let id222 = GRIDDATAJSON[gridKeys[1]].REF_CODE + '__' + GRIDDATAJSON[gridKeys[1]].MENU_APPLY_COMPONENT_DTL_COLUMN[0].COLUMN_CODE_HEADER

	// 	const link = new CustomLink({
	// 		source: {
	// 			id: GRIDDATAJSON[gridKeys[0]].REF_CODE,
	// 			selector: ' #' + id111,
	// 		},
	// 		target: {
	// 			id: GRIDDATAJSON[gridKeys[1]].REF_CODE,
	// 			selector: ' #' + id222,
	// 		},
	// 	})

	// 	graph.addCell(link);
	// }

}

/** 창 닫기 **/
async function onClick(val) {
	emit('cancel')
}

/** 저장 **/
async function onSave(val) {
	if (val) {
		let gridKeys = Object.keys(gridDataJson)

		const graphJson = paper.model.toJSON();
		let paramData = []

		// 컨포넌트 위치 정보 저장
		for (let i = 0; i < gridKeys.length; i++) {
			paper.getModelById(gridKeys[i]).position()
			let devRuleData = {
				MODELPOSITION : {
					X: paper.getModelById(gridKeys[i]).position().x,
					Y: paper.getModelById(gridKeys[i]).position().y,
				},
			}
			let paramD = {
				METHOD: 'S',
				VIEW_TYPE: 'MST',
				VIEW_CODE: props.viewSelectData.VIEW_CODE,
				REF_CODE: gridDataJson[gridKeys[i]].REF_CODE,
				KEY_CODE: '',
				RULE_DATA: JSON.stringify(gridDataJson[gridKeys[i]].RULE_DATA),
				DEV_RULE_DATA: JSON.stringify(devRuleData),
				
			}

			paramData.push(paramD)
		}
		

		// 링크 정보 저장

		// 동일 source의 경우 묶음 처리
		let ruleDatas = {}		
		
		// 데이터 생성(링크 데이터)
		let links = paper.model.getLinks()
		for(let i = 0; i < links.length; i++) {
			let attrData = paper.model.getLinks()[i].attributes
			let ruleData = {}
			ruleData['COMMAND'] = attrData.data.COMMAND
			ruleData['ACTIONTIME'] = attrData.data.ACTIONTIME

			
			switch(attrData.data.COMMAND) {
				case 'SEARCH':
					ruleData['DATA'] = {
						SELECTTYPE: 'RADIO',
						SEARCHREFCODE: attrData.data.DATA.SEARCHREFCODE
					}
					break
				case 'DATAREF':
					ruleData['DATA'] = {
						SELECTTYPE: 'RADIO',
						REFCODE: attrData.data.DATA.REFCODE,
						REFCOLUMN: attrData.data.DATA.REFCOLUMN,
					}
					break
				// case 'MOVEROW':
				// 	break
				// case 'DEFAULTVAL':
				// 	debugger
				// 	if(attrData.data.DATA.TYPE == 'TEXT') {

				// 	} else if(attrData.data.DATA.TYPE == 'COMBO') {

				// 	} else if(attrData.data.DATA.TYPE == 'DATE') {
				// 	}
				// 	break
				
			}
			let selector = attrData.source.selector.replace('#', '').trim()
			if(ruleDatas[selector] == null) {
				ruleDatas[selector] = []
			}
			ruleDatas[selector].push(ruleData)
		}

		// 컬럼 정보 저장
		let tmpColumnRuleData = paper.getModelById(props.compSelectData.REF_CODE).groupRectAttributes.columnInData
		let tmpcolumnRuleKeys = Object.keys(tmpColumnRuleData)
		console.log(columnSelectList)
		for(let i = 0; i < tmpcolumnRuleKeys.length; i++) {
			let tmpCommands = tmpColumnRuleData[tmpcolumnRuleKeys[i]].filter((row) => columnSelectList.includes(row.COMMAND) && (row.DATA.TYPE || '') != '' )
			for(let j = 0; j < tmpCommands.length; j++) {
				let tmpNm = props.compSelectData.REF_CODE + '__' + tmpcolumnRuleKeys[i]
				if(ruleDatas[tmpNm] == null) {
					ruleDatas[tmpNm] = []
				}
				ruleDatas[tmpNm].push(tmpCommands[j])
			}
		}

		// 저장을 위한 데이터 정리
		let ruleKeys = Object.keys(ruleDatas)
		for(let i = 0; i < ruleKeys.length; i++) {
			let refCode = ruleKeys[i].split('__')[0]
			let keyCode = ruleKeys[i].split('__')[1]
			let paramD = {
				METHOD: 'S',
				VIEW_TYPE: props.selectedTab,
				VIEW_CODE: props.viewSelectData.VIEW_CODE,
				REF_CODE: refCode,
				KEY_CODE: keyCode,
				RULE_DATA: JSON.stringify(ruleDatas[ruleKeys[i]]),
				DEV_RULE_DATA: '',
			}
			paramData.push(paramD)
		}
		
		// let optKeys = Object.keys(devRuleDatas.OPTION)
		// for(let i = 0; i < optKeys.length; i++) {
		// 	let optData = devRuleDatas.OPTION[optKeys[i]]
		// 	let optData_REF_CODE = optKeys[i].split('__')[0];
		// 	let optData_COLUMN_CODE = optKeys[i].split('__')[1];
		// 	let paramD = {
		// 		METHOD: 'S',
		// 		VIEW_TYPE: 'OPTION',
		// 		VIEW_CODE: props.viewSelectData.VIEW_CODE,
		// 		REF_CODE: optData_REF_CODE,
		// 		KEY_CODE: optData_COLUMN_CODE,
		// 		RULE_DATA: JSON.stringify(optData),
		// 		DEV_RULE_DATA: '',
		// 	}

		// 	paramData.push(paramD)
		// }

		// 내용 저장
		var params = {
            METHOD_CODE: 'VIEW_COMPONENT_RULE',
            SQLLOG_YN: 'Y',             // db log 남길지 유무
            paramsdata: paramData
        }
        try {
            let rtn = await store.dispatch('NGCore/saveSysData', params)

        } catch(err) {
            console.log('onClickMenuFavorite')
            console.dir(err)
        }
		//emit('confirm', graphJson)
	} else {
		//emit('cancel')
	}
}

</script>

<template>
	<!-- First modal -->
	<vue-final-modal class="flex justify-center items-center modal-container"
		content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
		:drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom" :click-to-close="false" transition=""
		overlay-transition="">
		<div class="border-bottom">
			<span class="modal__title">{{ props.title }} {{ t(props.viewSelectData.VIEW_DESC) + ' (' + t(props.currentView.COMPONENT_NAME) + ') ' }}</span>
			<button class="btn-close modal__close" @click="onClick(false)"></button>
		</div>
		<div class="modal__content" :class="{ rules : shouldAddClass }" :style="modalSize" style="position: relative">
			<div style="position: absolute; top: 10; left: 5;" class="btn-group" role="group" aria-label="Toggle buttons">
				<button v-for="(button, index) in showButtons" :key="index" :class="['btn', isActive(button) ? 'btn-primary' : 'btn-secondary']" @click="toggleButton(button, index)" >
					{{ button.label }}
				</button>		
			</div>
			<div ref="paperContainer" style="height:100%; width:100%"></div>
			<Inspector id="inspector-container" ref="inspectorRef" class="tapSetting"/>
			<!-- <div id="inspector-container">
				<div class="custom-fields"></div>
			</div>
			<div id="toolbar-container"></div> -->
		</div>
		<div class="modal__action">
			<!-- <button type="button" class="btn btn-primary" @click="$emit('cancel', close)">{{ t('BUTTON.BUTTON0001')}}</button> -->
			<button class="btn btn-primary" @click="onClick(true)">{{ t('BUTTON.BUTTON0001') }}</button>
			<button class="btn btn-primary" @click="onSave(true)">{{ t('BUTTON.BUTTON0003') }}</button>
		</div>
	</vue-final-modal>
</template>

<style >
.flex {
	display: grid !important;
}

.vfm--inset {
	bottom: 0 !important;
	left: 0 !important;
	right: 0 !important;
	top: 0 !important;
}

.vfm--fixed {
	position: fixed !important;
}

.justify-center {
	justify-content: center !important;
}

.items-center {
	align-items: center !important;
}

/*
*, :after, :before {
    border: 0 solid #e5e7eb !important;
    box-sizing: border-box !important;
}
*/
</style>

<style >
.modal__content {
	flex-grow: 1;
	overflow-y: auto;
	padding: 5px;
	height: 100%;
}

.modal__action {
	display: flex;
	justify-content: center;
	align-items: center;
	flex-shrink: 0;
	padding-top: 10px;
}

.modal__close {
	top: 0.5rem;
	right: 0.5rem;
}
</style>

<style scoped>
.container-fluid-sub {
	height: 100vh;
}

.resultArea-sub {
	display: flex;
	height: 100%;
}

.paper-wrapper {
	display: flex;
	flex-grow: 1;
	position: relative;
}

.paper-container {
	flex-grow: 1;
}

#inspector-container {
	width: 220px;
	background-color: #ffffff;
	transition: all 0.5
}

/* port styling */
.available-magnet {
	fill: #5DA271;
}

/* element styling */
.available-cell rect {
	stroke-dasharray: 5, 2;
}

.rules{
	display: flex;
}
.tapSetting{
	padding-left: 10px;
}
</style>