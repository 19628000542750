<script setup>
import { onMounted, computed, ref, nextTick } from "vue";

const props = defineProps({
    menu: Object
})

onMounted(() => {
    nextTick(() => {
        console.log(props)
    })
    
})


</script>

<template>
    
    <!-- 일반 메뉴 -->
    <li class="nav-item" v-if="(props.menu.MENU_TYPE != null && props.menu.MENU_TYPE != 'CATEGORY')">
        <a class="nav-link collapsed" :href="props.menu.FILE_PATH">
            <i class="bi bi-emoji-smile"></i>
            <span>{{ props.menu.MENU_DESC }}</span>
        </a>
    </li>

    <!-- 펼침 -->
    <li class="nav-item" v-if="(props.menu.MENU_TYPE == null || props.menu.MENU_TYPE == 'CATEGORY')">
        <a class="nav-link collapsed" :data-bs-target="('#md-' + props.menu.MENU_CODE)" data-bs-toggle="collapse" href="#">
            <i class="bi bi-1-square"></i><span>{{ props.menu.MENU_DESC }}</span><i class="bi bi-chevron-down ms-auto"></i>
        </a>
        <div :id="('md-' + props.menu.MENU_CODE)" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
            <div class="menu">
                <!-- <i class="bi bi-grid"></i> -->
                <img src="assets/img/menu1.png" alt="">
                <div class="label">외매현황</div>
            </div>
            <div class="menu">
                <i class="bi bi-grid"></i>
                <div class="label">제품정보</div>
            </div>
        </div>
    </li>
</template>

