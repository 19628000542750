<script setup>
import { ref, defineProps, defineEmits, onMounted, } from 'vue'

import Chart from 'chart.js/auto';
import { LinearScale } from 'chart.js';

Chart.register(LinearScale);

// import { Chart } from 'chart.js'

defineExpose({ fillData })

const props = defineProps(['ChartData'])
let name = 'LineChart01'
let renderChart = ref('')
var ctx

function fillData(chartdata) {
    ctx = renderChart.value.getContext('2d')
    new Chart(ctx, {
        type: props.ChartData.value.type,
        data: {
            labels: chartdata.labels,
            datasets: [{
                label: chartdata.label,
                data: chartdata.datas,
                borderWidth: 1
            }]
        },
        
        options: {
            layout: {
                autoPadding: true,
            },
            scales: {
                y: {
                    display: false,
                    //beginAtZero: true
                }
            },
            // legend: {
            //     display: true,
            // },
            plugins: {
                legend: {
                    display: false
                }
            },
            maintainAspectRatio: false, // default value. false일 경우 포함된 div의 크기에 맞춰서 그려짐.
            resizeDelay: 200,
        }
    });

}
</script>

<template>
    <div style="height: 100%">
        <canvas ref="renderChart" style="height:100%"></canvas>
    </div>
</template>
<style scoped>
canvas {
    height: 100%;
    top: 0;
    left: 0;
}
</style>