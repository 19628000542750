<script setup>
import { ref, getCurrentInstance, onMounted, defineAsyncComponent, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import { nextTick } from 'process'
import { useStore } from "vuex";
import SelectIcon from '@/components/popup/SelectIcon.vue'
import * as commonLogic from './commonLogic/commonLogic.js';

const { t } = useI18n()
const store = useStore()

let search = {}
const vfmBasePopup = inject('vfmBasePopup')

const grid01 = ref()
const grid02 = ref()
const grid03 = ref()
const grid_arr = {
    grid01: grid01,
    grid02: grid02,
    grid03: grid03,
}

const grid01Button = ref([])
const grid02Button = ref([])
const grid03Button = ref([])
const gridRefButtons = {
    grid01Button: grid01Button,
    grid02Button: grid02Button,
    grid03Button: grid03Button,
}

const grid01Search = ref([])
const grid02Search = ref([])
const grid03Search = ref([])
const gridRefSearch = {
    grid01Search: grid01Search,
    grid02Search: grid02Search,
    grid03Search: grid03Search,
}

// Tab 타이틀
const tab01 = ref('')
const tab02 = ref('')
const tab03 = ref('')

// 조회나 로우 선택 조회시 초기화 해야 할 대상 리스트
let clearGridList = []

let grids = {
}
// 현재 화면에 사용되는 그리드 코드 리스트(팝업은 제외)
let grid_keys = []
commonLogic.BaseInit(t, vfmBasePopup, ref, grids, grid_arr, grid_keys, search)

onMounted(async () => {
    commonLogic.MountedByInit(grids, grid_keys, search, gridRefSearch, gridRefButtons)

    // tab 헤더 정의
    tab01.value =  t(grid_arr['grid01'].value.getGrid().value.options.title)
    tab02.value =  t(grid_arr['grid02'].value.getGrid().value.options.title)
    tab03.value =  t(grid_arr['grid03'].value.getGrid().value.options.title)
})

async function onBtnClick(btnKey, compName) {
    let buttonData = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_BUTTON.filter((btn) => btn.BUTTON_CODE == btnKey)[0]
    let rtn = true
    switch (btnKey) {
        // 조회
        case 'READ':
            rtn = await commonLogic.SearchCommon(grids, search, compName, {}, {}, buttonData)
            break
        // 행 추가
        case 'PLUS':
            commonLogic.AddRowCommon(grids, compName, buttonData)
            break
        // 행 삭제
        case 'MINUS':
            commonLogic.RemoveRowCommon(grids, compName, buttonData)
            break
        // 저장
        case 'SAVE':
            rtn = await commonLogic.SaveCommon(grids, compName, buttonData)
            break
        // 엑셀 다운로드
        case 'EXCELDOWNLOAD':
            commonLogic.ExcelDownCommon(grids, compName, buttonData)
            break
        // 엑셀 업로드
        case 'EXCELUPLOAD':
            commonLogic.ExcelUploadCommon(grids, compName, buttonData)
            break
        // 개인화
        case 'PERSONALIZATION':
            commonLogic.PersonalizationCommon(grids, compName, buttonData)
            break
    }
    // 정상 처리를 못했다면 이후 로직은 실행하지 않음
    if(rtn == false) return

    // RULE_DATA 처리(다른 기본 기능을 처리하더라도 추가 처리)
    commonLogic.RULE_Data_Button(grids, compName, buttonData)
}

/** 행선택 */
function onRowClickRadio(ui, row, baseComponentData) {
    commonLogic.RowClickRadioCommon(grids, search, ui, row, baseComponentData)
}


/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    commonLogic.GrdCellButtonCommon(vfmBasePopup, grids, sender, ui, event)
}


/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}

/*************************************************
 * Tab 관련 : End
 *************************************************/
</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal
                @resize="tabPageContentResize" :dbl-click-splitter="false">
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab"
                        @resize="tabPageContentResize">
                        <!-- content-class="mt-2" -->
                        <b-tab :title="tab01" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <component v-for="(component, index) in grid01Search" :key="index" :is="component.component"
                                            :search="component.search" :searchLanguage="component.searchLanguage"
                                            :searchData="component.searchData" @Search="onBtnClick">
                                        </component>
                                    </div>
                                    <div class="rightCon">
                                        <component v-for="(component, index) in grid01Button" :key="index" :is="component.component"
                                            :buttonData="component.buttonData" :buttonKey="component.buttonKey"
                                            :buttonLanguage="component.buttonLanguage" :grid_key="component.grid_key"
                                            @BtnClick="onBtnClick">
                                        </component>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="grid01" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <b-tab :title="tab02">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <component v-for="(component, index) in grid02Search" :key="index" :is="component.component"
                                            :search="component.search" :searchLanguage="component.searchLanguage"
                                            :searchData="component.searchData" @Search="onBtnClick">
                                        </component>
                                    </div>
                                    <div class="rightCon">
                                        <component v-for="(component, index) in grid02Button" :key="index" :is="component.component"
                                            :buttonData="component.buttonData" :buttonKey="component.buttonKey"
                                            :buttonLanguage="component.buttonLanguage" :grid_key="component.grid_key"
                                            @BtnClick="onBtnClick">
                                        </component>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="grid02" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <b-tab :title="tab03">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <component v-for="(component, index) in grid03Search" :key="index" :is="component.component"
                                            :search="component.search" :searchLanguage="component.searchLanguage"
                                            :searchData="component.searchData" @Search="onBtnClick">
                                        </component>
                                    </div>
                                    <div class="rightCon">
                                        <component v-for="(component, index) in grid03Button" :key="index" :is="component.component"
                                            :buttonData="component.buttonData" :buttonKey="component.buttonKey"
                                            :buttonLanguage="component.buttonLanguage" :grid_key="component.grid_key"
                                            @BtnClick="onBtnClick">
                                        </component>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="grid03" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>

<style></style>