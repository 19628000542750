<script setup>

import { onMounted, computed, defineProps, defineEmits, ref, watch } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'

const router = useRouter()
const store = useStore()
const { t } = useI18n()

const noligoui = ref(ng_core.common.siteInfo.NAME)
const props = defineProps(['opened'])
const emit = defineEmits(['changeOpen', 'changeMenu'])

/** 메뉴 정보 */
let treemenu = ref([])
let menuwidth = ref('250px')
onMounted(() => {
    treemenu.value = createTreeMenu('/' + noligo_config.menuAreaType, store.state.NGCore.menuInfo)

    windowWidthSize();
    window.addEventListener('resize', function(e){
        if(window.innerWidth < 992) {
            menuwidth.value = "100%";
            emit('changeOpen', false)
        } else {
            menuwidth.value = "250px";
            emit('changeOpen', true)
        }
    });
})

/** 메뉴 구조 생성 */
function createTreeMenu(parentMenuPath, sourceMenu, lv = 1) {
    let pMneu = []
    for (let i = 0; i < sourceMenu.length; i++) {
        if(sourceMenu[i].MENU_VIEW_YN_REAL == 'N') continue     // MENU_VIEW_YN_REAL가 N이면 라우터에는 있는만 왼쪽 메뉴에는 보이지 않음

        let tmpM = sourceMenu[i]
        let currentMenuPath = tmpM.FULLPATH; //parentMenuPath + (lv == 1 ? '/' : '_') + (tmpM.MENU_CODE).toLowerCase()
        let tmenu = {
            href: currentMenuPath,
            title: tmpM.PARENT_MENU_CODE == "FAVORITES" ? t('MENU.' + tmpM.ROUTER_NAME.replace('FAV_', '')) : t('MENU.' + tmpM.ROUTER_NAME),
            hiddenOnCollapse: false,
            icon: {
                element: 'font-awesome-icon',
                class: 'padding-icon',
                attributes: {
                    icon: (tmpM.MENU_ICON || '') == '' ? 'sitemap' : tmpM.MENU_ICON,
                }
            },
            child: []
        }
        if (tmpM.CHILD_MENU.length > 0) {
            tmenu.child = createTreeMenu(currentMenuPath, tmpM.CHILD_MENU, lv + 1)
            delete tmenu['href']
            tmenu['hiddenOnCollapse'] = true
        }
        pMneu.push(tmenu)
    }
    return pMneu
}

function onItemClick(event, item) {

    if (window.innerWidth < 992 && item.href != null) {
        emit('changeOpen', false);
    }
}

function windowWidthSize() {
    if (window.innerWidth < 992) {
        // emit('opened', false);
        menuwidth.value = "100%";
        emit('changeOpen', false)
    } else {
        menuwidth.value = "250px";
        emit('changeOpen', true);
    }
}

watch([props], (newValue, oldVale) => {
    // const sidebarElement = document.getElementsByClassName("navbar")[0];
    // sidebarElement.addEventListener("transitionend", () => {
    //     window.dispatchEvent(new Event("resize"));
    // });
})

function ChangeOpen() {
    emit('changeOpen', !props.opened)
}
function ChangeMenu(event, val) {
    emit('changeMenu', event, val)

    if (window.innerWidth < 992 && val.href != null) {
        emit('changeOpen', false);
    }
}

function prfile() {
    router.push('/' + noligo_config.menuAreaType + '/profile')
}

async function logout() {
    let req = await store.dispatch('NGCore/logout')
    if(req.data.status) {
        router.replace('/login')
    }
}




</script>


<template>
    <sidebar-menu :menu="treemenu" :collapsed="!props.opened" :hideToggle="true" :width="menuwidth" widthCollapsed="0"
        :disableHover="true" @item-click="ChangeMenu">
        <template v-slot:header>
            <div :class="{ hidden: !props.opened }">
                <a class="logo" href="/">
                    <h1 style="color:#E0E0E0; text-align:center; font-size:2em; margin-top:10px;">{{ noligoui }}</h1>
                </a>
                <button id="testst" class="sidemenu-close" @click="ChangeOpen"><font-awesome-icon
                        icon="times" /></button>
            </div>
        </template>
        <template v-slot:dropdown-icon="{ isOpen }">
            <span v-if="!isOpen">▶</span>
            <span class="actcolor" v-else>▼</span>
        </template>
        <template v-slot:footer>
            <ul :class="{ hidden: !props.opened }" class="side_bot">
                <li @click.prevent="prfile">{{ t('LABEL.PROFILE') }}</li>
                <li @click.prevent="logout">{{ t('LABEL.LOGOUT') }}</li>
            </ul>
        </template>
    </sidebar-menu>
</template>

<style scoped>
.v-sidebar-menu .vsm--link_open {
    background-color: blue;
}

</style>