<script setup>
import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import pq from 'pqgrid';
import { $vfm } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'popupSize', 'grid', 'componentList', 'targetREFCODEList', 'width', 'height', 'api', 'cClose', 'parentRowData', 'tname'])
const emit = defineEmits(['confirm', 'cancel'])
const modalRef = ref(null)

let search = {}
search['TABLENAME'] = ref('');
search['IDX'] = ref('');

// 사이즈
let modalSize = computed(() => {
    let styles = {
        width: (props.width || '') == '' ? '0px' : props.width,
        height: (props.height || '') == '' ? '0px' : props.height,
    }
    if ((props.width || '') != '') styles['width'] = props.width
    if ((props.height || '') != '') styles['height'] = props.height

    return styles
})

// 검색 조건 생성
// let tmpkeys = [props.grid]
// for (let i = 0; i < tmpkeys.length; i++) {
//     let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
//     search[tmpkeys[i]] = {}
//     for (let j = 0; j < tmpData.ARGS.length; j++) {
//         search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
//     }
// }
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const grid = ref()
let grids = {
    'BOARDREPLY': grid,
}
let cClose;
let gridHand;

onMounted(() => {
    search['BOARDREPLY'].TABLENAME.value = props.tname
    search['BOARDREPLY'].IDX.value = props.parentRowData.IDX;
    grids[props.grid] = grid

    setTimeout(() => {
        onSearch('BOARDREPLY')
    }, 200);
});

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search['BOARDREPLY']);
    
}

let menuCodeIdx = 0
/** 행추가 */
function onAddRow(compName) {
    grids[compName].value.grdAddRow()
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    
    // const tmprows = grids[compName].value.getrows()
    // let chkParentCode = false
    // for (let soure = 0; soure < tmprows.length; soure++) {
    //     chkParentCode = false
    //     for (let target = 0; target < tmprows.length; target++) {
    //         // 같은 행은 검사하지 않음
    //         if (soure == target) continue

    //         // 메뉴코드에 중복이 있는지 확인
    //         if (tmprows[soure].MENU_CODE == tmprows[target].MENU_CODE) {
    //             // 메뉴 코드 중복
    //             alert('메뉴코드가 중복 되었습니다.');
    //             return
    //         }
    //     }
    // }
    // let tmp = grids[compName].value.getRowSelectedData()
    // tmp = 
    // search['BOARDREPLY'].TABLENAME.value = props.tname;
    // grids[compName].value.grdSave()
    grids[compName].value.getRowSelectedData().TABLENAME = props.tname
    grids[compName].value.grdSave(grids[compName].value.getRowSelectedData())
}

function onCellDbClick(sender, event, ui, cClose) {
    emit('confirm', cClose, ui.rowData)
}

/** OK 버튼 */
function onConfirm(close) {
    let tmpSctData = grid.value.getRowSelectedData()
    if (!tmpSctData) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
    } else {
        emit('confirm', close, tmpSctData)
    }
}

/** 취소 버튼 */
function onCancel(close) {
    emit('cancel', close, false)
}

function onBeforeOpen(a, b, c) {
}

const vfmref = ref()        // 테스트 필요함


</script>

<template>
    <!-- Modal -->
    <vue-final-modal v-slot="{ close }" @before-open="onBeforeOpen" classes="modal-container"
    content-class="modal-content2" :esc-to-close="true" :click-to-close="true" transition="" overlay-transition=""
    :drag="true" drag-selector=".topbar" :resize="true">
        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel(close)"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div style="width:100%; height:40px; overflow:auto;">
                <div class="searchSplitCon parentCon">
                    <div class="rightCon">
                        <button ref="read" @click="onSearch(props.grid)" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                        <button ref="plus" @click="onAddRow(props.grid)" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                        <button ref="minus" @click="onRemoveRow(props.grid)" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                        <button ref="save" @click="onSave(props.grid)" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                    </div>
                </div>
            </div>
            <div class="resultGrid" style="height:calc(100% - 50px);">
                <BaseGrid ref='grid' :refCode="'BOARDREPLY'" @rowclick="onGrdRowClick" @CellDblClick="onCellDbClick" />
            </div>
        </div>
        <div class="modal__action">
            <button type="button" class="btn btn-primary" @click="onConfirm(close)">{{ t('BUTTON.BUTTON0001') }}</button>
            <button type="button" class="btn btn-secondary" @click="onCancel(close)">{{t('BUTTON.BUTTON0002') }}</button>
        </div>
    </vue-final-modal>

</template>

<style scoped>
    ::v-deep .modal-container {
        display: flex;
        justify-content: center;
        align-items: center;
    }
    ::v-deep .modal-content2 {
        position: relative;
        display: flex;
        flex-direction: column;
        max-height: 90%;
        margin: 0 1rem;
        padding: 1rem;
        border: 1px solid #e2e8f0;
        border-radius: 0.25rem;
        background: #fff;
    }

    .modal__title {
        margin: 0 2rem 0.5rem 0;
        font-size: 1.5rem;
        font-weight: 700;
    }

    .modal__content {
        flex-grow: 1;
        overflow-y: auto;
        padding: 15px;
    }

    .modal__action {
        display: flex;
        justify-content: center;
        align-items: center;
        flex-shrink: 0;
        padding: 1rem 0 0;
    }

    .modal__close {
        position: absolute;
        top: 0.5rem;
        right: 0.5rem;
    }
</style>