<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import SelectCompInView from '@/components/popup/SelectCompInView.vue'
import SelectBoard from '@/components/popup/SelectBoard.vue'
import SelectIcon from '@/components/popup/SelectIcon.vue'
import Alert from '@/components/popup/Alert.vue'
import { useStore } from "vuex";
import { useModal  } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}
// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const MENU_ADM = ref()
const MENU_USER = ref()
const MENU_MOBILE = ref()
const COMMON_CODE_DTL = ref()

let grids = {
    'MENU_ADM': MENU_ADM,
    'MENU_USER': MENU_USER,
    'MENU_MOBILE': MENU_MOBILE,
    'COMMON_CODE_DTL': COMMON_CODE_DTL,
}

/*************************************************
 * Grid 관련 : Start
 *************************************************/
/** 조회 */
function onSearch(compName) {

    search[compName].MENU_TYPE.value = compName.split('_')[1]
    grids[compName].value.grdSearchRef(search[compName])
}

let menuCodeIdx = 0
/** 행추가 */
function onAddRow(compName) {
    grids[compName].value.grdAddRow({ MENU_AREA_TYPE: compName.split('_')[1], MENU_CODE: 'MENU_' + compName.split('_')[1] + '_' + menuCodeIdx }, 'last')
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
async function onSave(compName) {     
    const tmprows = grids[compName].value.getrows()
    let chkParentCode = false
    for(let soure = 0; soure < tmprows.length; soure++) {
        chkParentCode = false
        for(let target = 0; target < tmprows.length; target++) {
            // 같은 행은 검사하지 않음
            if(soure == target) continue
            
            // 메뉴코드에 중복이 있는지 확인
            if(tmprows[soure].MENU_CODE == tmprows[target].MENU_CODE) {
                // 메뉴 코드 중복
                const tmpAlert = await vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0013') });
                return
            }

            // 상위 메뉴코드가 존재하는지 확인
            if((tmprows[soure].PARENT_MENU_CODE || '') == '' || (chkParentCode == false && tmprows[soure].PARENT_MENU_CODE == tmprows[target].MENU_CODE)) {
                chkParentCode = true
            }
        }

        if(chkParentCode == false) {
            const tmpAlert = await vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0012') });
            return
        }

    }
    // const menuCode = grids[compName].value.getrows().map(row => row.MENU_CODE);
    // const setMenuCode = new Set(menuCode);
    // const parentMenuCode = grids[compName].value.getrows().map(row => row.PARENT_MENU_CODE);
    // const selectParentMenu = grids.MENU_ADM.value.getRowSelectedData().PARENT_MENU_CODE;

    // const tmp_row = [
    //     {
    //         DATA: {
    //             METHOD: 'A',
    //             ROWRADIO: 'N',
    //             ROWCHECK: 'N',
    //             MENU_CODE: grids.MENU_ADM.value.getRowSelectedData().MENU_CODE,
    //             PARENT_MENU_CODE: selectParentMenu,
    //         }
    //     }
    // ]
    
    // if (menuCode.length !== setMenuCode.size) {
    //     alert('메뉴코드가 중복 되었습니다.');
    //     return
    // }

    // if (!menuCode.includes(selectParentMenu)) {
    //     alert('해당 상위 메뉴 코드가 없습니다!');
    //     return
    // }

    grids[compName].value.grdSave()
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    var gridstr = ui.rowData.MENU_TYPE;

    if(ui.dataIndx === 'MENU_ICON'){

        let paramsPopup = {
            modalName: 'SelectIcon',
            title: 'Font Icon List',
            width: '1000px !important',
            height: '500px !important',
            grid: store.state.NGCore.currentPageInfo[sender.refCode]['MENU_APPLY_COMPONENT_DTL_COLUMN'].filter(row => row.COLUMN_CODE == ui.dataIndx)[0].LISTDATA_BINDING,
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['MENU_ICON'], buttons: ['ok']
        }
        vfmBasePopup(SelectIcon, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.CODE)
            }
            
        })
    }

   if(ui.dataIndx === 'MENU_TYPE_REF'){
    if(gridstr === 'CATEGORY' || !!gridstr === false){
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0012') })
        return;
    }
    if (gridstr === 'PAGE') {
        let paramsPopup = {
            modalName: 'SelectCompInView',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: gridstr,
            componentList: store.state.NGCore.currentPageInfo,

            targetREFCODEList: ['PAGE'], buttons: ['ok']
        }
        vfmBasePopup(SelectCompInView, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
                sender.setCellData(ui.rowIndx, Object.keys(ui.rowData)[4], rtn.VIEW_DESC)
                sender.setCellData(ui.rowIndx, Object.keys(ui.rowData)[6], rtn.FILE_PATH)
            }
        })
    } else if (gridstr === 'BOARD' || gridstr === 'BOARDSMNG') {
        let paramsPopup = {
            modalName: 'DataBindingList',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: 'BOARD',
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['BOARD'], buttons: ['ok']
        }

        vfmBasePopup(SelectBoard, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.TABLENAME)
            }
        })
    }

   }


}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}

/*************************************************
 * Tab 관련 : End
 *************************************************/

</script>
<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal
                @resize="tabPageContentResize" :dbl-click-splitter="false">
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab"
                        @resize="tabPageContentResize">
                        <!-- content-class="mt-2" -->
                        <b-tab :title="t('ADMIN')" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_ADM')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('MENU_ADM')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_ADM')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_ADM')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_ADM' :refCode="'MENU_ADM'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <b-tab :title="t('USER')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_USER')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('MENU_USER')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_USER')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_USER')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_USER' :refCode="'MENU_USER'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <b-tab :title="t('MOBILE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="searchSplitCon parentCon">
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch('MENU_MOBILE')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('MENU_MOBILE')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('MENU_MOBILE')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('MENU_MOBILE')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='MENU_MOBILE' :refCode="'MENU_MOBILE'" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>

</style>