<script setup>
import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import pq from 'pqgrid';
import { VueFinalModal } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'grid', 'compName', 'width', 'height'])
const emit = defineEmits(['confirm', 'cancel'])

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if ((props.width || '') != '') styles['width'] = props.width
    if ((props.height || '') != '') styles['height'] = props.height

    return styles
})

let data = [];
// 현재 화면에 보여지고 있는 컬럼 데이터
const colModelData = props.grid.getGrid().value.options.colModel;
// 고정된 컬럼 데이터를 제외한 컬럼 데이터
const componentData = ng_core.common.currentPageInfo[props.compName].MENU_APPLY_COMPONENT_DTL_COLUMN;
// 틀고정된 컬럼 코드값(틀 고정이 없다면 예외 처리)
const fixation = colModelData[props.grid.getGrid().value.options['freezeCols']-1]
const fixationCode = !!fixation ? fixation.COLUMN_CODE : null
// 개인화에 필료한 컬럼만 추출
let common = colModelData.filter(col => Object.values(componentData).map(detailCol => detailCol.COLUMN_CODE).includes(col.COLUMN_CODE));

for(let i=0; common.length > i; i++){
    var obj = {
        COLUMN_CODE: common[i].COLUMN_CODE
        , COLUMN_NAME: common[i].title
        , WIDTH_HEADER: common[i].width
        , HIDDEN_YN_HEADER: common[i].hidden ? 'Y' : 'N'
        , FIX_YN: fixationCode == common[i].COLUMN_CODE ? 'Y' : 'N'
        , SORT: common[i].leftPos
    }
    data.push(obj)
}
            
//array of columns.
var colModel = [
    { 
        title: t('LABEL.COLUMN_NAME')
        , width: 150
        , dataType: "string"
        , hvalign: 'center'
        , halign: 'center'
        , dataIndx: "COLUMN_CODE" 
        , editable: false
    },
    { 
        title: t('LABEL.NAME')
        , width: 150
        , dataType: "string"
        , hvalign: 'center'
        , halign: 'center'
        , dataIndx: "COLUMN_NAME"
        , editable: false
    },
    { 
        title: t('LABEL.WIDTH')
        , width: 100
        , dataType: "integer"
        , hvalign: 'center'
        , halign: 'center'
        , dataIndx: "WIDTH_HEADER"
        , cls: "cell-edit-apply"
    },
    { 
        title: t('LABEL.HIDDEN_YN')
        , width: 100 
        , dataType: "string"
        , align: "center"
        , dataIndx: "HIDDEN_YN_HEADER"
        , type: 'checkbox'
        , cls: "cell-edit-apply"
        , cb: {
            all: false,
            header: false,
            check: "Y",
            uncheck: "N"
        }
    },
    { 
        title: t('LABEL.FIX_YN')
        , width: 100
        , dataType: "string"
        , align: "center"
        , dataIndx: "FIX_YN"
        , type: 'checkbox'
        , cls: "cell-edit-apply"
        , cb: {
            all: false,
            header: false,
            check: "Y",
            uncheck: "N"
        }
    },
    { 
        title: t('LABEL.SORT')
        , width: 100
        , dataType: "integer"
        , hvalign: 'center'
        , halign: 'center'
        , dataIndx: "SORT"
        , cls: "cell-edit-apply"
    }
];

//main object to be passed to pqGrid constructor.    
var options = {
    width: '100%',
    height: '100%',
    colModel: colModel,
    dataModel: {data: data}
};  


let gridHand;

const gridPOPPerson = ref()

onMounted(() => {
    gridHand = pq.grid(gridPOPPerson.value, options);
    //$(grid.value).pqGrid("refreshDataAndView")
    gridHand.refreshCM()
    gridHand.refreshDataAndView()

});

/** 하단 버튼(초기화, 적용, 확인) */
async function btnClick(type) {
    // 초기화
    if(type === 'I'){
        props.grid.resetColumn()
    // 적용, 저장
    }else if(type === 'A' || type === 'S'){
        // 틀고정이 두개 이상 체크 되었는지 확인
        const filteredData = gridHand.getData().filter(obj => obj.FIX_YN === 'Y')
        if(filteredData.length > 1){
            await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0029')})
            return
        }
        for(let j=0; gridHand.getData().length > j; j++){   
            let filetData = colModelData.filter((col) => col.COLUMN_CODE == gridHand.getData()[j].COLUMN_CODE)[0]
            filetData.hidden = gridHand.getData()[j].HIDDEN_YN_HEADER == 'Y' ? true : false
            filetData.width = gridHand.getData()[j].WIDTH_HEADER
            filetData.leftPos = gridHand.getData()[j].SORT
            if(gridHand.getData()[j].FIX_YN === 'Y'){
                props.grid.getGrid().value.options['freezeCols'] =  common[j].leftPos + 1
            }
        }
        // FIX_YN의 N의 갯수의 따라 틀고정 해제 여부
        const NFixCnt = gridHand.getData().filter(item => item.FIX_YN === 'N').length
        if(gridHand.getData().length == NFixCnt) props.grid.getGrid().value.options['freezeCols'] = null
        // 컬럼 재정렬
        colModelData.sort((a, b) => a.leftPos - b.leftPos)
        props.grid.getGrid().value.option("colModel", colModelData);
        props.grid.getGrid().value.refreshHeader()
        // 저장시에만 DB에 값을 저장한다
        if(type === 'S'){props.grid.saveColumn()}
    }

    emit('cancel', true)
}

/** 취소 버튼 */
function onCancel() {
    emit('cancel', false)
}

</script>

<template>
    <!-- Modal -->
    <vue-final-modal 
        class="flex justify-center items-center modal-container"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
            
        :drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel()"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div class="resultGrid" style="height:calc(100% - 50px); width: 100%;">
                <div ref="gridPOPPerson" style="width:100%; height:100%"></div>
            </div>
        </div>
        <div class="modal__action">
            <button class="btn btn-primary" @click="btnClick('I')">{{ t('BUTTON.BUTTON00012') }}</button>
            <button class="btn btn-primary" @click="btnClick('A')">{{ t('BUTTON.BUTTON00013') }}</button>
            <button class="btn btn-primary" @click="btnClick('S')">{{ t('BUTTON.BUTTON0003') }}</button>
            <button class="btn btn-secondary" @click="onCancel()">{{ t('BUTTON.BUTTON0005') }}</button>
        </div>
    </vue-final-modal>
</template>


<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
.cell-edit-apply {
    background-color: rgba(230, 228, 119, 0.527);
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>