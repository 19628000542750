<script setup>
import { onMounted, computed, ref, nextTick } from "vue";

const props = defineProps({
    menu: Object
})

onMounted(() => {
    
})


</script>

<template>

    <div class="pagetitle">
        <h1>Dashboard</h1>
        <nav>
            <ol class="breadcrumb">
                <li class="breadcrumb-item"><a href="index.html">Home</a></li>
                <li class="breadcrumb-item active">Dashboard</li>
            </ol>
        </nav>
    </div><!-- End Page Title -->

    <section class="section dashboard">
        <div class="row">
            HOME
        </div>
    </section>

</template>


<style>

</style>