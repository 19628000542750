<script setup>
    import { onMounted, computed, ref } from "vue";
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex";
    import { useI18n } from 'vue-i18n'

    import LeftMenu from './components/leftMenu.vue'

	const router = useRouter()
    const store = useStore()
    const { t } = useI18n()

    /** 유저 정보 */
    let userInfo = computed(() => store.state.NGCore.userInfo)
    /** 메뉴 정보 */
    let menuInfo = computed(() => store.state.NGCore.menuInfo)



    /** 메뉴 ON/PFF 관련 */
    let isMenuOpen = ref(false)

    onMounted(() => {
        
    })

    /** 로그 아웃 처리 */
    function logout() {
        ng_core.api.loginApi.logout().then(req => {
            router.replace('/login')
        }).catch(err => {
            router.replace('/login')
        })
    }

    /** 사이드 메뉴 토글 */
    function menuToggle() {
        document.querySelector('body').classList.toggle('toggle-sidebar')
    }

    /**
     * 메인화면으로 이동
     */
    function goHome() {
        router.replace('/')
    }
</script>


<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <a href="#" class="logo d-flex align-items-center" @click="goHome">
                <img src="assets/img/icon.png" alt="">
                <span class="d-none d-lg-block">Noligo FW</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" @click="menuToggle"></i>
        </div><!-- End Logo -->

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">

                <li class="nav-item dropdown pe-3">

                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ userInfo.NAME }}</span>
                    </a><!-- End Profile Iamge Icon -->

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ userInfo.NAME }}</h6>
                            <span>Web Designer</span>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-person"></i>
                                <span>My Profile</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-gear"></i>
                                <span>Account Settings</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                <i class="bi bi-question-circle"></i>
                                <span>Need Help?</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li @click="logout">
                            <a class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>

                    </ul><!-- End Profile Dropdown Items -->
                </li><!-- End Profile Nav -->

            </ul>
        </nav><!-- End Icons Navigation -->

    </header><!-- End Header -->

    <!-- ======= Sidebar ======= -->
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav" >
            <!-- <li class="nav-item">
                <a class="nav-link " href="index.html">
                    <i class="bi bi-grid"></i>
                    <span>Dashboard</span>
                </a>
            </li> -->

            <LeftMenu v-for="menu in menuInfo" :menu="menu" />
            
        </ul>

    </aside><!-- End Sidebar-->

</template>

<style>

</style>