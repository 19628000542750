<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
// import personalization from '@/components/popup/personalization.vue'
import personalization from '@/components/popup/personalization.vue'


const { t } = useI18n()

let con = getCurrentInstance()
let search = {}
const vfmBasePopup = inject('vfmBasePopup')

const grid01 = ref()
const grid_arr = [ grid01 ]

let grids = {
}
let grid_keys = Object.keys(ng_core.common.currentPageInfo)
// 그리드 ref 리스트 생성
for(let i = 0; i < grid_keys.length; i++) {
    grids[grid_keys[i]] = grid_arr[i]
}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

onMounted(() => {
    // 그리드 초기화
    // for(let i = 0; i < grid_keys.length; i++) {
    //     let tmpBaseComponentData = ng_core.common.currentPageInfo[grid_keys[i]]
    //     grids[grid_keys[i]].value.GridbaseInit(tmpBaseComponentData, tmpBaseComponentData['COMPONENT_TYPE'])
    // }
})

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    // let tmpData = {}
    // if(compName == 'COMMON_CODE_DTL') {
    //     tmpData['GROUP_CODE'] = grids['COMMON_CODE_MST'].value.getRowSelectedData().GROUP_CODE
    // }
    grids[compName].value.grdAddRow()
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 콤포넌트 팝업으로 선택 기능 */
function onComponentClone(compName) {
    let paramsPopup = {
        modalName: 'personalization',
        title: 'personalization',
        width: '1000px !important',
        height: '500px !important',
        grid: '',
        componentList: '',
        targetREFCODEList: [''], buttons: ['ok']
    }
    vfmBasePopup(personalization, paramsPopup).then(async (rtn) => {

    })
}


</script>
<template>
    <div class="container-fluid">

        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="searchSplitCon parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.LANG_TYPE')}} :</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_TYPE.value" :placeholder="t('LABEL.LANG_TYPE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.GROUP_CODE')}} :</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].GROUP_CD.value" :placeholder="t('LABEL.GROUP_CODE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.LANG_KEY')}} :</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_KEY.value" :placeholder="t('LABEL.LANG_KEY')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.LANG_VALUE')}} :</label>
                                <input type="text" class="conInputText" v-model="search['LANG'].LANG_VALUE.value" :placeholder="t('LABEL.LANG_VALUE')"
                                        @keyup.enter="onSearch('LANG')" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_MST')" :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                <button ref="read" @click="onSearch('LANG')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('LANG')" :title="t('BUTTON009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('LANG')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('LANG')" :title="t('BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                                
                                <GridColumnPerson @Grid="'LANG'" :Refs="$refs" :title="$t('BUTTON.BUTTON0011')" />
                                <ExportButton @Refs="$refs" Grid="'LANG'" :title="$t('BUTTON.BUTTON0012')" />
                                <ImportButton @Refs="$refs" Grid="'LANG'" :title="$t('BUTTON.BUTTON0013')" />
                            </div>
                        </div>
                    </div>
                     <div class="resultGrid" style="height:calc(100% - 40px);">
                        sdfsdf
                        <BaseGrid ref='grid01' :refCode="'LANG'" />
                    </div>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>