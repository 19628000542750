<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import dayjs from 'dayjs'

const { t } = useI18n()
const con = getCurrentInstance()
const search = {}
const menu_type = ref('T');
const USER_LOGIN = ref()

const grids = {
    'USER_LOGIN': USER_LOGIN
}

// 검색 조건 생성 -> 최적의 반복문
const tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0, n=tmpkeys.length; i<n; i++) {
    const tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0, m=tmpData.ARGS.length; j<m; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const onSearch = async compName => {
    search[compName].MODE.value = menu_type.value
     await grids[compName].value.grdSearchRef(search[compName])
} 

// 날짜
const today = new Date();
const year = today.getFullYear();     // 년도
const month = today.getMonth() + 1;   // 월
const date = today.getDate();         // 날짜
const result = year + '-' + month + '-' + date

search.USER_LOGIN.START_DATE.value = dayjs(result).format('YYYY-MM-DD')
search.USER_LOGIN.END_DATE.value = dayjs(result).format('YYYY-MM-DD')


</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
          
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.START_DATE')}} :</label>
                            <input type="text" class="conInputText" v-model="search['USER_LOGIN'].START_DATE.value" :placeholder="t('LABEL.START_DATE')" 
                                    @keyup.enter="onSearch('USER_LOGIN')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.END_DATE')}} :</label>
                            <input type="text" class="conInputText" v-model="search['USER_LOGIN'].END_DATE.value" :placeholder="t('LABEL.END_DATE')" 
                                    @keyup.enter="onSearch('USER_LOGIN')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{ $t('LABEL.DATE') }} :</label>
                            <select name="menu_type" data-title="선택" v-model="menu_type">
                                <option value="T">일별</option>
                                <option value="M">월별</option>
                           </select>
                        </div>
                        <div class="rightCon">
                            <div>
                            </div>
                            <button ref="read" @click="onSearch('USER_LOGIN')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                          
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <BaseGrid ref="USER_LOGIN" :refCode="'USER_LOGIN'"/>
                </div>
            </Pane>
        </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>