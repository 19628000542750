<script setup>
import { ref, onMounted, onBeforeUnmount, computed, watch, inject } from 'vue'
import { useRouter } from 'vue-router'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import Alert from '../../components/popup/Alert.vue'

import TopMenu from './TopMenu.vue'
import LeftMenu from './LeftMenuMan.vue'
import StatusBar from './StatusBar.vue'

import { useVfm } from 'vue-final-modal'
const vfm = useVfm()

const vfmBasePopup = inject('vfmBasePopup')
const router = useRouter()
const store = useStore()
const { t } = useI18n()

let opened = ref(true)
function changeOpen(val) {
    opened.value = val
}

// // 즐겨찾기 버튼 표시
let menuFavorite = ref(false)

/** 메뉴코드 */
let menuCode = computed(() => {
    let menuData = store.state.NGCore.currentMenuInfo
    if (router.currentRoute.value.meta.data['ROUTER_NAME'] == null) return ""

    // 즐겨찾기 표시 처리 : Start ===
    if (router.currentRoute.value.meta.data['MENU_CODE'] == null) {
        menuFavorite.value = false
        return
    }
    if(store.state.NGCore.menuFavorites[router.currentRoute.value.meta.data['MENU_CODE']] == null) {
        menuFavorite.value = false
    } else {
        menuFavorite.value = true
    }
    // 즐겨찾기 표시 처리 : End ===

    return menuData.PARENT_MENU_CODE == "FAVORITES" ? menuData.ROUTER_NAME.replace('FAV_', '') : menuData.ROUTER_NAME
})
const menuPath = computed(() => {
    if (store.state.NGCore.currentMenuInfo['FULLPATH'] == null) return []
    let menuPath = []
    let fullPath = store.state.NGCore.currentMenuInfo.FULLPATH.toUpperCase().split('/')
    if(fullPath.length <= 2) return []
    else {
        let subMenu = fullPath[2].split('__')
        for(let j = 0; j < subMenu.length; j++) {
            menuPath.push(subMenu[j])
        }
        return menuPath
    }
    //return filePath.splice(2, filePath.length - 2)
})

onMounted(() => {
    console.log('main')
    console.dir(router.getRoutes())
    ActivateWindow()
    window.addEventListener('focus', ActivateWindow)

    // 스타일 제정의
    for(let i = 0; i < ng_core.common.theme.length; i++) {
        ng_core.common.theme
        document.documentElement.style.setProperty(ng_core.common.theme[i].THEME_CSS, ng_core.common.theme[i].VAL);
    }

})

onBeforeUnmount(() => {
    window.removeEventListener('focus', ActivateWindow)
})

/** 해당 브라우저 활성화 시 호출 */
function ActivateWindow() {
    if (ng_core.common.isNGCoreReady == 'Y') {
        store.dispatch('NGCore/checkLogin').then(async () => {
            if (ng_core.common.isLogin() != 'Y') {
                vfm.closeAll()
                vfmBasePopup(Alert, { state: 'info', contents: 'MSG.MSG0017' })
                router.replace('/login')
            }
        }).catch(err => {
            console.log('ActivateWindow')
            console.dir(err)
        })
    }
}

/** 즐겨 찾기 처리  */
async function onClickMenuFavorite(method) {
    var params = {
        METHOD_CODE: 'MENU_FAVORITES',
        SQLLOG_YN: 'N',             // db log 남길지 유무
        paramsdata: [{
            METHOD: method,
            MENU_AREA_TYPE: ng_core.common.config.menuAreaType,
            MENU_CODE: menuCode.value,
            SORT: -1,
        }]
    }
    try {
        let rtn = await store.dispatch('NGCore/saveSysData', params)
        if(rtn.status) {
            menuFavorite.value = !menuFavorite.value
            if(menuFavorite.value) {
                store.state.NGCore.menuFavorites[menuCode.value] = { MENU_CODE: menuCode.value }
            } else {
                delete store.state.NGCore.menuFavorites[menuCode.value]
            }
        }
        //vfmBasePopup(Alert, { state: 'info', contents: t(rtn.rtnmsgcode) })
    } catch(err) {
        console.log('onClickMenuFavorite')
        console.dir(err)
    }
    
}

function changeMenu(event, val) {
    
}
</script>

<template>
    <div id="allTheNav">
        <TopMenu v-model:opened="opened" @changeOpen="changeOpen" />
        <LeftMenu v-model:opened="opened" type="board" @changeOpen="changeOpen" @changeMenu="changeMenu" />
        <div id="main" :class="{ mainopen: opened, sidebarclose: !opened }">
            <header class="card header-card">
                <h5 class="header-body">
                    &nbsp;<font-awesome-icon class="favorite-icon" v-if="menuFavorite" :icon="['fas', 'star']"
                        @click="onClickMenuFavorite('D')"></font-awesome-icon> <!-- 즐겨찾기 O -->
                    <font-awesome-icon class="favorite-icon" v-if="!menuFavorite" :icon="['far', 'star']"
                        @click="onClickMenuFavorite('S')"></font-awesome-icon> <!-- 즐겨찾기 X -->
                    <span>&nbsp;</span><strong>{{ t('MENU.' + menuCode) }}</strong>
                    <div class="r">
                        <span style="font-size:12px"><font-awesome-icon icon="home" size="sm" /> Home </span>
                        <span style="font-size:11px" v-for="(menuItem, index) in menuPath" v-bind:item="menuItem"
                            v-bind:index="index" v-bind:key="index">
                            &nbsp;<font-awesome-icon icon="angle-right" size="sm" />&nbsp;{{ t('MENU.' + menuItem) }}
                        </span>
                        &nbsp;

                        <!-- 위 즐겨찾기 아이콘 클릭시 이벤트 태우기 -->

                    </div>
                </h5>
            </header>
            <router-view></router-view>
        </div>
        <StatusBar :opened="opened" />
    </div>
</template>

<style lang="scss">
#main {
    height: calc(100% - 80px);
}
</style>