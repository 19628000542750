<script setup>
    import { onMounted, computed, ref } from "vue";
    import { useRouter } from 'vue-router'
    import { useStore } from "vuex";
    import { useI18n } from 'vue-i18n'

    import LeftMenu from './components/leftMenu.vue'

	const router = useRouter()
    const store = useStore()
    const { t } = useI18n()

    /** 유저 정보 */
    let userInfo = computed(() => store.state.NGCore.userInfo)
    /** 메뉴 정보 */
    let menuInfo = computed(() => store.state.NGCore.menuInfo)



    /** 메뉴 ON/PFF 관련 */
    let isMenuOpen = ref(false)

    onMounted(() => {
        
    })

    /** 로그 아웃 처리 */
    function logout() {
        ng_core.api.loginApi.logout().then(req => {
            router.replace('/login')
        }).catch(err => {
            router.replace('/login')
        })
    }

    /** 사이드 메뉴 토글 */
    function menuToggle() {
        document.querySelector('body').classList.toggle('toggle-sidebar')
    }

    /**
     * 메인화면으로 이동
     */
    function goHome() {
        router.replace('/')
    }
</script>


<template>
    <!-- ======= Header ======= -->
    <header id="header" class="header fixed-top d-flex align-items-center">

        <div class="d-flex align-items-center justify-content-between">
            <a href="#" class="logo d-flex align-items-center" @click="goHome">
                <img src="assets/img/icon.png" alt="">
                <span class="d-none d-lg-block">Noligo FW</span>
            </a>
            <i class="bi bi-list toggle-sidebar-btn" @click="menuToggle"></i>
        </div><!-- End Logo -->

        <nav class="header-nav ms-auto">
            <ul class="d-flex align-items-center">

                <li class="nav-item dropdown pe-3">

                    <a class="nav-link nav-profile d-flex align-items-center pe-0" href="#" data-bs-toggle="dropdown">
                        <img src="assets/img/profile-img.jpg" alt="Profile" class="rounded-circle">
                        <span class="d-none d-md-block dropdown-toggle ps-2">{{ userInfo.NAME }}</span>
                    </a><!-- End Profile Iamge Icon -->

                    <ul class="dropdown-menu dropdown-menu-end dropdown-menu-arrow profile">
                        <li class="dropdown-header">
                            <h6>{{ userInfo.NAME }}</h6>
                            <span>Web Designer</span>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-person"></i>
                                <span>My Profile</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="users-profile.html">
                                <i class="bi bi-gear"></i>
                                <span>Account Settings</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li>
                            <a class="dropdown-item d-flex align-items-center" href="pages-faq.html">
                                <i class="bi bi-question-circle"></i>
                                <span>Need Help?</span>
                            </a>
                        </li>
                        <li>
                            <hr class="dropdown-divider">
                        </li>

                        <li @click="logout">
                            <a class="dropdown-item d-flex align-items-center" href="#">
                                <i class="bi bi-box-arrow-right"></i>
                                <span>Sign Out</span>
                            </a>
                        </li>

                    </ul><!-- End Profile Dropdown Items -->
                </li><!-- End Profile Nav -->

            </ul>
        </nav><!-- End Icons Navigation -->

    </header><!-- End Header -->

    <!-- ======= Sidebar ======= -->
    <aside id="sidebar" class="sidebar">
        <ul class="sidebar-nav" id="sidebar-nav">
            <li class="nav-item">
                <a class="nav-link " href="index.html">
                    <i class="bi bi-grid"></i>
                    <span>Dashboard</span>
                </a>
            </li><!-- End Dashboard Nav -->

            <!-- 로그인 -->
            <li class="nav-item">
                <a class="nav-link collapsed" href="./pages/users/login.html">
                    <i class="bi bi-emoji-smile"></i>
                    <span>로그인</span>
                </a>
            </li>

            <LeftMenu :menuInfo="menuInfo" />
            <!-- 기준정보 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#md-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-1-square"></i><span>기준정보</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="md-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <!-- <i class="bi bi-grid"></i> -->
                        <img src="assets/img/menu1.png" alt="">
                        <div class="label">외매현황</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">제품정보</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">고객점정보</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">고객점목표</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">방문계획</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">주변고객점</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">설문지</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">게시판</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">고객점특성</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">신제품정보</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">판매가출력</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">용어사전</div>
                    </div>
                </div>
            </li>

            <!-- 판매관리 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#mp-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-2-square"></i><span>판매관리</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="mp-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">판매활동</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">수금입력</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">전용계좌처리</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">전자어음처리</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">명세서발행</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">프로모션조회</div>
                    </div>
                </div>
            </li>

            <!-- 재고관리 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#st-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-3-square"></i><span>재고관리</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="st-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">차량입고</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">창고반환</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">차량재고조회</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">반품장출고</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">재고실사</div>
                    </div>
                </div>
            </li>

            <!-- 송수신/결산 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#se-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-4-square"></i><span>송수신/결산</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="se-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">기준정보수신</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">프로모션수신</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">물류자료송신</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">일일자료송신</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">일일결산서</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">주행Km</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">목표수신</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">사진송신</div>
                    </div>
                </div>
            </li>

            <!-- 실적조회 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#rt-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-5-square"></i><span>실적조회</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="rt-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">당일판매조회</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">당일수금조회</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">고객점달성율</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">일자별매출</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">일자별수금</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">매출종합</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">월별실적</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">고객점커버율</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">알림</div>
                    </div>
                </div>
            </li>

            <!-- 기타처리 -->
            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#et-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-6-square"></i><span>기타처리</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <div id="et-nav" class="menu-grid-list nav-content collapse" data-bs-parent="#sidebar-nav">
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">매대사진등록</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">비밀번호변경</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">바코드샘플출력</div>
                    </div>
                    <div class="menu">
                        <i class="bi bi-grid"></i>
                        <div class="label">매대입력</div>
                    </div>
                </div>
            </li>

            <li class="nav-heading">Sample</li>

            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#components-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-menu-button-wide"></i><span>Components</span><i
                        class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="components-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="components-alerts.html">
                            <i class="bi bi-circle"></i><span>Alerts</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-accordion.html">
                            <i class="bi bi-circle"></i><span>Accordion</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-badges.html">
                            <i class="bi bi-circle"></i><span>Badges</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-breadcrumbs.html">
                            <i class="bi bi-circle"></i><span>Breadcrumbs</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-buttons.html">
                            <i class="bi bi-circle"></i><span>Buttons</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-cards.html">
                            <i class="bi bi-circle"></i><span>Cards</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-carousel.html">
                            <i class="bi bi-circle"></i><span>Carousel</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-list-group.html">
                            <i class="bi bi-circle"></i><span>List group</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-modal.html">
                            <i class="bi bi-circle"></i><span>Modal</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-tabs.html">
                            <i class="bi bi-circle"></i><span>Tabs</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-pagination.html">
                            <i class="bi bi-circle"></i><span>Pagination</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-progress.html">
                            <i class="bi bi-circle"></i><span>Progress</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-spinners.html">
                            <i class="bi bi-circle"></i><span>Spinners</span>
                        </a>
                    </li>
                    <li>
                        <a href="components-tooltips.html">
                            <i class="bi bi-circle"></i><span>Tooltips</span>
                        </a>
                    </li>
                </ul>
            </li><!-- End Components Nav -->

            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#forms-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-journal-text"></i><span>Forms</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="forms-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="forms-elements.html">
                            <i class="bi bi-circle"></i><span>Form Elements</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-layouts.html">
                            <i class="bi bi-circle"></i><span>Form Layouts</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-editors.html">
                            <i class="bi bi-circle"></i><span>Form Editors</span>
                        </a>
                    </li>
                    <li>
                        <a href="forms-validation.html">
                            <i class="bi bi-circle"></i><span>Form Validation</span>
                        </a>
                    </li>
                </ul>
            </li><!-- End Forms Nav -->

            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#tables-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-layout-text-window-reverse"></i><span>Tables</span><i
                        class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="tables-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="tables-general.html">
                            <i class="bi bi-circle"></i><span>General Tables</span>
                        </a>
                    </li>
                </ul>
            </li><!-- End Tables Nav -->

            <li class="nav-item">
                <a class="nav-link collapsed" data-bs-target="#icons-nav" data-bs-toggle="collapse" href="#">
                    <i class="bi bi-gem"></i><span>Icons</span><i class="bi bi-chevron-down ms-auto"></i>
                </a>
                <ul id="icons-nav" class="nav-content collapse " data-bs-parent="#sidebar-nav">
                    <li>
                        <a href="icons-bootstrap.html">
                            <i class="bi bi-circle"></i><span>Bootstrap Icons</span>
                        </a>
                    </li>
                </ul>
            </li><!-- End Icons Nav -->

            <li class="nav-heading">Pages</li>

            <li class="nav-item">
                <a class="nav-link collapsed" href="pages-blank.html">
                    <i class="bi bi-file-earmark"></i>
                    <span>Blank</span>
                </a>
            </li><!-- End Blank Page Nav -->

        </ul>

    </aside><!-- End Sidebar-->

</template>

<style>

</style>