<script setup>
import { ref, getCurrentInstance, onMounted, defineAsyncComponent, inject, onBeforeUpdate } from 'vue'
import { useStore } from "vuex";
import { useI18n } from 'vue-i18n'
import ChartComp from '/src/components/chart/Chart.vue'

const store = useStore()
const { t } = useI18n()

const cards = [
    ref({ id: 0, title: '메뉴이동', METHOD_CODE: 'DASHBOARD_TEST1', type: 'line' }),
    ref({ id: 1, title: '카드 2', METHOD_CODE: 'DASHBOARD_TEST2', type: 'bar' }),
    // ref({ id: 2, title: '카드 3', METHOD_CODE: 'DASHBOARD_TEST3', type: 'bar' }),
    // ref({ id: 3, title: '카드 4', METHOD_CODE: 'DASHBOARD_TEST4', type: 'bar' }),
]
const charts = ref([])

onMounted(async () => {
    for (let i = 0; i < cards.length; i++) {
        // 메뉴 이동 처리
        var params = {
            PREF: 'SYS',
            VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
            METHOD_CODE: cards[i].value.METHOD_CODE,
            DATA: {},
            SQLLOG_YN: 'N',
        }

        try {
            let rtn = await ng_core.common.getData(params)
            if (rtn.data.status) {

                var datas = {
                    label: '메뉴 이동',
                    labels: [],
                    datas: [],
                }
                let dataKeys = []
                for (let j = 0; j < rtn.data.rtndata.length; j++) {
                    if (j == 0) {
                        dataKeys = Object.keys(rtn.data.rtndata[0])
                    }
                    datas.labels.push(rtn.data.rtndata[j][dataKeys[0]]);
                    datas.datas.push(rtn.data.rtndata[j][dataKeys[1]]);
                }
                charts.value[i].fillData(datas)

            }
        } catch (err) {
            console.log('getData')
            console.dir(err)
            return err
        } finally {
        }
    }
})

onBeforeUpdate(() => {
    charts.value = [];
});

</script>

<template>
    <div class="container-fluid">
        <b-row>
            <b-col md="6" sm="12" class="mb-3" v-for="(card, idx) in cards" :key="card.value.id">
                <b-card>
                    <template #header>
                        <h5 class="mb-0">{{ card.value.title }}</h5>
                    </template>
                    <p>
                        <ChartComp :ref="el => { charts[idx] = el }" :ChartData="card" />
                    </p>
                </b-card>
            </b-col>
        </b-row>
    </div>
</template>
