import { defineAsyncComponent } from 'vue'
import personalization from '@/components/popup/personalization.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import JSONPopup from '@/components/popup/JSON.vue'
import { useStore } from "vuex";
import dayjs from 'dayjs';

// 팝업 관련
let vfmBasePopup
// 언어팩 관련
let t
// 검색 관련
let searchRef

const store = useStore()

let clearGridList = []
/**
 * 화면에서 사용되는 grid 리스트
 * @param {Array} grid_arr 화면에 미리 지정된 TEMPLATE_GRID_CODE 값 리스트
 * @param {Array} targetRefCode 리스트의 refcode만 매칭
 * @returns 
 */
function getGridKeys(grid_arr, targetRefCode) {
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)
    let tmpRtnKeys = []
    let tmpScreenGridKeys = Object.keys(grid_arr)           // 해당 화면에 정의 되어 있는 템플릿 그리드 키
    for(let i = 0; i < tmpGridKeys.length; i++) {
        if((ng_core.common.currentPageInfo[tmpGridKeys[i]].TEMPLATE_GRID_CODE || '') != '') {
            let tmpRefCode = ng_core.common.currentPageInfo[tmpGridKeys[i]].REF_CODE
            if(targetRefCode && targetRefCode.length > 0) {     // 지정 대상이 있으면 등록
                if(tmpScreenGridKeys.includes(ng_core.common.currentPageInfo[tmpGridKeys[i]].TEMPLATE_GRID_CODE)) {
                    if(targetRefCode.includes(tmpRefCode)) { 
                        tmpRtnKeys.push(tmpRefCode)
                    }
                }
            } else {
                if(tmpScreenGridKeys.includes(ng_core.common.currentPageInfo[tmpGridKeys[i]].TEMPLATE_GRID_CODE)) {
                    tmpRtnKeys.push(tmpRefCode)
                }
            }
        }
    }
    return tmpRtnKeys
}

/**
 * 기본 초기화
 * @param {*} ref 
 * @param {*} grids 
 * @param {*} grid_arr 
 * @param {*} grid_keys_org 
 * @param {*} search 
 * @param {Array} targetRefCode 리스트의 refcode만 매칭
 */
export async function BaseInit(t_org, vfmBasePopup_org, ref, grids, grid_arr, grid_keys, search, targetRefCode) {
    
    t = t_org
    vfmBasePopup = vfmBasePopup_org
    searchRef = search

    // 템플릿 그리드명 매칭 확인
    let grid_keys_tmp = getGridKeys(grid_arr, targetRefCode) //Object.keys(ng_core.common.currentPageInfo)
    if(grid_keys_tmp.length == 0) {
        // 매칭이 하나도 없나면 에러 메시지
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKVIEWCOMPGRIDID') })
        return
    }
    for(let i = 0; i < grid_keys_tmp.length; i++) {
        grid_keys.push(grid_keys_tmp[i])
    }

    // 그리드 ref 리스트 생성
    for (let i = 0; i < grid_keys_tmp.length; i++) {
        grids[grid_keys_tmp[i]] = grid_arr[ng_core.common.currentPageInfo[grid_keys_tmp[i]].TEMPLATE_GRID_CODE]
    }

    // 검색 조건 생성
    // let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
    for (let i = 0; i < grid_keys_tmp.length; i++) {
        let tmpData = ng_core.common.currentPageInfo[grid_keys_tmp[i]]
        search[grid_keys_tmp[i]] = {}
        for (let j = 0; j < tmpData.ARGS.length; j++) {
            // 기본 데이터 처리 룰을 받아서 조회 조건에 추가처리
            let ruleData = tmpData.ARGS[j].RULE_DATA.length == 0 ? {} : tmpData.ARGS[j].RULE_DATA[0]        // 값이 있느면 무조건 하나만 조건이 있음(추가로 있더라도 무시)

            if(ruleData.COMMAND == 'DEFAULTVAL') {
                switch(ruleData.DATA.TYPE) {
                    case 'TEXT':
                    case 'COMBO':
                        search[grid_keys_tmp[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref(ruleData.DATA.DEFAULT_VAL)
                        break
                    case 'DATE':
                        let d = dayjs()
                        if (ruleData.DATA.DEFAULT_VAL_DATE.TYPE == 'DAY') {
                            d = d.add(Number(ruleData.DATA.DEFAULT_VAL_DATE.DIFF), 'day');
                        } else if (ruleData.DATA.DEFAULT_VAL_DATE.TYPE == 'MONTH') {
                            d = d.add(Number(ruleData.DATA.DEFAULT_VAL_DATE.DIFF), 'month');
                        } else if (ruleData.DATA.DEFAULT_VAL_DATE.TYPE == 'YEAR') {
                            d = d.add(Number(ruleData.DATA.DEFAULT_VAL_DATE.DIFF), 'year');
                        }
                        if ((ruleData.DATA.DEFAULT_VAL_DATE.FORMAT || '') != '') {
                            d = d.format(ruleData.DATA.DEFAULT_VAL_DATE.FORMAT)
                        }
                        search[grid_keys_tmp[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref(d)
                        break
                    default:
                        search[grid_keys_tmp[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
                        break
                }
            } else {
                search[grid_keys_tmp[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
            }

        }
    }

}

export async function MountedByInit(grids, grid_keys, search, gridRefSearch, gridRefButtons) {
    // 그리드 초기화
    for (let i = 0; i < grid_keys.length; i++) {
        let tmpBaseComponentData = ng_core.common.currentPageInfo[grid_keys[i]]
        if ((grids[grid_keys[i]] || '') == '') {
            await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKVIEWCOMPGRIDID', [grid_keys[i]]) })
            return
        }
        grids[grid_keys[i]].value.GridbaseInit(tmpBaseComponentData, tmpBaseComponentData['COMPONENT_TYPE'])

    }
    InitSearchBinding(grids, grid_keys, search, gridRefSearch, gridRefButtons)

}

/**
 * 조회, 버튼 초기화 처리
*/
async function InitSearchBinding(grids, grid_keys, search, gridRefSearch, gridRefButtons) {
    if (Object.keys(ng_core.common.currentPageBindingData).filter((row) => row == 'status').length > 0) {
        for (let i = 0; i < grid_keys.length; i++) {
            if ((grids[grid_keys[i]] || '') == '') {
                await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKVIEWCOMPGRIDID', [grid_keys[i]]) })
                return
            }
            let tmpBaseComponentData = ng_core.common.currentPageInfo[grid_keys[i]]

            let grid_code = ng_core.common.currentPageInfo[grid_keys[i]].TEMPLATE_GRID_CODE

            // 조회 조건생성
            for (let j = 0; j < tmpBaseComponentData.ARGS.length; j++) {
                if(tmpBaseComponentData.ARGS[j].USE_YN == 'N') continue
                let loadComp = null
                switch(tmpBaseComponentData.ARGS[j].SEARCH_COMP_TYPE) {
                    case 'DROPDOWNLIST':
                        loadComp = defineAsyncComponent(() => import('@/components/commons/searchDropdownList.vue'))
                        break
                    case 'CHECKBOX':
                        loadComp = defineAsyncComponent(() => import('@/components/commons/searchCheckBox.vue'))
                        break
                    case 'RADIOBOX':
                        loadComp = defineAsyncComponent(() => import('@/components/commons/searchRadioBox.vue'))
                        break
                    default:
                        loadComp = defineAsyncComponent(() => import('@/components/commons/searchInput.vue'))
                        break
                }
                gridRefSearch[grid_code + 'Search'].value.push({
                    search: search[grid_keys[i]],
                    searchData: tmpBaseComponentData.ARGS[j],
                    component: loadComp,
                })

            }

            // 버튼 생성
            if(tmpBaseComponentData.MENU_APPLY_COMPONENT_DTL_BUTTON) {
                for (let z = 0; z < tmpBaseComponentData.MENU_APPLY_COMPONENT_DTL_BUTTON.length; z++) {
                    if (ng_core.common.getLoginInfo().LEVELS <= Number(tmpBaseComponentData.MENU_APPLY_COMPONENT_DTL_BUTTON[z].USER_AUTH_LEVEL || 99)) {
                        let tmpbtn = tmpBaseComponentData.MENU_APPLY_COMPONENT_DTL_BUTTON[z]
                        if (tmpbtn.USE_YN == 'N') continue
    
                        gridRefButtons[grid_code + 'Button'].value.push({
                            buttonData: tmpbtn,
                            buttonLanguage: t(`BUTTON.${tmpbtn.BUTTON_NAME}`),
                            grid_key: grid_keys[i],
                            component: defineAsyncComponent(() => import('@/components/commons/button.vue')),
                        })

                        let tmpRuldDatas = tmpbtn.RULE_DATA
                        for(let h = 0; h < tmpRuldDatas.length; h++) {
                            let tmpRuldData = tmpRuldDatas[h]
                            if(tmpRuldData.DEFAULT_SEARCH && tmpRuldData.DEFAULT_SEARCH == 'Y') {     // 기본 조회 여부
                                await SearchCommon(grids, search, grid_keys[i], {}, {})
                            }
                        }
                    }
                }
            }
        }
    } else {
        requestAnimationFrame(function() {
            InitSearchBinding(grids, grid_keys, search, gridRefSearch, gridRefButtons)
        });
    }
}

/**
 * 조회(공통)
 * @param {*} grids 
 * @param {*} search 
 * @param {*} compName 
 * @param {*} fixDatasRef_org 
 * @param {*} options_org 
 * @param {*} buttonData 
 * @returns 
 */
export async function SearchCommon(grids, search, compName, fixDatasRef_org, options_org, buttonData) {
    let options = options_org || {}
    let fixDatasRef = fixDatasRef_org || {}
    try {
        // 조회 조건 수만큼 반복해서 RULE_DATA에 DATAREF를 모두 참음
        let tmpARGS = ng_core.common.currentPageInfo[compName].ARGS
        for(let j = 0; j < tmpARGS.length; j++) {
            let searGridRefCode = tmpARGS[j].RULE_DATA.filter(row => (row.COMMAND || '') == 'DATAREF')
            for (let i = 0; i < searGridRefCode.length; i++) {
                if (grids[searGridRefCode[i].DATA.REFCODE] != null && grids[searGridRefCode[i].DATA.REFCODE].value.getRowSelectedDataByRadio() != null) {
                    let tmpRowSelectedData = grids[searGridRefCode[i].DATA.REFCODE].value.getRowSelectedDataByRadio()[searGridRefCode[i].DATA.REFCOLUMN]
                    search[compName][tmpARGS[j].PARAMETER_NAME2].value = tmpRowSelectedData
                } else {
                    await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0030') })
                    // alert('선택 정보가 없어 조회가 불가능합니다.')
                    return false
                }
            }
        }

        clearGridList = []
        clearGridHirackey(compName)
        for(let i = 0; i < clearGridList.length; i++) {
            grids[clearGridList[i]].value.grdDataClear()
            grids[clearGridList[i]].value.getGrid().value.refreshDataAndView()
        }
        
        // 조회
        grids[compName].value.grdSearchRef(search[compName], fixDatasRef, options)
        return true

    } catch(e) {
        debugger
        return false
    }

}

/**
 * 조회시 내가 조회 시킨 모든 대상 그리드를 모두 초기화(하이라키)
*/
function clearGridHirackey(compName) {
    if(!ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_OPTION) return
    let targetGridList = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_OPTION.filter((row) => row.OPTION_CODE == 'ROWRADIO' && row.SEARCH_GRID_LIST.length > 0)
    if (targetGridList.length == 1) {
        for (let i = 0; i < targetGridList[0].SEARCH_GRID_LIST.length; i++) {
            clearGridList.push(targetGridList[0].SEARCH_GRID_LIST[i])
            clearGridHirackey(targetGridList[0].SEARCH_GRID_LIST[i])
        }
    }
}

/**
 * 행 추가
 * @param {*} grids 
 * @param {*} compName 
 */
export function AddRowCommon(grids, compName, buttonData) {
    let tmpData = {}

    // RUEL_DATA를 보고 기본 설정 값 처리
    let tmpColumns = ng_core.common.currentPageInfo[compName].MENU_APPLY_COMPONENT_DTL_COLUMN
    for(let j = 0; j < tmpColumns.length; j++) {
        let tmpRefData = tmpColumns[j].RULE_DATA.filter(row => (row.COMMAND || '') == 'DATAREF')
        for (let i = 0; i < tmpRefData.length; i++) {
            if (grids[tmpRefData[i].DATA.REFCODE] != null && grids[tmpRefData[i].DATA.REFCODE].value.getRowSelectedDataByRadio() != null) {
                let tmpRowSelectedData = grids[tmpRefData[i].DATA.REFCODE].value.getRowSelectedDataByRadio()[tmpRefData[i].DATA.REFCOLUMN]
                tmpData[tmpColumns[j].COLUMN_CODE] = tmpRowSelectedData
            }
        }    
    }

    grids[compName].value.grdAddRow(tmpData)
}

/**
 * 행 삭제
 * @param {*} grids 
 * @param {*} compName 
 */
export function RemoveRowCommon(grids, compName, buttonData) {
    return grids[compName].value.grdDeleteRow()
}

/**
 * 저장
 * @param {*} grids 
 * @param {*} compName 
 */
export function SaveCommon(grids, compName, buttonData) {
    return grids[compName].value.grdSave()
}

/**
 * 엑셀 다운로드
 * @param {*} grids 
 * @param {*} compName 
 */
export function ExcelDownCommon(grids, compName, buttonData) {
    grids[compName].value.grdExcelDown()
}

/**
 * 엑셀 업로드
 * @param {*} grids 
 * @param {*} compName 
 */
export function ExcelUploadCommon(grids, compName, buttonData) {
    grids[compName].value.grdExcelUpload()
}

/**
 * 개인화
 * @param {*} grids 
 * @param {*} compName 
 */
export function PersonalizationCommon(grids, compName, buttonData) {
    let paramsPopup = {
        modalName: 'personalization',
        title: t('LABEL.PERSONALIZATION'),
        width: '760px !important',
        height: '440px !important',
        grid: grids[compName],
        compName: compName,
        buttons: ['ok', 'cancel'],
    }
    vfmBasePopup(personalization, paramsPopup).then(async (rtn) => {
        grids[compName].value.refresh();
    })    
}

/**
 * RULE Data를 이용한 버튼
 * @param {*} grids 
 * @param {*} compName 
 */
export function RULE_Data_Button(grids, compName, buttonData) {
    let RULE_DATA_ARRAY = buttonData.RULE_DATA

    for(let i = 0; i < RULE_DATA_ARRAY.length; i++) {
        let command = RULE_DATA_ARRAY[i].COMMAND
        if(!command) continue
        
        switch(command) {
            case 'SEARCH':      // 검색
                if(RULE_DATA_ARRAY[i].DATA.SEARCHREFCODE != null) {
                    SearchCommon(grids, searchRef, RULE_DATA_ARRAY[i].DATA.SEARCHREFCODE, {}, { hideMessageYN: 'N' }, buttonData)
                }
                break
            case 'MOVEROW':     // 행 이동
                BtnCommand_MOVEROW(grids, compName, buttonData, RULE_DATA_ARRAY[i])
                break
            case 'DELETEROW':   // 행 삭제
                RemoveRowCommon(grids, compName, {})
                break
            case 'ACTION':      // 실행
                break
        }
    }
}

/**
 * 행 이동
 * @param {*} grids 
 * @param {*} compName 
 * @param {*} buttonData 
 * @param {*} RULE_DATA_ARRAY 
 */
function BtnCommand_MOVEROW(grids, compName, buttonData, RULE_DATA) {
    if(RULE_DATA.TYPE == 'CHECK') {     // 행선택이 채크의 경우
        let checkRowList = grids[RULE_DATA.FROMREFCODE].value.getGrdCheckList()
        for(let i = checkRowList.length-1; i >= 0; i--) {
            let copyColData = {}
            for(let j = 0; j < RULE_DATA.COLUMN.length; j++) {
                if(RULE_DATA.COLUMN[j].FROMREFCODE) {
                    // 기본 참고코드가 아닌 별로 참고 코드 사용(선택 그리드)
                    copyColData[RULE_DATA.COLUMN[j].TO] = grids[RULE_DATA.COLUMN[j].FROMREFCODE].value.getRowSelectedData()[RULE_DATA.COLUMN[j].FROMREFCOLUMN]
                } else {
                    // 기본 설저의 값 사용
                    copyColData[RULE_DATA.COLUMN[j].TO] = checkRowList[i][RULE_DATA.COLUMN[j].FROM]
                }
            }
            // 행 내용을 복사해 torefcode에 추가
            grids[RULE_DATA.TOREFCODE].value.grdAddRow(copyColData, 'last')

            // fromrefcode 체크행 삭제
            grids[RULE_DATA.FROMREFCODE].value.getGrid().value.deleteRow({ rowIndx: checkRowList[i].pq_ri })

        }
        
        grids[RULE_DATA.FROMREFCODE].value.getGrid().value.refreshDataAndView()
        //grids[RULE_DATA.TOREFCODE].value.getGrid().value
        //$(grid.value.element).pqGrid("refreshDataAndView")
        checkRowList.splice(0, checkRowList.length)
    } else if(RULE_DATA.TYPE == 'SELECTROW') {  // 행 선택이 로우 선택의 경우

    }
}

/**
 * Row Click Radio(행 선택 조회)
 * @param {*} grids 
 * @param {*} search 
 * @param {*} ui 
 * @param {*} row 
 * @param {*} baseComponentData 
 */
export function RowClickRadioCommon(grids, search, ui, row, baseComponentData) {
    let ruleDatas = []
    try {
        ruleDatas = baseComponentData.MENU_APPLY_COMPONENT_DTL_OPTION.filter((opt) => opt.OPTION_CODE == 'ROWRADIO')[0].RULE_DATA
    } catch(err) {}

    for(let i = 0; i < ruleDatas.length; i++) {
        let ruleData = ruleDatas[i]
        switch(ruleData.COMMAND) {
            case 'SEARCH':      // 조회
                if((ruleData.DATA.SEARCHREFCODE || '') != '') {

                    // 대상 그리드 조회 연결된 그리드 초기화
                    clearGridList = []
                    clearGridHirackey(ruleData.DATA.SEARCHREFCODE)
                    for(let i = 0; i < clearGridList.length; i++) {
                        grids[clearGridList[i]].value.grdDataClear()
                        grids[clearGridList[i]].value.getGrid().value.refreshDataAndView()
                    }
                    SearchCommon(grids, search, ruleData.DATA.SEARCHREFCODE, {}, {})
                }
                break
        }
    }

}


function OneSelectRowByPopup(sender, ui, SelectRow, RESULT_PROC) {
    for(let i = 0; i < RESULT_PROC.length; i++) {
        sender.setCellData(ui.rowIndx, RESULT_PROC[i]['GRID_COLUMN'], SelectRow[RESULT_PROC[i]['POP_GRID_COLUMN']])
    }
}

/**
 * 셀 버튼 클릭
 */
export async function GrdCellButtonCommon(vfmBasePopup, grids, sender, ui, event) {
    let ruleData = ui.column.orgColumn.RULE_DATA
    let popData = ruleData.length == 0 ? {} : ruleData[0]

    if(ui.column.orgColumn.COLUMN_COMP_TYPE == 'JSON') {
        let paramsPopup = {
            modalName: 'DataBindingList',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            jsonData: ui.rowData[ui.dataIndx],
            targetREFCODEList: ['DATA_MST_POPUP'], buttons: ['ok', 'cancel']
        }

        vfmBasePopup(JSONPopup, paramsPopup).then(rtn => {
            sender.setCellData(ui.rowIndx, ui.dataIndx, rtn)
        })

    } else if(popData.COMMAND != null && popData.COMMAND == 'POPUP') {
        const componentName = 'TemplatePopup'
        let popupComp2 = await import('@/components/popup/' + componentName + '.vue')
        let popupGridRef = ''        // GRID REF
        let gridArgsData = []   // 
        if(popData.POPUPREFCOLUMNYN == 'N') {
            let keys = Object.keys(popData.POPUPGRIDLIST)
            if(keys.length == 0) {
                // 에러 메시지 표시해줘야함..................................................
                return 
            } else {
                popupGridRef = keys[0]
            }
        } else {
            // 여러개의 참조중 선택해야하는 경우 로직 추가 필요 .............................
        }
        let paramsPopup = {
            title: t(ng_core.common.currentPageInfo[popupGridRef].COMPONENT_NAME),
            width: '1000px !important',
            height: '500px !important',
            refcodeList: [popupGridRef],
            args: (gridArgsData),
            //data_binding: popData.DATA_BINDING,
            componentList: ng_core.common.currentPageInfo,
            //targetREFCODEList: ['MENU_ICON'], 
            //buttons: ['ok']
        }
        vfmBasePopup(popupComp2.default, paramsPopup).then(rtn => {
            if (rtn != false) {
                //console.log(popData.POPUPGRIDLIST[popupGridRef])
                OneSelectRowByPopup(sender, ui, rtn, popData.POPUPGRIDLIST[popupGridRef].RESULT_PROC)
                //sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.CODE)
            }
        })
    } else if(popData.REF_CODE) {      // ref_code가 있으면 화면 관리에 등록된 컨포넌트를 가져다 화면에 구현함
        //const componentName = 'SelectIcon'
        const componentName = 'TemplatePopup'
        let popupComp2 = await import('@/components/popup/' + componentName + '.vue')

        let paramsPopup = {
            title: t(ng_core.common.currentPageInfo[popData.REF_CODE].COMPONENT_NAME),
            width: '1000px !important',
            height: '500px !important',
            refcodeList: [popData.REF_CODE],
            args: (ruleData.ARGS || ''),
            //data_binding: popData.DATA_BINDING,
            componentList: ng_core.common.currentPageInfo,
            //targetREFCODEList: ['MENU_ICON'], 
            //buttons: ['ok']
        }
        vfmBasePopup(popupComp2.default, paramsPopup).then(rtn => {
            if (rtn != false) {
                console.log(popData)
                OneSelectRowByPopup(sender, ui, rtn, popData.RESULT_PROC)
                //sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.CODE)
            }
        })
    } else if(popData.POPUPREFCOLUMN) {
        //let popRefColData = ui.rowData[ruleData.POPUPREFCOLUMN]
        if(popData.POPUPLIST[ui.rowData[popData.POPUPREFCOLUMN]]) {
            const componentName = 'TemplatePopup'
            let popupComp2 = await import('@/components/popup/' + componentName + '.vue')
            let girdData = popData.POPUPLIST[ui.rowData[popData.POPUPREFCOLUMN]]
            let gridRedCode = girdData.GRID
            let paramsPopup = {
                title: t(ng_core.common.currentPageInfo[gridRedCode].COMPONENT_NAME),
                width: '1000px !important',
                height: '500px !important',
                refcodeList: [gridRedCode],
                args: (girdData.ARGS || ''),
                //data_binding: popData.DATA_BINDING,
                componentList: ng_core.common.currentPageInfo,
                //targetREFCODEList: ['MENU_ICON'], 
                //buttons: ['ok']
            }
            vfmBasePopup(popupComp2.default, paramsPopup).then(rtn => {
                if (rtn != false) {
                    console.log(girdData)
                    OneSelectRowByPopup(sender, ui, rtn, girdData.RESULT_PROC)
                    //sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.CODE)
                }
            })

        } else {
            // 선택 대상으로는 팝업을 뛰울수 없음
        }
    }
    return
    
    
    // sender.baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN.filter((col) => col.COLUMN_CODE == ui.dataIndx)[0]
    var gridstr = ui.rowData.MENU_TYPE;

    if(ui.dataIndx === 'MENU_ICON'){
        const componentName = 'SelectIcon'
        let popupComp2 = await import('@/components/popup/' + componentName + '.vue')

        let paramsPopup = {
            modalName: 'SelectIcon',
            title: 'Font Icon List',
            width: '1000px !important',
            height: '500px !important',
            grid: store.state.NGCore.currentPageInfo[sender.baseComponentData.REF_CODE]['MENU_APPLY_COMPONENT_DTL_COLUMN'].filter(row => row.COLUMN_CODE == ui.dataIndx)[0].LISTDATA_BINDING,
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['MENU_ICON'], buttons: ['ok']
        }
        vfmBasePopup(popupComp2.default, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.CODE)
            }
            
        })
    }

   if(ui.dataIndx === 'MENU_TYPE_REF'){
    if(gridstr === 'CATEGORY' || !!gridstr === false){
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0012') })
        return;
    }
    if (gridstr === 'PAGE') {
        let paramsPopup = {
            modalName: 'SelectCompInView',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: gridstr,
            componentList: store.state.NGCore.currentPageInfo,

            targetREFCODEList: ['PAGE'], buttons: ['ok']
        }
        vfmBasePopup(SelectCompInView, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.VIEW_CODE)
                sender.setCellData(ui.rowIndx, Object.keys(ui.rowData)[4], rtn.VIEW_DESC)
                sender.setCellData(ui.rowIndx, Object.keys(ui.rowData)[6], rtn.FILE_PATH)
            }
        })
    } else if (gridstr === 'BOARD' || gridstr === 'BOARDSMNG') {
        let paramsPopup = {
            modalName: 'DataBindingList',
            title: 'Data Binding List',
            width: '1000px !important',
            height: '500px !important',
            grid: 'BOARD',
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['BOARD'], buttons: ['ok']
        }

        vfmBasePopup(SelectBoard, paramsPopup).then(rtn => {
            if (rtn != false) {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.TABLENAME)
            }
        })
    }

   }


}