<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components/popup/Comfirm.vue'
import JointPopup from '@/components/popup/joint.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'
import JSONPopup from '@/components/popup/JSON.vue'
import CloneComponentPopup from './popup/CloneComponentPopup.vue'
import { useStore } from "vuex";
import TemplatePopup from '@/components/popup/TemplatePopup.vue';
const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const store = useStore()

let con = getCurrentInstance()
let search = {}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}
const VIEW = ref()
const VIEW_COMPONENT_MST = ref()
const VIEW_COMPONENT_DTL_PROP = ref()
const VIEW_COMPONENT_DTL_OPTION = ref()
const VIEW_COMPONENT_DTL_COLUMN = ref()
const VIEW_COMPONENT_DTL_HEADER = ref()
const VIEW_COMPONENT_DTL_COLUMN_W_H = ref()
const VIEW_COMPONENT_DTL_SEARCH = ref()
const VIEW_COMPONENT_DTL_BUTTON = ref()

let grids = {
    'VIEW': VIEW,
    'VIEW_COMPONENT_MST': VIEW_COMPONENT_MST,
    'VIEW_COMPONENT_DTL_PROP': VIEW_COMPONENT_DTL_PROP,
    'VIEW_COMPONENT_DTL_OPTION': VIEW_COMPONENT_DTL_OPTION,
    'VIEW_COMPONENT_DTL_COLUMN': VIEW_COMPONENT_DTL_COLUMN,
    'VIEW_COMPONENT_DTL_HEADER': VIEW_COMPONENT_DTL_HEADER,
    'VIEW_COMPONENT_DTL_COLUMN_W_H': VIEW_COMPONENT_DTL_COLUMN_W_H,
    'VIEW_COMPONENT_DTL_SEARCH': VIEW_COMPONENT_DTL_SEARCH,
    'VIEW_COMPONENT_DTL_BUTTON': VIEW_COMPONENT_DTL_BUTTON,
}



onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for (let i = 0; i < tmpGridKeys.length; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for (let j = 0; j < tmpSearchKeys.length; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'

        console.log(tmpSearchColumn)
    }
})
/*************************************************
 * Grid 관련 : Start
 *************************************************/

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 조회 */
async function onSearch2(compName) {

    //     if (compName.includes('VIEW_COMPONENT_MST') == false
    //     && (grids['VIEW_COMPONENT_MST'].value.getRowSelectedIndex() == null || grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE == null)) {
    //         const tmpAlert = await vfmBasePopup(Alert, { state: 'info', contents: 'MSG.MSG0027' })
    //     return
    // }

    for (let i = 0; i < compName.length; i++) {
        grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
    

}

/** 콤포넌트 팝업으로 선택 기능 */
function onComponentClone(compName) {
    if(VIEW.value.getRowSelectedDataByRadio() != null) {
        
        let paramsPopup = {
            //modalName: '화면 관리',
            title: '[' + t('LABEL.RULEMNG') + ']',
            width: '1400px !important',
            height: '800px !important',
            selectedTab: compName.split('_')[compName.split('_').length-1],
            currentView: ng_core.common.currentPageInfo[compName],      // 현재 선택한 컨포넌트 정보(옵션, 컬럼, 헤더, 버튼, 조회 ...)
            viewSelectData: VIEW.value.getRowSelectedDataByRadio(),
            compSelectData: VIEW_COMPONENT_MST.value.getRowSelectedDataByRadio(),
            //VIEW.value.getRowSelectedDataByRadio(),
            // targetREFCODEList: ['DATA_MST_POPUP'], 
            // buttons: ['ok', 'cancel'],
        }

        vfmBasePopup(JointPopup, paramsPopup).then(rtn => {
            
        })    
    }


}

let addCount = 0;

/** 행추가 */
function onAddRow(compName) {
    if (compName.includes('VIEW_COMPONENT_MST') == false && compName.includes('VIEW') == false
        && (grids['VIEW_COMPONENT_MST'].value.getRowSelectedIndex() == null || grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE == null)) {
        return
    }

    let tmpAddData = {}
    if (compName == 'VIEW_COMPONENT_MST') {
        let tmpData = grids['VIEW'].value.getRowSelectedData()
        tmpAddData['VIEW_CODE'] = tmpData['VIEW_CODE']
    } else if (!['VIEW', 'VIEW_COMPONENT_MST'].includes(compName)) {
        let tmpData = grids['VIEW_COMPONENT_MST'].value.getRowSelectedData()
        tmpAddData['VIEW_CODE'] = tmpData['VIEW_CODE']
        tmpAddData['COMPONENT_CODE'] = tmpData['COMPONENT_CODE']
        tmpAddData['REF_CODE'] = tmpData['REF_CODE']
    }
    // if(ng_core.common.currentPageInfo[compName].COMPONENT_TYPE == 'TREEGRID') {
    //     tmpAddData['COLUMN_CODE'] = 'ADD_COLUMN_' + addCount;
    //     addCount++
    // }

    grids[compName].value.grdAddRow(tmpAddData)
}

/** 행 삭제 */
async function onRemoveRow(compName) {
    if (compName.includes('VIEW_COMPONENT_MST') == false && compName.includes('VIEW') == false
        && (grids['VIEW_COMPONENT_MST'].value.getRowSelectedIndex() == null || grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE == null)) {
        return
    }

    if (compName == 'VIEW_COMPONENT_DTL_COLUMN') {
        // 컬럼 삭제의 경우 헤더도 같이 삭제 해야하므로 경고 메시지를 별도로 줘야 함
        let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.MSG0026') })
        if (rtnComfirm == false) return


        grids[compName].value.grdDeleteRow({ hideMessageYN: false })
    } else {

        grids[compName].value.grdDeleteRow()
    }
}

/** 저장 */
async function onSave(compName) {
    if (compName.includes('VIEW_COMPONENT_MST') == false && compName.includes('VIEW') == false
        && (grids['VIEW_COMPONENT_MST'].value.getRowSelectedIndex() == null || grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE == null)) {
        return
    }

    const tmprows = grids[compName].value.getrows()
    let chkParentCode = false
    for (let soure = 0; soure < tmprows.length; soure++) {
        chkParentCode = false
        for (let target = 0; target < tmprows.length; target++) {
            // 같은 행은 검사하지 않음
            if (soure == target) continue

            // 메뉴코드에 중복이 있는지 확인
            if (!!tmprows[soure].OPTION_CODE == tmprows[target].OPTION_CODE) {
                // 메뉴 코드 중복
                const tmpAlert = await vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0013') });
                return
            }

        }
    }

    // let tmpData = {}
    // if(compName === 'VIEW_COMPONENT_MST') {
    //     tmpData['TEMPLATE_GRID_CODE'] = grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().TEMPLATE_GRID_CODE;
    // }

    grids[compName].value.grdSave()
}

/** 행 선택 라디오 */
function onRowClickRadio(ui, row) {
    search.VIEW_COMPONENT_MST.VIEW_CODE.value = row.VIEW_CODE

    // if(row.TEMPLATE_IDX !== null){
    //     search.VIEW_COMPONENT_MST.TEMPLATE_GRID_CODE.value = row.TEMPLATE_IDX;
    // }
    onSearch('VIEW_COMPONENT_MST')
}

/** 행 선택 라디오2 */
function onRowClickRadio2(ui, row) {
    if ((row.REF_CODE || '') == '') {
        return
    }
    for (let i = 0; i < tabGrid.length; i++) {
        for (let j = 0; j < tabGrid[i].length; j++) {
            let tmpGrdNm = tabGrid[i][j]
            search[tmpGrdNm].VIEW_CODE.value = row.VIEW_CODE
            search[tmpGrdNm].COMPONENT_CODE.value = row.COMPONENT_CODE
            search[tmpGrdNm].REF_CODE.value = row.REF_CODE
            grids[tmpGrdNm].value.grdSearchRef(search[tmpGrdNm])
        }
    }
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    let column_comp_type = ng_core.common.currentPageInfo[sender.baseComponentData.COMPONENT_CODE].MENU_APPLY_COMPONENT_DTL_COLUMN.filter((col) => col.COLUMN_CODE == ui.dataIndx)
    if (column_comp_type.length > 0) {
        if (column_comp_type[0].COLUMN_COMP_TYPE == 'JSON') {
            let paramsPopup = {
                modalName: 'DataBindingList',
                title: 'Data Binding List',
                width: '1000px !important',
                height: '500px !important',
                jsonData: ui.rowData[ui.dataIndx],
                targetREFCODEList: ['DATA_MST_POPUP'], buttons: ['ok', 'cancel']
            }

            vfmBasePopup(JSONPopup, paramsPopup).then(rtn => {
                sender.setCellData(ui.rowIndx, ui.dataIndx, rtn)
            })

        } else {
            let paramsPopup = {
                modalName: 'DataBindingList',
                title: 'Data Binding List',
                width: '1000px !important',
                height: '500px !important',
                grid: 'DATA_MST_POPUP',
                componentList: store.state.NGCore.currentPageInfo,
                targetREFCODEList: ['DATA_MST_POPUP'], buttons: ['ok', 'cancel']
            }

            vfmBasePopup(DataSelectPopup, paramsPopup).then(rtn => {
                if (rtn != false) {
                    sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.DATA_CODE)
                }
            })

        }
    }

}

/*************************************************
 * Tab 관련 : Start
*************************************************/
let tabIndex = ref(0)
let tabGrid = [
    ['VIEW_COMPONENT_DTL_PROP']
    , ['VIEW_COMPONENT_DTL_OPTION']
    , ['VIEW_COMPONENT_DTL_COLUMN']
    , ['VIEW_COMPONENT_DTL_HEADER', 'VIEW_COMPONENT_DTL_COLUMN_W_H']
    , ['VIEW_COMPONENT_DTL_SEARCH']
    , ['VIEW_COMPONENT_DTL_BUTTON']
]
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(toTabIdx, fromTabIdx, event, sender) {
    // 탭 페이지 리사이징
    tabPageContentResize(toTabIdx)
}

/** 탭 리사이즈 */
function tabPageContentResize(toTabIdx) {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

/** 컬럼 불러오기 */
async function onLoadCloumn() {

    if ((grids['VIEW_COMPONENT_MST'].value.getRowSelectedIndex() == null || grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE == null)) {
        return
    }

    grids['VIEW_COMPONENT_DTL_COLUMN'].value.showloadelement()

    if ((search['VIEW_COMPONENT_DTL_COLUMN'].COMPONENT_CODE.value || '') == '') {
        // 컨포넌트를 먼저 선택하세요.
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.CHKCOMPONENTCODE') })
        return
    }

    let tmpSelectedRowData = grids['VIEW_COMPONENT_MST'].value.getRowSelectedData()
    ng_core.api.baseCoreApi.getComponentColumn(tmpSelectedRowData.SYSTEM_TYPE, tmpSelectedRowData.COMPONENT_CODE).then(rtn => {
        if (rtn.data.status) {
            console.log(grids)
            let rtndata = rtn.data.rtndata

            grids['VIEW_COMPONENT_DTL_COLUMN'].value.beginupdate()

            // 현재 리스트를 가져옴
            var oldrows = grids['VIEW_COMPONENT_DTL_COLUMN'].value.getrows()
            var tmp_rtndata = rtndata.filter(row => {
                // 받아온 데이터 확인

                try {
                    var tmp = oldrows.filter(row_sub => {
                        // 기존 데이터를 가져와 받아온 데이터와 비교 처리
                        if (row['COMPONENT_CODE'] === row_sub['COMPONENT_CODE']
                            && row['COLUMN_CODE'] === row_sub['COLUMN_CODE']) {
                            return true     // 최종 제외 대상
                        } else {
                            return false
                        }
                    })
                    // 대상이 발견되면 최종 대상으로 처리
                    if (tmp.length >= 1) return false
                    return true

                } catch (err) {
                    console.log('onLoadCloumn')
                    console.dir(err)
                }
            })

            // 추가 대상을 넣음
            tmp_rtndata.map(row => {
                row['VIEW_CODE'] = tmpSelectedRowData['VIEW_CODE']
                row['COMPONENT_CODE'] = tmpSelectedRowData['COMPONENT_CODE']
                row['REF_CODE'] = tmpSelectedRowData['REF_CODE']
                grids['VIEW_COMPONENT_DTL_COLUMN'].value.grdAddRow(row, 'last')
            })
            grids['VIEW_COMPONENT_DTL_COLUMN'].value.endupdate()
        } else {
            vfmBasePopup(Alert, { state: 'error', contents: t(rtn.data.errorcode) })
        }
        grids['VIEW_COMPONENT_DTL_COLUMN'].value.hideloadelement()

    }).catch(err => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'error', contents: t('ERRORMSG.ERRORMSG0009') })

        grids['VIEW_COMPONENT_DTL_COLUMN'].value.hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })
}

/** 컬럼 불러오기2 */
async function onLoadCloumn2() {
    // UI나 다른 컴포넌트 유형도 넣어야함?
    let systemType = grids.VIEW_COMPONENT_MST.value.getRowSelectedData().SYSTEM_TYPE;

    ng_core.api.baseCoreApi.getArgs(systemType, grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().COMPONENT_CODE).then(rtn => {
        if (rtn.data.status) {

            let rtndata = rtn.data.rtndata
            grids['VIEW_COMPONENT_DTL_SEARCH'].value.showloadelement()

            // 현재 리스트를 가져옴
            var oldrows = grids['VIEW_COMPONENT_DTL_SEARCH'].value.getrows()
            var tmp_rtndata = rtndata.filter(row => {
                // 받아온 데이터 확인
                try {
                    var tmp = oldrows.filter(row_sub => {
                        // 기존 데이터를 가져와 받아온 데이터와 비교 처리
                        if (row['PARAMETER_NAME2'] === row_sub['PARAM_CODE']) {
                            return true     // 최종 제외 대상
                        } else {
                            return false
                        }
                    })
                    // 대상이 발견되면 최종 대상으로 처리
                    if (tmp.length >= 1) return false
                    return true

                } catch (err) {
                    console.log('onGetArgs')
                    console.dir(err)
                }
            })

            // 추가 대상을 넣음
            var notInArray = ['RUN_USER_ID', 'RETURN_FLAG', 'RETURN_MSG_CODE', 'RETURN_ERR_MSG']
            tmp_rtndata.map(row => {
                try {
                    var tmp_data = {
                        // DATA_CODE: grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().VIEW_CODE,
                        VIEW_CODE: grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().VIEW_CODE,
                        COMPONENT_CODE: grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().COMPONENT_CODE,
                        PARAM_CODE: row['PARAMETER_NAME2'],
                        REF_CODE: grids['VIEW_COMPONENT_MST'].value.getRowSelectedData().REF_CODE,
                        DATA_TYPE: row.TYPE,
                        DATA_LENGTH: row.LENGTH,

                    }
                    var oldrows = grids['VIEW_COMPONENT_DTL_SEARCH'].value.grdAddRow(tmp_data, 'last')

                } catch (error) {
                    console.log('onGetArgs2')
                    console.dir(error)
                }
            })

            grids['VIEW_COMPONENT_DTL_SEARCH'].value.hideloadelement()
        } else {
            alert('?????')
        }

    }).catch(error => {
        // 처리 중 오류 발생
        vfmBasePopup(Alert, { state: 'info', contents: t('ERRORMSG.ERRORMSG0009') })

        grids['VIEW_COMPONENT_DTL_SEARCH'].value.hideloadelement()
        let tmpMessage = (error.response && error.response.data) || error.message || error.toString()
        store.dispatch('MQ/addMessage', { state: 'error', message: tmpMessage }, { root: true })
    })
}

/** 대상을 왼쪽으로 이동 */
async function onLeftMove() {
    let tmpDataList = grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.getGrdCheckList()
    if (tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return
    }
    // let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.CHK_ADD') })
    // if(rtnComfirm == false) return

    tmpDataList.map(function (row) {
        var tmp_row = Object.assign({}, row, {
            ROWRADIO: 'N',
            METHOD: 'A',
            ROWCHECK: 'N',
            COLUMN_SEQ: row.SEQ,
            VIEW_CODE: row.VIEW_CODE,
            COMPONENT_CODE: row.COMPONENT_CODE,
            REF_CODE: row.REF_CODE,

        })
        // 상위 정보를 같이 넘겨야 함****
        //self.$refs.VIEW_COMPONENT_DTL_HEADER.grdAddRowChild(tmp_row)       
        // treegrid 상위 처리 관계 없이 끝에 row추가
        grids['VIEW_COMPONENT_DTL_HEADER'].value.grdAddRow(tmp_row, 'last')
    })

    // await grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSave({ hideComfirm: 'Y'/**, hideMessageYN: 'Y', reloadYn: 'N' */ })

    // //grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
    // grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
}

/** 대상을 오른쪽으로 이동 */
async function onRightMove() {
    let tmpDataList = grids['VIEW_COMPONENT_DTL_HEADER'].value.getGrdCheckList()
    if (tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return
    }

    let rtn = await grids['VIEW_COMPONENT_DTL_HEADER'].value.grdDeleteWithDB()
    if (rtn) {
        grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSearchRef()
        grids['VIEW_COMPONENT_DTL_COLUMN_W_H'].value.grdSearchRef()
    } else {
        alert('???????')
    }
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton2(sender, ui, event) {

    let paramsPopup = {
        modalName: 'TemplatePopup',
        title: 'TemplatePopup',
        width: '1000px !important',
        height: '500px !important',
        refcodeList: ['TEMPLATE_MST'],
        //args: (girdData.ARGS || ''),
        componentList: store.state.NGCore.currentPageInfo,
    }

    vfmBasePopup(TemplatePopup, paramsPopup).then(async (rtn) => {
        if (rtn != false) {
            sender.setCellData(ui.rowIndx, ui.dataIndx, rtn.TEMPLATE_CODE)
        }
    })

}

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <Splitpanes :orientation="'horizontal'" @resize="doResize">
                        <!-- 상단 왼쪽 : Start -->
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <!-- 검색 : Start -->
                                <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ t('LABEL.VIEW_CODE') }} :</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW'].VIEW_CODE.value"
                                            :placeholder="t('LABEL.VIEW_CODE')" @keyup.enter="onSearch(['VIEW'])"
                                            :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ t('LABEL.VIEW_NAME') }} :</label>
                                        <input type="text" class="conInputText" v-model="search['VIEW'].VIEW_NAME.value"
                                            :placeholder="t('LABEL.VIEW_NAME')" @keyup.enter="onSearch(['VIEW'])"
                                            :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch(['VIEW'])"
                                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('VIEW')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW')"
                                            :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                                <!-- 검색 : End -->
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="VIEW" :refCode="'VIEW'" @RowClickRadio="onRowClickRadio"
                                    @CellButtonClick="onGrdCellButton2" />
                            </div>
                        </Pane>
                        <!-- 상단 왼쪽 : End -->
                        <!-- 상단 오른쪽 : Start -->
                        <Pane>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <!-- 검색 : Start -->
                                <div class="parentCon">
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('LABEL.COMPONENT_CODE') }}
                                            :</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['VIEW_COMPONENT_MST'].COMPONENT_CODE.value"
                                            :placeholder="$t('LABEL.COMPONENT_CODE')"
                                            @keyup.enter="onSearch2(['VIEW_COMPONENT_MST'])" :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('LABEL.COMPONENT_TYPE') }} :</label>
                                        <input type="text" class="conInputText"
                                            v-model="search['VIEW_COMPONENT_MST'].COMPONENT_TYPE.value"
                                            :placeholder="$t('LABEL.COMPONENT_TYPE')"
                                            @keyup.enter="onSearch2(['VIEW_COMPONENT_MST'])" :state="null">
                                    </div>
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_MST'])"
                                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_MST')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_MST')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_MST')"
                                            :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                                <!-- 검색 : End -->
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref='VIEW_COMPONENT_MST' :refCode="'VIEW_COMPONENT_MST'"
                                    @RowClickRadio="onRowClickRadio2" @CellButtonClick="onGrdCellButton" />
                            </div>
                        </Pane>
                        <!-- 상단 오른쪽 : End -->
                    </Splitpanes>
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab">
                        <!-- 속성 관리 : Start -->
                        <b-tab :title="$t('LABEL.ATTR_MANAGE')" active>
                            <div style="width:100%; height:40px; overflow:auto;">
                                <!-- 검색 : Start -->
                                <div class="parentCon">
                                </div>
                                <div class="rightCon">
                                    <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_PROP')"
                                            :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                    <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_PROP'])"
                                        :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                    <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_PROP')"
                                        :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                    <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_PROP')"
                                        :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                    <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_PROP')"
                                        :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                </div>
                                <!-- 검색 : End -->
                            </div>
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_PROP' :refCode="'VIEW_COMPONENT_DTL_PROP'"
                                    @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <!-- 속성 관리 : End -->
                        <!-- 옵션 관리 : Start -->
                        <b-tab :title="$t('LABEL.OPTION_MANAGE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <!-- 검색 : Start -->
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_OPTION')"
                                            :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_OPTION'])"
                                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_OPTION')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_OPTION')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_OPTION')"
                                            :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                                <!-- 검색 : End -->
                            </div>
                            <div class="resultGrid" style="height: calc(100% - 80px)">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_OPTION' :refCode="'VIEW_COMPONENT_DTL_OPTION'"
                                    @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <!-- 옵션 관리 : End -->
                        <!-- 컬럼 관리 : Start -->
                        <b-tab :title="$t('LABEL.COLUMN_MANAGE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <!-- 검색 : Start -->
                                    <div class="rightCon">
                                        <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_COLUMN')"
                                            :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                        <button ref="loadColumn" @click="onLoadCloumn()"
                                            :title="t('LABEL.LOAD_COLUMN')">{{ t('LABEL.LOAD_COLUMN') }}</button>
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_COLUMN'])"
                                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_COLUMN')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_COLUMN')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_COLUMN')"
                                            :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                    <!-- 검색 : End -->
                                </div>
                            </div>
                            <div class="resultGrid" style="height: calc(100% - 80px)">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_COLUMN' :refCode="'VIEW_COMPONENT_DTL_COLUMN'"
                                    @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                        <!-- 컬럼 관리 : End -->
                        <!-- 헤더 관리 : Start -->
                        <b-tab :title="t('LABEL.HEADER_MANAGE')">
                            <Splitpanes @resize="doResize">
                                <Pane>
                                    <!-- 왼쪽 : Start -->
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <!-- 검색 : Start -->
                                        <div class="parentCon">
                                            <div class="rightCon">
                                                <!-- <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_HEADER')"
                                                    :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button> -->
                                                <button ref="read05"
                                                    @click="onSearch2(['VIEW_COMPONENT_DTL_HEADER', 'VIEW_COMPONENT_DTL_COLUMN_W_H'])"
                                                    :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                                <button ref="plus05" @click="onAddRow('VIEW_COMPONENT_DTL_HEADER')"
                                                    :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                                <button ref="minus05" @click="onRemoveRow('VIEW_COMPONENT_DTL_HEADER')"
                                                    :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                                <button ref="save05" @click="onSave('VIEW_COMPONENT_DTL_HEADER')"
                                                    :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                                <button @click="onLeftMove()" :title="t('BUTTON0014')"><font-awesome-icon
                                                        icon="angle-left" /></button>
                                                <button @click="onRightMove()" :title="t('BUTTON0015')"><font-awesome-icon
                                                        icon="angle-right" /></button>
                                            </div>
                                        </div>
                                        <!-- 검색 : End -->
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="VIEW_COMPONENT_DTL_HEADER" :refCode="'VIEW_COMPONENT_DTL_HEADER'"
                                            @CellButtonClick="onGrdCellButton" />
                                    </div>
                                </Pane>
                                <!-- 왼쪽 : End -->
                                <!-- 오른쪽 : Start -->
                                <Pane>
                                    <div style="width:100%; height:40px; overflow:auto;">
                                        <div class="parentCon">
                                        </div>
                                    </div>
                                    <div class="resultGrid" style="height:calc(100% - 80px);">
                                        <BaseGrid ref="VIEW_COMPONENT_DTL_COLUMN_W_H"
                                            :refCode="'VIEW_COMPONENT_DTL_COLUMN_W_H'" />
                                    </div>
                                </Pane>
                                <!-- 오른쪽 : End -->
                            </Splitpanes>
                        </b-tab>
                        <!-- 헤더 관리 : End -->
                        <!-- 조회 관리 : Start -->
                        <b-tab :title="$t('LABEL.SEARCH_MANAGE')">
                            <div style="width:100%; height:40px; overflow:auto;">
                                <!-- 검색 : Start -->
                                <div class="parentCon">
                                    <div class="rightCon">
                                        <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_SEARCH')"
                                            :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                        <button ref="loadColumn" @click="onLoadCloumn2()"
                                            :title="t('LABEL.LOAD_COLUMN')">{{ t('LABEL.LOAD_COLUMN') }}</button>
                                        <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_SEARCH'])"
                                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_SEARCH')"
                                            :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_SEARCH')"
                                            :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_SEARCH')"
                                            :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                                <!-- 검색 : End -->
                            </div>
                            <div class="resultGrid" style="height: calc(100% - 80px)">
                                <BaseGrid ref='VIEW_COMPONENT_DTL_SEARCH' :refCode="'VIEW_COMPONENT_DTL_SEARCH'"
                                    @CellButtonClick="onGrdCellButton" />
                            </div>
                        </b-tab>
                    <!-- 조회 관리 : End -->
                    <!-- 버튼 관리 : START -->
                    <b-tab :title="$t('LABEL.BUTTON_MANAGE')">
                        <div style="width:100%; height:40px; overflow:auto;">
                            <!-- 검색 : Start -->
                            <div class="parentCon">
                                <div class="rightCon">
                                    <button ref="clone" @click="onComponentClone('VIEW_COMPONENT_DTL_BUTTON')"
                                        :title="$t('BUTTON.BUTTON0016')"><font-awesome-icon icon="clone" /></button>
                                    <button ref="read" @click="onSearch2(['VIEW_COMPONENT_DTL_BUTTON'])"
                                        :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                                    <button ref="plus" @click="onAddRow('VIEW_COMPONENT_DTL_BUTTON')"
                                        :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                    <button ref="minus" @click="onRemoveRow('VIEW_COMPONENT_DTL_BUTTON')"
                                        :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                    <button ref="save" @click="onSave('VIEW_COMPONENT_DTL_BUTTON')"
                                        :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                </div>
                            </div>
                            <!-- 검색 : End -->
                        </div>
                        <div class="resultGrid" style="height: calc(100% - 80px)">
                            <BaseGrid ref='VIEW_COMPONENT_DTL_BUTTON' :refCode="'VIEW_COMPONENT_DTL_BUTTON'"
                                @CellButtonClick="onGrdCellButton" />
                        </div>
                    </b-tab>
                    <!-- 버튼 관리 : END -->
                </b-tabs>
            </Pane>
            <!-- 하단 : End   -->
        </Splitpanes>
    </div>
</div></template>
<style></style>