import router from "@/router";

export const MessageQueue = {
    namespaced: true,
    state: () => ({
        /** 메시지들 { state: [info, error], mssage: string.... } */
        messages: [],
    }),
    mutations: {
        /** 메시지 추가 */
        addMessage(state, message) {
            state.messages.push(message)

        },
        /** 메시지 초기화 */
        clearMessage(state) {
            state.messages.splice(0, state.messages.length);
        }
        // /** 로딩 카운트 증가 */
        // addLoadingCount(state) {
        //     state.loadingCount++
        //     state.isLoading = state.loadingCount == 0 ? 'N' : 'Y'
        // },
    },
    actions: {
        /** 메시지 추가 */
        async addMessage(state, message) {
            if(state.rootState.NGCore.isDev == 'Y') {
                state.commit('addMessage', message)
            }
        }
        
    },
    getters: {
    }
};