//import { initCustomFormatter, ref } from 'vue'
import dayjs from 'dayjs';

/**
 * 
 * @param {any} baseGrid 그리드 기본 정보
 * @returns baseGrid.options에 값이 적용됨
 */
export default function createColModel(t, baseGrid) {
    /** 컬럼 고정 */
    let freezeCols = null

    var tmp_columns = []
    var tmp_fields = []
    var tmp_groups = []
    //var tmp_model = {}
    var tmp_treeModel = {}

    /***************************************
     * Option으로 설정된 컬럼 정의 : Start
     /***************************************/
    if (baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_OPTION != null) {
        var rtn = GenerateOptionColumn(tmp_columns, baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_OPTION)
        freezeCols = rtn.freezeCols
    }

    // 컬럼별 설정(루핑)
    switch (baseGrid.gridType) {
        case 'GRID':
        case 'TREEGRID':
        case 'PIVOTGRID':
        case 'PIVOTMODEGRID':
            // 개인화
            let columns = null;
            if (!!baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_PERSON_COLUMN
                && baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_PERSON_COLUMN.length > 0) {
                columns = baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_PERSON_COLUMN;
            } else {
                columns = baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN;
            }
            var rtn = GenerateBaseGridColumn(freezeCols, tmp_columns, columns)

            break

        case "ALLUNPIVOTGRID":      // 전체를 UNPIVOT처리
            var rtn = GenerateAllUnpivotGridColumn(freezeCols, tmp_columns, baseGrid.baseComponentData.MENU_APPLY_COMPONENT_DTL_COLUMN)
            break;
    }
    freezeCols = rtn.freezeCols

    // baseGrid.hideloadelement()
    // baseGrid.beginupdate()
    if(baseGrid.gridType === 'TREEGRID') {
        GenerateTreeGridColumn()        // 트리 그리드 컬럼 설정
    }



    // Header 구성 로직
    if(baseGrid.gridType == 'PIVOTGRID') {
        tmp_columns = GenerationPivotHader(tmp_columns, null)
    }
    tmp_columns = GenerationHreader(tmp_columns, null)


    baseGrid.options['colModel'] = tmp_columns
    //baseGrid.options['freezeCols'] = freezeCols

    return { columns: tmp_columns, freezeCols: freezeCols }


    // baseGrid.grdInitFinish()
    // baseGrid.hideloadelement()


    /**
     * 전체 컬럼 중 옵션에 해당하는 컬럼을 추출하여 option생성
     * @param {any} targetColumn 컬럼 생성 관련
     * @param {any} sourceColumn 전체 컬럼 생성 정보
     * @returns 생성 할 컬럼 정보
     */
    function GenerateOptionColumn(targetColumn, sourceColumn) {
        let freezeCols = 0
        var self = baseGrid
        
        /******************************************************************************
         * 행 선택용 라디오 박스
         *   - 행 선택 용 라디오 박스 추가
         ******************************************************************************/
        const tmpRowRadio = sourceColumn.filter((row) => { return row.OPTION_CODE === 'ROWRADIO' })
        if (tmpRowRadio.length > 0) {
            targetColumn.push({
                title: '',
                dataIndx: "ROWRADIO",
                maxWidth: 30,
                minWidth: 30,
                sortable: false,
                hvalign: 'center',
                halign: 'center',
                align: 'center',
                type: 'checkbox',
                cb: {
                    all: false,
                    header: false,
                },
                skipExport: true,
                nodrag: true,
                menuIcon: false,
                menuInHide: true,
                valign: 'center',
                hidden: tmpRowRadio[0].HIDDEN_YN_HEADER === 'Y' ? true : false,
                render: function (ui) {
                    try {
                        
                        var cb = ui.column.cb,
                            cellData = ui.cellData,
                            checked = self.getRowSelectedIndexByRadio() === ui.rowIndx ? "checked" : ""
                            ;
                        var hidden = false //!(self.grid.getChanges().addList.filter(row => { return row.pq_ri === ui.rowIndx }).length === 0)

                        return {
                            text: hidden ? "" : "<input type='radio' name='" + baseGrid.baseComponentData.COMPONENT_CODE + "_" + ui.dataIndx + "' " + checked + " />",
                            style: "background:white"
                        };
                    } catch (err) {
                        console.log('render')
                        console.dir(err)
                    }
                },
                editor: false,
                editable: false,
                beforeCheck: function (event, ui) {

                    console.dir('beforeCheck')
                    return false
                },

            })

            freezeCols = tmpRowRadio[0].OPTION_CODE
        }

        /******************************************************************************
         * 행 상태
         *   - 행 상태를 표시하는 컬럼 추가
         ******************************************************************************/
        const tmpMethod = sourceColumn.filter((row) => { return row.OPTION_CODE === 'METHOD' })
        if (tmpMethod.length > 0) {
            targetColumn.push({
                skipExport: true,
                title: '',
                dataIndx: 'METHOD',
                dataType: 'string',
                width: 10,
                resizable: false,
                sortable: false,
                hvalign: 'center',
                halign: 'center',
                align: 'center',
                editor: false,
                editable: true,
                nodrag: true,
                menuIcon: false,
                menuInHide: true,
                valign: 'center',
                headerTooltip: 'test',
                defaultValue: 'A',
                hidden: tmpMethod[0].HIDDEN_YN_HEADER === 'Y' ? true : false,
                render: function (ui) {
                    var cb = ui.column.cb,
                        cellData = ui.cellData
                        ;
                    var text = "";
                    if (!!cellData && cellData.indexOf('S') != -1) {
                        text += "<img src='/icon/update.png' class='grid-method-icon' />";
                    }
                    if (!!cellData && cellData.indexOf('A') != -1) {
                        text += "<img src='/icon/add.png' class='grid-method-icon' />";
                    }
                    if (!!cellData && cellData.indexOf('U') != -1) {
                        text += "<img src='/icon/update.png' class='grid-method-icon' />";
                    }
                    // 체크는 꼭 표현할필요 없음
                    // if (!!cellData && cellData.indexOf('C') != -1) {
                    //     text += "<img src='/icon/check.png' class='grid-method-icon' />";
                    // }
                    if (!!cellData && cellData.indexOf('D') != -1) {
                        text += "<img src='/icon/delete.png' class='grid-method-icon' />";
                    }
                    return {
                        text: text,
                        style: "background:white"
                    };
                },
            })

            freezeCols = tmpMethod[0].OPTION_CODE
        }

        // row 체크 선택
        /******************************************************************************
         * 멀티 행 선택용 체크박스
         *   - 멀티 행을 선택 가능하도록 하는 체크박스 추가
         ******************************************************************************/
        const tmpRowCheck = sourceColumn.filter((row) => { return row.OPTION_CODE === 'ROWCHECK' })
        if (tmpRowCheck.length > 0) {
            targetColumn.push({
                skipExport: true,
                title: "<input type='checkbox'/>",
                dataIndx: 'ROWCHECK',
                maxWidth: 30,
                minWidth: 30,
                sortable: false,
                hvalign: 'center',
                halign: 'center',
                align: 'center',
                type: 'checkbox',
                cb: {
                    all: false,
                    header: true,
                    check: "Y",
                    uncheck: "N"
                },
                nodrag: true,
                menuIcon: false,
                menuInHide: true,
                valign: 'center',
                hidden: tmpRowCheck[0].HIDDEN_YN_HEADER === 'Y' ? true : false,
                render: function (ui) {
                    var cb = ui.column.cb,
                        cellData = ui.cellData,
                        checked = cb.check === cellData ? 'checked' : '',
                        disabled = this.isEditableCell(ui) ? "" : "disabled";
                    return {
                        text: "<input type='checkbox' " + checked + " />",
                        style: "background:white"
                    };
                },
                editor: false,
                editable: true,
                beforeCheck: function (event, ui) {

                    return false
                }

            })

            freezeCols = tmpRowCheck[0].OPTION_CODE
        }

        return { targetColumn: targetColumn, freezeCols: freezeCols }
    }

    /**
     * 날짜, 숫자 등 지정된 포멧으로 데이터 표현
     * @param {*} ui 
     * @returns 
     */
    function getColumnFormat(ui) {
        // if(baseGrid.baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID') {
        if(ui.column.orgColumn.COLUMN_FORMAT) {
            if(ui.column.orgColumn.COLUMN_TYPE == 'date') {
                if(ui.column.orgColumn.COLUMN_FORMAT && ui.cellData) {
                    try {
                        if(baseGrid.baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID') {
                            return ui.cellData
                        } else {
                            return dayjs(ui.cellData.slice(0, -1)).format(ui.column.orgColumn.COLUMN_FORMAT)
                        }
                        
                    } catch(err) {
                        return ui.cellData
                    }
                } else {
                    return ui.cellData
                }
                
            }
        } else {
            return false
        }
    }

    /**
     * 일반 Grid 컬럼 생성 로직(gridType: GRID, TREEGRID같은 아주 일반적인 그리드 처리)
     * targetColumn : 시스템에 사용할 컬럼 정의용 배열
     * sourceColumn : 참조할 컬럼 배열
     */
    function GenerateBaseGridColumn(freezeCols, targetColumn, sourceColumn) {
        var self = baseGrid

        sourceColumn.map(rowColumn => {
            var tmp_column = {}

            // 일반 헤더 처리
            // Header 정렬
            var headerHAgilgn = null
            if (rowColumn.HALIGN_HEADER != null && (rowColumn.HALIGN_HEADER.trim() || '') != '') {
                headerHAgilgn = rowColumn.HALIGN_HEADER.toLowerCase().trim()
            } else {
                // Header의 기본은 가운데 정렬
                headerHAgilgn = "center"
            }

            // Cell 정렬
            var cellHAlign = null
            var cellHAlign = null

            if (rowColumn.HALIGN != null && (rowColumn.HALIGN.trim() || '') != '') {
                cellHAlign = rowColumn.HALIGN.toLowerCase().trim()
            } else {
                switch (rowColumn.COLUMN_TYPE) {
                    case "string":
                        cellHAlign = "left"
                        break
                    case "number":
                        cellHAlign = "right"
                        break
                    default:
                        cellHAlign = "left"
                        break
                }
            }

            // 컬럼 기본 정보 설정
            tmp_column = {
                //dataIndx: "state",
                //maxWidth: 30,
                //minWidth: 30, 
                SEQ: rowColumn.SEQ_HEADER,
                PARENT_COLUMN_CODE: rowColumn.PARENT_COLUMN_CODE,
                COLUMN_CODE: rowColumn.COLUMN_CODE,
                title: t(rowColumn.COLUMN_NAME_HEADER),
                dataIndx: rowColumn.COLUMN_CODE,
                width: rowColumn.WIDTH_HEADER,
                format: baseGrid.baseComponentData.COMPONENT_TYPE == 'PIVOTMODEGRID' ? '' : rowColumn.COLUMN_FORMAT,
                align: cellHAlign,
                halign: headerHAgilgn,
                hvalign: 'center',
                resizable: true,
                hidden: rowColumn.HIDDEN_YN_HEADER === 'Y' ? true : false,
                // Filter ===S
                filter: { crules: [{ condition: 'range' }] },
                menuIcon: rowColumn.FILTER_YN === 'Y' ? true : false,
                menuInHide: rowColumn.FILTER_YN === 'Y' ? false : true,
                // Filter ===E
                //type: row.COLUMN_COMP_TYPE,
                //cls: 'ui-state-default',
                // sortable: false,
                editor: rowColumn.EDIT_YN === 'Y' ? true : false,
                dataType: rowColumn.COLUMN_TYPE,
                orgColumn: rowColumn,
                orgHeader: rowColumn,
                valign: 'center',
                attrHead: {
                    title: t((rowColumn.COLUMN_DESC || rowColumn.COLUMN_NAME_HEADER)),      // 헤더 툴팁
                },
                editable: function (ui) {
                    return baseGrid.onEditable(ui, rowColumn.COLUMN_CODE)
                },
                render: function (ui) {
                    let tmpCellData = getColumnFormat(ui)
                    if(tmpCellData != false) {
                        return {
                            //style: baseGrid.onColumnStyle(ui),
                            text: tmpCellData,
                            cls: baseGrid.onColumnCls(ui, rowColumn.COLUMN_CODE)
                        };
                    } else {
                        return {
                            //style: baseGrid.onColumnStyle(ui),
                            // text: tmpCellData,
                            cls: baseGrid.onColumnCls(ui, rowColumn.COLUMN_CODE)
                        };
                            
                    }
                    // var $cell = $("<div>")
                    //     .addClass("pq-grid-cell")
                    //     .append("Data");

                    // if (this.colDef.colType === "header") {
                    //     var $headerCell = $("<div>")
                    //         .addClass("pq-grid-header-cell")
                    //         .css({
                    //             "text-align": "center",
                    //             "white-space": "nowrap",
                    //             "overflow": "hidden",
                    //             "text-overflow": "ellipsis"
                    //         })
                    //         .append("Column 2");

                    //     // adjust header width to fit the screen
                    //     var gridWidth = this.grid.option("width");
                    //     var headerWidth = $headerCell.outerWidth(true);
                    //     if (headerWidth > gridWidth) {
                    //         $headerCell.outerWidth(gridWidth);
                    //     }

                    //     return $headerCell;
                    // }

                    // return $cell;                    
                },
                validations: [],            // 제약 조건 리스트
                // cb: {
                //     all: true,
                //     header: true
                // },
            }

            // 컬럼 타입별 처리
            if (rowColumn.COLUMN_TYPE != null && rowColumn.COLUMN_TYPE !== '') {
                switch (rowColumn.COLUMN_TYPE) {
                    case 'number':
                        // 숫자 타입 관련 제약 조건 추가
                        tmp_column.validations.push({
                            type: 'regexp',
                            value: '[0-9]',
                            msg: t('MSG.MSG0015'),      // 숫자만 입력 가능합니다.
                        })

                        // 숫자 포멧이 있는 경우
                        if(rowColumn.COLUMN_FORMAT) {
                            tmp_column['format'] = rowColumn.COLUMN_FORMAT
                        }
                        break

                }

            }

            // 컬럼별 컨포넌트 처리(CheckBox, SelectBox ....)
            if (rowColumn.COLUMN_COMP_TYPE != null && rowColumn.COLUMN_COMP_TYPE !== '') {
                switch (rowColumn.COLUMN_COMP_TYPE) {
                    case "CHECKBOX":        // 체크 박스 처리
                        tmp_column = Object.assign(tmp_column, {
                            type: 'checkbox',
                            editor: false,
                            editable: tmp_column.editor,
                            useLabel: true,
                            hvalign: 'center',
                            halign: 'center',
                            align: 'center',
                            cb: {
                                all: false,
                                header: false,
                                check: "Y",
                                uncheck: "N"
                            },
                            render: function (ui) {

                                var cb = ui.column.cb,
                                    cellData = ui.cellData,
                                    checked = cb.check === cellData ? 'checked' : '',
                                    readonly = this.isEditableCell(ui) ? "" : "readonly";
                                return {
                                    text: "<input type='checkbox' " + checked + " " + readonly + " />",
                                    cls: this.isEditableCell(ui) ? "cell-edit-apply" : ""
                                };
                            },
                        })

                        break

                    case "DROPDOWNLIST":        // 드롭다운 리스트 박스 처리
                        tmp_column = Object.assign(tmp_column, {
                            columntype: 'select',
                            editModel: {
                                clicksToEdit: 1
                            },
                            // cls: 'pq-dropdown pq-side-icon',
                            editor: {
                                type: 'select',
                                // options: [], // self.componentTypeList,
                                options: function (ui) {
                                    // Datalist 값이 있는 경우 받아서 넘김
                                    if (baseGrid.baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING] != null) {
                                        ui.column['editor'].options =
                                        baseGrid.baseComponentData.DATALIST[ui.column.orgColumn.LISTDATA_BINDING].filter((r) => {
                                                return r.CODE != ''
                                            })
                                    }
                                    return ui.column['editor'].options
                                },
                                labelIndx: 'NAME',
                                valueIndx: 'CODE',
                            },
                            render: function (ui) {
                                var tmp = []
                                if (ui.rowData.pq_grandsummary == true && rowColumn.LISTDATA_BINDING != null && baseGrid.baseComponentData.DATALIST != null && baseGrid.baseComponentData.DATALIST[rowColumn.LISTDATA_BINDING] != null) {
                                    tmp = baseGrid.baseComponentData.DATALIST[rowColumn.LISTDATA_BINDING].filter((r) => {
                                        return (ui.rowData[ui.dataIndx] || '').toString() === (r[ui.column.editor.valueIndx] || '').toString()
                                    })
                                }
                                return {
                                    text: tmp.length === 0 ? ui.rowData[ui.dataIndx] : tmp[0][ui.column.editor.labelIndx],
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                }

                            }

                        })

                        // // Datalist 값이 있는 경우 받아서 넘김
                        // if(baseGrid.baseComponentData.DATALIST[rowColumn.LISTDATA_BINDING] != null) {
                        //     tmp_column['editor'].options = 
                        //     baseGrid.baseComponentData.DATALIST[rowColumn.LISTDATA_BINDING].filter((r) => {
                        //         return r.CODE != ''
                        //     })
                        // }
                        /*
                        tmp_column['columntype'] = 'select'
                        tmp_column['editor'] = {
                            type: 'select',
                            options: [], // self.componentTypeList,
                            labelIndx: 'NAME',
                            valueIndx: 'CODE',
                        }
                        */

                        break;

                    case "TEXTBOXBUTTON":       // 버튼포함 텍스트 박스
                    case "NE_TEXTBOXBUTTON":       // 버튼포함 텍스트 박스
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            //editable: true,
                            //useLabel: true,
                            render: function (ui) {
                                var cb = ui.column.cb,
                                    cellData = ui.cellData
                                    ;
                                let view_html = '<div style="display: flex; width: 100%;"><span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-grow: 1;">' + (cellData == null ? '' : cellData) + '</span>'
                                if(ui.column.orgColumn.COLUMN_COMP_TYPE == 'NE_TEXTBOXBUTTON') {
                                    if(ui.column.orgColumn.EDIT_YN == 'Y') {
                                        if(ui.column.orgColumn.KEYID_YN == 'N') {
                                            view_html += '<button>...</button>'
                                        } else if(ui.column.orgColumn.KEYID_YN == 'Y' && (ui.rowData.METHOD || '').indexOf('A') >= 0) {
                                            view_html += '<button>...</button>'
                                        }
                                    }
                                } else if(this.isEditableCell(ui)) {
                                    view_html += '<button>...</button>'
                                }
                                view_html += '</div>'

                                return {
                                    text: view_html, //'<div style="display: flex; width: 100%;"><span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-grow: 1;">' + (cellData == null ? '' : cellData) + '</span></div>',
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                            postRender: function (ui) {
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                    ;
                                $cell.find('button').bind('click', function (e) {
                                    console.log('test')
                                    self.onGrdCellButtonClick(ui, e)
                                })
                            },
                        })

                        break

                    case "JSON":          // JSON
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            //editable: true,
                            //useLabel: true,
                            render: function (ui) {
                                var cb = ui.column.cb,
                                    cellData = ui.cellData
                                    ;
                                return {
                                    text: '<div style="display: flex; width: 100%;"><span style="white-space: nowrap; overflow: hidden; text-overflow: ellipsis; flex-grow: 1;">' + (cellData == null ? '' : cellData) + '</span><button>JSON</button></div>',
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                            postRender: function (ui) {
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                    ;
                                $cell.find('button').bind('click', function (e) {
                                    self.onGrdCellButtonClick(ui, e)
                                })
                            },
                        })
                        break
                        
                    case "BUTTON":          // 버튼
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            //editable: true,
                            //useLabel: true,
                            render: function (ui) {
                                var cb = ui.column.cb,
                                    cellData = ui.cellData
                                    ;
                                return {
                                    text: "<button  >...</button>",
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                            postRender: function (ui) {
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                    ;
                                $cell.find('button').bind('click', function (e) {
                                    self.onGrdCellButtonClick(ui, e)
                                })
                            },
                        })
                        break

                    case "IMAGE":       // 이미지
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            //editable: true,
                            //useLabel: true,
                            render: function (ui) {
                                var colRef1 = ui.column.orgColumn.REF_COL_01;
                                // var image = ui.rowData[colRef1 + '_FILENAME'];
                                var url = ui.rowData[colRef1 + '_URL'];
                                let imgBase64 = ui.rowData[ui.column.orgColumn.REF_COL_01 + '_64DATA']
                                if ((ui.rowData[colRef1] || '') == '' || ((url || '') == '' && (imgBase64 || '') == '')) {       // 참조 컬럼에 값이 없고 url과 base64이미지 값 둘다 없다면 Pass
                                    // url = 'img/noimg.png';
                                    return {
                                        text: "",
                                        cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                    };
                                }
                                const imgBaseurl = ng_core.common.config.baseUrl;
                                let grdImgWidth = '';
                                let grdImgHeight = '';
                                if(ui.style != null) {
                                    ui.style.join(';').split(';').map((val) => {
                                        if(val.indexOf('width') != -1) {
                                            grdImgWidth = val + ";";
                                        } else if(val.indexOf('height') != -1) {
                                            let tmpHeight = val;
                                            tmpHeight = tmpHeight.split(':')
                                            tmpHeight = "height:calc(" + tmpHeight[1] + " - 10px);";
                                            grdImgHeight = tmpHeight;
                                        }
                                    })
                                    var srcimage = ''
                                    srcimage += '<div class="gridupload">';
                                    if(imgBase64) {
                                        srcimage += "<img style='"+grdImgWidth+grdImgHeight+"' src='" + imgBase64 + "'  />";  //  //baseconfig.Url
                                    } else {
                                        srcimage += "<img style='"+grdImgWidth+grdImgHeight+"' src='" + imgBaseurl + '/' + url + "'  />";  //  //baseconfig.Url
                                    }
                                    srcimage += '</div>';
                                    
                                    return {
                                        text: srcimage,
                                        cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                    };
    
                                } else {
                                    var srcimage = "<img src='" + imgBaseurl + '/' + url + "' />";  //  //baseconfig.Url
                                    return {
                                        text: srcimage,
                                        cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                    };
                                }
                            },
                            postRender: function (ui) {
                                /*
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                ;
                                $cell.find('button').bind('click', function() { 
                                    self.onGrdCellButtonClick(ui, grid, rowColumn.COLUMN_CODE) 
                                })
                                */
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                ;
                                $cell.find('img').bind('click', function (e) {
                                    const imgBaseurl = ng_core.common.config.baseUrl;
                                    var popupWidth = 500
                                    var popupHeight = 600
                                    var popupX = (window.screen.width / 2) - (popupWidth / 2);
                                    var popupY= (window.screen.height / 2) - (popupHeight / 2);
                                    var options = 'top=' + popupY + ', left=' + popupX + ', width=' + popupWidth + ', height=' + popupHeight + ', status=no, menubar=no, toolbar=no, resizable=no';
                                    window.open(imgBaseurl+ui.rowData[ui.column.orgColumn.REF_COL_01 + '_URL'], ui.rowData[ui.column.orgColumn.REF_COL_01 + '_ORG_NAME'], options);
                                });
                            },
                        })
                        break
                    case "LANGPACK":        // 언어팩(????)
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            //editable: true,
                            //useLabel: true,
                            render: function (ui) {
                                // ref가 있으면 그대로 사용 없으면 현재 컬럼것을 사용(ref를 사용하면 트리그리드에 문제가 생김)
                                var colRef1 = ui.column.orgColumn.REF_COL_01;
                                var colText = ''
                                
                                if((colRef1 || '') == '') {
                                    colText = t((ui.cellData || ''))
                                } else {
                                    colText = t(ui.rowData[colRef1] || '')
                                }
                                return {
                                    text: colText,
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                            postRender: function (ui) {
                                /*
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                ;
                                $cell.find('button').bind('click', function() { 
                                    self.onGrdCellButtonClick(ui, grid, rowColumn.COLUMN_CODE) 
                                })
                                */
                            },
                        })
                        break

                    case "FILE":       // File
                        tmp_column = Object.assign(tmp_column, {
                            //editor: true,
                            editable: false,
                            //useLabel: true,
                            render: function (ui) {
                                const imgBaseurl = ng_core.common.config.baseUrl;
                                // const img = "<image style='height:18px' src='" + imgBaseurl + '/' + ui.rowData[ui.dataIndx + '_URL'] + "' />";
                                const cellData = !!ui.cellData ? ui.cellData : "";
                                let inputStr = '';
                                inputStr += '<div class="gridupload">';
                                inputStr += '<label for="grd_file_'+ui.rowIndx+'">Upload</label>';
                                inputStr += '<input type="file" id="grd_file_'+ui.rowIndx+'" accept="image">';
                                // Filename
                                if(!!cellData) {
                                    inputStr += '<button>X</button>';
                                }
                                // Image
                                if(!!ui.rowData[ui.dataIndx + '_URL']) {
                                    inputStr += '<img style="height:18px" src="' + imgBaseurl + ui.rowData[ui.dataIndx + '_URL'] + '" />';
                                } else if(!!ui.rowData[ui.dataIndx + '_64DATA']) {
                                    inputStr += '<img style="height:18px" src="' + ui.rowData[ui.dataIndx + '_64DATA'] + '" />';
                                }
                                inputStr += '<span>' + (ui.rowData[ui.dataIndx + '_ORG_NAME'] || '') + '</span>';
                                inputStr += '</div>';
                                return {
                                    text: inputStr,
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                            postRender: function (ui) {
                                var rowIndx = ui.rowIndx
                                    , grid = this
                                    , $cell = grid.getCell(ui)
                                ;
                                $cell.find('input[type="file"]').bind('change', function (e) {
                                    // ui.rowData[ui.dataIndx] = e.target.files[0];
                                    if(e.target.files.length > 0) {
                                        const reader = new FileReader();
                                        const fileInfo = e.target.files[0];
                                        reader.onload = (er) => {
                                            self.setCellData(ui.rowIndx, ui.dataIndx+"_FLAG", "A")  // 파일 업로드 확인용 Flag: Create
                                            self.setCellData(ui.rowIndx, ui.dataIndx, fileInfo.name)
                                            self.setCellData(ui.rowIndx, ui.dataIndx+"_ORG_NAME", fileInfo.name)
                                            self.setCellData(ui.rowIndx, ui.dataIndx+"_EXC", fileInfo.type)
                                            self.setCellData(ui.rowIndx, ui.dataIndx+"_SIZE", fileInfo.size)
                                            self.setCellData(ui.rowIndx, ui.dataIndx+"_64DATA", er.target.result)
                                        }
                                        reader.readAsDataURL(fileInfo);
                                    }
                                });
                                $cell.find('button').bind('click', function (e) {
                                    self.setCellData(ui.rowIndx, ui.dataIndx+"_FLAG", "D")  // 파일 업로드 확인용 Flag: Delete
                                    self.setCellData(ui.rowIndx, ui.dataIndx, "")
                                    self.setCellData(ui.rowIndx, ui.dataIndx+"_ORG_NAME", "")
                                    self.setCellData(ui.rowIndx, ui.dataIndx+"_EXC", "")
                                    self.setCellData(ui.rowIndx, ui.dataIndx+"_SIZE", "")
                                    self.setCellData(ui.rowIndx, ui.dataIndx+"_64DATA", "")
                                });
                                $cell.find('img').bind('click', function (e) {
                                    const imgBaseurl = ng_core.common.config.baseUrl;
                                    var popupWidth = 500
                                    var popupHeight = 600
                                    var popupX = (window.screen.width / 2) - (popupWidth / 2);
                                    var popupY= (window.screen.height / 2) - (popupHeight / 2);
                                    var options = 'top=' + popupY + ', left=' + popupX + ', width=' + popupWidth + ', height=' + popupHeight + ', status=no, menubar=no, toolbar=no, resizable=no';
                                    window.open(imgBaseurl+ui.rowData[ui.dataIndx + '_URL'], ui.rowData[ui.dataIndx + '_ORG_NAME'], options);
                                });
                            },
                        })
                        break

                    case "DATEPICKER":          // Date Picker
                        tmp_column = Object.assign(tmp_column, {
                            dataType: 'date',
                            cls: 'pq-calendar pq-side-icon',
                            editor: {
                                type: 'textbox',
                                init: bootstrapDateEditor,
                                // getData: function (ui) {
                                //     //debugger
                                //     //convert from column format to native js date format "mm/dd/yy"
                                //     //return ui.$cell.find("input").val()
                                //     debugger
                                //     let tmpformat = ((ui.column.format || '').toLowerCase() || "yy-mm-dd").replace('yyyy', 'yy')
                                //     let dt = $.datepicker.parseDate(tmpformat, ui.$cell.find("input").val());
                                //     //var dt = $.datepicker.parseDate("yy-mm-dd", ui.$cell.find("input").val());
                                //     //return $.datepicker.formatDate(tmpformat, dt);//don't change it.
                                //     return $.datepicker.formatDate('mm/dd/yyyy', dt);//don't change it.
                                // },
                                preventClose: function (ui) {                    
                                    return $(".datepicker-dropdown").is(":visible");
                                }
                            },
                            render: function (ui) {
                                
                                if(ui.rowData.pq_grandsummary == true) ui.cls[1] = ''
                                const imgBaseurl = ng_core.common.config.baseUrl;
                                const cellData = !!ui.cellData ? ui.cellData : "";
                                let inputStr = '';
                                
                                return {
                                    text: cellData,
                                    cls: self.onColumnCls(ui, rowColumn.COLUMN_CODE)
                                };
                            },
                        })
                        break
                }
            }

            // 트리 그리드의 경우 해당 기능 추가 설정
            if (self.gridType === 'TREEGRID') {
                if (rowColumn.TREEGRIDMAIN === 'Y') {
                    self.treeModel['dataIndx'] = rowColumn.COLUMN_CODE
                }

                if (rowColumn.MAINID_YN === 'Y') {
                    self.treeModel['id'] = rowColumn.COLUMN_CODE
                }
                if (rowColumn.PARENTID_YN === 'Y') {
                    self.treeModel['parentId'] = rowColumn.COLUMN_CODE
                }

            } else {

            }

            targetColumn.push(tmp_column)
            self.grdColumns[rowColumn.COLUMN_CODE] = rowColumn
            //self.grdHeaders[rowHeader.COLUMN_CODE] = rowHeader

            if(rowColumn.FIX_YN === 'Y') {
                freezeCols = rowColumn.COLUMN_CODE
            }

        });
        return { targetColumn: targetColumn, freezeCols: freezeCols }
    }

    function bootstrapDateEditor(ui) {
        // ui.rowData.pq_grandsummary == true
        var $inp = ui.$cell.find("input"),
            grid = this,
            format = ((ui.column.format || '').toLowerCase() || "yy-mm-dd").replace('yyyy', 'yy'),
            val = $inp.val(),
            val = val ? $.datepicker.formatDate(format, new Date(val)) : "";

        //initialize the editor
        $inp
            .val(val)
            .datepicker({
                language: 'ko',
                dateFormat: format,
            })
            .on('changeDate', function (e) {
                debugger
                // $(this).focus();
                // $(this).bootstrapDatepicker('hide');
            })

    }

    /**
     * Component Type이 AllUnpivotGrid의 경우 컬럼 구성
     */
    function GenerateAllUnpivotGridColumn(freezeCols, sourceColumn) {
        // Header정보를 담을 Pivot용 컬럼 생성
        sourceColumn.push({
            title: "Field",
            dataIndx: "Field",
            width: 150,
            sortable: false,
            hvalign: 'center',
            halign: 'center',
            align: 'center',
            dataType: 'string',
            // Header 컬럼에 따라 다르게 표시되어야 함
            // render: function (ui) {      
            //     try {
            //         var cb = ui.column.cb,
            //             cellData = ui.cellData,
            //             checked = self.rowRadioSelect_id === ui.rowIndx ? "checked" : ""
            //         ;  
            //         var hidden = false //!(self.grid.getChanges().addList.filter(row => { return row.pq_ri === ui.rowIndx }).length === 0)

            //         return {
            //             text: hidden ? "" : "<input type='radio' name='" + baseGrid.baseComponentData.COMPONENT_CODE + "_" + ui.dataIndx +  "' " + checked + " />",
            //             style: "background:white"
            //         };
            //     } catch(err) {
            //     }
            // },
            editor: false,
            editable: false,
            beforeCheck: function (event, ui) {

                return false
            },

        })

        // 컬럼에 Type에 따라 컨트롤을 다르게 보이게 함
        // 신규인지 기존인지 구분이 필요해보임(***********************************)
        targetColumn.push({
            title: "Data",
            dataIndx: "Data",
            width: 150,
            sortable: false,
            hvalign: 'center',
            halign: 'center',
            align: 'center',
            dataType: 'string',
            // Header 컬럼에 따라 다르게 표시되어야 함
            // render: function (ui) {      
            //     try {
            //         var cb = ui.column.cb,
            //             cellData = ui.cellData,
            //             checked = self.rowRadioSelect_id === ui.rowIndx ? "checked" : ""
            //         ;  
            //         var hidden = false //!(self.grid.getChanges().addList.filter(row => { return row.pq_ri === ui.rowIndx }).length === 0)

            //         return {
            //             text: hidden ? "" : "<input type='radio' name='" + baseGrid.baseComponentData.COMPONENT_CODE + "_" + ui.dataIndx +  "' " + checked + " />",
            //             style: "background:white"
            //         };
            //     } catch(err) {
            //     }
            // },
            editor: false,
            editable: false,
            beforeCheck: function (event, ui) {

                return false
            },

        })

        freezeCols = targetColumn.length
        return { targetColumn: targetColumn, freezeCols: freezeCols }
    }

    /**
     * 트리 그리드 컬럼 설정
     */
    function GenerateTreeGridColumn() {
        var self = baseGrid

        // treegrid 상하 관계 컬럼 정의
        self.options['treeModel'] = Object.assign(tmp_treeModel, self.treeModel)

        // treegrid의 경우에 TREEGRIDMAIN에 해당 하는 컬럼을 최상위로 이동 시킴
        var tmp_arr = ['ROWRADIO', 'METHOD', 'ROWCHECK']

        var tmp_treemaincolumn = tmp_columns.filter(function(row2) { return row2.dataIndx === tmp_treeModel['dataIndx'] })
        if(tmp_treemaincolumn.length > 0) {
            var tmp_rowidx = tmp_columns.indexOf(tmp_treemaincolumn[0])
            
            tmp_columns.splice(tmp_rowidx, 1)           // column 리스트에서 제거
            for(var i = 0; i < tmp_columns.length; i++) {
                if(tmp_arr.indexOf(tmp_columns[i].dataIndx) === -1 ) {
                    tmp_columns.splice(i, 0, tmp_treemaincolumn[0])
                    break
                }
            }

        }        
    }

    // 해더 생성용 메소드
    function GenerationHreader(source_data, parentid) {
        let self = baseGrid
        let newHeaderData = null
        if ((parentid ||'') == '') {
            // 최상단 가져와 세팅
            newHeaderData = source_data.filter(function (row) {
                return row.PARENT_COLUMN_CODE == null || row.PARENT_COLUMN_CODE === ''
            })
        } else {
            newHeaderData = source_data.filter(function (row) {
                return row.PARENT_COLUMN_CODE === parentid && row.COLUMN_CODE !== parentid
            })
            if (newHeaderData.length === 0) return null
        }
        // 대상으로 잡은 내용은 source에서 삭제
        newHeaderData.map(function (row) {
            source_data.splice(source_data.indexOf(row), 1)
        })

        // 하위 대상을 등록
        newHeaderData.map(function (row) {
            var tmp_sub_data = GenerationHreader(source_data, row.COLUMN_CODE)
            if (tmp_sub_data != null && tmp_sub_data.length > 0) {
                row['colModel'] = tmp_sub_data
            }
        })

        return newHeaderData
    }

    function GenerationPivotHader(source_data, parentid) {
        let self = baseGrid
        let newHeaderData = null
        if ((parentid ||'') == '') {
            // 최상단 가져와 세팅
            newHeaderData = source_data.filter(function (row) {
                return row.orgColumn.PIVOT_PARENT_CODE == null || row.orgColumn.PIVOT_PARENT_CODE === ''
            })
        } else {
            newHeaderData = source_data.filter(function (row) {
                return row.orgColumn.PIVOT_PARENT_CODE === parentid && row.COLUMN_CODE !== parentid
            })
            if (newHeaderData.length === 0) return null
        }
        // 대상으로 잡은 내용은 source에서 삭제
        newHeaderData.map(function (row) {
            source_data.splice(source_data.indexOf(row), 1)
        })

        // 하위 대상을 등록
        newHeaderData.map(function (row) {
            var tmp_sub_data = GenerationPivotHader(source_data, row.COLUMN_CODE)
            if (tmp_sub_data != null && tmp_sub_data.length > 0) {
                row['colModel'] = tmp_sub_data
            }
        })

        return newHeaderData    
    }

    return true
}
