<script setup>
import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from './Alert.vue'
import { VueFinalModal } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'popupSize', 'grid', 'componentList', 'targetREFCODEList', 'width', 'height', 'api'])
const emit = defineEmits(['confirm', 'cancel'])

const modalRef = ref(null)

// 사이즈
let modalSize = computed(() => {
    let styles = {
        //width: (props.width || '') == '' ? '0px' : props.width,
        //height: (props.height || '') == '' ? '0px' : props.height,
    }
    if ((props.width || '') != '') styles['width'] = props.width
    if ((props.height || '') != '') styles['height'] = props.height

    return styles
})

let search = {}
// 검색 조건 생성
let tmpkeys = [props.grid]
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}


const grid = ref()
let grids = {}
let cClose;

onMounted(() => {
    grids[props.grid] = grid
    cClose = props.close

    setTimeout(() => {
        onSearch(props.grid)
    }, 200);

});

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onCellDbClick(sender, event, ui) {
    emit('confirm', ui.rowData)
}

/** OK 버튼 */
function onConfirm() {
    let tmpSctData = grid.value.getRowSelectedData()
    if (!tmpSctData) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
    } else {
        emit('confirm', tmpSctData)
    }
}

/** 취소 버튼 */
function onCancel() {
    emit('cancel', false)
}

const vfmref = ref()        // 테스트 필요함

</script>

<template>
    <!-- Modal -->
    <vue-final-modal 
        class="flex justify-center items-center modal-container"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
            
        :drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel()"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div style="width:100%; height:40px; overflow:auto;">
                <div class="searchSplitCon parentCon">
                    <!-- 공통 클래스 사용(Style 정의[component_code]) -->
                    <!-- <div class="leftCon ml-1 mr-1">
                        <label for="input-none" class="conLabel">{{t('VIEW_CODE')}}</label>
                        <input type="text" class="conInputText"
                            v-model="search[props.grid].VIEW_CODE.value" :placeholder="t('VIEW_CODE')"
                            @keyup.enter="onSearch(props.grid)" :state="null">
                    </div> -->
                    <!-- 공통 클래스 사용(Style 정의[component_code]) -->
                    <!-- <div class="leftCon ml-1 mr-1">
                        <label for="input-none" class="conLabel">{{t('VIEW_NAME')}}</label>
                        <input type="text" class="conInputText"
                            v-model="search[props.grid].VIEW_NAME.value" :placeholder="t('VIEW_NAME')"
                            @keyup.enter="onSearch(props.grid)" :state="null">
                    </div> -->

                    <div class="rightCon">
                        <button ref="read" @click="onSearch(props.grid)"
                            :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                    </div>
                </div>
            </div>
            <div class="resultGrid" style="height:calc(100% - 50px);">
                <BaseGrid ref='grid' :refCode="'DATA_MST_POPUP'" @rowclick="onGrdRowClick"
                    @CellDblClick="onCellDbClick" />
            </div>
        </div>
        <div class="modal__action">
            <button class="btn btn-primary" @click="onConfirm()">{{ t('BUTTON.BUTTON0001') }}</button>
            <button class="btn btn-secondary" @click="onCancel()">{{ t('BUTTON.BUTTON0002') }}</button>
        </div>
    </vue-final-modal>

</template>

<style scoped>
::v-deep .modal-container {
    display: flex;
    justify-content: center;
    align-items: center;
}

::v-deep .modal-content2 {
    position: relative;
    display: flex;
    flex-direction: column;
    max-height: 90%;
    margin: 0 1rem;
    padding: 1rem;
    border: 1px solid #e2e8f0;
    border-radius: 0.25rem;
    background: #fff;
}

.modal__title {
    margin: 0 2rem 0.5rem 0;
    font-size: 1.5rem;
    font-weight: 700;
}

.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
    padding: 1rem 0 0;
}

.modal__close {
    position: absolute;
    top: 0.5rem;
    right: 0.5rem;
}
</style>

<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style>