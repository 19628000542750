<script setup>
import { ref, getCurrentInstance, onMounted, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
// import personalization from '@/components/popup/personalization.vue'
import personalization from '@/components/popup/personalization.vue'
import pq from 'pqgrid';


const { t } = useI18n()

var data = [
    { rank: 1, EmployeeID: 'Exxon Mobil', FirstName: 'a' },
];
            
//array of columns.
var options = {
    height: 'flex',
    maxHeight: 500,
    menuIcon: true,
    showTitle: false,
    autoRow: true,
    rowHt: 100,
    dataModel: {data: data},
    toolbar: {
        items: [
            {
                type: 'button', icon: 'ui-icon-plus', label: 'New root node', listener: function () {
                    var grid = this,
                        ri,
                        newRow = { EmployeeID: "pq_tmp_" + Math.random() };

                    grid.Tree().addNodes([newRow])

                    //get rowIndx of newly inserted row.
                    ri = grid.getRowIndx({ rowData: newRow }).rowIndx;

                    grid.scrollRow({ rowIndx: ri }, function () {
                        grid.editFirstCellInRow({ rowIndx: ri })
                    });
                }
            },
            {
                type: 'button', icon: 'ui-icon-plus', label: 'New child node', listener: function () {
                    var grid = this,
                        sel = grid.Selection().getSelection(),
                        parent;

                    if (sel.length && (parent = sel[0].rowData)) {
                        var newRow = { EmployeeID: "pq_tmp_" + Math.random(), FirstName: "new item" };

                        grid.Tree().expandNodes([parent]);

                        //append new node to selected parent.
                        grid.Tree().addNodes([newRow], parent);

                        //get rowIndx of newly inserted row.
                        var ri = grid.getRowIndx({ rowData: newRow }).rowIndx;
                        grid.scrollRow({ rowIndx: ri }, function () {
                            grid.editFirstCellInRow({ rowIndx: ri })
                        });
                    }
                    else {
                        alert("Select parent node");
                    }
                }
            },
            { type: 'separator' },
            {
                type: 'button',
                icon: 'ui-icon-disk',
                label: 'Save Changes',
                cls: 'changes',
                //listener: saveChanges,
                options: { disabled: true }
            },
            {
                type: 'button',
                icon: 'ui-icon-cart',
                label: 'Get Changes',
                cls: 'changes',
                listener: function () {
                    var changes = getChanges(this);
                    if (console && console.log) {
                        console.log(changes);
                    }
                    alert("Please see the log of changes in your browser console.");
                },
                options: { disabled: true }
            },
            { type: 'separator' },
            {
                type: 'button', icon: 'ui-icon-arrowreturn-1-s', label: 'Undo', cls: 'changes', listener: function () {
                    this.History().undo();
                },
                options: { disabled: true }
            },
            {
                type: 'button', icon: 'ui-icon-arrowrefresh-1-s', label: 'Redo', listener: function () {
                    this.History().redo();
                },
                options: { disabled: true }
            }
        ]
    },
    history: function (evt, ui) {
        var $tb = this.toolbar();
        if (ui.canUndo != null) {
            $("button.changes", $tb).button("option", { disabled: !ui.canUndo });
        }
        if (ui.canRedo != null) {
            $("button:contains('Redo')", $tb).button("option", "disabled", !ui.canRedo);
        }
        $("button:contains('Undo')", $tb).button("option", { label: 'Undo (' + ui.num_undo + ')' });
        $("button:contains('Redo')", $tb).button("option", { label: 'Redo (' + ui.num_redo + ')' });
    },
    animModel: { on: true },
    treeModel: {
        dataIndx: 'FirstName',
        indent: 36,
        id: 'EmployeeID',
        parentId: 'ReportsTo',
        childstr: 'pq_children',
        historyMove: true, //7.4
        leafIfEmpty: true //7.4            
    },
    sortModel: { ignoreCase: true },
    scrollModel: { autoFit: true },
    columnTemplate: { maxWidth: '80%', width: 100, valign: 'center' },
    //collapsible: { on: true, toggled: true },
    trackModel: { on: true },
    colModel: [
        {
            dataIndx: 'FirstName',
            validations: [{ type: 'nonEmpty', msg: "Required" }]
        },
        {
            dataIndx: 'Title',
            title: 'Title',
            validations: [{ type: 'nonEmpty', msg: "Required" }]
        },
        {
            dataIndx: 'EmployeeID', width: 50
        },
        {
            dataIndx: 'ReportsTo', width: 50
        },
        
    ],
    postRenderInterval: -1, //call postRender synchronously.        
    // dataModel: {
    //     recIndx: 'EmployeeID',
    //     location: 'remote',
    //     //url: 'treegrid/tree_batch_db.php' //for PHP
    //     url: '/pro/treeEdit'
    // }
}

const gridPOPPerson = ref()
let gridHand;

onMounted(() => {
    // 그리드 초기화
    // for(let i = 0; i < grid_keys.length; i++) {
    //     let tmpBaseComponentData = ng_core.common.currentPageInfo[grid_keys[i]]
    //     grids[grid_keys[i]].value.GridbaseInit(tmpBaseComponentData, tmpBaseComponentData['COMPONENT_TYPE'])
    // }

    gridHand = pq.grid(gridPOPPerson.value, options);

})


</script>
<template>
    <div ref="gridPOPPerson" style="width:100%; height:100%"></div>
</template>
<style>
</style>