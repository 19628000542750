import * as joint from 'jointjs';

// 컬럼 형태의 컨맨드
export const columnSelectList = ['MOVEROW', 'CALLSERVICE', 'DEFAULTVAL']

// 선택 탭에 대한 설정 값
export const tabAttr = {
	// 속성 : 아직 기능이 없음
	OPTION: {	// 옵션
		eleNM: 'MENU_APPLY_COMPONENT_DTL_OPTION',
		columnNM: 'OPTION_CODE',
		viewNM: 'OPTION_NAME',
		columnCD: 'OPTION_CODE',
		// 버튼 종류
		BUTTONTYPE: {
			SEARCH : {
				// 인스펙터 방식(LINE: 선연결방식, COLUMN: 테이블의 컬럼 선택 방식)
				IM: ['LINE'],
				// 인스펙터 선택 방식(TABLE: 선 연결 시 테이블 선택, COLUMN: 선 연결 시 컬럼 선택)
				ISM: ['TABLE'],
			},
		},
	},
	COLUMN: {	// 컬럼
		eleNM: 'MENU_APPLY_COMPONENT_DTL_COLUMN',
		columnNM: 'COLUMN_CODE_HEADER',
		viewNM: 'COLUMN_NAME',
		columnCD: 'COLUMN_CODE',
		BUTTONTYPE: {
			DATAREF : {
				IM: ['LINE'],
				ISM: ['COLUMN'],
			},
			DEFAULTVAL : {
				IM: ['COLUMN'],
			},
		}
	},
	// 헤더 : 아직 기능이 없음
	SEARCH: {	// 조회
		eleNM: 'ARGS',
		columnNM: 'PARAMETER_NAME2',
		viewNM: 'LABEL',
		columnCD: 'PARAMETER_NAME2',
		BUTTONTYPE: {
			// SEARCH : {
			// 	IM: ['LINE'],
			// 	ISM: ['TABLE'],
			// },
			DATAREF : {
				IM: ['LINE'],
				ISM: ['COLUMN'],
			},
			DEFAULTVAL : {
				IM: ['COLUMN'],
			},
		}
	},
	BUTTON: {	// 버튼
		eleNM: 'MENU_APPLY_COMPONENT_DTL_BUTTON',
		columnNM: 'BUTTON_CODE',
		viewNM: 'BUTTON_CODE',
		columnCD: 'BUTTON_CODE',
		BUTTONTYPE : {
			SEARCH : {
				IM: ['LINE'],
				ISM: ['TABLE'],
			},
			CALLSERVICE : {
				IM: ['COLUMN'],
			},
			MOVEROW : {
				IM: ['COLUMN'],	// 컬럼을 선택해서 세부 설정해야 할것으로 보임
				// IM: ['LINE'],
				// ISM: ['TABLE'],
			},
		},
	},

}	

export function showLinkTools(linkView) {
	var tools = new joint.dia.ToolsView({
		tools: [
			new joint.linkTools.Remove({
				distance: '50%',
				markup: [{
					tagName: 'circle',
					selector: 'button',
					attributes: {
						'r': 7,
						'fill': '#f6f6f6',
						'stroke': '#ff5148',
						'stroke-width': 2,
						'cursor': 'pointer'
					}
				}, {
					tagName: 'path',
					selector: 'icon',
					attributes: {
						'd': 'M -3 -3 3 3 M -3 3 3 -3',
						'fill': 'none',
						'stroke': '#ff5148',
						'stroke-width': 2,
						'pointer-events': 'none'
					}
				}]
			})
		]
	});
	linkView.addTools(tools);
}

var svg = null
export function setSVG(_svg) {
	svg = _svg
}

var t = null
export function setT(_t) {
	t = _t
}

export class Shape extends joint.dia.Element {
	defaults() {
		return {
			...super.defaults,
			type: 'custom.Shape',
			fillColor: 'red',
			outlineColor: 'blue',
			gridType: '',
			label: '',
			//image: '',
			eleJsonData: '',			// 그리드 정보
			tabType: 'COLUMN',				// 옵션(OPTION), 컬럼(COLUMN), 버튼(BUTTON), 조회(SEARCH) .... : 컬럼을 어떤것을 보여줘야 할지 결정
			mainYN: 'N',
		};
	}

	test() {
		return this.getEmbeddedCells()
	}

	preinitialize() {
		this.spacing = 10;
		this.elementAttributes = {
			rx: 1,
			ry: 1,
			cursor: 'move',
		}

		this.labelAttributes = {
			'font-size': 14,
			'font-family': 'sans-serif',
			cursor: 'move', // Set the cursor style to 'move' for dragging
		};

		this.groupRectAttributes = {
			//width: 100, // Set the width of the rect
			height: 25, // Set the height of the rect
			fill: 'white', // Set the fill color of the rect
			cursor: 'pointer', // Set the cursor style to 'move' for dragging
			//magnet: true, // Enable magnet to allow link connections
			gtype: 'columnGroup',
			columnInData: {},		// 각 컬럼별 포함된 데이터를 담는곳(컬럼별로 분리되어 저자됨)
		}

		this.rectInlabelAttributes = {
			'font-size': 14,
			'font-family': 'sans-serif',
			'gtype': 'columnLabel',
		};

		this.rectAttributes = {
			//width: 100, // Set the width of the rect
			height: 25, // Set the height of the rect
			fill: 'white', // Set the fill color of the rect
			cursor: 'pointer', // Set the cursor style to 'move' for dragging
			stroke: 'gray', // Set the border color to gray
  			strokeWidth: 1, // Set the border width
			gtype: 'columnRect',

		}

		// this.imageAttributes = {
		// 	'width': 50,
		// 	'height': 50,
		// 	'preserveAspectRatio': 'none',
		// };
		this.cache = {};
	}

	initialize() {
		super.initialize();
		this.on('change', this.onAttributeChange);
		this.setSizeFromContent();
	}

	/* Attributes that affects the size of the model. */
	onAttributeChange() {
		const { changed, cache } = this;
		if ('label' in changed) {
			// invalidate the cache only if the text of the `label` has changed
			delete cache.label;
		}
		if ('label' in changed || 'eleJsonData' in changed) {
			this.setSizeFromContent();
		}
	}

	setSizeFromContent() {
		delete this.cache.layout;
		const { width, height } = this.layout();
		this.resize(width, height);
	}

	layout() {
		const { cache } = this;
		let { layout } = cache;
		if (layout) {
			return layout;
		} else {
			const layout = this.calcLayout();
			cache.layout = layout;
			return layout;
		}
	}

	calcLayout() {
		const { attributes, labelAttributes, rectAttributes/*, imageAttributes*/, eleJsonData, spacing, cache } = this;
		let width = spacing * 2;
		let height = spacing * 2;
		let x = spacing;
		let y = spacing;

		// // image metrics
		// let $image;
		// if (attributes.image) {
		// 	const {
		// 		width: w,
		// 		height: h
		// 	} = imageAttributes;
		// 	$image = {
		// 		x,
		// 		y,
		// 		width: w,
		// 		height: h
		// 	};
		// 	height += spacing + h;
		// 	y += spacing + h;
		// 	width += w;
		// } else {
		// 	$image = null;
		// }
		
		// label metrics
		let $label; {
			let w, h;
			if ('label' in cache) {
				w = cache.label.width;
				h = cache.label.height;
			} else {
				const {
					width,
					height
				} = measureText(svg, attributes.label, labelAttributes);
				w = width;
				h = height;
				cache.label = {
					width,
					height
				};
			}
			width = Math.max(width, spacing + w + spacing);
			height += h;
			if (!h) {
				// no text
				height -= spacing;
			}
			$label = {
				x,
				y,
				width: w,
				height: h
			};
		}

		// Add a rect element
		let $rects = []; 
		
		if(attributes.eleJsonData != null && attributes.eleJsonData != '') {
			let eleJsonDataList = null
			if(attributes.tabType == 'OPTION') {
				eleJsonDataList = attributes.eleJsonData.MENU_APPLY_COMPONENT_DTL_OPTION
			} else if(attributes.tabType == 'SEARCH') {
				eleJsonDataList = attributes.eleJsonData.ARGS
			} else if(attributes.tabType == 'BUTTON') {
				eleJsonDataList = attributes.eleJsonData.MENU_APPLY_COMPONENT_DTL_BUTTON
			} else {
				eleJsonDataList = attributes.eleJsonData.MENU_APPLY_COMPONENT_DTL_COLUMN
			}

			const {
				width: w,
				height: h
			} = rectAttributes;

			for(let i = 0; i < eleJsonDataList.length; i++) {
			//if (attributes.image) {
				let $rect = {
					x: 0,
					y: height, //y,
					width: width,
					height: h
				};
				height += h;
				$rects.push($rect)
				// y += spacing + h;
				// width += w;
			// } else {
			// 	$image = null;
			// }
			}
		}
		

		// root metrics
		return {
			x: 0,
			y: 0,
			width,
			height,
			//$image,
			$label,
			$rects, // Include the rect in the layout object
		};
	}
}


function measureText(svgDocument, text, attrs) {
	const vText = joint.V('text').attr(attrs).text(text);
	vText.appendTo(svgDocument);
	const bbox = vText.getBBox();
	vText.remove();
	return bbox;
}

const ElementView = joint.dia.ElementView;

const ShapeView = ElementView.extend({

	// pointerdblclick: function(evt, x, y) {
	// 	this.model.remove();
	// },

	// pointerdown: function(evt, x, y) {
	// },

	presentationAttributes: ElementView.addPresentationAttributes({
		// attributes that changes the position and size of the DOM elements
		label: [ElementView.Flags.UPDATE],
		eleJsonData: [ElementView.Flags.UPDATE],
		//image: [ElementView.Flags.UPDATE],
		// attributes that do not affect the size
		outlineColor: ['@color'],
		fillColor: ['@color'],
	}),


	confirmUpdate: function (...args) {
		let flags = ElementView.prototype.confirmUpdate.call(this, ...args);
		if (this.hasFlag(flags, '@color')) {
			// if only a color is changed, no need to resize the DOM elements
			this.updateColors();
			flags = this.removeFlag(flags, '@color');
		}
		// must return 0
		return flags;
	},

	// Other Methods
	/* Runs only once while initializing */
	render: function () {
		const { vel, model } = this;


		const tmpG = this.vBody = joint.V('g').addClass('body').attr(model.elementAttributes);
		const body = this.vBody = joint.V('rect').addClass('body').attr(model.elementAttributes);
		const label = this.vLabel = joint.V('text').addClass('label').attr(model.labelAttributes);
		tmpG.append(body)
		tmpG.append(label)
		//this.vImage = joint.V('image').addClass('image').attr(model.imageAttributes);
		
		this.vRectG = []
		this.vRects = []
		this.vRectInTexts = []

		let eleJsonDataList = this.selectEleJsonDataList(model.attributes.tabType, model.attributes.eleJsonData)
		for(let i = 0; i < eleJsonDataList.length; i++) {
			let tmpExtData = {
				id : model.attributes.eleJsonData.REF_CODE + '__' + this.selectColumnData(model.attributes.tabType, eleJsonDataList[i])
			}
			let tagG
			//if(model.attributes.mainYN == 'Y') {
				tmpExtData['magnet'] = true
			//}
			tagG = joint.V('g').addClass('body')
									.attr(model.groupRectAttributes)
									.attr(tmpExtData)
									;
			// columnInData
			model.groupRectAttributes.columnInData[this.selectColumnData(model.attributes.tabType, eleJsonDataList[i])] = eleJsonDataList[i].RULE_DATA
			
			let rect = joint.V('rect').addClass('rect').attr(model.rectAttributes);
			if(model.groupRectAttributes.columnInData[this.selectColumnData(model.attributes.tabType, eleJsonDataList[i])].filter((row) => columnSelectList.includes(row.COMMAND)).length > 0) {
				rect.attr('fill', 'yellow')
			}
			const text = joint.V('text').text(this.selectColumnNameData(model.attributes.tabType, eleJsonDataList[i])).attr(model.rectInlabelAttributes);
			tagG.append(rect)
			tagG.append(text)

			this.vRectG.push(tagG)
			this.vRects.push(rect)
			this.vRectInTexts.push(text)
		}

		vel.empty().append([
			tmpG,
			//body,
			//label,
			...this.vRectG,
			// ...this.vRects, // Append the rect to the view's element
			// ...this.vRectInTexts,
		]);
		this.update();
		this.updateColors();
		this.translate(); // default element translate method
	},

	// 컬럼 타입에 따라 가져오는 데이터 선택
	selectEleJsonDataList: function(tabType, eleJsonData) {
		return eleJsonData[tabAttr[tabType].eleNM]

		// switch(tabType) {
		// 	case 'OPTION':
		// 		return eleJsonData.MENU_APPLY_COMPONENT_DTL_OPTION
		// 	case 'SEARCH':
		// 		return eleJsonData.MENU_APPLY_COMPONENT_DTL_BUTTON
		// 	case 'BUTTON':
		// 		return eleJsonData.MENU_APPLY_COMPONENT_DTL_BUTTON
		// 	default:
		// 		return eleJsonData.MENU_APPLY_COMPONENT_DTL_COLUMN
		// }
	},

	// 컬럼 타입에 따라 가져오는 컬럼 정보 선택
	selectColumnData: function(tabType, eleJsonData) {
		return eleJsonData[tabAttr[tabType].columnNM]
		
		// switch(tabType) {
		// 	case 'OPTION':
		// 		return eleJsonData.OPTION_CODE
		// 	case 'SEARCH':
		// 		return eleJsonData.PARAMETER_NAME2
		// 	case 'BUTTON':
		// 		return eleJsonData.BUTTON_CODE
		// 	default:
		// 		return eleJsonData.COLUMN_CODE_HEADER
		// }
	},

	// 컬럼 타입에 따라 가져오는 컬럼 이름 정보 선택
	selectColumnNameData: function(tabType, eleJsonData) {
		return t == null ? eleJsonData[tabAttr[tabType].viewNM] : t(eleJsonData[tabAttr[tabType].viewNM])

		// switch(tabType) {
		// 	case 'OPTION':
		// 		return t == null ? eleJsonData.OPTION_NAME : t(eleJsonData.OPTION_NAME)
		// 	case 'SEARCH':
		// 		return t == null ? eleJsonData.LABEL : t(eleJsonData.LABEL)
		// 	case 'BUTTON':
		// 		return t == null ? eleJsonData.BUTTON_CODE : t(eleJsonData.BUTTON_CODE)
		// 	default:
		// 		return t == null ? eleJsonData.COLUMN_NAME : t(eleJsonData.COLUMN_NAME)
		// }
	},

	update: function () {
		const layout = this.model.layout();
		this.updateBody();
		//this.updateImage(layout.$image);
		this.updateLabel(layout.$label);
		this.updateRect(layout.$rects);
	},

	updateColors: function () {
		const { model, vBody } = this;
		vBody.attr({
			fill: model.get('fillColor'),
			stroke: model.get('outlineColor')
		});
	},

	updateBody: function () {
		const { model, vBody } = this;
		const { width, height } = model.size();
		const bodyAttributes = { width, height };
		vBody.attr(bodyAttributes);
	},

	// updateImage: function ($image) {
	// 	const { model, vImage, vel } = this;
	// 	const image = model.get('image');
	// 	if (image) {
	// 		if (!vImage.parent()) {
	// 			vel.append(vImage);
	// 		}
	// 		vImage.attr({
	// 			'xlink:href': image,
	// 			x: $image.x,
	// 			y: $image.y
	// 		});

	// 	} else {
	// 		vImage.remove();
	// 	}
	// },

	updateLabel: function ($label) {
		const { model, vLabel } = this;
		vLabel.attr({
			'text-anchor': 'middle',
			x: $label.x + $label.width / 2,
			y: $label.y + $label.height / 2
		});
		vLabel.text(model.get('label'), {
			textVerticalAnchor: 'middle'
		});
	},

	updateRect: function ($rects) {
		const { model, vRectG, vRects, vRectInTexts } = this;
		
		for(let i = 0; i < vRectG.length; i++) {
			const tagG = vRectG[i];
			const rect = vRects[i];
			const text = vRectInTexts[i];
			tagG.attr({
				//'text-anchor': 'middle',
				x: $rects[i].x+1,
				y: $rects[i].y-1,
				width: $rects[i].width-2,
				height: $rects[i].height,
			});
			rect.attr({
				//'text-anchor': 'middle',
				x: $rects[i].x+1,
				y: $rects[i].y-1,
				width: $rects[i].width-2,
				height: $rects[i].height,
			});
			
			//const text =vRectInTexts[i]; // Find the text element within the rect
			// const text = rect.findOne('text')
			// Set the text color to red
			text.attr('fill', 'black');
			// Set the text position to the center of the rect
			const rectWidth = $rects[i].width;
			const rectHeight = $rects[i].height;
			const textWidth = text.getBBox().width;
			const textHeight = text.getBBox().height;
			const textX = $rects[i].x + ($rects[i].width - textWidth) / 2;
			const textY = $rects[i].y + (textHeight);
			text.attr('x', textX);
			text.attr('y', textY);

		}
		
		// vLabel.text(model.get('label'), {
		// 	textVerticalAnchor: 'middle'
		// });
	}
});

joint.shapes.custom = {
	Shape,
	ShapeView
};

export function enableVirtualRendering(paper) {
	const paperContainer = paper.el.parentNode;

	let viewportArea;
	function updateViewportArea() {
		viewportArea = paper.clientToLocalRect(
			paperContainer.getBoundingClientRect()
		);
	}

	// Setup listeners
	updateViewportArea();
	paperContainer.addEventListener("scroll", updateViewportArea, false);
	paper.on("scale", updateViewportArea);

	// Paper `viewport` option
	// https://resources.jointjs.com/docs/jointjs/#dia.Paper.prototype.options.viewport
	paper.options.viewport = (view) => {
		const { model } = view;
		// Hide elements and links which are not in the viewport.
		const bbox = model.getBBox();
		if (model.isLink()) {
			// Vertical/horizontal links have zero width/height.
			bbox.width += 1;
			bbox.height += 1;
		}
		return viewportArea.intersect(bbox) !== null;
	};
}