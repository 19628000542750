<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import dayjs from 'dayjs'
const { t } = useI18n()
let con = getCurrentInstance()
let search = {}
const MENU_USER_LOG = ref()

let grids = {
    'MENU_USER_LOG': MENU_USER_LOG,

}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}
/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])


}


// 날짜
let today = new Date();
let year = today.getFullYear();     // 년도
let month = today.getMonth() + 1;   // 월
let date = today.getDate();         // 날짜

let result = year + '-' + month + '-' + date

search.MENU_USER_LOG.START_TIME.value = dayjs(result).format('YYYY-MM-DD')
search.MENU_USER_LOG.END_TIME.value = dayjs(result).format('YYYY-MM-DD')

</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
                <Pane>
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.LOG_SEQ')}} :</label>
                                <input type="text" class="conInputText" v-model="search['MENU_USER_LOG'].LOG_SEQ.value" :placeholder="t('LABEL.LOG_SEQ')"
                                        @keyup.enter="onSearch('MENU_USER_LOG')" :state="null">
                            </div>
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.START_DATE')}} :</label>
                                <input type="text" class="conInputText" v-model="search['MENU_USER_LOG'].START_TIME.value" :placeholder="t('LABEL.START_TIME')"
                                        @keyup.enter="onSearch('MENU_USER_LOG')" :state="null">
                            </div> 
                             <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{t('LABEL.END_DATE')}} :</label>
                                <input type="text" class="conInputText" v-model="search['MENU_USER_LOG'].END_TIME.value" :placeholder="t('LABEL.END_TIME')"
                                        @keyup.enter="onSearch('MENU_USER_LOG')" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('MENU_USER_LOG')" :title="t('BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            </div>
                        </div>
                    </div>
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="MENU_USER_LOG" :refCode="'MENU_USER_LOG'"/>
  
                    </div>
                    
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>