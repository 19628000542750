import jQuery from 'jquery'

/**
 * 공용 스타일
 *   - pqgrid 스타일이 들어가있음
 */
import './style.css';

import { createApp } from 'vue'
import App from './App.vue'
import createRouter from './router'
import createStore from "./store";
import { createI18n } from 'vue-i18n'
import * as ConfirmDialog from 'vuejs-confirm-dialog'
import { BootstrapVue3, BToastPlugin } from 'bootstrap-vue-3'
import VueSidebarMenu from 'vue-sidebar-menu'
import 'vue-sidebar-menu/dist/vue-sidebar-menu.css'


/* import font awesome icon component */
import { FontAwesomeIcon } from '@fortawesome/vue-fontawesome'

const i18n = createI18n({
    locale: 'ko',
    allowComposition: true, // you need to specify that!
    legacy: false,
    globalInjection: true,
    messages: {}
})
// import { setupI18n } from './i18n'

// let i18n = setupI18n()

/* import the fontawesome core */
import { library } from '@fortawesome/fontawesome-svg-core'
/* import specific icons */
import { fas } from '@fortawesome/free-solid-svg-icons'
import { far } from '@fortawesome/free-regular-svg-icons'
/* add icons to the library */
library.add(fas, far)

import "bootstrap"
import "bootstrap/dist/css/bootstrap.min.css"
import 'bootstrap-vue-3/dist/bootstrap-vue-3.css'

const store = createStore(i18n)
const app = createApp(App)


import { createVfm, useModal } from 'vue-final-modal'
const vfm = createVfm()

async function init() {

    app.use(vfm)

    const router = await createRouter(store, i18n)
    app.component('font-awesome-icon', FontAwesomeIcon)

    app.provide('vfmBasePopup', vfmBasePopup)       // vfm 기본 팝업

    app.use(i18n)
    app.use(router)
    app.use(store)
    app.use(BootstrapVue3)
    app.use(BToastPlugin)
    app.use(ConfirmDialog)
    app.use(VueSidebarMenu)
    app.mount('#app')


}
init()

global.$ = jQuery

// vue final modal 팝업창 기본 팝업
function vfmBasePopup(component, data) {
    return new Promise((resolve, reject) => {
        
        let rntdata = false    // 리턴 데이터 임시 저장

        const modalInstance = useModal({
            context: vfm,
            component: component,
            attrs: {
                ...data,
                onConfirm(data) {
                    modalInstance.close()
                    rntdata = data
                },
                onCancel(data) {
                    modalInstance.close()
                    rntdata = false
                },
                onClosed() {
                    modalInstance.destroy()
                    resolve(rntdata)
                },
            },
            // slots: { default: '<p>The content of the modal</p>' }
        })
        modalInstance.open()

        // vfm.open({
        //     component: component,
        //     // bind: {
        //     //     state: 'none',
        //     //     contents: 'VDynamicAdvacedModal',
        //     //     width: '500px',
        //     //     height: '100px',
        //     //     drag: true,
        //     //     data: data,
        //     // },
        //     bind: data,
        //     on: {
        //         // event by custom-modal
        //         confirm(close, data) {
        //             rntdata = data
        //             //console.log('confirm')
        //             close()
        //         },
        //         cancel(close, data) {
        //             rntdata = data
        //             //console.log('cancel')
        //             close()
        //         },
        //         // event by vue-final-modal
        //         'click-outside'() {
        //             //console.log('@click-outside')
        //         },
        //         'before-open'() {
        //             //console.log('@before-open')
        //         },
        //         opened() {
        //             //console.log('@opened')
        //         },
        //         'before-close'() {
        //             //console.log('@before-close')
        //         },
        //         closed(a, b, c) {
        //             resolve(rntdata)
        //             //console.log('@closed')
        //         }
        //     }
        // })
    })
}