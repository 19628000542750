<script setup>
import { ref, getCurrentInstance, onMounted, defineAsyncComponent, inject, nextTick } from 'vue'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import { useI18n } from 'vue-i18n'
const { t } = useI18n()


const MENU_FAVORITES = ref()
let search = {}

let grids = {
    'MENU_FAVORITES': MENU_FAVORITES
}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    grids[compName].value.grdAddRow({}, 'last')
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

let datas = ref([])

onMounted(async () => {
    var params = {
        PREF: 'SYS',
        VIEW_CODE: ng_core.common.currentMenuInfo.MENU_CODE,
        METHOD_CODE: ng_core.common.currentPageInfo.USER_PROFILE.COMPONENT_CODE,
        DATA: {
            'USER_ID' : ng_core.common.getUserId()
        },
        SQLLOG_YN: 'N',
    }

    try {
        let rtn = await ng_core.common.getData(params)
        if (rtn.data.status) {

            if(rtn.data.rtndata.length == 1) {
                datas = rtn.data.rtndata[0]
            }

        }
    } catch (err) {
        console.log('getData')
        console.dir(err)
        return err
    } finally {
    }    
})

/*************************************************
 * Tab 관련 : Start
*************************************************/
/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize()
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            if(grids[tmpGridKeys[i]].value.refresh) {
                grids[tmpGridKeys[i]].value.refresh()
            }
                
        }
    });
}

/*************************************************
 * Tab 관련 : End
 *************************************************/


</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal
                @resize="tabPageContentResize" :dbl-click-splitter="false">
            
                <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab"
                        @resize="tabPageContentResize">
                    <b-tab :title="t('일반')" active>
                        <div style="width:100%; height:40px; overflow:auto;">
                            test
                        </div>
                    </b-tab>
                    <b-tab :title="t('암호')" active>
                        <div style="width:100%; height:40px; overflow:auto;">
                            test2
                        </div>
                    </b-tab>
                    <b-tab :title="t('즐겨찾기')" active>
                        <div style="width:100%; height:40px; overflow:auto;">
                            <div class="searchSplitCon parentCon">
                                <div class="rightCon">
                                    <button ref="read" @click="onSearch('MENU_FAVORITES')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                    <button ref="plus" @click="onAddRow('MENU_FAVORITES')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                    <button ref="minus" @click="onRemoveRow('MENU_FAVORITES')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                    <button ref="save" @click="onSave('MENU_FAVORITES')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                </div>
                            </div>
                        </div>
                        <div class="resultGrid" style="height:calc(100% - 80px);">
                            <BaseGrid ref='MENU_FAVORITES' :refCode="'MENU_FAVORITES'" @CellButtonClick="onGrdCellButton" />
                        </div>
                    </b-tab>
                </b-tabs>
            </Splitpanes>
        </div>
    </div>
</template>
