<script setup>
import { ref, getCurrentInstance, onMounted, inject, } from 'vue';
import { useI18n, } from 'vue-i18n';
import { useRouter, } from 'vue-router';
import { Splitpanes, Pane, } from 'splitpanes';
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue';
import BoardMngPopup from './popup/BoardMngPopup.vue';
import { useStore, } from "vuex";

const store = useStore();
const vfmBasePopup = inject('vfmBasePopup');
const { t } = useI18n();
const router = useRouter();
const con = getCurrentInstance();
const search = {};

const BOARDLIST = ref();
const grids = {
    'BOARDLIST': BOARDLIST,
}
const exclefile = ref()

// 검색 조건 생성 
for (const [compName, { ARGS }] of Object.entries(ng_core.common.currentPageInfo)) {
    search[compName] = {};
    for (const { PARAMETER_NAME2 } of ARGS) {
        search[compName][PARAMETER_NAME2] = ref('');
    }
}

onMounted(() => {
    // Router Query : TABLENAME데이터 확인
    let board = 'EVENT';
    if (router.currentRoute.value.meta.data.MENU_TYPE == 'PAGE') {
        if (router.currentRoute.value.query?.board) {
            board = router.currentRoute.value.query.board;
        }
    } else {
        board = router.currentRoute.value.meta?.data.MENU_TYPE_REF ?? board;
    }
    search['BOARDLIST'].TABLENAME.value = board;
});

async function onSearch(compName) {
    const { PAGE_NO, PAGE_SIZE } = search[compName];
    PAGE_NO.value = -1;
    PAGE_SIZE.value = 20;
    await grids[compName].value.grdSearchRef(search[compName]);
}

async function onAddRow(compName) {
    await grids[compName].value.grdAddRow({}, 'last');
}

async function onRemoveRow(compName) {
    await grids[compName].value.grdDeleteRow();
}

async function onSave(compName) {
  await grids[compName].value.grdSave({ boardName: search[compName].TABLENAME.value });
}

async function onExcelDown(compName) {
  await grids[compName].value.grdExcelDown()
  // if(exclefile.value.files.length == 1) {
  //   const file = exclefile.value.files[0];
  //   grids[compName].value.grdExcelUpload(file)
  // }
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    if (ui.dataIndx == 'REPLE') {
        const paramsPopup = {
            modalName: 'BoardMngPopup',
            title: 'BoardMngPopup',
            width: '1000px !important',
            height: '500px !important',
            componentList: store.state.NGCore.currentPageInfo,
            targetREFCODEList: ['EVENT'], buttons: ['ok'],
            parentRowData: ui.rowData,
            tname: search['BOARDLIST'].TABLENAME.value,
        }
        await vfmBasePopup(BoardMngPopup, paramsPopup);
    }
}

</script>
<template>
    <div class="container-fluid">
      <div class="resultArea">
        <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize" :dbl-click-splitter="false">
          <!-- 조회 상단-->
          <Pane>
            <div style="width:100%; height:40px; overflow:auto;">
              <div class="parentCon">
                <div class="leftCon ml-1 mr-1">
                  <label for="input-none" class="conLabel">{{ t('LABEL.TABLENAME') }} :</label>
                  <input type="text" class="conInputText" v-model="search.BOARDLIST.TABLENAME.value" :placeholder="t('LABEL.TABLENAME')" @keyup.enter="onSearch('BOARDLIST')" :state="null" disabled>
                </div>
                <div class="leftCon ml-1 mr-1">
                  <label for="input-none" class="conLabel">{{ t('LABEL.SUBJECT') }} :</label>
                  <input type="text" class="conInputText" v-model="search.BOARDLIST.SUBJECT.value" :placeholder="t('LABEL.SUBJECT')" @keyup.enter="onSearch('BOARDLIST')" :state="null">
                </div>
                <div class="leftCon ml-1 mr-1">
                  <label for="input-none" class="conLabel">{{ t('LABEL.CONTENTS') }} :</label>
                  <input type="text" class="conInputText" v-model="search.BOARDLIST.CONTENTS.value" :placeholder="t('LABEL.CONTENTS')" @keyup.enter="onSearch('BOARDLIST')" :state="null">
                </div>
                <div class="leftCon ml-1 mr-1">
                  <label for="input-none" class="conLabel">{{ $t('LABEL.NOTICE_YN') }} :</label>
                  <select class="conInputText" v-model="search.BOARDLIST.NOTICE_YN.value" >
                    <option value="">전체</option>
                    <option value="Y">Y</option>
                    <option value="N">N</option>
                  </select>
                </div>
                <div class="rightCon">
                  [<input type="file" ref="exclefile" id="excelfile" />]
                  <button ref="read" @click="onSearch('BOARDLIST')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                  <button ref="plus" @click="onAddRow('BOARDLIST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                  <button ref="minus" @click="onRemoveRow('BOARDLIST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                  <button ref="save" @click="onSave('BOARDLIST')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                  <button ref="save" @click="onExcelDown('BOARDLIST')" :title="t('BUTTON.BUTTON00010')">{{ t('BUTTON.BUTTON00010') }}</button>
                </div>
              </div>
            </div>
            <div class="resultGrid" style="height:calc(100% - 40px);">
              <BaseGrid ref="BOARDLIST" :refCode="'BOARDLIST'" @CellButtonClick="onGrdCellButton" />
            </div>
          </Pane>
        </Splitpanes>
      </div>
    </div>
  </template>
<style>
</style>