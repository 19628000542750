<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'

const { t } = useI18n()
const vfmBasePopup = inject('vfmBasePopup')
const con = getCurrentInstance()
const search = {}
const menuAreaType = ref('adm')
const USER_INFO = ref()
const USER_AUTH_DTL_MENU = ref()
const USER_AUTH_DTL_GROUP = ref()
const USER_AUTH_DTL_GROUP_WO_GRP = ref()
const grids = {
    'USER_INFO': USER_INFO,
    'USER_AUTH_DTL_MENU': USER_AUTH_DTL_MENU,
    'USER_AUTH_DTL_GROUP': USER_AUTH_DTL_GROUP,
    'USER_AUTH_DTL_GROUP_WO_GRP': USER_AUTH_DTL_GROUP_WO_GRP,
}

// 검색 조건 생성 -> 최적의 반복문, 메소드를 호출하지 않고 변수만 참조한다.
const tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0, n=tmpkeys.length; i<n; i++) {
    const tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0, m=tmpData.ARGS.length; j<m; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

onMounted(() => {
    console.log('==== 컨포넌트 리스트 ====')
    let tmpGridKeys = Object.keys(ng_core.common.currentPageInfo)

    for (let i = 0, n=tmpGridKeys.length; i<n; i++) {
        console.log('** ' + tmpGridKeys[i] /**+ ' : ' + t('COMPONENT.' + tmpGridKeys[i])*/)
        let tmpSearchKeys = Object.keys(search[tmpGridKeys[i]])
        let tmpSearchColumn = ' -- Search : { '
        for (let j = 0, m=tmpSearchKeys.length; j<m; j++) {
            tmpSearchColumn += tmpSearchKeys[j] + ' '
        }
        tmpSearchColumn += '}'
        console.log(tmpSearchColumn)
    }
})

/*************************************************
 * Grid 관련 : Start
 *************************************************/
const onSearch = async compName => {
    if (compName.includes('USER_INFO') === false && grids['USER_INFO'].value.getRowSelectedIndex() === null) return
    for (let i = 0, n=compName.length; i<n; i++) {
        if (compName.includes('USER_INFO') === false && search[compName[i]].MENU_AREA_TYPE !== null) {
            search[compName[i]].MENU_AREA_TYPE.value = menuAreaType.value
        }
        await grids[compName[i]].value.grdSearchRef(search[compName[i]])
    }
}

/** 행 선택 라디오 */
const onRowClickRadio = async(ui, row) => {
    for (let i = 0, n=tabGrid.length; i<n; i++) {
        for (let j = 0, m=tabGrid[i].length; j<m; j++) {
            let tmpGrdNm = tabGrid[i][j]
            if (search[tmpGrdNm].USER_ID != null) {
                search[tmpGrdNm].USER_ID.value = row.USER_ID
            }
            if (search[tmpGrdNm].MENU_AREA_TYPE != null) {
                search[tmpGrdNm].MENU_AREA_TYPE.value = menuAreaType.value
            }
            await grids[tmpGrdNm].value.grdSearchRef(search[tmpGrdNm])
        }
    }
}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/
const tabIndex = ref(0)
const tabGrid = [
    ['USER_AUTH_DTL_MENU'],
    ['USER_AUTH_DTL_GROUP', 'USER_AUTH_DTL_GROUP_WO_GRP'],
]
/** 탭 이동 */
const activateTab = async(a, b, c) => {
    // 탭 페이지 리사이징
    await tabPageContentResize()
}
/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0, n=tmpGridKeys.length; i<n; i++) {
            grids[tmpGridKeys[i]].value.refresh()
        }
    });
}

/** 저장 **/
async function onSave(compName) {
    grids[compName].value.grdSave()
}

/** 대상을 왼쪽으로 이동 */
async function onLeftMove() {
    let tmpDataList = grids['USER_AUTH_DTL_GROUP_WO_GRP'].value.getGrdCheckList()
    if (tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return
    }
    let rtnComfirm = await vfmBasePopup(Comfirm, { state: 'info', contents: t('MSG.CHK_ADD') })
    if (rtnComfirm == false) return
    tmpDataList.map(function (row) {
        var tmp_row = Object.assign({}, row, {
            ROWRADIO: 'N',
            METHOD: 'A',
            ROWCHECK: 'N',
            USER_ID: grids['USER_INFO'].value.getRowSelectedData().USER_ID,

        })
        // 상위 정보를 같이 넘겨야 함****
        //self.$refs.USER_AUTH_DTL_GROUP.grdAddRowChild(tmp_row)       
        // treegrid 상위 처리 관계 없이 끝에 row추가
        grids['USER_AUTH_DTL_GROUP'].value.grdAddRow(tmp_row, 'last')
    })

    await grids['USER_AUTH_DTL_GROUP'].value.grdSave({ hideComfirm: 'Y'/**, hideMessageYN: 'Y', reloadYn: 'N' */ })

    //grids['VIEW_COMPONENT_DTL_HEADER'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
    grids['USER_AUTH_DTL_GROUP_WO_GRP'].value.grdSearchRef(null, null, { hideMessageYN: 'Y' })
}

/** 대상을 오른쪽으로 이동 */
async function onRightMove() {
    let tmpDataList = grids['USER_AUTH_DTL_GROUP'].value.getGrdCheckList()
    if (tmpDataList.length == 0) {
        await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0013') })
        return
    }

    let rtn = await grids['USER_AUTH_DTL_GROUP'].value.grdDeleteWithDB()
    if (rtn) {
        grids['USER_AUTH_DTL_GROUP'].value.grdSearchRef()
        grids['USER_AUTH_DTL_GROUP_WO_GRP'].value.grdSearchRef()
    } else {
        alert('???????')
    }
}

/*************************************************
 * Tab 관련 : End
 *************************************************/
</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('LABEL.USER_ID') }} :</label>
                                <input type="text" class="conInputText" v-model="search['USER_INFO'].USER_ID.value" :placeholder="t('LABEL.USER_ID')" 
                                @keyup.enter="onSearch(['USER_INFO'])" :state="null">
                                <label class="conLabel">&nbsp;&nbsp;{{ t('LABEL.MENU_AREA_TYPE') }} :</label> ADMIN<input type="radio" name="menu_area_type" value="adm" v-model="menuAreaType"
                                    @change="onSearch(['USER_AUTH_DTL_MENU', 'USER_AUTH_DTL_GROUP', 'USER_AUTH_DTL_GROUP_WO_GRP'])" /> USER<input type="radio" name="menu_area_type" value="user" v-model="menuAreaType"
                                    @change="onSearch(['USER_AUTH_DTL_MENU', 'USER_AUTH_DTL_GROUP', 'USER_AUTH_DTL_GROUP_WO_GRP'])" /> MOBILE<input type="radio" name="menu_area_type" value="mobile" v-model="menuAreaType"
                                    @change="onSearch(['USER_AUTH_DTL_MENU', 'USER_AUTH_DTL_GROUP', 'USER_AUTH_DTL_GROUP_WO_GRP'])" />
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch(['USER_INFO'])" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                            </div>
                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="USER_INFO" :refCode="'USER_INFO'" @RowClickRadio="onRowClickRadio" />
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                    <b-tabs ref="bottomTabs" small class="h-100" @activate-tab="activateTab">
                        <!-- Tab Page 01 : Start-->
                        <b-tab :title="$t('LABEL.MENU_AUTH_MANAGE')" active>
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                <div class="parentCon">
                                    <!-- <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('MENU_CODE') }}</label>
                                        <input type="text" class="conInputText" v-model="search['USER_AUTH_DTL_MENU'].MENU_CODE.value" :placeholder="$t('MENU_CODE')" 
                                            @keyup.enter="onSearch('USER_AUTH_DTL_MENU')" :state="null">
                                    </div>
                                    <div class="leftCon ml-1 mr-1">
                                        <label for="input-none" class="conLabel">{{ $t('MENU_NAME') }}</label>
                                        <input type="text" class="conInputText" v-model="search['USER_AUTH_DTL_MENU'].MENU_NAME.value" :placeholder="$t('MENU_NAME')"
                                            @keyup.enter="onSearch(['USER_AUTH_DTL_MENU'])" :state="null">

                                    </div> -->
                                    <div class="rightCon">
                                        <button ref="read" @click="onSearch(['USER_AUTH_DTL_MENU'])" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                                        <button ref="save" @click="onSave('USER_AUTH_DTL_MENU')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 80px);">
                                <BaseGrid ref="USER_AUTH_DTL_MENU" :refCode="'USER_AUTH_DTL_MENU'" />
                            </div>
                        </b-tab>
                        <!-- 그리드 영역 : End -->
                        <!-- 그룹권한관리-->
                        <b-tab :title="$t('LABEL.GROUP_AUTH_MANAGE')">
                            <div class="" style="height:100%; margin-bottom:10px">
                                <Splitpanes :orientation="'horizontal'" @resize="doResize">
                                    <Pane>
                                        <!-- grid 01 -->
                                        <div style="width:100%; height:40px; overflow:auto;">
                                            <div class="parentCon">
                                                <div class="rightCon">
                                                    <button @click="onLeftMove()" :title="t('BUTTON0014')"><font-awesome-icon icon="angle-left" /></button>
                                                    <button @click="onRightMove()" :title="t('BUTTON0015')"><font-awesome-icon icon="angle-right" /></button>
                                                    <button ref="save" @click="onSave('USER_AUTH_DTL_GROUP')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003') }}</button>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="resultGrid" style="height:calc(100% - 80px);">
                                            <BaseGrid ref='USER_AUTH_DTL_GROUP' :refCode="'USER_AUTH_DTL_GROUP'" />
                                        </div>
                                    </Pane>
                                    <Pane>
                                        <!-- grid 02 -->
                                        <div style="width:100%; height:40px; overflow:auto;">
                                            <div class="parentCon">
                                                <!-- <div class="leftCon ml-1 mr-1">
                                                    <label for="input-none" class="conLabel">{{$t('GROUP_CODE')}}</label>
                                                    <input type="text" class="conInputText" v-model="search['USER_AUTH_DTL_GROUP_WO_GRP'].GROUP_CODE.value" :placeholder="$t('GROUP_CODE')">
                                                </div>
                                                <div class="rightCon">
                                                </div> -->
                                            </div>
                                        </div>
                                        <div class="resultGrid" style="height:calc(100% - 80px);">
                                            <BaseGrid ref='USER_AUTH_DTL_GROUP_WO_GRP' :refCode="'USER_AUTH_DTL_GROUP_WO_GRP'" />
                                        </div>
                                    </Pane>
                                </Splitpanes>
                            </div>
                        </b-tab>
                    </b-tabs>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>

</style>