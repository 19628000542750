<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { useRouter } from 'vue-router'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()

const router = useRouter()
let con = getCurrentInstance()
let search = {}

const BOARDMSTMNG = ref()

let grids = {
    'BOARDMSTMNG': BOARDMSTMNG
}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

function onAddRow(compName) {
    grids[compName].value.grdAddRow({}, 'last')
}

function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

function onSave(compName) {
    grids[compName].value.grdSave()
    // let batch = [
    //     { 
    //         BATCH_CODE: 'SUM',
    //         DATA: {
    //             METHOD: 'R',
    //             SALES_DATE: '',
    //             NAME: '',
    //             TABLENAME: '',
    //         }
    //     }
    // ]
    // grids[compName].value.grdSave({}, batch)
}

/** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {
    const tablenameStr = ui.rowData.TABLENAME;
    router.push({path: '/adm/systemmng__boardmng__boardsmng', query: {board: tablenameStr}})
}

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.NAME')}} :</label>
                            <input type="text" class="conInputText" v-model="search['BOARDMSTMNG'].NAME.value" :placeholder="t('LABEL.NAME')"
                                    @keyup.enter="onSearch('BOARDMSTMNG')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.TABLENAME')}} :</label>
                            <input type="text" class="conInputText" v-model="search['BOARDMSTMNG'].TABLENAME.value" :placeholder="t('LABEL.TABLENAME')"
                                    @keyup.enter="onSearch('BOARDMSTMNG')" :state="null">
                        </div>
                        <div class="rightCon">
                            <button ref="read" @click="onSearch('BOARDMSTMNG')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            <button ref="plus" @click="onAddRow('BOARDMSTMNG')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                            <button ref="minus" @click="onRemoveRow('BOARDMSTMNG')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                            <button ref="save" @click="onSave('BOARDMSTMNG')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <BaseGrid ref="BOARDMSTMNG" :refCode="'BOARDMSTMNG'" @CellButtonClick="onGrdCellButton" />
                </div>
            </Pane>
            
        </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>