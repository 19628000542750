<script setup>
import { ref, getCurrentInstance, onMounted } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'

const { t } = useI18n()
const con = getCurrentInstance()
const search = {}
const USER = ref()

const grids = {
    'USER': USER,
}

// 검색 조건 생성 -> 최적의 반복문
const tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for(let i = 0, n=tmpkeys.length; i<n; i++) {
    const tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for(let j = 0, m=tmpData.ARGS.length; j<m; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

// argument 가 하나 일때 소괄호 생략가능
const onSearch = async compName => {
    await grids[compName].value.grdSearchRef(search[compName]);
}

const onAddRow = async compName => {
    await grids[compName].value.grdAddRow({}, 'last');
}

const onRemoveRow = async compName => {
    await grids[compName].value.grdDeleteRow()
}

const onSave = async compName => {
    await grids[compName].value.grdSave()
}

</script>

<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter"
                        horizontal
                        @resize="doResize" :dbl-click-splitter="false">
            <!-- 조회 상단-->
            <Pane>
                <div style="width:100%; height:40px; overflow:auto;">
                    <div class="parentCon">
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.USER_ID')}} :</label>
                            <input type="text" class="conInputText" v-model="search['USER'].USER_ID.value" :placeholder="t('LABEL.USER_ID')"
                                    @keyup.enter="onSearch('USER')" :state="null">
                        </div>
                        <div class="leftCon ml-1 mr-1">
                            <label for="input-none" class="conLabel">{{t('LABEL.USER_PW')}} :</label>
                            <input type="text" class="conInputText" v-model="search['USER'].USER_PW.value" :placeholder="t('LABEL.USER_PW')"
                                    @keyup.enter="onSearch('USER')" :state="null">
                        </div>
                        <div class="rightCon">
                            <button ref="read" @click="onSearch('USER')" :title="t('BUTTON.BUTTON0008')">{{t('BUTTON.BUTTON0008')}}</button>
                            <button ref="plus" @click="onAddRow('USER')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                            <button ref="minus" @click="onRemoveRow('USER')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                            <button ref="save" @click="onSave('USER')" :title="t('BUTTON.BUTTON0003')">{{t('BUTTON.BUTTON0003')}}</button>
                        </div>
                    </div>
                </div>
                <div class="resultGrid" style="height:calc(100% - 40px);">
                    <BaseGrid ref="USER" :refCode="'USER'"/>
                </div>
            </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>