<script setup>
import { ref, onMounted, computed, defineProps } from 'vue'
import { useStore } from "vuex";
const store = useStore()

const props = defineProps(['opened'])

let currentMessage = computed(() => {
    if(store.state.MQ.messages.length == 0) {
        return []
    } else {
        return store.state.MQ.messages
    }
})

let opened = ref(false)
let isNavbarOpen = computed(() => {
    return opened.value
})
function toggleNavbar() {
    opened.value = !opened.value
}

</script>

<template>
    <div id="statusbar" :class="{navbaropen: opened, sidebarclose: !opened}" class="statusbar">
      	<div :class="{ messageopen: opened, messageclose: !opened }" >
            <div v-for="msg in currentMessage.slice().reverse()" :key="msg">{{ '[ MENU : ' + msg.menu + ' ] ' + (msg.component ? '[ AREA : ' + msg.component + ' ] ' : '') + msg.message }}</div>
          </div>
        <button @click="toggleNavbar" style="width: 26px; height: 26px;line-height: normal;position: absolute; top: 2px; right: 3px;"><font-awesome-icon icon="arrow-up" font-size="9px" /></button>
    </div>
</template>


<style scoped>
.statusbar {
    position: relative;
    padding-right: 35px !important;
  }
  
  
  .statusbar.navbaropen {
    margin-top: -180px;
    height: 210px !important;
  }
  
  .statusbar.navbaropen .statusbar__button {
    transform: translateY(-100%);
  }
  
 
  
  .statusbar.sidebarclose {
    margin-top: 0;
    height: 30px;
  }
  
  .messageopen {
    overflow-y: auto;
    height: 210px;
    padding-right: 8px;
  }

  .messageclose {
    overflow-y: hidden;
    height: 30px;
  }

</style>