<script setup>

import { ref, defineProps, computed, defineEmits } from 'vue'
import { useI18n } from 'vue-i18n'
import { useStore } from "vuex";
import { useRouter } from 'vue-router'

const { t } = useI18n()
const router = useRouter()
const store = useStore()
const props = defineProps(['opened'])
const emit = defineEmits(['changeOpen'])

/** 유저 정보 */
let userInfo = computed(() => store.state.NGCore.userInfo)

let isLogin = ref(ng_core.common.isLogin() == 'Y' ? true : false)
let languageCode = ref(ng_core.common.getCurrentLang())

async function changeLang(lang) {
    languageCode.value = lang.toLowerCase();
    ng_core.common.setCurrentLang(languageCode.value)

    if (ng_core.common.isLogin() == 'Y') {

        var params = {
            METHOD_CODE: 'USER_LANG',
            SQLLOG_YN: 'Y',             // db log 남길지 유무
            paramsdata: {
                LANG: languageCode.value,
            }
        }
        try {
            let rtn = await store.dispatch('NGCore/saveSysData', params)
            router.go();
        } catch(err) {
            console.log('onClickMenuFavorite')
            console.dir(err)
        }
        
    }
}

function prfile() {
    router.push('/' + noligo_config.menuAreaType + '/profile')
}

function admin() {
    router.push('/' + noligo_config.menuAreaType)
}

async function logout() {
    let req = await store.dispatch('NGCore/logout')
    if(req.data.status) {
        router.replace('/login')
    }
}

function ChangeOpen() {
    emit('changeOpen', !props.opened)
}

</script>

<template>
    <div>
        <b-navbar toggleable="lg" type="dark" :class="{ navbaropen: props.opened, sidebarclose: !props.opened }">
            <b-navbar-brand href="#" @click="ChangeOpen">
                <font-awesome-icon icon="bars" :class="{ sideopen: props.opened }" class="th" />
            </b-navbar-brand>
            <span class="open-slide">
				<!-- <a href="#" @click="$emit('update:props.opened', props.opened = !props.opened)" >
					<font-awesome-icon icon="th" :class="{sideopen: props.opened}" class="th"/>
				</a> -->
			</span>
            <!-- <b-navbar-toggle target="nav-collapse"></b-navbar-toggle> -->
            <b-collapse id="nav-collapse" is-nav>
                <!-- <div style="position:absolute; top:0;"> -->
                <b-navbar-nav class="ml-auto" :class="{ navrightopen: props.opened }" right>
                    <!-- <b-nav-item>
						<span style="color:white;"><font-awesome-icon icon="user-circle"/>AAA님(개발 1팀 / 사원)</span>
					</b-nav-item> -->

                    <b-nav-item v-if="isLogin" >
                        <font-awesome-icon icon="user-circle" />{{ userInfo.NAME }}
                    </b-nav-item>
                    <b-nav-item-dropdown :text="languageCode.toUpperCase()" right v-if="isLogin">
                        <b-dropdown-item href="#" @click="changeLang('ko')">KO</b-dropdown-item>
                        <b-dropdown-item href="#" @click="changeLang('en')">EN</b-dropdown-item>
                        <!-- <b-dropdown-item href="#">JP</b-dropdown-item>
						<b-dropdown-item href="#">CN</b-dropdown-item> -->
                    </b-nav-item-dropdown>
                    <!-- <b-nav-item @click.prevent="logout">
						<font-awesome-icon icon="sign-out-alt"/>Log_out
					</b-nav-item> -->
                    <!-- <b-nav-item>
						<span style=""><font-awesome-icon icon="cog"/></span>
					</b-nav-item> -->
                    <b-nav-item-dropdown right>
                        <template #button-content >
                            <span style=""><font-awesome-icon icon="cog" /></span>
                        </template>
                        <b-dropdown-item href="#" v-if="!isLogin"
                            @click.prevent="login">{{ t('LABEL.LOGIN') }}</b-dropdown-item>

                        <b-dropdown-item href="#" v-if="isLogin"
                            @click.prevent="prfile">{{ t('LABEL.PROFILE') }}</b-dropdown-item>
                        <b-dropdown-item href="#" v-if="isLogin"
                            @click.prevent="logout">{{ t('LABEL.LOGOUT') }}</b-dropdown-item>
                    </b-nav-item-dropdown>
                </b-navbar-nav>
            </b-collapse>
        </b-navbar>
    </div>
</template>


