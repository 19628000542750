<script setup>
import { ref, getCurrentInstance, onMounted, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import { Splitpanes, Pane } from 'splitpanes'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import Comfirm from '@/components//popup/Comfirm.vue'
import DataSelectPopup from '@/components/popup/DataSelectPopup.vue'
import { useStore } from "vuex";

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
// const store = useStore()

let con = getCurrentInstance()
let search = {}

// 검색 조건 생성
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const TEMPLATE_MST = ref()
const TEMPLATE_DTL_GRID = ref()
let grids = {
    'TEMPLATE_MST': TEMPLATE_MST,
    'TEMPLATE_DTL_GRID': TEMPLATE_DTL_GRID,

}

/*************************************************
 * Grid 관련 : Start
 *************************************************/

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search[compName])
}

/** 행추가 */
async function onAddRow(compName) {
    let tmpData = {}
    if(grids['TEMPLATE_MST'].value.getRowSelectedData() === null){
            const tmpAlert = await vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0028') })
            return;
        }
    if(compName === 'TEMPLATE_DTL_GRID') {
        tmpData['TEMPLATE_IDX'] = grids['TEMPLATE_MST'].value.getRowSelectedData().TEMPLATE_IDX;
    }

    grids[compName].value.grdAddRow(tmpData);
}

/** 행 삭제 */
function onRemoveRow(compName) {
    grids[compName].value.grdDeleteRow()
}

/** 저장 */
function onSave(compName) {
    grids[compName].value.grdSave()
}

// /** 셀 버튼 이벤트 */
async function onGrdCellButton(sender, ui, event) {

}
function onGrd02InitFinish(sender, ui, event) {

}
/*************************************************
 * Grid 관련 : End
 *************************************************/

/*************************************************
 * Tab 관련 : Start
*************************************************/

/** 탭 이동 */
function activateTab(a, b, c) {
    // 탭 페이지 리사이징
    tabPageContentResize();
}

/** 탭 리사이즈 */
function tabPageContentResize() {
    window.requestAnimationFrame(function () {
        let tmpGridKeys = Object.keys(grids)
        for (let i = 0; i < tmpGridKeys.length; i++) {
            // grids[tmpGridKeys[i]].value.refresh()
        }
    });
}

/** 라디오버튼 */
function onRowClickRadio(ui, row) {
    search.TEMPLATE_DTL_GRID.TEMPLATE_IDX.value = row.TEMPLATE_IDX;
    onSearch('TEMPLATE_DTL_GRID');
}
/*************************************************
 * Tab 관련 : End
 *************************************************/

</script>
<template>
    <div class="container-fluid">
        <div class="resultArea">
            <Splitpanes ref="resultSplitter" class="default-theme resultSplitter" horizontal @resize="doResize"
                :dbl-click-splitter="false">
                <!-- 상단 : Start -->
                <Pane>
                    <!-- 검색 조건 : Start -->
                    <div style="width:100%; height:40px; overflow:auto;">
                        <div class="parentCon">
                            <div class="leftCon ml-1 mr-1">
                                <label for="input-none" class="conLabel">{{ t('LABEL.TEMPLATE_NAME') }} :</label>
                                <input type="text" class="conInputText" v-model="search['TEMPLATE_MST'].TEMPLATE_NAME.value" 
                                @keyup.enter="onSearch(['TEMPLATE_MST'])" :state="null">
                            </div>
                            <div class="rightCon">
                                <button ref="read" @click="onSearch('TEMPLATE_MST')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008')}}</button>
                                <button ref="plus" @click="onAddRow('TEMPLATE_MST')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                <button ref="minus" @click="onRemoveRow('TEMPLATE_MST')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                <button ref="save" @click="onSave('TEMPLATE_MST')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                            </div>
                        </div>
                    </div>
                    <!-- 검색 조건 : End -->
                    <!-- 그리드 영역 : Start -->
                    <div class="resultGrid" style="height:calc(100% - 40px);">
                        <BaseGrid ref="TEMPLATE_MST" :refCode="'TEMPLATE_MST'" @RowClickRadio="onRowClickRadio" />
                    </div>
                    <!-- 그리드 영역 : End -->
                </Pane>
                <!-- 상단 : End   -->
                <!-- 하단 : Start -->
                <Pane>
                    <!-- Tabs : Start -->
                        <!-- Tab Page 01 : Start-->
                            <!-- 검색 조건 : Start -->
                            <div style="width:100%; height:40px; overflow:auto;">
                                 <div class="parentCon">
                                    <div class="rightCon">
                                        <!-- <button ref="read" @click="onSearch('TEMPLATE_DTL_GRID')" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008')}}</button> -->
                                        <button ref="plus" @click="onAddRow('TEMPLATE_DTL_GRID')" :title="t('BUTTON0009')"><font-awesome-icon icon="plus" /></button>
                                        <button ref="minus" @click="onRemoveRow('TEMPLATE_DTL_GRID')" :title="t('BUTTON0010')"><font-awesome-icon icon="minus" /></button>
                                        <button ref="save" @click="onSave('TEMPLATE_DTL_GRID')" :title="t('BUTTON.BUTTON0003')">{{ t('BUTTON.BUTTON0003')}}</button>
                                    </div>
                                </div>
                            </div>
                            <!-- 검색 조건 : End -->
                            <!-- 그리드 영역 : Start -->
                            <div class="resultGrid" style="height:calc(100% - 40px);">
                                <BaseGrid ref="TEMPLATE_DTL_GRID" :refCode="'TEMPLATE_DTL_GRID'" />
                            </div>
                </Pane>
            </Splitpanes>
        </div>
    </div>
</template>
<style>
</style>