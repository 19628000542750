import { ref, defineProps } from 'vue'
export default function BaseGrid(tmpRefCode) {
    /** 그리드 참조 코드 */
    let refCode = tmpRefCode

    /** 컨포넌트 데이터 */
    let baseComponentData = ng_core.common.currentPageInfo[refCode]

    ///// 아래 데이터 부분은 ref 처리를 해야 될지=도 모름
    /** 현재 메뉴 코드 */
    let menuCode = baseComponentData == null ? null : baseComponentData.MENU_CODE

    /** 그리드 타입 */
    let gridType = baseComponentData == null ? null : baseComponentData.COMPONENT_TYPE

    $.datepicker.setDefaults({
        dateFormat: 'yymmdd',
        prevText: '이전 달',
        nextText: '다음 달',
        monthNames: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        monthNamesShort: ['1월', '2월', '3월', '4월', '5월', '6월', '7월', '8월', '9월', '10월', '11월', '12월'],
        dayNames: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesShort: ['일', '월', '화', '수', '목', '금', '토'],
        dayNamesMin: ['일', '월', '화', '수', '목', '금', '토'],
        showMonthAfterYear: true,
        yearSuffix: '년'
    });

    // 추후에 언어팩 적용해야함~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~~
    $.paramquery.pqGrid.regional['kr'] = {
        strAdd: "Add",
        strDelete: "Delete",        
        strEdit: "Edit",
        //used in row grouping.
        strGroup_header: "Drag a column here to group by that column",
        strGroup_merge: 'Merge cells',
        strGroup_fixCols: 'Fix columns',
        strGroup_grandSummary: 'Grand summary',
        strLoading: "Loading",
        strNextResult: "Next Result",        
        strNoRows: "데이터 없음.",        
        strNothingFound: "Nothing found",
        strPrevResult: "Previous Result",
        strSearch: "Search",
        strSelectedmatches: "Selected {0} of {1} match(es)",
        //used in toolPanel
        strTP_aggPane: "Aggregates",
        strTP_colPane: 'Group columns',
        strTP_pivot: "Pivot mode",
        strTP_rowPane: "Group rows",
        strTP_aggPH: "Drop columns for computing aggregate values",
        strTP_colPH: "Drop columns here for grouping along column or x axis",
        strTP_rowPH: "Drop columns here for grouping along row or y axis"
    };
    $.paramquery.pqPager.regional['kr'] = {
        strDisplay:"Displaying {0} to {1} of {2} items.",       
        strFirstPage:"First Page",
        strLastPage:"Last Page",
        strNextPage:"Next Page",        
        strPage:"Page {0} of {1}",
        strPrevPage:"Previous Page",    
        strRefresh:"Refresh",   
        strRpp:"Records per page: {0}" 
    }; 			


    return {
        refCode,
        menuCode,
        gridType,
        baseComponentData,
    }
}