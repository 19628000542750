<script setup>
import { ref, onMounted } from 'vue';
// import { dia, shapes, paper, mvc, util, V } from 'jointjs';
import * as joint from 'jointjs';

const paperContainer = ref(null);
var paper = null
var svg = null

onMounted(() => {
	const graph = new joint.dia.Graph({}, { cellNamespace: joint.shapes });
	paper = new joint.dia.Paper({
		model: graph,
		cellViewNamespace: joint.shapes,
		width: "100%",
		height: "100%",
		gridSize: 20,
		drawGrid: { name: "mesh" },
		async: true,
		sorting: joint.dia.Paper.sorting.APPROX,
		background: { color: "#aaaaaa" },

		linkPinning: false, // Prevent link being dropped in blank paper area
		markAvailable: true,
		highlighting: {
			'magnetAvailability': {
				name: 'addClass',
				options: {
					className: 'custom-available-magnet'
				}
			},
			'elementAvailability': {
				name: 'stroke',
				options: {
					padding: 20,
					attrs: {
						'stroke-width': 3,
						'stroke': '#ED6A5A'
					}
				}
			}
		},
		defaultLink: () => new joint.shapes.standard.Link({
			attrs: {
				wrapper: {
					cursor: 'default'
				}
			}
		}),
		defaultConnectionPoint: { name: 'boundary' },
		validateConnection: function (cellViewS, magnetS, cellViewT, magnetT, end, linkView) {
			// Prevent loop linking
			if (magnetS == magnetT) return false
			if (cellViewS == cellViewT) return false
			if (magnetT == null) return false
			return true;
		}
	});
	// Register events
	paper.on('link:mouseenter', (linkView) => {
		showLinkTools(linkView);
	});

	paper.on('link:mouseleave', (linkView) => {
		linkView.removeTools();
	});


	svg = paper.svg

	paperContainer.value.appendChild(paper.el);

	enableVirtualRendering(paper);


	const customShape4 = new Shape();
	customShape4
		.set('image', 'https://via.placeholder.com/150/FF0000')
		.set('label', 'Lorem ipsum dolor sit amet,\nconsectetur adipiscing elit.\nInteger vehicula.')
		.set('outlineColor', 'red')
		.set('gridType', 'grid01')
		.position(200, 50)
		.prop('fillColor', 'lightgray')
		.addTo(graph);

	const customShape42 = new Shape();
	customShape42
		.set('image', 'https://via.placeholder.com/150/FF0000')
		.set('label', 'Lorem ipsum dolor sit amet,\nconsectetur adipiscing elit.\nInteger vehicula.')
		.set('outlineColor', 'red')
		.set('gridType', 'grid02')
		.position(300, 150)
		.prop('fillColor', 'lightgray')
		.addTo(graph);



});

function showLinkTools(linkView) {
	var tools = new joint.dia.ToolsView({
		tools: [
			new joint.linkTools.Remove({
				distance: '50%',
				markup: [{
					tagName: 'circle',
					selector: 'button',
					attributes: {
						'r': 7,
						'fill': '#f6f6f6',
						'stroke': '#ff5148',
						'stroke-width': 2,
						'cursor': 'pointer'
					}
				}, {
					tagName: 'path',
					selector: 'icon',
					attributes: {
						'd': 'M -3 -3 3 3 M -3 3 3 -3',
						'fill': 'none',
						'stroke': '#ff5148',
						'stroke-width': 2,
						'pointer-events': 'none'
					}
				}]
			})
		]
	});
	linkView.addTools(tools);
}

class Shape extends joint.dia.Element {
	defaults() {
		return {
			...super.defaults,
			type: 'custom.Shape',
			fillColor: 'red',
			outlineColor: 'blue',
			gridType: '',
			label: '',
			image: '',
		};
	}

	preinitialize() {
		this.spacing = 10;
		this.elementAttributes = {
			rx: 1,
			ry: 1,
		}

		this.labelAttributes = {
			'font-size': 14,
			'font-family': 'sans-serif',
		};
		this.imageAttributes = {
			'width': 50,
			'height': 50,
			'preserveAspectRatio': 'none',
		};
		this.cache = {};
	}

	initialize() {
		super.initialize();
		this.on('change', this.onAttributeChange);
		this.setSizeFromContent();
	}

	/* Attributes that affects the size of the model. */
	onAttributeChange() {
		const { changed, cache } = this;
		if ('label' in changed) {
			// invalidate the cache only if the text of the `label` has changed
			delete cache.label;
		}
		if ('label' in changed || 'image' in changed) {
			this.setSizeFromContent();
		}
	}

	setSizeFromContent() {
		delete this.cache.layout;
		const { width, height } = this.layout();
		this.resize(width, height);
	}

	layout() {
		const { cache } = this;
		let { layout } = cache;
		if (layout) {
			return layout;
		} else {
			const layout = this.calcLayout();
			cache.layout = layout;
			return layout;
		}
	}

	calcLayout() {
		const { attributes, labelAttributes, imageAttributes, spacing, cache } = this;
		let width = spacing * 2;
		let height = spacing * 2;
		let x = spacing;
		let y = spacing;

		// Add a rect element
		const rect = joint.V('rect').attr({
			x,
			y,
			width: 100, // Set the width of the rect
			height: 50, // Set the height of the rect
			fill: 'blue', // Set the fill color of the rect
		});
		rect.appendTo(svg); // Append the rect to the SVG document

		// image metrics
		let $image;
		if (attributes.image) {
			const {
				width: w,
				height: h
			} = imageAttributes;
			$image = {
				x,
				y,
				width: w,
				height: h
			};
			height += spacing + h;
			y += spacing + h;
			width += w;
		} else {
			$image = null;
		}
		// label metrics
		let $label; {
			let w, h;
			if ('label' in cache) {
				w = cache.label.width;
				h = cache.label.height;
			} else {
				const {
					width,
					height
				} = measureText(svg, attributes.label, labelAttributes);
				w = width;
				h = height;
				cache.label = {
					width,
					height
				};
			}
			width = Math.max(width, spacing + w + spacing);
			height += h;
			if (!h) {
				// no text
				height -= spacing;
			}
			$label = {
				x,
				y,
				width: w,
				height: h
			};
		}
		// root metrics
		return {
			x: 0,
			y: 0,
			width,
			height,
			$image,
			$label,
			$rect: rect, // Include the rect in the layout object
		};
	}
}


function measureText(svgDocument, text, attrs) {
	const vText = joint.V('text').attr(attrs).text(text);
	vText.appendTo(svgDocument);
	const bbox = vText.getBBox();
	vText.remove();
	return bbox;
}

const ElementView = joint.dia.ElementView;

const ShapeView = ElementView.extend({

	// pointerdblclick: function(evt, x, y) {
	// 	debugger
	// 	this.model.remove();
	// },

	// pointerdown: function(evt, x, y) {
	// 	debugger
	// },

	presentationAttributes: ElementView.addPresentationAttributes({
		// attributes that changes the position and size of the DOM elements
		label: [ElementView.Flags.UPDATE],
		image: [ElementView.Flags.UPDATE],
		// attributes that do not affect the size
		outlineColor: ['@color'],
		fillColor: ['@color'],
	}),


	confirmUpdate: function (...args) {
		let flags = ElementView.prototype.confirmUpdate.call(this, ...args);
		if (this.hasFlag(flags, '@color')) {
			// if only a color is changed, no need to resize the DOM elements
			this.updateColors();
			flags = this.removeFlag(flags, '@color');
		}
		// must return 0
		return flags;
	},

	// Other Methods
	/* Runs only once while initializing */
	render: function () {
		const { vel, model } = this;

		const body = this.vBody = joint.V('rect').addClass('body').attr(model.elementAttributes);
		const label = this.vLabel = joint.V('text').addClass('label').attr(model.labelAttributes);
		this.vImage = joint.V('image').addClass('image').attr(model.imageAttributes);
		// Add a rect element
		const rect = joint.V('rect').attr({
			x: 50, // Set the x position of the rect
			y: 50, // Set the y position of the rect
			width: 100, // Set the width of the rect
			height: 50, // Set the height of the rect
			fill: 'blue', // Set the fill color of the rect
			cursor: 'move', // Set the cursor style to 'move' for dragging
			magnet: true, // Enable magnet to allow link connections
		});

		vel.empty().append([
			body,
			label,
			rect, // Append the rect to the view's element
		]);
		this.update();
		this.updateColors();
		this.translate(); // default element translate method
	},

	update: function () {
		const layout = this.model.layout();
		this.updateBody();
		this.updateImage(layout.$image);
		this.updateLabel(layout.$label);
	},

	updateColors: function () {
		const { model, vBody } = this;
		vBody.attr({
			fill: model.get('fillColor'),
			stroke: model.get('outlineColor')
		});
	},

	updateBody: function () {
		const { model, vBody } = this;
		const { width, height } = model.size();
		const bodyAttributes = { width, height };
		vBody.attr(bodyAttributes);
	},

	updateImage: function ($image) {
		const { model, vImage, vel } = this;
		const image = model.get('image');
		if (image) {
			if (!vImage.parent()) {
				vel.append(vImage);
			}
			vImage.attr({
				'xlink:href': image,
				x: $image.x,
				y: $image.y
			});

		} else {
			vImage.remove();
		}
	},

	updateLabel: function ($label) {
		const { model, vLabel } = this;
		vLabel.attr({
			'text-anchor': 'middle',
			x: $label.x + $label.width / 2,
			y: $label.y + $label.height / 2
		});
		vLabel.text(model.get('label'), {
			textVerticalAnchor: 'middle'
		});
	}
});

joint.shapes.custom = {
	Shape,
	ShapeView
};

function enableVirtualRendering(paper) {
	const paperContainer = paper.el.parentNode;

	let viewportArea;
	function updateViewportArea() {
		viewportArea = paper.clientToLocalRect(
			paperContainer.getBoundingClientRect()
		);
	}

	// Setup listeners
	updateViewportArea();
	paperContainer.addEventListener("scroll", updateViewportArea, false);
	paper.on("scale", updateViewportArea);

	// Paper `viewport` option
	// https://resources.jointjs.com/docs/jointjs/#dia.Paper.prototype.options.viewport
	paper.options.viewport = (view) => {
		const { model } = view;
		// Hide elements and links which are not in the viewport.
		const bbox = model.getBBox();
		if (model.isLink()) {
			// Vertical/horizontal links have zero width/height.
			bbox.width += 1;
			bbox.height += 1;
		}
		return viewportArea.intersect(bbox) !== null;
	};
}

</script>

<template>
	<div class="container-fluid">
		<div class="resultArea">
			<div ref="paperContainer" style="height:100%; width:100%"></div>


			<div id="inspector-container">
				<div class="custom-fields"></div>
			</div>
			<div id="toolbar-container"></div>
		</div>
	</div>
</template>

<style scoped>
.container-fluid {
	height: 100vh;
}

.resultArea {
	display: flex;
	height: 100%;
}

.paper-wrapper {
	display: flex;
	flex-grow: 1;
	position: relative;
}

.paper-container {
	flex-grow: 1;
}

#inspector-container {
	width: 250px;
	background-color: #ffffff;
}

/* port styling */
.available-magnet {
	fill: #5DA271;
}

/* element styling */
.available-cell rect {
	stroke-dasharray: 5, 2;
}
</style>