<script setup>

import { ref } from 'vue'
import { defineProps, defineEmits, onMounted, computed, nextTick, inject } from 'vue'
import { useI18n } from 'vue-i18n'
import BaseGrid from '@/components/grid/pqgrid/PQGridCon.vue'
import Alert from '@/components/popup/Alert.vue'
import pq from 'pqgrid';
import { VueFinalModal } from 'vue-final-modal'

const vfmBasePopup = inject('vfmBasePopup')
const { t } = useI18n()
const props = defineProps(['modalName', 'title', 'popupSize', 'grid', 'componentList', 'targetREFCODEList', 'width', 'height', 'api', 'parentRowData', 'tname', 'groupCode'])
const emit = defineEmits(['confirm', 'cancel'])
const modalRef = ref(null)

let search = {}
search['GROUP_CODE'] = ref('');
//FONT_AWSOME_ICON
// 사이즈
let modalSize = computed(() => {
    let styles = {
    }
    if ((props.width || '') != '') styles['width'] = props.width
    if ((props.height || '') != '') styles['height'] = props.height

    return styles
})

// 검색 조건 생성
// let tmpkeys = [props.grid]
// for (let i = 0; i < tmpkeys.length; i++) {
//     let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
//     search[tmpkeys[i]] = {}
//     for (let j = 0; j < tmpData.ARGS.length; j++) {
//         search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
//     }
// }
let tmpkeys = Object.keys(ng_core.common.currentPageInfo)
for (let i = 0; i < tmpkeys.length; i++) {
    let tmpData = ng_core.common.currentPageInfo[tmpkeys[i]]
    search[tmpkeys[i]] = {}
    for (let j = 0; j < tmpData.ARGS.length; j++) {
        search[tmpkeys[i]][tmpData.ARGS[j].PARAMETER_NAME2] = ref('')
    }
}

const grid = ref()
let grids = {

    'COMMON_CODE_DTL': grid,
}

let cClose;
let gridHand;

onMounted(() => {
    search['COMMON_CODE_DTL'].GROUP_CODE.value = 'FONT_AWSOME_ICON'
    grids[props.grid] = grid

    setTimeout(() => {
        onSearch('COMMON_CODE_DTL')
    }, 200);
});

function onCellDbClick(sender, event, ui) {
    emit('confirm', ui.rowData)
}

/** 조회 */
function onSearch(compName) {
    grids[compName].value.grdSearchRef(search['COMMON_CODE_DTL'])
}
let menuCodeIdx = 0

/** OK 버튼 */
function onConfirm() {
    let tmpSctData = grid.value.getRowSelectedData()
    if (!tmpSctData) {
        vfmBasePopup(Alert, { state: 'info', contents: t('MSG.MSG0009') })
    } else {
        emit('confirm', tmpSctData)
    }
}

/** 취소 버튼 */
function onCancel() {
    emit('cancel', false)
}

/** 행 선택 라디오 */
function onRowClickRadio(ui, row) {

search.COMMON_CODE_DTL.VIEW_CODE.value = row.VIEW_CODE
onSearch('VIEW_COMPONENT_MST')
}
const vfmref = ref()        // 테스트 필요함

</script>

<template>
    <!-- Modal -->
    <vue-final-modal 
        class="flex justify-center items-center modal-container"
        content-class="flex flex-col max-w-xl mx-4 p-4 bg-white dark:bg-gray-900 border dark:border-gray-700 rounded-lg space-y-2 modal-content2"
            
        :drag="(props.drag || '') == '' ? true : drag" drag-selector=".border-bottom"
        :click-to-close="false" transition="" overlay-transition="" >
        <div class="topbar border-bottom">
            <button class="btn-close modal__close" @click="onCancel()"></button>
            <span class="modal__title">{{ props.title }}</span>
        </div>
        <div class="modal__content" :style="modalSize">
            <div style="width:100%; height:40px; overflow:auto;">
                <div class="searchSplitCon parentCon">
                    <div class="leftCon ml-1 mr-1">
                        <label for="input-none" class="conLabel">{{ t('LABEL.CODE') }} :</label>
                        <input type="text" class="conInputText" v-model="search['COMMON_CODE_DTL'].CODE.value" :placeholder="t('LABEL.CODE')" @keyup.enter="onSearch(['COMMON_CODE_DTL'])"
                            :state="null">
                    </div>
                    <div class="rightCon">
                        <button ref="read" @click="onSearch(props.grid)" :title="t('BUTTON.BUTTON0008')">{{ t('BUTTON.BUTTON0008') }}</button>
                    </div>
                </div>
            </div>
            <div class="resultGrid" style="height:calc(100% - 50px);">
                <BaseGrid ref='grid' :refCode="'COMMON_CODE_DTL'" @rowclick="onGrdRowClick" @CellDblClick="onCellDbClick" />
            </div>
        </div>
        <div class="modal__action">
            <button type="button" class="btn btn-primary" @click="onConfirm()">{{ t('BUTTON.BUTTON0001') }}</button>
            <button type="button" class="btn btn-secondary" @click="onCancel()">{{t('BUTTON.BUTTON0002') }}</button>
        </div>
    </vue-final-modal>

</template>

<style >
.flex {
    display: grid !important;
}
.vfm--inset {
    bottom: 0 !important;
    left: 0 !important;
    right: 0 !important;
    top: 0 !important;
}
.vfm--fixed {
    position: fixed !important;
}
.justify-center {
    justify-content: center !important;
}
.items-center {
    align-items: center !important;
}
/*
*, :after, :before {
    border: 0 solid #e5e7eb !important;
    box-sizing: border-box !important;
}
*/
</style>
<style >


.modal__content {
    flex-grow: 1;
    overflow-y: auto;
    padding: 5px;
}

.modal__action {
    display: flex;
    justify-content: center;
    align-items: center;
    flex-shrink: 0;
}

.modal__close {
    top: 0.5rem;
    right: 0.5rem;
}

</style>
<!-- 
<style scoped>
.dark-mode div::v-deep .modal-content2 {
    border-color: #2d3748;
    background-color: #1a202c;
}
</style> -->